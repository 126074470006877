import React  from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useCart } from "../../context/cartContext";
import { Link } from 'react-router-dom';
import { useAuth } from '../../authContext';
import { getSearchResult } from '../../api/searchApi';
import {getProductsSearch, images_products_route} from "../../api/productApi";
import { ProductComponent } from '../../components/productComponent';
import { useApi } from '../../context/apiContext';
import RecipeComponent from '../../components/recipeComponent';

export const SearchPage = () => {

    const { getTextByKey, getTextBigByKey, currentLocale } = useApi();
    const { currentUser } = useAuth();
    
    const { addToCart, showCart, checkIfExistInCart,tiendaGeneral } = useCart();
    const { search:s } = useLocation();
    const search = new URLSearchParams(s).get('query');
    const [products, setProducts] = React.useState(false);
    const [recipes, setRecipes] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [show, setShow] = React.useState({recipes: false, products: false});
    const OBJECTS_TO_SHOW = 8;

    useEffect(() => {
   
        if(currentUser){
          if(currentUser.isAnonymous){ 
            if (!tiendaGeneral) {
              location.href = "/checkout-a";   
            }
           
          }else{
            if (!tiendaGeneral) {
              location.href = "/direccion-envio";
            }
          }
        }else{
          location.href = "/checkout-a";
        }
      
      },[currentUser,tiendaGeneral])

    React.useEffect(() => {
        searchProducts(search)
    }, [search,tiendaGeneral])

    const toggleShow = (componentName, state) => {
        setShow(show => ({...show, [componentName]: state}));
    }

    const searchProducts = async (search) => {
        setLoading(true);
        const resultsSearch = await getProductsSearch( search,tiendaGeneral );
        if( resultsSearch ) {
            setProducts(resultsSearch.products);
            setRecipes(resultsSearch.recipes);
        } else {
            setProducts( [] );
            setRecipes([]);
        }
        setLoading(false);
    }


    let productsToShow = [];
    if (products.productos) {
        productsToShow = show.products ? products : products.productos.slice(0, OBJECTS_TO_SHOW);
    }

    let recipesToShow = [];
    if (recipes) {
        recipesToShow = show.recipes ? recipes : recipes.slice(0, OBJECTS_TO_SHOW);
    }

		if(loading)
		return (
		<div className='container'>
				<div className="text-center-c">
						<div className="loader-box">
								<img src="/img/SVG/loader.svg" alt="" />
						</div>
				</div>
		</div>
		);


    return (
    <>
      <div className="container my-5">    
            <div className='productos gray-1-color'>
                <h3>{getTextByKey("productos")}</h3>
                <div className='row'>
                {productsToShow.length > 0 ? (
                        <>
                            {productsToShow.map((product, index) => <ProductComponent key={index} product={product} className="col-12 col-md-2 col-lg-3" />)}
                        </>
                    ) : (
                        <div className="col-12 my-5 text-center-c mb-5">
                            <br /><br />
                            <strong>{getTextByKey("no_results")}</strong>
                            <br /><br />
                            <hr />
                        </div>
                    )
                  }
                </div>
                {/*<h3 className="mt-5">{getTextByKey("recetas")}</h3>
                <div className="row">
                    {recipesToShow.length > 0 ? (
                        <>
                            {recipesToShow.map((recipe) => (
                                <div key={recipe.id} className="col-12 col-md-6 col-lg-4">
                                    <RecipeComponent recipe={recipe} className="col-12 col-md-2 col-lg-3" />
                                </div>
                            ))}
                        </>
                    ) : (
                        <div className="col-12 my-5 text-center-c">
                            <br /><br />
                            <strong>{getTextByKey("no_results")}</strong>
                            <br /><br />
                            <hr />
                        </div>
                    )
                    }
                </div>*/}
            </div>
      </div>

    </>
  )
}
