import { Fragment, useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from '../../authContext';
import ModalLogUserComponent from "../../components/ModalLogUserComponent";
import { useApi } from "../../context/apiContext";
import { getBlogs } from "../../api/blogApi";
import { images_products_route } from "../../api/productApi";
import StyleText from "../../scss/text.module.scss";

import '../../scss/blog_page.scss';
import {createDateFormatTr, createDatetime} from "../../helpers/dateHelper";
import NewsComponent from "../../components/NewsComponent";
import TittleH1 from "../../components/TittleH1Component";
import Pagination from "../../components/PaginationComponent";
import {usePage} from "../../hooks/usePage";

const MAX_ITEMS_PER_PAGE = 3;

export default function BlogPage() {
    const { getTextByKey, getTextBigByKey, getPropiertyByLocale, currentLocale } = useApi();
    const {changePage, page, pages, setPages} = usePage(1);

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getBlogs({ locale: currentLocale, limit: MAX_ITEMS_PER_PAGE, page })
            .then(({list, pages: pages_}) => {
            setPosts(list);
            setPages(pages_);
            setLoading(false);
        });
    }, [page])


    return (
        <Fragment>
            <div className="blog my-lg-5 my-4 pb-5">
                <div className="container px-lg-0 px-4">
                    <div className="text-center mb-3 mb-lg-5">
                        <TittleH1 styleGroup={`quaternary-color text-center autography fw-bold py-lg-4 py-0 mb-lg-4 mb-3 ${StyleText.h4Special}`}>
                            {getTextByKey('titulo_noticias')}
                        </TittleH1>
                        <div className={StyleText.paragraph} dangerouslySetInnerHTML={{ __html: getTextBigByKey('texto_noticias')}}></div>
                    </div>
                    {
                        !loading && (
                            <div className="row">
                                {posts.map((post) => {
                                    const fullTime = createDateFormatTr(post.created_at.date, getTextByKey)
                                    // console.log("fullTime: " + fullTime);
                                    return (
                                        <div className="col-12 col-md-6 col-lg-4 px-2 mb-4" key={post.id}>
                                            {
                                                post.BlogPostImages.length &&
                                                <NewsComponent
                                                    img={post.BlogPostImages[0].coreImage.image}
                                                    title={post.translations[0].title}
                                                    date={fullTime}
                                                    link={`/noticia/${post.id}`}>
                                                    <div className={StyleText.paragraph} dangerouslySetInnerHTML={{ __html: post.translations[0].content.slice(0, 200)+'...' }}></div>
                                                </NewsComponent>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                    <div className="text-center mt-5 pb-2">
                        <Pagination
                            pages={pages}
                            currentPage={page}
                            onPageChange={changePage}
                            onNext={ () => {changePage( page + 1 )} }
                            onPrevious={ () => {changePage( page - 1 )} }
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
