import { useState, useEffect } from "react";
import { Fragment } from "react";
import { getGallery } from "../../api/galleryApi";
import { useApi } from "../../context/apiContext";


export default function FormulariocontactoPage() {
    const [error, seterror] = useState('')
    const [succesfull, setsuccesfull] = useState('')
    const [listadoHelpYou, setListadoHelpYou] = useState([])
    const { getTextByKey, getTextBigByKey, currentLocale } = useApi();


    useEffect(() => {
        getGallery("options-help-you").then((data) => {
            const listadoAux = [];
            data.forEach(element => {
                element.names.forEach( content => {
                    if( content.locale === currentLocale ) {
                        listadoAux.push(content.titulo)
                    }
                })
            });
            setListadoHelpYou( listadoAux );
        });
    }, [currentLocale])

    const handleSubmitForm = async (e) => {
        seterror('');        
        setsuccesfull('');        
        const { fullname, email, number_phone, help_you, message } = e.target.elements;
        e.preventDefault();

        //Validate the form
        if (fullname.value === "" || email.value === "" || number_phone.value === "" || message.value === "") {
            seterror(getTextByKey('error-all-fields-form'));
            return;
        }

        //Validate the email format
        if (!email.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            seterror(getTextByKey('phone-invalid-form'));
            return;
        }

        //Validate the phone format
        if (!number_phone.value.match(/^[0-9]{10}$/)) {
            seterror(getTextByKey('error-email-invalid-form'));
            return;
        }

        const messageData = {
            fullname: fullname.value,
            email: email.value,
            number_phone: number_phone.value,
            help_you: help_you.value,
            message: message.value
        }

        Object.keys(messageData).forEach((data) => {
            e.target.elements[data].value = '';
            messageData[data] = '';
            console.log(e.target.elements[data].value);
        })
        setsuccesfull(getTextByKey('succesfull-email-message'))
        setTimeout(() => {
            setsuccesfull('')
        }, 5000);
        
    }


    return (
        <Fragment>

            <div className="banner contactanos position-relative mb-2 mb-md-5">
                <img className="w-100 fondo" src="img/group-141.jpg" />
                <div className="content-banner">
                    <div className="container">
                        <h2 className="text-uppercase text-center secundary-color mb-4">{getTextByKey('title-banner-contactanos-page')}</h2>
                        <h3 className="secundary-color text-center px-5" dangerouslySetInnerHTML={{ __html: getTextBigByKey("desc-banner-contactanos-page") }}></h3>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <form onSubmit={handleSubmitForm}>
                    <div className="row">
                        <div className="col-12 form-group mb-4">
                            <h2 className="text-center">{getTextByKey('title-form-contactanos-page')}*</h2>
                        </div>
                        {succesfull ? <div className='succesfull-msg text-center mb-4'>{succesfull}</div> : null}
                        <div className="col-12 col-lg-5 offset-lg-1 form-group mb-3">
                            <label>{getTextByKey('fullname-form-contactanos-page')}*</label>
                            <input type="text" className="p-2" name="fullname" />
                        </div>
                        <div className="col-12 col-lg-5 form-group mb-3">
                            <label>{getTextByKey('email-form-contactanos-page')}*</label>
                            <input type="email" className="p-2" name="email" />
                        </div>
                        <div className="col-12 col-lg-5 offset-lg-1 form-group mb-3">
                            <label>{getTextByKey('number-phone-form-contactanos-page')}*</label>
                            <input type="tel" className="p-2" name="number_phone" />
                        </div>
                        <div className="col-12 col-lg-5 form-group mb-3">
                            <label>{getTextByKey('help-form-contactanos-page')}*</label>
                            <select className="p-2" name="help_you">
                                <option value='N/A'>{ currentLocale === 'es' ? 'Seleccione una opcion' : 'Select a option' }</option>
                                {
                                    listadoHelpYou.map( (option, index) => (<option value={option} key={index}>{option}</option> ))
                                }
                            </select>
                        </div>
                        <div className="col-12 col-lg-10 offset-lg-1 form-group mb-3">
                            <label className="mb-1">{getTextByKey('message-form-contactanos-page')}*</label>
                            <textarea className="p-2" name="message"></textarea>
                        </div>
                        {error ? <div className='error-msg'>{error}</div> : null}
                        <div className="col-12 form-group mb-4 text-center">
                            <button className="btn-g py-2 px-4 text-uppercase">{getTextByKey('btn-send-form-contactanos-page')}</button>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}
