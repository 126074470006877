import React from 'react'
import { useState } from 'react';
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { default as ModalCompraRecoger } from './ModalCompraRecoger';
import { default as ModalDireccionEnvio } from './ModalDireccionEnvio';



export default function ModalCompra({ showModalCompra, setShowModalCompra }) {

    const { getTextByKey, getTextBigByKey } = useApi();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowModalCompra(false);
        gotoTop();
    }

    const [showModalRecoger, setShowModalRecoger] = useState(false);
    const [showModalDireccion, setShowModalDireccion ] = useState(false);

    const handleOpenModal = () => {
      setShowModalCompra(false);
      setShowModalRecoger(true);

    }

    const handleOpenEnvio = () => {
      setShowModalCompra(false);
      setShowModalDireccion(true);
    }

    return (
        <Fragment>
            <Modal className="modal-compra" show={showModalCompra} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-color' icon="bi:x-lg" onClick={handleClose}></Icon>
                    </div>
                    <div className="text-center">
                      <h4 className='gray-1-color'>{getTextByKey('modal-compra-titulo')}</h4>
                    </div>
                    <div className="d-md-flex justify-content-center d-block py-3 col-11 mx-auto">
                      <div className="secundary-bg-color py-2 px-3 rounded mx-2 my-3 my-md-0">
                        <div className="d-flex justify-content-start align-items-start title-card">
                          <div className='icon-compra primary-color'>
                            <Icon icon="iconoir:small-shop"></Icon>
                          </div>
                          <p className='primary-color ms-2 mb-0 mt-2'>{getTextByKey('modal-compra-recoge')}</p>
                        </div>
                        <div className="gray-1-color sm pb-4" dangerouslySetInnerHTML={{ __html: getTextBigByKey("modal-compra-recoger-texto") }}></div>
                        <div className="text-center">
                          <button className='btn-g px-4' onClick={handleOpenModal}>{getTextByKey('seleccionar')}</button>
                        </div>
                      </div>
                      <div className="secundary-bg-color py-2 px-3 rounded mx-2 my-3 my-md-0">
                        <div className="d-flex justify-content-start align-items-start title-card">
                          <div className='icon-compra primary-color'>
                            <Icon icon="bi:truck"></Icon>
                          </div>
                          <p className='primary-color ms-2 mb-0 mt-2'>{getTextByKey('modal-compra-envio')}</p>
                        </div>
                        <div className="gray-1-color sm pb-4" dangerouslySetInnerHTML={{ __html: getTextBigByKey("modal-compra-envio-texto") }}></div>
                        <div className="text-center">
                          <button className='btn-g px-4' onClick={handleOpenEnvio}>{getTextByKey('seleccionar')}</button>
                        </div>
                      </div>
                    </div>
                  
                </Modal.Body>
            </Modal>      
            <ModalCompraRecoger showModalRecoger={showModalRecoger} setShowModalRecoger={setShowModalRecoger} setShowModalCompra={setShowModalCompra} />
            <ModalDireccionEnvio showModalDireccion={showModalDireccion} setShowModalDireccion={ setShowModalDireccion} setShowModalCompra={setShowModalCompra} ></ModalDireccionEnvio>
        </Fragment>
    )
}
