import { Fragment } from "react";
import { Icon } from '@iconify/react';
import { NavComponent } from "./components/NavComponent";
import { NavsupComponent } from "./components/NavsupComponent";
import { FormularioDireccion } from "../../components/FormularioDireccion";
import { MapComponent } from "../../components/MapComponent";
import DireccionComponent from "./components/DireccionComponent";
import { useApi } from "../../context/apiContext";
import { useAuth } from "../../authContext";
import { useState } from "react";
import { listAddressUser } from "../../api/addressApi";
import { useEffect } from "react";
import { useRef } from "react";
import { useMemo } from "react";

import StyleGeneral from "../../scss/general.module.scss";
import StylePerfil from "../../scss/perfil.module.scss";
import StyleButton from "../../scss/btn.module.scss";
import ButtonLink from "../../components/ButtonLinkComponent";

import TittleH1 from "../../components/TittleH1Component";
import TittleH2 from "../../components/TittleH2Component";
import TextBlock from "../../components/TextBlockComponent";
import StyleText from "../../scss/text.module.scss";

export default function NuevaDireccionPage() {
    // const { currentUser } = useAuth();
    // const [addressList, setAddressList] = useState([]);
    // const [addressFromMap, setAddressFromMap] = useState({});
    const { getTextByKey, getTextBigByKey, currentLocale } = useApi();
    // const [locationMarker, setLocationMarker] = useState({});

    // const updateAddress = async () => {
    //     try {
    //         const listAddressResponse = await listAddressUser(currentUser.uid)
    //         setAddressList(listAddressResponse)
    //     } catch (error) {
    //         console.log('Error: ', error)
    //     }
    // }

    // const updateAddressFromMap = e => {
    //     setAddressFromMap(e);
    // }

    // useEffect(() => {
    //     listAddressUser(currentUser.uid).then((addressResponse) => {
    //         setAddressList(addressResponse)
    //     })
    // }, [])

    // useEffect(() => {
    //     console.log(locationMarker);
    // }, [locationMarker])

    // const onSubmit = (event) => {
    //     event.preventDefault();
    //     updateUser(profileInfo).then((response) => {
    //         console.log('after update', response)
    //     })
    // }


    return (
        <Fragment>
            {/* <div className="py-5 gray-2-bg-color">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-6 offset-lg-6 text-center text-lg-start mb-4">
                            <TittleH1 styleGroup={`primary-color autography ${StyleText.h4Special}`}>{getTextByKey('perfil')}</TittleH1>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4 px-xl-5 mb-4">
                            <NavComponent />
                        </div>
                        <div className="col-12 col-lg-8 px-xl-5 mb-4">
                            <div className="border white-bg-color p-4 p-xl-5">
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </Fragment>
    )
}