import React, { Fragment } from 'react'
import { useCart } from "../../context/cartContext";

import { images_products_route, getProductDetail, getRecommendedHome,getRelacionados } from "../../api/productApi";
import { useApi } from "../../context/apiContext";
import { useEffect } from 'react';
import { useAuth } from '../../authContext';
import { useParams } from 'react-router-dom';
import { ProductImage } from './components/ProductImage';
import QuantyControls from '../../components/QuantyControls'
import { ProductComponent } from '../../components/productComponent';
import { useState } from 'react';

import StyleGeneral from '../../scss/general.module.scss';
import StyleBtn from '../../scss/btn.module.scss';
import StyleProduct from '../../scss/product.module.scss';

import { Navigation, Pagination, Autoplay, Thumbs, FreeMode, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import TittleH1 from '../../components/TittleH1Component';
import TittleH4 from '../../components/TittleH4Component';
import TittleH5 from '../../components/TittleH5Component';
import TextBlockSmall from '../../components/TextBlockSmallComponent';
import ButtonLink from '../../components/ButtonLinkComponent';
import ReactGA from 'react-ga4';

export default function ProductPage() {

  const params = useParams();

  const { getTextByKey, getPropiertyByLocale, convertCurrency } = useApi();

  const [productData, setProductData] = React.useState(false);

  const [products, setProducts] = useState([]);

  const [cant, setCant] = React.useState(1);

  const { addToCart, showCart, checkIfExistInCart,tiendaGeneral,addToCartOld } = useCart();

  const [widthheight, setWidthheight] = React.useState({ width: 0, height: 0 });

  const [showModal, setShowModal] = useState(false);
  const { currentUser } = useAuth();

  const addCart = (e) => {
    e.preventDefault();
    /* console.log("addCart", productData); */
    ReactGA.event({
      category:'ecommerce',
      action: 'add_to_cart',
      value: (productData.price?.precio)*cant,
      currency: "COP",
      items :[
        {
          item_id:productData?.plu,
          item_name: productData.name?.nombre,
          item_category: productData.categorias?.[0]?.nombre,
          price: productData.price?.precio,
          quantity: cant
        }
      ]
    });
    let sigue=0;
    if(currentUser){
      if(currentUser.isAnonymous){ 
        if (!tiendaGeneral) {
          sigue=1;
        }
       
      }else{
        if (!tiendaGeneral) {
                
          sigue=2;
        }
      }
    }else{
     
      sigue=1;
    }
    if(sigue!=0){

      addToCartOld(productData, cant);
      if(sigue==1){
        location.href = "/checkout-a";
      }else{
        location.href = "/direccion-envio";
      }
      return;
    }
    productData.type = "product";
    addToCart(productData, cant);
    showCart();
  }

  const checkIfExist = (actualItemId) => {
    return checkIfExistInCart(actualItemId);
  }

  const changeCantidad = ({target: {value}}) => {
    if (value < 1) value = 1;
    if (productData.cantidad < value) value = productData.cantidad;
    setCant(value);
  }

  useEffect(() => {
   
    /*if(currentUser){
      if(currentUser.isAnonymous){ 
        if (!tiendaGeneral) {
          location.href = "/checkout-a";   
        }
       
      }else{
        if (!tiendaGeneral) {
          location.href = "/direccion-envio";
        }
      }
    }else{
      location.href = "/checkout-a";
    }*/
  
  },[currentUser,tiendaGeneral])


  useEffect(() => {
    if(productData){
      ReactGA.event({
        category:'ecommerce',
        action: 'view_item',
        value: productData.price?.precio,
        currency: "COP",
        items :[
          {
            item_id:productData?.plu,
            item_name: productData.name?.nombre,
            item_category: productData.categorias?.[0]?.nombre,
            price: productData.price?.precio,
            quantity: 1
          }
        ]
      });
    }

  },[productData]);


  useEffect(() => {
    console.log(tiendaGeneral);
    window.scrollTo(0, 0);
    setProductData(false);
    getProductDetail(params.plu,tiendaGeneral).then((data) => {
      console.log("getProductDetail", data);
      data.size = '';
      data.color = '';
      setProductData(data);
      if(data){
        let newImg = new Image();
        newImg.onload = function() {
          var height = newImg.height;
          var width = newImg.width;
          setWidthheight({ width: width, height: height });
        }
        newImg.src = images_products_route + data.images[0].image;
      }
    });

    getRelacionados(params.plu,tiendaGeneral).then(({productos}) => {
      setProducts(productos);
    });

 
    
  }, [params.plu,tiendaGeneral]);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  let paramsGallery = {
    modules: [Navigation, Pagination, Scrollbar, A11y, Thumbs, FreeMode],
    spaceBetween: 30,
    slidesPerView: 1,
    simulateTouch: true,
    lazy: true,
    loop: true,
    freeMode: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  }

  let paramsThumbs = {
    modules: [ Scrollbar, A11y, Thumbs, FreeMode],
    spaceBetween: 1,
    lazy: true,
    freeMode: true,
    loop: false,
    breakpoints: {
      320: {
        direction: "horizontal",
        slidesPerView: 2,
      },
      768: {
        direction: "vertical",
        slidesPerView: 3,
      },
      1200: {
        direction: "vertical",
        slidesPerView: 3.8,
      }
    }
  }

  if(!productData)
    return (
      <div className='container'>
        <div className="text-center-c">
          <div className="loader-box">
            <img src="/img/SVG/loader.svg" alt="" />
          </div>
        </div>
      </div>
    );


  return (
    <Fragment>
      <div className='product-page mt-4 mt-xl-5'>
        {productData ? (
          <div className='container mb-5'>
            <div className='row'>
              
              <div className="col-xl-7 col-lg-12">
                
                <div className='form-group d-lg-none'>
                  <p className='mb-0'>
                    {!productData.cantidad && <span className='alert-danger'>{getTextByKey('shop_agotado')}</span>}
                    {!productData.cantidad && productData.price.precio_sin_descuento && '/'}
                    {productData.price.precio_sin_descuento && <span className='quaternary-color'>
                      {getTextByKey('shop_descuento')}
                    </span>}
                    {productData.price.precio_sin_descuento && productData.nuevo && '/'}
                    {productData.nuevo && <span> {getTextByKey('shop_new_product')}</span>}
                  </p>
                  <hr className='hr-color-1'/>
                  <TittleH1 styleGroup="primary-color fw-bold mb-4">{productData.name.nombre}</TittleH1>
                </div>

                <div className={StyleProduct.containerImages}>
                  <div className={StyleProduct.contentThumbnails}>
                      <Swiper {...paramsThumbs} watchSlidesProgress onSwiper={setThumbsSwiper} className={StyleProduct.thumbnails}>
                          {productData.images.length &&
                            productData.images.slice().sort((a, b) => a.orden - b.orden)
                            .map((image, index) => {
                              return(
                                <SwiperSlide className='cursor-pointer text-center' key={index}>
                                  <picture>
                                    <img className={StyleProduct.thumbnailsImg} src={images_products_route + image.image}/>
                                  </picture>
                                </SwiperSlide>
                              );
                          })}
                      </Swiper>
                  </div>

                  <div className={`p-1 mb-4 mb-lg-0 ${StyleProduct.contentSlider}`}>
                    <div className={`h-100 d-flex justify-content-center align-items-center ${StyleGeneral.boxShadowB}`}>
                      <Swiper {...paramsGallery} thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}>
                          {productData.images.length && 
                            productData.images.slice().sort((a, b) => a.orden - b.orden)
                            .map((image, index) => {
                                return(
                                  <SwiperSlide key={index}>
                                      <ProductImage imageSrc={ image.image && images_products_route + image.image } />
                                  </SwiperSlide>
                                );
                            })}
                            <div className="only-mobile">
                              <div className="swiper-button-next"></div>
                              <div className="swiper-button-prev"></div>
                            </div>
                      </Swiper>
                    </div>
                  </div>
                  
                </div>

              </div>

              <div className="col-xl-5 col-lg-12">
  
                <form className='pt-md-4 pt-xl-0 px-lg-4'>
                  <div className='form-group d-none d-lg-block'>
                    <p className='mb-0'>
                      {!productData.cantidad && <span className='alert-danger'>{getTextByKey('shop_agotado')}</span>}
                      {!productData.cantidad && productData.price.precio_sin_descuento && '/'}
                      {productData.price.precio_sin_descuento && 
                      <span className='quaternary-color'>{getTextByKey('shop_descuento')}</span>}
                      {productData.price.precio_sin_descuento && productData.nuevo && '/'}
                      {productData.nuevo && <span> {getTextByKey('shop_new_product')}</span>}
                    </p>
                    <TittleH1 styleGroup="primary-color fw-bold mb-0">{getPropiertyByLocale(productData.names, "nombre")}</TittleH1>
                  </div>

                  <hr className='hr-color-1'/>
                  
                  <div className="form-group d-none d-lg-flex align-items-center mb-4">
                    <TittleH1 styleGroup="primary-color fw-bold mb-0">{productData.name.nombre}</TittleH1>
                  </div>

                  <div dangerouslySetInnerHTML={{__html: getPropiertyByLocale(productData.resumenes,'resumen')}}></div>
                  

                  <div className='form-group'>
                    <div className='gray-1-color' dangerouslySetInnerHTML={{__html: getPropiertyByLocale(productData.inspirations, "inspiracion")}}></div>
                  </div>

                  <div className='mb-4'>
                  {productData.price.sindescuento && <TittleH4 styleGroup="fw-bold price-normal">{convertCurrency(productData.price.precio_sin_descuento)}</TittleH4>}
                    <TittleH4 styleGroup="fw-bold">{convertCurrency(productData.price.precio)}</TittleH4>
                  </div>

                  {!!productData.cantidad &&
                  <div className='mb-4'>
                    <TextBlockSmall styleGroup="text-uppercase mb-3">{getTextByKey('shop_amount')}</TextBlockSmall>
                  
                    <div className={StyleProduct.containterQuanty}>
                      <QuantyControls
                          quantity={cant}
                          onChange={changeCantidad}
                          onMinus={e => changeCantidad({target: {value: cant -1} })}
                          onPlus={e => changeCantidad({target: {value: cant +1} })}
                          styleGroup="justify-content-start"
                      />
                    </div>
                    
                  </div>
                 }

                  {!!productData.cantidad &&<button onClick={addCart} className={!checkIfExist(productData.plu) ? `btn-add-car ${StyleBtn.btn}` : `btn-add-car ${StyleBtn.btn}`}>
                    {!checkIfExist(productData.plu) ? `${getTextByKey('agregar-carrito')}` : `${getTextByKey('agregado-carrito')}`}
                  </button>}
                  
                </form>
                  
              </div>
            </div>
          </div>
        ) : (
          <div className='container'>
            <div className="text-center-c">
              <div className="loader-box">
                <img src="/img/SVG/loader.svg" alt="" />
              </div>
            </div>
          </div>
        )}

      <div className="others-products quinaryA-bg-color py-5">
        <div className="container px-3">
            <TittleH5 styleGroup="text-center quinary-color fw-600">{getTextByKey('shop_can_want')}</TittleH5>
              <div className="swiper-relacionados py-2 py-lg-5">
                <Swiper modules={[Autoplay]}
                        autoplay={{ delay: 4000, disableOnInteraction: true }}
                        loop={true}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 0,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 0,
                            },
                            992: {
                              slidesPerView: 4,
                              spaceBetween: 0,
                            },
                            1200: {
                                slidesPerView: 5,
                                spaceBetween: 0,
                            },
                        }}>
                 
                    {products ?  products.map((product, index) => (
                        <SwiperSlide key={index}>
                            <ProductComponent key={index} product={product} noaddCart />
                        </SwiperSlide>
                    )): ''}
                      
                </Swiper>
              </div>
            <div className='text-center pb-3 pb-lg-5'>
              <ButtonLink to="/tienda">{getTextByKey('shop_see_all_products')}</ButtonLink>
            </div>
          
        </div>
      </div>
      </div>
    
    </Fragment>
  )
}
