import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../authContext";
import { useApi } from "../../../context/apiContext";
import { useForm } from "../../../hooks/useForm";
import { ModalEstadoOrden } from "./ModalEstadoOrden";


export function ContactComponent({onInput, formState, disabled}){
  const [showModal, setShowModal] = useState(false);

  const { getTextByKey, getTextBigByKey } = useApi();
  const { currentUser } = useAuth();

    const handleShowModal = () => {
      if( currentUser ) {
        location.href = '/mis-pedidos';
      } else {
        setShowModal(true)
      }
    }

    const onFile = (e) => {
      const event = {
        target: {
          name: e.target.name,
          value: e.target.files[0]
        }
      }
      onInput(event);
    }

    return(
      <div className="row">
        <div className="col-12">
          <label className="mb-1 gray-1-color">{getTextByKey('persona')}*</label>
          <ul className="mb-3 d-flex p-0">
            <li className='list-style-none'>
              <label className="d-flex align-items-center radio-button-container">
                <input type="radio" name='juridico' value={false} onChange={onInput} checked={formState.juridico == "false"} />
                <p className='gray-1-color mb-0'>{getTextByKey('natural')}</p>
              </label>
            </li>
            <li className='list-style-none ms-4'>
              <label className="d-flex align-items-center radio-button-container">
                <input type="radio" name='juridico' value={true} onChange={onInput} onInput={onInput} checked={formState.juridico == "true"}  />
                <p className='gray-1-color mb-0'>{getTextByKey('juridica')}</p>
              </label>
            </li>
          </ul>
        </div>
        {
          formState.juridico == 'false' ? (
            <>
              <div className="col-md-6 col-lg-4">
                <div className="form-group mb-3">
                    <label className="mb-1 gray-1-color">{getTextByKey('nombres')}*</label>
                    <input value={formState.name} onInput={onInput} name='name' type="text" className="p-2" required/>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group mb-3">
                    <label className="mb-1 gray-1-color">{getTextByKey('apellidos')}*</label>
                    <input value={formState.surname} onInput={onInput} name='surname' type="text" className="p-2" required/>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group mb-3">
                    <label className="mb-1 gray-1-color">{getTextByKey('numero_identificacion')}*</label>
                    <input value={formState.identification_number} onInput={onInput} name='identification_number' type="number" className="p-2" required/>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6 col-lg-8">
                <div className="form-group mb-3">
                    <label className="mb-1 gray-1-color">{getTextByKey("razon_social")}*</label>
                    <input onInput={onInput} value={formState.business_name} name='business_name' type="text" className="p-2" required/>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group mb-3">
                    <label className="mb-1 gray-1-color">{getTextByKey("nit")}*</label>
                    <input onInput={onInput} value={formState.NIT} name='NIT' type="text" className="p-2" required/>
                </div>
              </div>
            </>
          )
        }
        <div className="col-md-6 col-lg-4">
            <div className="form-group mb-3">
                <label className="mb-1 gray-1-color">{getTextByKey('direccion')}*</label>
                <input value={formState.address} onInput={onInput} name='address' type="text" className="p-2" required/>
            </div>
        </div>
        <div className="col-md-6 col-lg-4">
            <div className="form-group mb-3">
                <label className="mb-1 gray-1-color">Ciudad*</label>
                <input value={formState.city} onInput={onInput} name='city' type="text" className="p-2" required/>
            </div>
        </div>
        <div className="col-md-6 col-lg-4">
            <div className="form-group mb-3">
                <label className="mb-1 gray-1-color">Departamento*</label>
                <input value={formState.department} onInput={onInput} name='department' type="text" className="p-2" required/>
            </div>
        </div>
        <div className="col-md-6 col-lg-8">
            <div className="form-group mb-3">
                <label className="mb-1 gray-1-color">Correo electrónico*</label>
                <input value={formState.email} onInput={onInput} name='email' type="text" className="p-2" required/>
            </div>
        </div>
        <div className="col-md-6 col-lg-4">
            <div className="form-group mb-3">
                <label className="mb-1 gray-1-color">Teléfono móvil/fijo</label>
                <input value={formState.telephone} onInput={onInput} type="telephone" name='telephone' className="p-2"/>
            </div>
        </div>
        <div className="col-12">
            <div className="form-group mb-3">
                <label className="mb-3">Descripción del requerimiento*</label>
                <textarea value={formState.comment} onInput={onInput} name='comment' className="p-2" required></textarea>
            </div>
        </div>
        <div className="col-12">
          <div className="form-group mb-3">
            <label htmlFor="" className="mb-1 gray-1-color">{getTextByKey('adjunta_documentos')}</label>
              <div className="add-file position-relative">
                <input accept=".doc,.pdf, .DOCX, .odt"  name='file' type="file" onChange={onFile} />
                <button className="btn-g py-2 px-5">{getTextByKey('seleccionar_archivo')}</button>
              </div>
              <div className="sm gray-1-color mt-1" dangerouslySetInnerHTML={{ __html: getTextBigByKey("archivo_permitido") }}></div>
              <p className="gray-1-color sm-p">{getTextByKey('tamano')}</p>
          </div>
        </div>
        <div className="col-md-12">
          <label className="d-flex align-items-center radio-button-container my-3">
              <input type="radio" name='accept' required/>
              <p className='gray-1-color mb-0'>{getTextByKey('autorizo_contacto')} 
                <strong><Link className="gray-1-color" to="/politica-de-datos"> {getTextByKey('autorizo_contacto_parrafo_link')}*</Link></strong>
              </p>
          </label>
        </div>
        <div className="col-12 mt-4 mb-5">
          <button type="submit" className="btn-g px-5 py-2" disabled={disabled}>{disabled ? getTextByKey('enviando') : getTextByKey('enviar')}</button>
        </div>
      </div>
    );
}