import sellyApi from './sellyApi'

export const createAddress = async (inputData) => {
  try {
    const { data } = await sellyApi.post('/address', inputData)
    if (data?.code === 200) {
      location.href = "/direccion-envio";
    }
  } catch (error) {
    //console.log('Error creando dirección', error)
  }
}

export const createAddressForUser = async (inputData) => {
  try {
    const { data } = await sellyApi.post('/address', inputData)
    return data;
  } catch (error) {
    console.error(error)
    throw new Error("Error al crear la dirección");
  }
}


export const listAddressUser = async (uidUser) => {
  try {
    const { data } = await sellyApi.get(`/address?uid=${uidUser}`);
    return data;
  } catch (error) {
    return [];
  }
}

export const deleteAddress = async (addressId, setDisableButton) => {
  try {
    const { data } = await sellyApi.delete(`/address/${addressId}`)
    if (data?.code === 200) {
      setDisableButton(false)
      location.href = "/direccion-envio";
    }
  } catch (error) {
    //console.log('Error eliminando dirección', error)
  }
}

export const setDefaultAddress = async (uid, addressId, setDisableButton) => {
  try {
    const { data } = await sellyApi.put(`/address/default?uid=${uid}&address=${addressId}`)
    if (data?.code === 200) {
      setDisableButton(false)
      location.href = "/direccion-envio";
    }
  } catch (error) {
    //console.log('Error eliminando dirección', error)
  }
}

export default { createAddress, deleteAddress, listAddressUser, setDefaultAddress }
