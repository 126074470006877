import React, { Suspense } from "react";
import { Fragment, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import ButtonLink from "./ButtonLinkComponent";
import Picture from "./PictureComponent";
import TittleH1 from "./TittleH1Component";
import TittleH4 from "./TittleH4Component";
import styleBanner from "../scss/banner.module.scss";
import styleText from "../scss/text.module.scss";

import { images_products_route } from "../api/productApi";
import ReactPlayer from "react-player";
import { isIOS, isAndroid } from "react-device-detect";

function Banner(props) {

    const src = props.src;
    const alt = props.alt;
    const titleHead = props.titleHead;
    const titlecolor = props.titlecolor;
    const vlink = props.vlink;
    const textbutton = props.textbutton;
    const secundaryBanner = props.secundaryBanner;
    const styleGroup = props.styleGroup;
    const contentText = props.contentText;
    const smallBanner = props.smallBanner;
    const mp4 = props.mp4;
    const mp4Mobile = props.mp4Mobile;
    const webm = props.webm;
    const webmMobile = props.webmMobile;

    const styleGroupButton = props.styleGroupButton;
    const heightBanner = props.heightBanner;

    //const [playing, setPlaying] = useState(false);
    const [muted, setMuted] = useState(true);
    const [opacity, setOpacity] = useState(1);
    const [showPlayButton, setShowPlayButton] = useState(true);

    

    let productoRoute = images_products_route;

    /* console.log('ruta', productoRoute); */

    /* const toggleMute = () => {
        setMuted(!muted);
    } */

    //const LazyPicture = React.lazy(() => import('./PictureComponent'));

    /* const LazyPlayer = React.lazy(() => import('react-player'));
    
    const handlePlay = () => {
        setPlaying(true);
        setOpacity(1);
    };

    const handleOnPlay = () => {
        setShowPlayButton(false);
        setOpacity(1);
    }; */

    /* console.log('mp4', mp4);
    console.log('webm', webm); */

    /* const videoSources = [
        {
          src: productoRoute + webm,
          type: 'video/webm',
        },
        {
          src: productoRoute + mp4,
          type: 'video/mp4',
        },
    ]; */

    const [isPlaying, setPlaying] = useState(false);
    const [isPaused, setPaused] = useState(true);
    const videoRef = useRef(null);

    const playVideo = () => {
        videoRef.current.play();
        setPlaying(true);
        setPaused(false);
      };
    
      const pauseVideo = () => {
        videoRef.current.pause();
        setPaused(true);
      };
    
      const togglePlay = () => {
        if (isPaused) {
          playVideo();
        } else {
          pauseVideo();
        }
    };

    return (
        <Fragment>
            <div className={`${styleBanner.banner} ${heightBanner && heightBanner }`}>               
                {src ?    
                    <Picture src={src} alt={alt} />
                : null}
                {mp4 || mp4Mobile || webm || webmMobile ?
                    <Fragment>
                        {/* <Suspense fallback={<div>Loading...</div>}>
                            <LazyPlayer className={styleBanner.video}
                                url={videoSources}
                                playing={playing}
                                muted={muted}
                                style={{opacity: opacity}}
                                onPlay={handleOnPlay}
                                controls={true}
                                width="640px"
                                height="360px"
                            />
                        </Suspense> */}

                        { isIOS || isAndroid ? (
                            <video className={`mobile ${styleBanner.video} ${isPlaying ? styleBanner.playing : ''}`} loop muted playsInline ref={videoRef}>
                                <source src={productoRoute+mp4Mobile} type="video/mp4" />
                                <source src={productoRoute+webmMobile} type="video/webm" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <video className={`${styleBanner.video} ${isPlaying ? styleBanner.playing : ''}`} loop muted playsInline ref={videoRef}>
                                <source src={productoRoute+mp4} type="video/mp4" />
                                <source src={productoRoute+webm} type="video/webm" />
                                Your browser does not support the video tag.
                            </video>
                        )}

                        
                        
                        <div className="la"></div>

                        <button className={styleBanner.btnPlay} onClick={togglePlay}>
                            <Icon icon={isPaused ? "material-symbols:play-arrow-outline-rounded" : "material-symbols:pause-outline-rounded"} />
                        </button>

                    </Fragment>
                : null}

                <div className={styleBanner.wp}>
                    <div className={`container ${styleBanner.container}`}>
                        {titleHead ?
                            <div className="row">
                                <div className={smallBanner ? "col-10 offset-1" : `col-12 ${styleGroup}`}>
                                    {secundaryBanner ?
                                        <TittleH4 styleGroup={smallBanner ? `text-start autography ${titlecolor} ${styleText.h4Special}` : `text-center autography ${titlecolor} ${styleText.h4Special}`}>{titleHead}</TittleH4>
                                        :
                                        <TittleH1 styleGroup={smallBanner ? `text-start autography ${titlecolor}` : `text-center autography ${titlecolor}`}>{titleHead}</TittleH1>
                                    }
                                    {contentText !== '' &&   
                                        <div dangerouslySetInnerHTML={{__html: contentText}} className="white-color"></div>
                                    }
                                </div>
                                {vlink ?
                                    <div className="col-12 text-center">
                                        <ButtonLink to={vlink} styleGroup={styleGroupButton}>{textbutton}</ButtonLink>
                                    </div>
                                    : null}
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Banner;
