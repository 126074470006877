import { Fragment, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { getImages } from '../../api/imageApi';
import Banner from "../../components/BannerComponent";
import StyleGeneral from '../../scss/general.module.scss';
import TextBlock from "../../components/TextBlockComponent";
import {getFaqs} from "../../api/faqApi";
import {useApi} from "../../context/apiContext";

import StyleFaqs from '../../scss/faqs.module.scss';
import StyleText from '../../scss/text.module.scss';

const CLOSE_ICON = "ri:subtract-fill";
const OPEN_ICON = "ic:baseline-add";


export default function PreguntasPage() {
  const [pqrImg, setPqrImg] = useState('')
  const {currentLocale} = useApi();
  const [faqs, setFaqs] = useState([]);
  const [iconos, setIconos] = useState({})

  useEffect(() => {
    getImages('pqr').then((images) => {
      // console.log("pqr images: " + JSON.stringify(images));
      setPqrImg(images)
    })

    getFaqs(currentLocale).then((data) => {
      setFaqs(data.list);
      setIconos(data.list.reduce((obj, i) => {
        obj[i.id] = OPEN_ICON;
        return obj;
      }, {}))
    });
  }, [])

  const ChangeIco = (id) => {
    setIconos(i => ({...i, [id]: i[id] === OPEN_ICON ? CLOSE_ICON : OPEN_ICON}));
  }

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-5 px-0">
            <div className="position-relative">
              {pqrImg.length > 0 &&
                <Banner src={pqrImg[0].image}
                  // styleGroup="col-lg-12" alt="Rosmi"
                  titleHead={pqrImg[0].names[0].titulo}
                  titlecolor="white-color" />
              }
            </div>
          </div>
          <div className="col-12 col-lg-7">
            <div className="py-5">
              {
                faqs.map(item =>
                    (
                        <div key={item.id} className={`cursor-pointer mb-3 ${StyleGeneral.input} ${StyleFaqs.contItem}`}>
                          <div className={`d-flex align-items-center ${StyleFaqs.item}`} onClick={() => ChangeIco(item.id)} data-bs-toggle="collapse"
                               data-bs-target={`#collapseFaq-${item.id}`} aria-expanded="false" aria-controls={`collapseFaq-${item.id}`}>
                              <Icon className={`secundary-color fw-bolder me-3 ${StyleFaqs.icon}`} icon={iconos[item.id]}></Icon>
                              <TextBlock styleGroup="primary-color mb-0">{item.titulo}</TextBlock>
                          </div>
                          {
                            iconos[item.id] !== OPEN_ICON &&
                            <div dangerouslySetInnerHTML={{__html: item.texto}}
                                  className={`fw-normal px-3 px-5 ${StyleText.contentAdmin}`}>
                            </div>
                          }
                        </div>
                    )
                )

              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
