import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
/* import '../scss/carrito.scss' */

import StylesQuickCart from '../scss/quickCar.module.scss';

import { useCart } from "../context/cartContext";
import { images_products_route } from "../api/productApi";
import { useApi } from '../context/apiContext';
import { QuantyControls } from '../components/QuantyControls';
import TittleH6 from './TittleH6Component';
import TextBlockSmall from './TextBlockSmallComponent';

export default function ItemCarrito({ item, index, min }) {

    const { removeToCart, addToCart, setCartQuantityValue} = useCart();
    const { getTextByKey, getPropiertyByLocale,  extractNameFromLocate, currentSizes, currentColors, convertCurrency } = useApi();
    const [ imgPrincipal, setImgPrincipal ] = useState('');
    const removeItem = () => {
        removeToCart(index);
    }

    const getNameTalla = (talla) => {
        talla = parseInt(talla);
        let size = currentSizes.find((size) => size.id == talla);
        if (size) {
            return size.nombre;
        }
        return talla;
    }

    const getNameColor = (color) => {
        color = parseInt(color);
        let colorItem = currentColors.find((colorItem) => colorItem.id == color);
        if (colorItem) {
            return colorItem.nombre;
        }
        return color;
    }

    useEffect(() => {
        if(item.images && item.images.length>0){
            let principal=item.images.find(image=>image.principal==1);
          if(principal)
          setImgPrincipal(principal.image);
        }
      },[item])

    const renderProductItem = () => {
        return (
        <div className={`item-carrito py-2 py-lg-3 ${StylesQuickCart.itemCart}`}>
               <div className="d-flex justify-content-between align-items-stretch">
                <div className={`d-flex justify-content-center align-items-center ${StylesQuickCart.img}`}>
                    {item.images.length > 0 && 
                        <img className='w-75' src={images_products_route + imgPrincipal} />
                    }
                </div>
                
                <div className={StylesQuickCart.txt}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <TextBlockSmall styleGroup="fw-bold primary-color mb-0">{item.name.nombre}</TextBlockSmall>
                        <a className='primary-color icon-eliminar' onClick={removeItem}><Icon icon="carbon:close"/></a>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: getPropiertyByLocale(item.resumenes, "resumen")?.slice(0, 40)+'...'}} className={StylesQuickCart.resume}></div>
                    <div className={`d-flex justify-content-between align-items-center`}>
                        <div className={StylesQuickCart.smallControl}>
                            <div className={StylesQuickCart.scaleComponent}>
                            <QuantyControls
                                onMinus={() => setCartQuantityValue(item, item.quantity - 1)}
                                onPlus={() => setCartQuantityValue(item, item.quantity + 1)}
                                onChange={(e) => setCartQuantityValue(item, e.target.value)}
                                quantity={item.quantity}
                                styleGroup="justify-content-start"
                            />
                            </div>
                        </div>
                          <p className={`gray-1-color mb-0 md-p my-lg-0 my-2 fw-bold ${StylesQuickCart.price}`}><span className='fw-bold'>{item.quantity}</span> x {convertCurrency(item.price.precio)}</p>
                    </div>

                </div>
    
                
            </div>

        </div>
        )
    }

    return (
        <>
            {
                item.type === "giftcard" ? renderCardGiftItem() : renderProductItem()
            }
        </>
    )
}
