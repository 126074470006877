import React, { Fragment, useRef, useEffect, useState, lazy,Suspense} from "react";
import { BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import ReactGifLoader from "./components/ReactGifLoader";

import { Header } from "./components/header";
import { Footer } from "./components/footer";
import './scss/var.scss';
import './scss/mixin.scss';
import './App.scss';
import './scss/breackpoint_only_mobile.scss';
import './scss/breackpoint_sm.scss';
import './scss/breackpoint_md.scss';
import './scss/breackpoint_lg.scss';
import './scss/breackpoint_only_tablet.scss';
import './scss/breackpoint_xl.scss';
import './scss/breackpoint_only_hd.scss';
import './scss/breackpoint_huge.scss';

import StyleHeader from "./scss/header.module.scss";

import HomePage from "./pages/HomePage/HomePage";
import BasePage from "./pages/HomePage/BasePage";
import RecipesPage from "./pages/RecipesPage/RecipesPage";
import RecipePage from "./pages/RecipesPage/RecipePage";
import BlogPage from "./pages/BlogPage/BlogPage";
import AboutSingularBlogPage from "./pages/BlogPage/AboutSingularBlogPage";
import ShopPage from "./pages/ShopPage/ShopPage";
import ProductPage from "./pages/ProductPage/ProductPage";
import {CheckoutaPage} from "./pages/CheckoutPage/CheckoutaPage";
import CheckoutbPage from "./pages/CheckoutPage/CheckoutbPage";
import CheckoutcPage from "./pages/CheckoutPage/CheckoutcPage";
import CheckoutePage from "./pages/CheckoutPage/CheckoutePage";
import CheckoutFastPage from "./pages/CheckoutPage/CheckoutFastPage";
import AcercaPage from "./pages/AcercaPage/AcercaPage";
import DetallescuentaPage from "./pages/PerfilPage/DetallescuentaPage";
import MetodospagoPage from "./pages/PerfilPage/MetodospagoPage";
import DireccionesPage from "./pages/PerfilPage/DireccionesPage";
import NuevaDireccionPage from "./pages/PerfilPage/NuevaDireccionPage";
import TarjetaaPage from "./pages/TarjetaregaloPage/TarjetaaPage";
import TarjetabPage from "./pages/TarjetaregaloPage/TarjetabPage";
import TarjetacPage from "./pages/TarjetaregaloPage/TarjetacPage";
import TarjetadPage from "./pages/TarjetaregaloPage/TarjetadPage";
import ContactoPage from "./pages/ContactoPage/ContactoPage";
import PreguntasPage from "./pages/PqrsPage/PreguntasPage";
import EnviosPage from "./pages/PqrsPage/EnviosPage";
import ManualPage from "./pages/PqrsPage/ManualPage";
import FormulariocontactoPage from "./pages/PqrsPage/FormulariocontactoPage";
import PedidosPage from "./pages/PerfilPage/PedidosPage";
import PedidoPage from "./pages/PerfilPage/PedidoPage";
import CompraPage from "./pages/FeedbackPage/CompraPage";
import PagoExitosoPage from "./pages/CheckoutPage/PagoExitosoPage";
import LoginPage from "./pages/UserPage/LoginPage";
import RegisterPage from "./pages/UserPage/RegisterPage";
import { AuthProvider } from "./authContext";
import { CartProvider } from "./context/cartContext";
import { ApiProvider } from "./context/apiContext";
import ResetPassPage from "./pages/UserPage/ResetPassPage";
import Error404Page from "./pages/404Page/404Page";
import { SearchPage } from "./pages/SearchPage/SearchPage";
import SelectionPage from "./pages/SelectionsPage/SelectionPage";
import LegalPage from "./pages/PqrsPage/LegalPage";
import TipsPage from "./pages/TipsPage/TipsPage";
import StyleGeneral from "./scss/general.module.scss";



export function App(){

    const headerRef = useRef(null);
    const contentRef = useRef(null);
    const [headerHeight, setHeaderHeight] = useState(0);
  
    useEffect(() => {
      setTimeout(() => {
        if (headerRef.current) {
          setHeaderHeight(headerRef.current.clientHeight);
        }
      }, 1500);

    
    }, []);
  
    useEffect(() => {
      const handleResize = () => {
        if (headerRef.current) {
          setHeaderHeight(headerRef.current.clientHeight);
        }
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [headerHeight]);

   /* const ProductPageLazy = lazy(() => import("./pages/ProductPage/ProductPage"));
    const LoginPageLazy = lazy(() => import("./pages/UserPage/LoginPage"));
    const RegisterPage = lazy(() => import("./pages/UserPage/RegisterPage"));
    const RecipesPage = lazy(() => import("./pages/RecipesPage/RecipesPage"));
    const BlogPage = lazy(() => import("./pages/BlogPage/BlogPage"));
    const AboutSingularBlogPage = lazy(() => import("./pages/BlogPage/AboutSingularBlogPage"));
    const ShopPage = lazy(() => import("./pages/ShopPage/ShopPage"));
    //const CheckoutaPage = lazy(() => import("./pages/CheckoutPage/CheckoutaPage"));
    const AcercaPage = lazy(() => import("./pages/AcercaPage/AcercaPage"));
    const DireccionesPage = lazy(() => import("./pages/PerfilPage/DireccionesPage"));
    const NuevaDireccionPage = lazy(() => import("./pages/PerfilPage/NuevaDireccionPage"));
    const ContactoPage = lazy(() => import("./pages/ContactoPage/ContactoPage"));

    //const BasePage = lazy(() => import("./pages/HomePage/BasePage"));
    const RecipePage = lazy(() => import("./pages/RecipesPage/RecipePage"));
    const SearchPage = lazy(() => import("./pages/SearchPage/SearchPage"));
    const CheckoutbPage = lazy(() => import("./pages/CheckoutPage/CheckoutbPage"));
    const CheckoutcPage = lazy(() => import("./pages/CheckoutPage/CheckoutcPage"));
    const CheckoutePage = lazy(() => import("./pages/CheckoutPage/CheckoutePage"));
    const CheckoutFastPage = lazy(() => import("./pages/CheckoutPage/CheckoutFastPage"));
    const PagoExitosoPage = lazy(() => import("./pages/CheckoutPage/PagoExitosoPage"));
    const MetodospagoPage = lazy(() => import("./pages/PerfilPage/MetodospagoPage"));
    const PedidosPage = lazy(() => import("./pages/PerfilPage/PedidosPage"));
    const PedidoPage = lazy(() => import("./pages/PerfilPage/PedidoPage"));
    const TarjetaaPage = lazy(() => import("./pages/TarjetaregaloPage/TarjetaaPage"));
    const TarjetabPage = lazy(() => import("./pages/TarjetaregaloPage/TarjetabPage"));
    const TarjetacPage = lazy(() => import("./pages/TarjetaregaloPage/TarjetacPage"));
    const TarjetadPage = lazy(() => import("./pages/TarjetaregaloPage/TarjetadPage"));
    const CompraPage = lazy(() => import("./pages/FeedbackPage/CompraPage"));
    const DetallescuentaPage = lazy(() => import("./pages/PerfilPage/DetallescuentaPage"));*/
   
    return (
        <Fragment>
            <AuthProvider>
            <CartProvider>
            <ApiProvider>
            <BrowserRouter>
                {/*<Suspense fallback={<div>Loading...</div>}>*/}
                <header id="header" className={`${StyleHeader.header}`} ref={headerRef}>
                    <Header/>
                </header>
                <div className={`main ${StyleGeneral.paddingFixHeader} ${StyleGeneral.minHeight}`} id="main" ref={contentRef} style={{ paddingTop: `${headerHeight}px` }}>
                <Routes>
                    <Route   path="/" element={<HomePage />} />
                    <Route   path="/login" element={<LoginPage />} />
                    <Route   path="/register" element={<RegisterPage />} />
                    <Route   path="/reset-password" element={<ResetPassPage />} />
                    <Route   path="/base" element={<BasePage />} />
                    <Route   path="/recetas" element={<RecipesPage />} />
                    <Route   path="/recetas/:category" element={<RecipesPage />} />
                    <Route   path="/receta/:id" element={<RecipePage />} />
                    <Route   path="/noticias" element={<BlogPage />} />
                    <Route   path="/noticia/:id" element={<AboutSingularBlogPage />} />
                    <Route   path="/busqueda" element={<SearchPage />} />
                    {/* <Route   path="/puntos-venta" element={<PointsSalePage />} /> */}
                    <Route   path="/tienda" element={<ShopPage />} >
                        <Route   path=":categorias" element={<ShopPage />} >
                            <Route   path=":actividad/:colores/:talla/:linea/:marcas/:orden" element={<ShopPage />} />
                        </Route>
                    </Route>
                    <Route   path="/linea" element={<ShopPage />} >
                        <Route   path=":categorias" element={<ShopPage />} >
                            <Route   path=":actividad/:colores/:talla/:linea/:marcas/:orden" element={<ShopPage />} />
                        </Route>
                    </Route>
                    <Route   path="/sales" element={<ShopPage />} >
                        <Route   path=":categorias" element={<ShopPage />} >
                            <Route   path=":actividad/:colores/:talla/:linea/:marcas/:orden" element={<ShopPage />} />
                        </Route>
                    </Route>
                    <Route   path="/offers" element={<ShopPage />} >
                        <Route   path=":categorias" element={<ShopPage />} >
                            <Route   path=":actividad/:colores/:talla/:linea/:marcas/:orden" element={<ShopPage />} />
                        </Route>
                    </Route>
                    <Route  path="/product/:plu" element={<ProductPage />} />
                    <Route  path="/checkout-a" element={<CheckoutaPage />} />
                    <Route  path="/checkout-b" element={<CheckoutbPage />} />
                    <Route  path="/checkout-c" element={<CheckoutcPage />} />
                    <Route  path="/checkout-e" element={<CheckoutePage />} />
                    <Route  path="/checkout-d" element={<CheckoutFastPage />} />
                    <Route  path="/payment-success" element={<PagoExitosoPage />} />
                    <Route  path="/nosotros" element={<AcercaPage />} />
                    <Route  path="/detalles-cuenta" element={<DetallescuentaPage />} />
                    <Route  path="/profile-methods" element={<MetodospagoPage />} />
                    <Route  path="/direccion-envio" element={<DireccionesPage />} />
                    <Route  path="/nueva-direccion" element={<NuevaDireccionPage />} />
                    <Route  path="/mis-pedidos" element={<PedidosPage />}/>
                    <Route  path="/pedido/:order" element={<PedidoPage />}/>
                    <Route  path="/tarjeta-a" element={<TarjetaaPage />} />
                    <Route  path="/tarjeta-b" element={<TarjetabPage />} />
                    <Route  path="/tarjeta-c" element={<TarjetacPage />} />
                    <Route  path="/tarjeta-d" element={<TarjetadPage />} />
                    <Route  path="/compra-news" element={<CompraPage />} />
                    <Route  path="/contacto" element={<ContactoPage />} />
                    <Route  path="/preguntas-frecuentes" element={<PreguntasPage />} />
                    <Route  path="/envios" element={<EnviosPage />} />
                    <Route  path="/legal" element={<LegalPage />} />
                    <Route  path="/manual" element={<ManualPage />} />
                    <Route  path="/contactanos" element={<FormulariocontactoPage />} />
                    <Route  path="/recetas" element={<RecipesPage/>} />
                    <Route  path="/estilo-de-vida" element={<SelectionPage />} />
                    <Route  path="/tips" element={<TipsPage />} />
                    <Route  path="*" element={<Error404Page />} />
                </Routes>
                   </div>
                <Footer/>
                <ReactGifLoader />
                {/*</Suspense>*/}
            </BrowserRouter>
            </ApiProvider>
            </CartProvider>
            </AuthProvider>
        </Fragment>
    );
}