import React, { useState,useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import StyleProduct from '../scss/product.module.scss';
import StyleButton from '../scss/btn.module.scss';
import StyleGeneral from '../scss/general.module.scss';
import TittleH4 from './TittleH4Component';
import TextBlock from './TextBlockComponent';
import TextBlockSmall from './TextBlockSmallComponent';
import TittleH6 from './TittleH6Component';
import QuantyControls from './QuantyControls';
import { useApi } from '../context/apiContext';
import { images_products_route} from "../api/productApi";
import { useCart } from "../context/cartContext";
import { useAuth } from '../authContext';
import ReactGA from 'react-ga4';


function PreviewModal(props) {
  const { show, handleClose,product } = props;
  const { getTextByKey,convertCurrency,getPropiertyByLocale } = useApi();
  const [imagenPreview, setImagenPreview] = useState();
  const [cant, setCant] = useState(1);
  const { currentUser } = useAuth();
  const { addToCart, showCart, checkIfExistInCart,tiendaGeneral,addToCartOld } = useCart();
  const handleClick = (event) => {
    setImagenPreview(event.target.src);
  };

  const changeCantidad = ({target: {value}}) => {
    if (value < 1) value = 1;
    if (product.cantidad < value) value = product.cantidad;
    setCant(value);
  }
  const addCart = (e) => {
    e.preventDefault();
    console.log("addCart", product);
    ReactGA.event({
      category:'ecommerce',
      action: 'add_to_cart',
      value: product?.price?.precio,
      currency: "COP",
      items :[
        {
          item_id: product?.plu,
          item_name: product?.name?.nombre,
          item_category: product?.categorias?.[0]?.nombre,
          price: product?.price?.precio,
          quantity: cant
        }
      ]
    });

    product.type = "product";
    let sigue=0;
    if(currentUser){
      if(currentUser.isAnonymous){ 
        if (!tiendaGeneral) {
          sigue=1;
        }
       
      }else{
        if (!tiendaGeneral) {
                
          sigue=2;
        }
      }
    }else{
     
      sigue=1;
    }
    if(sigue!=0){

      addToCartOld(product, cant);
      if(sigue==1){
        location.href = "/checkout-a";
      }else{
        location.href = "/direccion-envio";
      }
      return;
    }
    addToCart(product, cant);
    showCart();
  }

  useEffect(() => {
      if(product.images.length>0);
      setImagenPreview(images_products_route+product.images[0].image)
  },[product]);




  return (
    <Modal show={show} onHide={handleClose} className={`modalPreview ${StyleProduct.modalPreview}`}>
      <Modal.Header closeButton className='border-0'>
        {/* <Modal.Title>Título del modal</Modal.Title> */}
      </Modal.Header>
      <Modal.Body className='pt-0 pb-4 pb-lg-5'>
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-md-7'>
                {product.images.length &&
                    <div className='d-flex justify-content-between align-items-start'>
                      <div className='w-25'>
                      {product.images .slice().sort((a, b) => a.orden - b.orden)
                      .map((image, index) => {
                              return(
                        <div className={`mb-2 mb-md-3 ${StyleGeneral.boxShadow}`} key={index}>
                          <img className='w-100 imgMin' src={`${images_products_route + image.image}`} onClick={handleClick}/>
                        </div>)
                        })}
                       </div>
                      <div className='w-75 px-4'>
                        <img className='w-100' id='previewImg' src={imagenPreview}/>
                      </div>
                    </div>
                   }
                </div>
                <div className='col-12 col-md-5'>
                    <TextBlockSmall styleGroup="mb-2 text-uppercase">
                    {!product.cantidad && <span className='alert-danger me-1'>AGOTADO</span>}
                    {product.price.precio_sin_descuento && <span className='quaternary-color me-1'>DESCUENTO</span>}
                    {product.nuevo && <span className='me-1'>NUEVO PRODUCTO</span> }
                    </TextBlockSmall>
                    <TittleH6 styleGroup="primary-color fw-bold">{product.name.nombre}</TittleH6>
                    <p dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(product.resumenes, "resumen")?.slice(0, 500)  }}></p>
                    <TittleH6 styleGroup="fw-bold mb-4">{convertCurrency(product.price.precio)}</TittleH6>
                    {!!product.cantidad && <>
                      <TextBlockSmall styleGroup="text-uppercase mb-0">CANTIDAD</TextBlockSmall>
                      <QuantyControls 
                      quantity={cant}
                      onChange={changeCantidad}
                      onMinus={e => changeCantidad({target: {value: cant -1} })}
                      onPlus={e => changeCantidad({target: {value: cant +1} })}
                      styleGroup="justify-content-start mb-4"/>
                      <button className={StyleButton.btn} onClick={addCart}>{getTextByKey('agregado-carrito')}</button>
                    </>
                    }
                </div>
            </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Guardar cambios
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default PreviewModal;
