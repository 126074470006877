import Modal from 'react-bootstrap/Modal';
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import TittleH4 from '../../../components/TittleH4Component';
import styleText from '../../../scss/text.module.scss';
import StyleBtn from '../../../scss/btn.module.scss';

export default function ModalComponent({showModal, setShowModal,email}) {
  return (
         <Modal show={showModal}  id="ModalConfirmation" centered>
            <Modal.Header className="modal-header justify-content-center">
 							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal( false )}></button>
            </Modal.Header>
            <Modal.Body className="modal-body px-5">
						<TittleH4 styleGroup={`text-center carrefourMetis-bold mb-5 autography primary-color
							${styleText.h4Special}`}>
									Restablecer contraseña
							</TittleH4>
							<form>
									<div className="form-group mb-3 text-center">
											<label>Recibirás un enlace en pocos instantes para restablecer tu contraseña</label>
									</div>
									<div className="form-group mb-3 text-center">
											<label>{email}</label>
									</div>
									<div className="form-group mb-2 text-center">
											<Link to="/login" className={`btn-g py-2 px-4 ${StyleBtn.btn}`}>Volver al inicio de sesión</Link>
									</div>
							</form>
            </Modal.Body>
         </Modal>
        
  )
}
