import { Fragment } from "react";
import { ProductComponent } from "../../components/productComponent";
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";

import stylesBtn from "../../scss/btn.module.scss";

import ButtonLink from "../../components/ButtonLinkComponent";
import ButtonLinkSecundary from "../../components/ButtonLinkSecundaryComponent";

import TittleH1 from "../../components/TittleH1Component";
import TittleH2 from "../../components/TittleH2Component";
import TittleH3 from "../../components/TittleH3Component";
import TittleH4 from "../../components/TittleH4Component";
import TittleH5 from "../../components/TittleH5Component";
import TittleH6 from "../../components/TittleH6Component";
import TextBlock from "../../components/TextBlockComponent";
import TextBlockSmall from "../../components/TextBlockSmallComponent";
import TextBlockSpecial from "../../components/TextBlockSpecialComponent";
import Picture from "../../components/PictureComponent";
import Banner from "../../components/BannerComponent";
import SelecctionsComponent from "../SelectionsPage/components/SelecctionsComponent";
import SelectionPage from "../SelectionsPage/SelectionPage";
import NewsComponent from "../../components/NewsComponent";

export default function BasePage() {
    const ZoomInScrollOut = batch(Sticky(), Fade(), ZoomOut(1, 2));
    const FadeUp = batch(Fade(), Move(), Sticky());
    return (
        <Fragment>
            
            <NewsComponent />
            <SelectionPage />

            <div className="mb-5">
                <Banner src="img/pexels-alexander-mils-2103949_1.jpg" alt="Rosmi" titleHead="Promoviendo un mundo Mejor" titlecolor="white-color" vlink="/" textbutton="lorem Ipsum" />
            </div>

            <div className="container mb-5">
                <div className="row">
                    <div className="col-12">
                        <Banner src="img/pexels-alexander-mils-2103949_1.jpg" alt="Rosmi" titleHead="Promoviendo un mundo Mejor" titlecolor="primary-color" vlink="/" textbutton="lorem Ipsum" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <Picture src="img/bg-3.png" alt="Rosmi" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <Picture src="img/pexels-photo-5651642-full.jpg" alt="Rosmi" />
                    </div>
                </div>
            </div>

            <div className="container py-5">

                <div className="row">
                    <div className="col-12 col-lg-6">
                        <TittleH1 styleGroup="alert-sucess fw-bold text-center mb-5">Componentes de titulos para home, titulo pruducto en interna, o titulos importantes</TittleH1>
                    </div>
                    <div className="col-12 col-lg-6">
                        <TittleH4 styleGroup="alert-danger fw-bold mb-5 text-center">Estos titulos son del uso general para el resto de la pagina, son titulos sin relevancia para el buscador (ABOUT  US, CONTACTO..)</TittleH4>
                    </div>
                </div>

                <div className="row">

                    <div className="col-12 col-lg-6">

                        <TittleH1 styleGroup="primary-color fw-bold">Componente h1</TittleH1>
                        <TextBlock>Exclusivamente una vez por pagina y si es el home debe ser un resumen del sitio</TextBlock>

                        <TittleH2 styleGroup="secundary-color">Componente h2</TittleH2>
                        <TextBlock>Exclusivo subindice del h1</TextBlock>

                        <TittleH3 styleGroup="gray-1-color">Componente h3 </TittleH3>
                        <TextBlock>Menos importante pero solo puede existir si hay un h2 subindice del h1</TextBlock>

                    </div>

                    <div className="col-12 col-lg-6">
                        <TittleH4 styleGroup="quaternary-color fw-bold">Componente h4 Lorem Ipsum</TittleH4>
                        <TittleH5>Componente h5</TittleH5>
                        <TittleH6>Componente h6</TittleH6>
                    </div>

                    <div className="col-12">
                        <div className="py-5">
                            <TextBlock styleGroup="gray-1-color">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ipsum elit, lacinia a enim ut, hendrerit aliquet risus. Fusce vestibulum sapien vitae ante fringilla, sed tristique erat tempor. Suspendisse vehicula congue augue, eu fringilla neque pellentesque vitae. Maecenas elementum felis ac erat ultricies facilisis. Nulla tellus purus, interdum non laoreet ut, gravida eget turpis. Nulla ac dapibus orci. Nulla dictum nunc eu augue blandit facilisis. Donec suscipit velit mi, eget faucibus nulla finibus quis. In eu finibus ligula, vitae pellentesque arcu. Nullam vitae odio non risus aliquam convallis ac consectetur quam. Curabitur cursus nisi ut mi sollicitudin auctor. Ut suscipit metus ut accumsan finibus. Duis non leo eu nisl malesuada malesuada. Nulla nec vulputate lacus, at facilisis urna. Praesent fermentum interdum justo non imperdiet.</TextBlock>

                            <TextBlockSmall styleGroup="alert-sucess">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ipsum elit, lacinia a enim ut, hendrerit aliquet risus. Fusce vestibulum sapien vitae ante fringilla, sed tristique erat tempor. Suspendisse vehicula congue augue, eu fringilla neque pellentesque vitae. Maecenas elementum felis ac erat ultricies facilisis. Nulla tellus purus, interdum non laoreet ut, gravida eget turpis. Nulla ac dapibus orci. Nulla dictum nunc eu augue blandit facilisis. Donec suscipit velit mi, eget faucibus nulla finibus quis. In eu finibus ligula, vitae pellentesque arcu. Nullam vitae odio non risus aliquam convallis ac consectetur quam. Curabitur cursus nisi ut mi sollicitudin auctor. Ut suscipit metus ut accumsan finibus. Duis non leo eu nisl malesuada malesuada. Nulla nec vulputate lacus, at facilisis urna. Praesent fermentum interdum justo non imperdiet.</TextBlockSmall>

                            <TextBlockSpecial>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ipsum elit, lacinia a enim ut, hendrerit aliquet risus. Fusce vestibulum sapien vitae ante fringilla, sed tristique erat tempor. Suspendisse vehicula congue augue, eu fringilla neque pellentesque vitae. Maecenas elementum felis ac erat ultricies facilisis. Nulla tellus purus, interdum non laoreet ut, gravida eget turpis. Nulla ac dapibus orci. Nulla dictum nunc eu augue blandit facilisis. Donec suscipit velit mi, eget faucibus nulla finibus quis. In eu finibus ligula, vitae pellentesque arcu. Nullam vitae odio non risus aliquam convallis ac consectetur quam. Curabitur cursus nisi ut mi sollicitudin auctor. Ut suscipit metus ut accumsan finibus. Duis non leo eu nisl malesuada malesuada. Nulla nec vulputate lacus, at facilisis urna. Praesent fermentum interdum justo non imperdiet.</TextBlockSpecial>
                        </div>
                    </div>



                    <div className="col-12 col-lg-6">

                        <div className="text-center py-4">
                            <p><strong>componente ButtonLink</strong> normal</p>
                            <ButtonLink to="/">Lorem Ipsum</ButtonLink>
                        </div>

                        <div className="text-center py-4">
                            <p><strong>componente ButtonLinkSecundary</strong> normal</p>

                            <ButtonLinkSecundary to="/">Lorem Ipsum 2</ButtonLinkSecundary>
                        </div>


                        <div className="text-center py-4">
                            <p><strong>.btn.disable</strong></p>
                            <a className={`disabled ${stylesBtn.btnDisabled}`}>Disable</a>
                        </div>
                    </div>

                </div>

                <div className="row">

                    <div className="col-12 col-lg-4">
                        <h2 className="primary-color">Colores principales</h2>
                        <div className="p-2 primary-bg-color">
                            <p className="white-color">primary-bg-color</p>
                        </div>
                        <div className="p-2 secundary-bg-color">
                            <p>secundary-bg-color</p>
                        </div>
                        <div className="p-2 tertiary-bg-color">
                            <p className="white-color">tertiary-bg-color</p>
                        </div>
                        <div className="p-2 quaternary-bg-color">
                            <p className="white-color">quaternary-bg-color</p>
                        </div>
                        <div className="p-2 quinary-bg-color">
                            <p className="white-color">quinary-bg-color</p>
                        </div>
                        <div className="p-2 alert-danger-bg-color">
                            <p className="white-color">alert-danger-bg-color</p>
                        </div>
                        <div className="p-2 alert-sucess-bg-color">
                            <p className="white-color">alert-sucess-bg-color</p>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 mb-5">

                        <h2 className="primary-color">Otros colores</h2>

                        <div className="p-2 rosa-1-bg-color">
                            <p className="white-color">rosa-1-bg-color</p>
                        </div>

                        <div className="p-2 rosa-2-bg-color">
                            <p className="white-color">rosa-2-bg-color</p>
                        </div>

                        <div className="p-2 rosa-3-bg-color">
                            <p className="white-color">rosa-3-bg-color</p>
                        </div>

                        <div className="p-2 rosa-4-bg-color">
                            <p className="white-color">rosa-4-bg-color</p>
                        </div>

                        <div className="p-2 gray-1-bg-color">
                            <p className="white-color">gray-1-bg-color</p>
                        </div>

                        <div className="p-2 gray-2-bg-color">
                            <p className="white-color">gray-2-bg-color</p>
                        </div>

                        <div className="p-2 gray-3-bg-color">
                            <p>gray-3-bg-color</p>
                        </div>
                    </div>

                </div>

                <div className="row">

                    <div className="col-12">
                        <div className="text-center">
                            <h2 className="primary-color">Formularios</h2>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4">
                        <form>
                            <div className="form-group mb-2">
                                <label className="mb-1">Nombre</label>
                                <input type="text" className="p-2" />
                            </div>
                            <div className="form-group mb-2">
                                <label className="mb-1">Telefono</label>
                                <input type="number" className="p-2" />
                            </div>
                            <div className="form-group mb-2">
                                <label className="mb-1">Password</label>
                                <input type="password" className="p-2" />
                            </div>
                            <div className="form-group mb-2">
                                <label className="mb-1">E-mail</label>
                                <input type="email" className="p-2" />
                            </div>
                            <div className="form-group mb-2">
                                <label className="mb-1">Lorem ipsum</label>
                                <select className="p-2">
                                    <option>option 1</option>
                                    <option>option 1</option>
                                    <option>option 1</option>
                                    <option>option 1</option>
                                </select>
                            </div>
                        </form>
                    </div>

                    <div className="col-12 col-lg-4">
                        <form>
                            <div className="form-group mb-2">
                                <label className="mb-1">Lorem ipsum</label>
                                <textarea className="p-2"></textarea>
                            </div>
                            <div className="form-group mb-2">
                                <label className="mb-1">Fecha</label>
                                <input className="p-2" type="date" />
                            </div>
                            <div className="form-group mb-2">
                                <label className="mb-1">Telefono</label>
                                <input className="p-2" type="tel" />
                            </div>
                            <div className="form-group mb-2">
                                <label className="mb-1">SUSCRIBIRME AL NEWSLETTER <input className="p-2" type="checkbox" /></label>
                            </div>
                            <div className="form-group text-center">
                                <button className="btn-g py-2 px-4">Enviar</button>
                            </div>
                        </form>
                    </div>

                    <div className="col-12 col-lg-4">
                        <form>
                            <div className="form-group mb-2">
                                <label className="mb-1">Cantidad</label>
                            </div>
                            <div className="form-group mb-2">
                                <label className="mb-1">Hora</label>
                                <input className="p-2" type="time" />
                            </div>
                            <div className="form-group text-center">
                                <button className="btn-g py-2 px-4">Guardar</button>
                            </div>
                        </form>
                    </div>

                </div>

            </div>


        </Fragment>
    )
}
