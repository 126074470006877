import React, { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../../../context/apiContext';
import { useEffect } from 'react';
import { deleteTarjeta } from '../../../api/productApi';
import { useState } from 'react';
export default function MetodoPagoComponent({ tarjeta, selected, handleTarjeta, updateTarjetas }) {

    const { getTextByKey } = useApi();

    const [ loading, setLoading ] = useState(false);

    const handleDelete = (e) => {
        e.preventDefault();
        setLoading(true);
        deleteTarjeta(tarjeta.id).then((response) => {
            updateTarjetas();
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        });
    }

    const handleChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleTarjeta(tarjeta.id);
    }

    useEffect(() => {
    }, [tarjeta, selected])

    return (
        
        <Fragment>
            {selected>0 && selected == tarjeta.id ? (
            <div className="ficha-arjeta-hover ficha-arjeta p-2 p-md-3 p-xl-5 secundary-bg-color mb-2 mb-lg-5 position-relative" 
            onClick={handleChange} >
                {selected == tarjeta.id && <Icon icon="el:ok-sign" className='icon-ok' />}
                <form className="d-flex align-items-center">
                    <div className="datos">
                        <div className="form-group">
                            <label>Brand</label>
                            <p className='upper'>{tarjeta.brand}</p>
                        </div>
                        <div className="form-group">
                            <label>{getTextByKey("card-number")}</label>
                            <p className="fw-bold numero-oculto">
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <span>{tarjeta.last_four_numbers}</span>
                            </p>
                        </div>
                        {/* <div className="form-group">
                            <label>CCV</label>
                            <p className="fw-bold numero-oculto">
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                            </p>
                        </div> */}
                    </div>
                    <div className="opciones text-center">
                    <button className="btn-g border-0 bg-transparent py-2 px-4 text-uppercase" type='submit' onClick={handleDelete}>
                            { loading ? <span className="spinner-border text-dark" role="status"></span> : <Icon icon="carbon:delete" className="icon" /> }
                        </button>
                    </div>
                </form>
            </div>
            ) : selected==0 ? (
                <div className="ficha-arjeta-hover ficha-arjeta p-2 p-md-3 p-xl-5 secundary-bg-color mb-2 mb-lg-5 position-relative" 
            onClick={handleChange} >
                {selected == tarjeta.id && <Icon icon="el:ok-sign" className='icon-ok' />}
                <form className="d-flex align-items-center">
                    <div className="datos">
                        <div className="form-group">
                            <label>Brand</label>
                            <p className='upper'>{tarjeta.brand}</p>
                        </div>
                        <div className="form-group">
                            <label>{getTextByKey("card-number")}</label>
                            <p className="fw-bold numero-oculto">
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <span>{tarjeta.last_four_numbers}</span>
                            </p>
                        </div>
                        {/* <div className="form-group">
                            <label>CCV</label>
                            <p className="fw-bold numero-oculto">
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                                <Icon icon="carbon:dot-mark" className="icon" />
                            </p>
                        </div> */}
                    </div>
                    <div className="opciones text-center">
                        <button className="border-0 bg-transparent" type='submit' onClick={handleDelete}>
                            { loading ? <span className="spinner-border text-dark" role="status"></span> : <Icon icon="carbon:delete" className="icon" /> }
                        </button>
                    </div>
                </form>
            </div>
            ) : null
            }
        </Fragment>
    )
}
