import { Fragment } from "react";
import { Icon } from '@iconify/react';
import { ContactComponent } from "../ContactoPage/components/ContactoComponent";

export default function EnviosPage() {
  return (
    <Fragment>
        <div className="banner position-relative">
            <img className="w-100" src="img/group-140.jpg"/>
            <div className="content-banner">
              <div className="container">
                <h2 className="text-uppercase text-center secundary-color">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</h2>
              </div>
            </div>
        </div>

        <div className="container mb-5">
          <div className="text-center py-5">
            <h2 className="text-uppercase primary-color">ENVIOS</h2>
          </div>

          <div className="item-collapse border-bottom primary-border-color mb-2">  
            <a className="d-flex justify-content-between align-items-center py-3 gotham-Bold" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              <span>1.</span>
              <span>¿Lorem Ipsum is simply dummy text of the printing and typesetting industry?</span>
              <Icon icon="akar-icons:chevron-down" />
            </a>
            <div className="collapse" id="collapseExample">
              <div className="py-3">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software</p>
              </div>
            </div>
          </div>

          <div className="item-collapse border-bottom primary-border-color mb-2">  
            <a className="d-flex justify-content-between align-items-center py-3 gotham-Bold" data-bs-toggle="collapse" href="#collapseExampleB" role="button" aria-expanded="false" aria-controls="collapseExample">
              <span>2.</span>
              <span>¿Lorem Ipsum is simply dummy text of the printing and typesetting industry?</span>
              <Icon icon="akar-icons:chevron-down" />
            </a>
            <div className="collapse" id="collapseExampleB">
              <div className="py-3">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software</p>
              </div>
            </div>
          </div>

          <div className="item-collapse border-bottom primary-border-color mb-2">  
            <a className="d-flex justify-content-between align-items-center py-3 gotham-Bold" data-bs-toggle="collapse" href="#collapseExampleC" role="button" aria-expanded="false" aria-controls="collapseExample">
              <span>3.</span>
              <span>¿Lorem Ipsum is simply dummy text of the printing and typesetting industry?</span>
              <Icon icon="akar-icons:chevron-down" />
            </a>
            <div className="collapse" id="collapseExampleC">
              <div className="py-3">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software</p>
              </div>
            </div>
          </div>
        </div>

        <ContactComponent/>
    </Fragment>
  )
}
