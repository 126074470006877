import React from "react";
import { NavLink } from "react-router-dom";
import styleButton from "../scss/btn.module.scss";


const ButtonLinkSecundary = ({ to, children }) => {
  return (
    <NavLink to={to} className={styleButton.btnSecundary}>
      {children}
      <span className={styleButton.loadingContainer}>
        <span className={styleButton.dot}>.</span>
        <span className={styleButton.dot}>.</span>
        <span className={styleButton.dot}>.</span>
      </span>
    </NavLink>
  );
};

export default ButtonLinkSecundary;