import { Fragment, useEffect, useState } from "react";
import { ModaleliminarcuentaComponent } from "./components/ModaleliminarcuentaComponent";
import { ModalGuardarCuentaComponent } from "./components/ModalGuardarCuentaComponent";
import { ModalCambiarPasswordComponent } from "./components/ModalCambiarPasswordComponent";
import { ModalGuardar } from "./components/ModalGuardar";
import { NavComponent } from "./components/NavComponent";
import { NavsupComponent } from "./components/NavsupComponent";
import { NavLink } from "react-router-dom";

import StyleButton from "../../scss/btn.module.scss";
import StyleGeneral from "../../scss/general.module.scss";

import TittleH1 from "../../components/TittleH1Component";
import TittleH2 from "../../components/TittleH2Component";
import TextBlock from "../../components/TextBlockComponent";
import StyleText from "../../scss/text.module.scss";


import { useApi } from "../../context/apiContext";
import { Icon } from '@iconify/react';
import { getUser, updateUser, deleteUser } from '../../helpers/userHelper';
import { useAuth } from "../../authContext";
import TittleH6 from "../../components/TittleH6Component";
import {useForm} from "../../hooks/useForm";
import { InputGroup } from "react-bootstrap";
import {getCiudades, getDepartamentos} from "../../api/productApi";

let initialState = {
    nombre: "",
    apellido: "",
    celular: "",
    email: "",
    password: "",
    newsletterStatus: null,
    language: 'es',
    departamento:0,
    ciudad: 0,
    direccion: "",
    barrio:"",
    uid: "",
    documento: ""
};
export default function DetallescuentaPage() {

    const { currentUser,handleChangePassword } = useAuth();
    const { getTextByKey ,currentLocale} = useApi();
    const [ errorNombre, setErrorNombre ] = useState();
    const [ errorNombreDos, setErrorNombreDos ] = useState();
    const [ errorApellido, setErrorApellido ] = useState();
    const [ errorApellidoDos, setErrorApellidoDos ] = useState();
    const [ errorContacto, setErrorContacto ] = useState();
    
    const [ errorTel, setErrorTel ] = useState();
    const [ errorNumDoc, setErrorNumDoc ] = useState(); 
    const [ errorPasswordactual, setErrorPasswordactual ] = useState(); 
    const [ errorPassword, setErrorPassword ] = useState(); 
    const [ success, setSuccess ] = useState(false);
    const [ciudades, setCiudades] = useState([]);
    const [load ,setLoad]=useState(false);
    const [departamentos, setDepartamentos] = useState([]);
    const [ dptoId, setDptoId ] = useState('');
  
    const [errorDpto, setErrorDpto] = useState('');
    const [errorCiudad, setErrorCiudad] = useState('');
    const [errorDireccion, setErrorDireccion] = useState('');
    const [errorBarrio, setErrorBarrio] = useState('');
    const [personajuridica, setJuridico] = useState(0);
    const [errors, setErrors] = useState("");
    
   
    const {
        formState,
        onInputChange,
        nombre,
        nombredos,
        apellido,
        apellidodos,
        celular,
        email,
        departamento,
        ciudad,
        direccion,
        barrio,
        contacto,
        password,
        documento,
        phoneRegex,
        nameRegex,
        setFormState
    } = useForm(initialState)

    const fetchProfile = () => {
        try {
            getUser(currentUser?.uid).then(async({ user }) => {
                console.log("Usuario: ", user);
                const {nombre, nombredos, apellido, apellidodos,celular, juridico,email, departamento, ciudad,direccion ,barrio, password,documento,contacto} = user;
                setFormState({
                    ...initialState,
                    nombre,
                    nombredos,
                    apellido,
                    apellidodos,
                    celular,
                    email,
                    departamento,
                    ciudad,
                    direccion,
                    barrio,
                    contacto,
                    password,
                    documento,
                    newsletterStatus: null,
                    language: 'es',
                    uid: currentUser?.uid
                })
                if(juridico)
                    setJuridico(1);
                setDptoId(departamento);
             
               
            })

        } catch (error) {
            console.log('error', error)
        }
    }
    const [showModal, setShowModal] = useState(false);

     const handleShowModal = async(event) => {
        event.preventDefault();
        const validate= await validateForm();
         if (!validate) {
         setShowModal(true)
        }
        return;
    }

    const handleDpto = (event)=>{
        setDptoId(event.target.value)
        onInputChange(event);
    }
    useEffect( () => {
        getDepartamentos(currentLocale).then(data => {
            if (data && Array.isArray(data)) {
                setDepartamentos(data);
            }
        });
    },[]);

    useEffect(()=>{
        if(dptoId){
            getCiudades(dptoId).then((data) => {
                setCiudades(data);
            });
        }
    },[dptoId])
    useEffect(() => {
        fetchProfile()
    }, [currentUser?.uid])

    const actualizado =()=>{
        setShowModal(false);
        setSuccess(true);
        setLoad(false);
        }

    const onSubmit = async(event) => {
        event.preventDefault();
        resetError();
        setLoad(true);
        let dataenvio='';
        dataenvio={...formState,juridico:personajuridica};
        updateUser(dataenvio).then((response) => {
           
           /* if(password && passwordactual && passwordrepetir){
                    handleChangePassword(passwordactual, password).then(() => {
                    actualizado();
                })
                .catch((error) => {
                    console.log(error);
                if(error.message=='auth/wrong-password'){
                    setErrorPasswordactual("La contraseña actual es errónea");
                    resetError();
                }else{
                setErrorPassword("¡Ups!, algo salió mal en el último momento, vuelva a intentar por favor");
                }
                setShowModal(false);
                });
            }else{*/
                actualizado();
            //}
        }).catch((error)=>{
             setErrors(error.message);
             setShowModal(false);
             setLoad(false);
        })
     
       
        
    }
    

    const resetError=()=>{
       
            setErrorNombre('');
            setErrorApellido('');
            setErrorApellidoDos('');
            setErrorNombreDos('');
            setErrorNumDoc('');
            setErrorPassword('');
            setErrorTel('');
            setErrorPasswordactual('');
            setErrorDpto('');
            setErrorCiudad('');
            setErrorDireccion('');
            setErrors('');   
            setSuccess(false);     
      
    }
    const validateForm = async () => {
        let errorFound = false;
        if (!nombre ) {
            errorFound = true;
            setErrorNombre('Este campo es requerido');
        } else {
            if(personajuridica==0){
                if(!nombre.match(nameRegex)){
                        errorFound = true;
                        setErrorNombre('Formato inválido, este campo solo recibe letras');
                }else{
                    setErrorNombre('');
                }
           }
        }

        if(personajuridica==0){
            if(nombredos){
                if(!nombre.match(nameRegex)){
                    errorFound = true;
                    setErrorNombreDos('Formato inválido, este campo solo recibe letras');
                }
            }
            
            if(apellido){
                if(!apellido.match(nameRegex)){
                    errorFound = true;
                    setErrorApellido('Formato inválido, este campo solo recibe letras');
                }else{
                    setErrorApellido('');
                }
            }
            if(apellidodos){
                if(!apellidodos.match(nameRegex)){
                    errorFound = true;
                    setErrorApellidoDos('Formato inválido, este campo solo recibe letras');
                }else{
                    setErrorApellidoDos('');
                }
            }
        }else{
            if (!contacto) {
                errorFound = true;
                setErrorContacto('Este campo es requerido');
            } else {
                if(!contacto.match(nameRegex)){
                    errorFound = true;
                    setErrorContacto('Formato inválido, este campo solo recibe letras');
                }else{
                    setErrorContacto('');
                }
            }
        }
    
        if (!celular.match(phoneRegex)){
            errorFound = true;
            setErrorTel("El teléfono no tiene un formato válido");
        }else{
            setErrorTel('');
        }
        if(!documento){
            errorFound = true;
            setErrorNumDoc('Este campo es requerido');
        }else{
            setErrorNumDoc('');
        }
        /*if(password || passwordactual || passwordrepetir){
            if(!password){
                errorFound = true;
                setErrorPassword('Ingrese la nueva contraseña');
            }else{
                if(password.length<6){
                    errorFound = true;
                    setErrorPassword('La contraseña debe tener al menos 6 caracteres');
                }else{
                 if(password != passwordrepetir){
                    errorFound = true;
                     setErrorPassword('Las contraseñas son diferentes');
                 }
               }
            }*/
        if(!departamento){
            errorFound = true;
            setErrorDpto("Debe seleccionar un departamento");
        }else{
            setErrorDpto('');
        }
        
        if (!ciudad ) {
            errorFound = true;
            setErrorCiudad("Debe seleccionar una ciudad");
        }else{
            setErrorCiudad('');
        }
        if(!direccion){
            errorFound = true;
            setErrorDireccion('Este campo es requerido');
        }else{
            setErrorDireccion('');
        } 
        if(!barrio){
            errorFound = true;
            setErrorBarrio('Este campo es requerido');
        }else{
            setErrorBarrio('');
        } 
		
        
        return errorFound;
    }


    return (
        <Fragment>
            <div className={`py-5 gray-2-bg-color ${StyleGeneral.minHeight}`}>
                <div className={`container ${StyleGeneral.container95}`}>
                    <div className="row">
                        <div className="col-12 text-center mb-4">
                            <TittleH1 styleGroup={`primary-color autography ${StyleText.h4Special}`}>{getTextByKey('perfil')}</TittleH1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4 px-xl-5 mb-4">
                            <NavComponent />
                        </div>
                        <div className="col-12 col-lg-8 px-xl-5 mb-4">
                            <div className="border white-bg-color p-4 p-xl-5">
                                <TittleH6 styleGroup="primary-color mb-3 mb-lg-4">Datos personales</TittleH6>
                                <form onSubmit={onSubmit} className="gray-1-color">
                                    <div className="row">
                                        <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group">
                                                {/* <label>{getTextByKey('nombres')}</label> */}
                                                <input
                                                    type="text"
                                                    className={`p-2 ${StyleGeneral.input}`}
                                                    name="nombre"
                                                    value={nombre}
                                                    onChange={onInputChange}
                                                    placeholder={getTextByKey('nombres')}
                                                />
                                                  {errorNombre ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorNombre}</div>
                                                        </span> : null}
                                            </div>
                                          
                                        </div>
                                        { personajuridica==0 ?
                                           <>
                                        <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group">
                                                {/* <label>{getTextByKey('nombres')}</label> */}
                                                <input
                                                    type="text"
                                                    className={`p-2 ${StyleGeneral.input}`}
                                                    name="nombredos"
                                                    value={nombredos}
                                                    onChange={onInputChange}
                                                    placeholder={getTextByKey('segundo_nombre')}
                                                />
                                                  {errorNombreDos ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorNombreDos}</div>
                                                        </span> : null}
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group">
                                                {/* <label>{getTextByKey('apellidos')}</label> */}
                                                <input
                                                    type="text"
                                                    className={`p-2 ${StyleGeneral.input}`}
                                                    name="apellido"
                                                    value={apellido}
                                                    onChange={onInputChange}
                                                    placeholder={getTextByKey('apellido')}
                                                />
                                          {errorApellido ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorApellido}</div>
                                                        </span> : null}
                                                
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group">
                                                {/* <label>{getTextByKey('apellidos')}</label> */}
                                                <input
                                                    type="text"
                                                    className={`p-2 ${StyleGeneral.input}`}
                                                    name="apellidodos"
                                                    value={apellidodos}
                                                    onChange={onInputChange}
                                                    placeholder={getTextByKey('segundo_apellido')}
                                                />
                                          {errorApellidoDos ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorApellidoDos}</div>
                                                        </span> : null}
                                                
                                            </div>
                                        </div>
                                        </>
                                        : 
                                        <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group">
                                                 <input
                                                    type="text"
                                                    className={`p-2 ${StyleGeneral.input}`}
                                                    name="contacto"
                                                    value={contacto}
                                                    onChange={onInputChange}
                                                    placeholder={getTextByKey('contacto-juridico')}
                                                />
                                                  {errorContacto ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorContacto}</div>
                                                        </span> : null}
                                            </div>
                                        </div>
                                        
                                        }
                                          
                                        <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className={`p-2 ${StyleGeneral.input}`}
                                                    name="documento"
                                                    value={documento}
                                                    onChange={onInputChange}
                                                    placeholder="Número documento"
                                                />
                                                 {errorNumDoc ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorNumDoc}</div>
                                                        </span> : null}
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group">
                                                {/* <label>{getTextByKey('numero_telefono_form')}</label> */}
                                                <input
                                                    type="tel"
                                                    className={`p-2 ${StyleGeneral.input}`}
                                                    name="celular"
                                                    value={celular}
                                                    onChange={onInputChange}
                                                    placeholder={getTextByKey('numero_telefono_form')}
                                                />
                                                {errorTel ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorTel}</div>
                                                        </span> : null}
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group">
                                                {/* <label>{getTextByKey('correo_electronico_form')}</label> */}
                                                <input
                                                    type="email"
                                                    className={`p-2 ${StyleGeneral.input}`}
                                                    name="email"
                                                    value={email}
                                                    onChange={onInputChange}
                                                    placeholder={getTextByKey('correo_electronico_form')}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        {/*<div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group position-relative">
                                             
                                                <input
                                                    type="password"
                                                    className={`p-2 ${StyleGeneral.input}`}
                                                    name="passwordactual"
                                                    value={passwordactual ? passwordactual : ""}
                                                    onChange={onInputChange}
                                                    placeholder={getTextByKey('contrasena_actual')}
                                                />
                                                 {errorPasswordactual ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorPasswordactual}</div>
                                                        </span> : null}
                                            
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group position-relative">
                                              
                                                <input
                                                    type="password"
                                                    className={`p-2 ${StyleGeneral.input}`}
                                                    name="password"
                                                    value={password? password : ""}
                                                    onChange={onInputChange}
                                                    placeholder={getTextByKey('contrasena_nueva')}
                                                />
                                               {errorPassword ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorPassword}</div>
                                                        </span> : null}
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group position-relative">
                                              
                                                <input
                                                    type="password"
                                                    className={`p-2 ${StyleGeneral.input}`}
                                                    name="passwordrepetir"
                                                    value={passwordrepetir ? passwordrepetir : ""}
                                                    onChange={onInputChange}
                                                    placeholder={getTextByKey('repetir_contrasena_nueva')}
                                                />
                                               
                                            </div>
                                               </div>*/}
                                          
                                        <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group">
                                              
                                                <select  name="departamento"  id="departamento" value={departamento} onChange={handleDpto} className={`p-2 ${StyleGeneral.input} ${StyleText.input}`} >
                                                        <option value={0}>{getTextByKey('departamento')}</option>
                                                        {
                                                            departamentos.map(dpto => (
                                                                <option key={dpto.id}  value={dpto.id}>{dpto.nombre}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {errorDpto ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorDpto}</div>
                                                        </span> : null}
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group">
                                                <select  name="ciudad"  value={ciudad} id="ciudad" className={`p-2 ${StyleGeneral.input} ${StyleText.input}`}  onChange={onInputChange} >
                                                        <option value={0}>{getTextByKey('ciudad')}</option>
                                                        {
                                                            ciudades.map(city => (
                                                                <option key={city.id} value={city.id}>{city.nombre}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {errorCiudad ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorCiudad}</div>
                                                        </span> : null}
                                            </div>
                                        </div>
                                         <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group">
                                                 <input
                                                    type="text"
                                                    className={`p-2 ${StyleGeneral.input}`}
                                                    name="direccion"
                                                    value={direccion}
                                                    onChange={onInputChange}
                                                    placeholder={getTextByKey('direccion')}
                                                />
                                                 {errorDireccion ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorDireccion}</div>
                                                        </span> : null}
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 mb-4">
                                            <div className="form-group">
                                                 <input
                                                    type="text"
                                                    className={`p-2 ${StyleGeneral.input}`}
                                                    name="barrio"
                                                    value={barrio}
                                                    onChange={onInputChange}
                                                    placeholder={getTextByKey('barrio')}
                                                />
                                                 {errorBarrio ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorBarrio}</div>
                                                        </span> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 text-center text-lg-start">
                                        {/* <button type="button" data-bs-toggle="modal" data-bs-target="#guardarModal" className="btn-g py-2 px-3 px-xl-5">{getTextByKey('guardar')}</button> */}
                                        <button type="button"  onClick={handleShowModal} className={`${StyleButton.btn}`}>{getTextByKey('guardar')}</button>
                                    </div>
                                    {errors && <p className="mt-4 err_login">{errors}</p>}
                                    {success && <p className="mt-4 source-sans-bold">Se ha guardado la información exitosamente</p>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalCambiarPasswordComponent />
            <ModalGuardarCuentaComponent />
            <ModalGuardar  showModal={showModal} setShowModal={setShowModal} load={load} saveFn={onSubmit}/>
        </Fragment>
    )
}