import React, { Fragment } from "react";
import { images_products_route } from "../api/productApi";
import { isIOS } from "react-device-detect";
import ImageLoader from "./ImageLoaderComponent";


function Picture(props) {

  let imgRoute = images_products_route;
  const dummy = props.dummy;
  //console.log("dummy in picture", dummy);

  if(props.route){
    imgRoute = props.route;
  }

  if(props.src){
    if(props.src.startsWith('/') ){
      imgRoute = '';
    }
  }else{
    imgRoute = '';
  }

  let iOSVersion = null;

  if (isIOS) {
    const iOSVersionMatch = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (iOSVersionMatch) {
      iOSVersion = parseInt(iOSVersionMatch[1], 10);
    }
  }

  imgRoute += props.src;

  return (
    <Fragment>
      {/* {isIOS && (
        <p>iOS version: {iOSVersion}</p>
      )}
      {!isIOS && (
        <p>Not an iOS device</p>
      )} */}

      { iOSVersion > 13 || !isIOS ? (
        <ImageLoader src={imgRoute} styleGroup={props.styleGroup} alt="Descripción de la imagen" dummy={dummy}/>
      ) : (
        <picture>
          <img className={`w-100 ${props.styleGroup}`} src={imgRoute} alt={props.alt} loading="lazy"/>
        </picture>
      )}

      {/* {!isIOS && (
        <Fragment>
          <picture>
            <source srcSet={imgRoute +'?format=webp'} type="image/webp"/>
            <source srcSet={imgRoute +'?format=jpg'} type="image/jpeg"/>
            <img className={`w-100 ${props.styleGroup}`} src={imgRoute} alt={props.alt} loading="lazy"/>
          </picture>
          <ImageLoader src={imgRoute} styleGroup={props.styleGroup} alt="Descripción de la imagen" dummy={dummy}/>
        </Fragment>
      )} */}
    </Fragment>
    
  );
}

export default Picture;
