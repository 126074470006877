import sellyApi from './sellyApi'

export const createSuscribe = async (inputData,setMessage) => {
  try {
    const data = await sellyApi.post(`/user/Suscribe`, inputData)
    console.log('suscribe', data)
    if (data?.status === 200) {
           
      setMessage('Suscripción exitosa');
      return true;
    }
  } catch (error) {
    if(error?.response.status === 404){
      setMessage('El usuario ya tiene una suscripción activa');
    }
    else{
      setMessage('Error en la suscripción');
    }
    throw error;
  }
}

export default { createSuscribe }
