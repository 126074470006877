import React from "react";
import styleTittle from "../scss/text.module.scss";


const TittleH5 = ({ children, styleGroup }) => {
  
  return (
    <h5 className={`${styleGroup} ${styleTittle.h2}`}>{children}</h5>
  );
};

export default TittleH5;