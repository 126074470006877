import React from "react";
import styleText from "../scss/text.module.scss";


const TextBlockSmall = ({ children, styleGroup }) => {
  
  return (
    <p className={`${styleGroup} ${styleText.p}`}><small>{children}</small></p>
  );
};

export default TextBlockSmall;