import React, { useState } from "react";
import { ContactComponent } from "./components/ContactoComponent";
import { useApi } from "../../context/apiContext";
import { Icon } from '@iconify/react';
import { getPqrsCategories, sendPqrs } from "../../api/pqrsApi";
import { useForm } from "../../hooks/useForm";
import ModalPqrsMandado from "../../components/ModalPqrsMandado";
import TittleH4 from "../../components/TittleH4Component";
import styeleTittle from "../../scss/text.module.scss";
import StyleGeneral from '../../scss/general.module.scss';
import StyleContect from '../../scss/contact.module.scss';
import StyleBtn from '../../scss/btn.module.scss';
import TextBlock from "../../components/TextBlockComponent";
import Banner from "../../components/BannerComponent";
import { getImages } from '../../api/imageApi';
import TittleH6 from "../../components/TittleH6Component";

import { images_products_route } from "../../api/productApi";

const initialState = {
  name: '',
  surname: '',
  type: 0,
  department: '',
  email: '',
  telephone: '',
  comment: '',
}


export default function ContactoPage() {

  const { getTextByKey, getTextBigByKey, currentLocale } = useApi();
  const [categories, setCategories] = React.useState(false);
  const [category, setCategory] = React.useState(false);
  const { onInputChange, setFormState, formState,phoneRegex,nameRegex,emailRegex, type, name, surname, email, telephone, comment } = useForm(initialState);
  const [sending, setSending] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [modalText, setModalText] = React.useState("");
  const [contactImg, setContactImg] = useState('')
  const [emailError, setEmailError] = useState('');
  const [errorType, seterrorType] = useState('')
  const [ errorNombre, setErrorNombre ] = useState('');
   const [ errorApellido, setErrorApellido ] = useState('');
   const [ errorTel, setErrorTel ] = useState('');
   const [ errorComentario, setErrorComentario] = useState('');
   const [ error, setError] = useState('');

  const [ success, setSucces] = useState('');


  React.useEffect(() => {

    getImages('contactImg').then((images) => {
      // console.log("images: " + JSON.stringify(images));
      setContactImg(images)
    })

    getPqrsCategories(currentLocale).then(data => {
      if (data) {
        if (data.length) {
          setCategories(data);
        }
      }
    })
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    setError('');
    setSucces('');
    const validar= await validateForm();
    if(!validar){
      try {
        const data = await sendPqrs(formState);
        //setShowModal(true);
        setFormState(initialState);
        setSucces(1);
        setSending(false);
      } catch (error) {
         setSucces(0);
         setError(1);
        setSucces('');
        setSending(false);
      }
    }else{
      setSending(false);
    }
  }


  const validateForm = ()=> {
    let errorFound = false;
    if (!type || type==0) {
      seterrorType('Campo obligatorio');
    }else{
      seterrorType('');
    }
    
    if (!name) {
        errorFound = true;
        setErrorNombre('Este campo es requerido');
    } else {
        if(!name.match(nameRegex)){
            errorFound = true;
            setErrorNombre('Formato inválido, este campo solo recibe letras');
        }else{
            setErrorNombre('');
        }
    }
        
    if(!surname){
      errorFound = true;
      setErrorApellido('Campo obligatorio');
    }else{
        if(!surname.match(nameRegex)){
          errorFound = true;
          setErrorApellido('Formato inválido, este campo solo recibe letras');
      }else{
          setErrorApellido('');
      }
    }
        
   
    if (!telephone.match(phoneRegex)){
        errorFound = true;
        setErrorTel("El teléfono no tiene un formato válido");
    }else{
        setErrorTel('');
    }

    if(!email.match(emailRegex)){
      errorFound = true;
      setEmailError("El email no es valido");
    }else{
      setEmailError('');
    }

    if(!comment){
      errorFound = true;
      setErrorComentario("Campo Obligatorio");
    }else{
      setErrorComentario('');
    }
  console.log(errorFound);    
    return errorFound;
}


  if (!categories)
    return (
      <div className='container'>
        <div className="text-center-c">
          <div className="loader-box">
            <img src="/img/SVG/loader.svg" alt="" />
          </div>
        </div>
      </div>
    );

  return (
    <>
      <div className={`container-fluid`}>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-7 d-flex align-items-strech px-0">
            {contactImg.length > 0 &&
            <div className={`position-relative w-100 h-100 ${StyleGeneral.minHeightScale} ${StyleGeneral.backgroundSize}`} style={{ backgroundImage: `url(${images_products_route + contactImg[0].image})`}}>
              <div className={`p-lg-2 w-100 ${StyleContect.tagBanner}`}>
                <TittleH6 styleGroup={`white-color mb-2 mb-md-0 d-flex align-items-center mx-4 mx-md-2 mx-lg-4`}>
                    {getTextByKey('contact_head')}</TittleH6>
                <TittleH6 styleGroup={`white-color mb-2 mb-md-0 d-flex align-items-center mx-4 mx-md-2 mx-lg-4`}>
                  <Icon className={`white-colo me-2 ${StyleContect.bigIco}`} icon="material-symbols:phone-enabled-outline-sharp"></Icon>
                    {getTextByKey('contact_mobile')}
                </TittleH6>
                <TittleH6 styleGroup={`white-color mb-2 mb-md-0 d-flex align-items-center mx-4 mx-md-2 mx-lg-4`}>
                  <Icon className={`white-colo me-2 ${StyleContect.bigIco}`} icon="material-symbols:mail-outline"></Icon>
                    {getTextByKey('Contact_email')}  
                </TittleH6>
              </div>

              {contactImg.length > 0 &&
                <Banner
                  heightBanner="h-100"
                  styleGroup="col-lg-8 mb-lg-5" alt="Rosmi"
                  titleHead={contactImg[0].names[0].titulo}
                  contentText={contactImg[0].names[0].descripcion}
                  smallBanner
                  titlecolor="white-color"/>
              }
            </div>
            }
          </div>
          <div className="col-12 col-md-8 offset-md-2 offset-lg-0 col-lg-5">
            <div className="form py-5 px-3 px-lg-4 py-lg-3 px-xl-5 px-xl-4">
              <form className="" onSubmit={handleSubmit}>
                <div className="form-group mb-3 mb-md-4">
                  <select value={type}
                    className={`px-3 py-2 ${errorType ? 'is-invalid' : ''} ${StyleGeneral.input} ${styeleTittle.input}`}
                    id="motivo"
                    name="type"
                    onChange={onInputChange}
                  >
                    <option disabled={true} value="0">
                      {getTextByKey('Contact_Category')}
                    </option>
                    {
                      categories.map(category_ => (
                          <option value={category_.id}>{category_.name}</option>
                      ))
                    }
                  </select>
                  {errorType && <div className="invalid-feedback">{errorType}</div>}
                </div>

                <div className="form-group mb-3 mb-md-4">
                  <input type="text"
                    className={`px-3 py-2  ${errorNombre ? 'is-invalid' : ''} ${StyleGeneral.input} ${styeleTittle.input}`}
                    id="name"
                         name="name"
                    value={name}
                    placeholder="Nombre*"
                         onChange={onInputChange}
                  />
                   {errorNombre && <div className="invalid-feedback">{errorNombre}</div>}
                </div>
               


                <div className="form-group mb-3 mb-md-4">
                  <input type="text"
                    className={`px-3 py-2  ${errorApellido ? 'is-invalid' : ''} ${StyleGeneral.input} ${styeleTittle.input}`}
                    id="lastname"
                         name="surname"
                    value={surname}
                    placeholder="Apellido*"
                         onChange={onInputChange}
                  />
                    {errorApellido && <div className="invalid-feedback">{errorApellido}</div>}
                </div>
              

                <div className="form-group mb-3 mb-md-4">
                  <input
                    type="email"
                    name="email"
                    className={` px-3 py-2 ${emailError ? 'is-invalid' : ''} ${StyleGeneral.input}  ${styeleTittle.input}`}
                    id="email"
                    value={email}
                    placeholder="Correo electrónico*"
                    onChange={onInputChange}
                  />
                  {emailError && <div className="invalid-feedback">{emailError}</div>}
                </div>
                <div className="form-group mb-3 mb-md-4">
                  <input type="tel"
                    className={`px-3 py-2  ${errorTel ? 'is-invalid' : ''}  ${StyleGeneral.input}  ${styeleTittle.input}`}
                         name="telephone"
                    id="phone"
                    value={telephone}
                    placeholder="Teléfono*"
                    onChange={onInputChange} />
                      {errorTel && <div className="invalid-feedback">{errorTel}</div>}
                </div>
              
                <div className="form-group mb-3 mb-md-4">
                  <textarea
                    className={`px-3 py-2 ${errorComentario ? 'is-invalid' : ''} ${StyleGeneral.textarea} ${styeleTittle.input} ${StyleContect.textarea}`}
                    id="message"
                    name="comment"
                    value={comment}
                    placeholder="Mensaje"
                    onChange={onInputChange}
                  />
                    {errorComentario && <div className="invalid-feedback">{errorComentario}</div>}
                </div>
            
                <button type="submit" className={`${StyleBtn.btn} ${sending ? StyleBtn.active :''}`} disabled={sending}>
                  {getTextByKey('Contact_btn_enviar')}
                  <span className={StyleBtn.loadingContainer}>
                    <span className={StyleBtn.dot}>.</span>
                    <span className={StyleBtn.dot}>.</span>
                    <span className={StyleBtn.dot}>.</span>
                  </span>
                  </button>
                    
                  {error &&<>
                    <p className='mt-4 alert-danger'>{getTextByKey('Información_mandada_invalida')}</p>
                    <p className='alert-danger'> {getTextByKey('Información_mandada_invalida_sub')}</p>
                    </>
                  }
 
                   {success &&<>
                    <p className='mt-4 primary-color'>{getTextByKey('pqrs-mandado')}</p>
                    <p className='primary-color'>{ getTextByKey('pqrs-mandado-sub')}</p>
                    </>
                  }
              </form>
            </div>
          </div>
        </div>
      </div>


      {/* <div className="contacto my-4">
        <div className="container">
          <div className="row">
            <div className="col-xl-2 col-lg-3 col-md-5 offset-md-0 col-8 offset-2 offset-lg-0">
              <div className="primary-border-color box-contacto white-bg-color px-3 py-2">
                <p className="primary-color  md-p mb-2">{getTextByKey('servicio_cliente')}</p>
                <a href="" className="icon-info-banner mb-0">
                  <p className="gray-1-color d mb-0"><Icon className="primary-color mb-1" icon="ic:baseline-whatsapp" /> {getTextByKey('numero')}</p>
                </a>
                <a href="" className="icon-info-banner mb-0">
                  <p className="gray-1-color d mb-0"><Icon className="primary-color mb-1" icon="fluent:call-12-regular" /> {getTextByKey('numero')}</p>
                </a>
                <a href="" className="icon-info-banner mb-0">
                  <p className="gray-1-color d mb-0"><Icon className="primary-color mb-1" icon="material-symbols:mail-outline-sharp" /> {getTextByKey('mail')}</p>
                </a>
              </div>
            </div>
            <div className="col-lg-8 px-lg-5 px-4 pt-lg-5 pt-3">
              <div className="text-center">
                <h2 className="primary-color  py-lg-3 py-2">{getTextByKey('formulario')}</h2>
                <p className="gray-1-color" dangerouslySetInnerHTML={{ __html: getTextBigByKey("formulario_desc") }}></p>
              </div>
              <form onSubmit={handleSubmit} action="">
                <ul className="d-flex flex-wrap my-3 p-0">
                  {
                    categories.map((category_) => (
                      <li key={category_.id} className='list-style-none mx-auto'>
                        <label className="d-flex align-items-center radio-button-container">
                          <input type="radio" name="type" onChange={() => setCategory(category_)} onInput={onInputChange} value={category_.id} checked={category_.id == formState.type} />
                          <p className='gray-1-color  mb-0'>{category_.name}</p>
                        </label>
                      </li>
                    ))
                  }
                </ul>
                <div className="info secundary-bg-color primary-border-colorpx-3 py-2 mb-2 mb-md-4rounded mx-auto mb-5 sm">
                  <p>{category.information}</p>
                </div>
                <ContactComponent onInput={onInputChange} formState={formState} disabled={sending} />
              </form>
            </div>
          </div>
        </div>
      </div>
      <ModalPqrsMandado showMsg={showModal} setShowMsg={setShowModal} text={modalText} /> */}
    </>
  )
}
