import Modal from "react-bootstrap/Modal";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { getImages } from "../api/imageApi";
import { images_products_route } from "../api/productApi";
import { createSuscribe } from "../api/suscribeApi";

import { useForm } from "../hooks/useForm";
import { useApi } from "../context/apiContext";

import { isIOS, isAndroid } from "react-device-detect";

import TittleH4 from "../components/TittleH4Component";
import TittleH6 from "../components/TittleH6Component";
import TextBlockSmall from "./TextBlockSmallComponent";

import StyleGeneral from "../scss/general.module.scss";
import styeleTittle from "../scss/text.module.scss";
import styleText from "../scss/text.module.scss";
import StyleButton from "../scss/btn.module.scss";
import StyeGeneral from "../scss/general.module.scss";



const initialState = {
	name: "",
	email: "",
};
export default function ModalNewsletter({ showModal, setShowModal }) {
	const { getTextByKey, getTextBigByKey, currentLocale } = useApi();
	const { onInputChange, name, email,emailRegex } = useForm(initialState);
	const [suscribeteImg, setSuscribeteImg] = useState("");
	const [suscribeteImgMobile, setSuscribeteImgMobile] = useState("");
	const [closeImg, setCloseImg] = useState("");
	const [emailError, setEmailError] = useState("");
	const [checkNewsletter, setCheckNewsletter] = useState(false); 
	const [message, setMessage] = useState()
	const [error, setError] = useState()
	const [loading, setLoading] = useState(false)
	const [tipoerror, setTipoError] = useState(false)

	const sendSubmit = async (e) => {
		e.preventDefault();
		let errorFound = false;

		if (email.match(emailRegex)) {
			setEmailError("");
		} else {
			errorFound=true;
			setEmailError("Por favor ingrese un correo valido");
		}

		if (!checkNewsletter) {
			errorFound = true;
			setError('Debes aceptar términos y condiciones');
			return;
		}else{
			setError('');
		}

		if (!errorFound) {
			setLoading(true);
			const dataSubscribe = {
				name: name,
				email: email,
				lan: currentLocale,
			};
			await createSuscribe(dataSubscribe, setMessage).then((data)=>{
                 setTipoError(false);
				 setLoading(false);
			}).catch((error)=>{
                setTipoError(true);
				setLoading(false);
			});
			
		}
	};

	const validateEmail = (email) => {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(email);
	};

	useEffect(() => {
		getImages("modal_inicio").then((images) => {
			// console.log("images: " + JSON.stringify(images));
			setSuscribeteImg(images);
		});
	}, []);

	useEffect(() => {
		getImages("modal_inicio_mobile").then((images) => {
			// console.log("images: " + JSON.stringify(images));
			setSuscribeteImgMobile(images);
		});
	}, []);

	useEffect(() => {
		getImages("btn_close").then((images) => {
			// console.log("images: " + JSON.stringify(images));
			// console.log("images: " + JSON.stringify(images[0].image));
			setCloseImg(images);
		});
	}, []);

	return (
		<Fragment>
			<Modal
				size="lg"
				animation= {true}
				show={showModal}
				onHide={() => setShowModal(false)}
				id="ModalConfirmation"
				className="p-0">
				<Modal.Body className="modal-body p-0 overflow-hidden">
					<div className="container">
						<div className="row">
							<div className="col-7">
								<div className="form py-3 py-lg-5 px-2">
									<form className="" onSubmit={sendSubmit}>
										<TittleH4 styleGroup={`autography ${styleText.h4Special} quaternary-color`}>
											<div className="text-start" dangerouslySetInnerHTML={{__html: getTextBigByKey("suscribete_title")}}></div>
										</TittleH4>
										<TittleH6 styleGroup="black-color mb-lg-4 mb-3">
											<div dangerouslySetInnerHTML={{__html: getTextBigByKey("suscribete_pagraph")}}></div>
										</TittleH6>
										<div className="form-group">
											<input type="text" className={`px-3 py-2 mb-2 mb-md-4 ${StyleGeneral.input} `}
												id="name"
												name={"name"}
												value={name}
												placeholder="Nombre"
												onChange={onInputChange}
											/>
										</div>

										<div className="form-group">
											<input
												type="email"
												name={"email"}
												className={` px-3 py-2 mb-2 mb-md-4 ${
													emailError ? "is-invalid" : ""
												} ${StyleGeneral.input}  ${styeleTittle.input}`}
												id="email"
												value={email}
												placeholder="Correo electrónico"
												onChange={onInputChange}
											/>
											{emailError && (
												<div className="alert-danger">{emailError}</div>
											)}
										</div>
										<div className="form-group">
											<label className={StyeGeneral.containerCheckS}>
												<small className="black-color">
													{getTextByKey("he_leido")}
												</small>
												<input type="checkbox" onChange={(event) => {
														setCheckNewsletter(event.target.checked);
													}}
												/>
												<span className={`border border-dark ${StyeGeneral.checkmarkS}`}>
													<img src="/img/SVG/check.svg" />
												</span>
											</label>
											{error && <small className='alert-danger'>{error}</small>}
											{message && <small className={tipoerror ?'alert-danger':''}>{message}</small>}
										</div>
										<div className="form-group text-center">
											<div className="pb-2">
												<label className={`ps-0 ${StyeGeneral.containerCheckS}`}>
													<Link to={`/register`}>
														<small className="black-color">
															*{getTextByKey("texto-bono-suscripcion")}
														</small>
													</Link>
												</label>
											</div>
											<div>
												<button className={`px-lg-4 me-md-4 px-4 mb-4 mb-md-0 ${StyleButton.btn} ${loading ? StyleButton.active :''}`} onClick={() => setShowModal(false)}>
													No, gracias
													<span className={StyleButton.loadingContainer}>
														<span className={StyleButton.dot}>.</span>
														<span className={StyleButton.dot}>.</span>
														<span className={StyleButton.dot}>.</span>
													</span>
												</button>

												<button type="submit" className={`px-lg-4 px-4 ${StyleButton.btn} ${loading ? StyleButton.active :''}`} disabled={loading} >
													{getTextByKey("Contact_btn_enviar")}
													<span className={StyleButton.loadingContainer}>
														<span className={StyleButton.dot}>.</span>
														<span className={StyleButton.dot}>.</span>
														<span className={StyleButton.dot}>.</span>
													</span>
												</button>
												
											</div>
										</div>
									</form>
								</div>
							</div>
							<div className="col-5 d-flex align-items-strech px-0">
								{suscribeteImg.length > 0 && (
									<Fragment>
									{isIOS || isAndroid ? (
										<div className={`position-relative mobile w-100 ${StyleGeneral.minHeightModal} ${StyleGeneral.backgroundSize}`}
											style={{backgroundImage: `url(${images_products_route + suscribeteImgMobile?.[0]?.image})`, right: '-1px'}}
											>
											<button
												type="button"
												className="btn-close float-end p-3"
												data-bs-dismiss="modal"
												aria-label="Close"
												variant="white"
												onClick={() => setShowModal(false)}
												style={{backgroundImage:`url(${images_products_route + closeImg?.[0]?.image})`}}
											></button>
										</div>
									) : (
										<div className={`position-relative w-100 ${StyleGeneral.minHeightModal} ${StyleGeneral.backgroundSize}`}
											style={{backgroundImage: `url(${images_products_route + suscribeteImg?.[0]?.image})`, right: '-1px'}}
											>
											<button
												type="button"
												className="btn-close float-end p-3"
												data-bs-dismiss="modal"
												aria-label="Close"
												variant="white"
												onClick={() => setShowModal(false)}
												style={{backgroundImage:`url(${images_products_route + closeImg?.[0]?.image})`}}
											></button>
										</div>
									)}
									</Fragment>
								)}
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</Fragment>
	);
}
