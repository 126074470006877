import React from 'react'
import { Fragment } from 'react'
import TittleH6 from './TittleH6Component'
import styleButton from '../scss/buttonMap.module.scss'
import { Icon } from '@iconify/react'

export default function ButtonMapComponent({title, description, horarios, telefono, onClick}) {

    return (
        <Fragment>
            <button className={`w-100 mb-3 text-start ${styleButton.btnMap}`} onClick={onClick}>
                <TittleH6 styleGroup="mb-1 fw-bold">{title}</TittleH6>
                <span className={`w-100 mb-1 d-flex align-items-center ${styleButton.span}`}>{description}</span>
                {
                    telefono && (
                        <span className={`w-100 mb-1 d-flex align-items-center ${styleButton.span}`}>
                            <Icon icon="material-symbols:perm-phone-msg-outline" className={`pe-2 ${styleButton.bigIcon}`}></Icon>
                            {telefono}
                        </span>
                    )
                }
                {
                    horarios && (
                        <span className={`w-100 mb-1 d-flex align-items-center ${styleButton.span}`}>
                            <Icon icon="material-symbols:nest-clock-farsight-analog-outline-rounded" className={`pe-2 ${styleButton.bigIcon}`}></Icon>
                                    {horarios}
                        </span>
                    )
                }

            </button>
        </Fragment>
    )
}
