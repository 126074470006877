import { Fragment, useState, useEffect } from "react";
import { ProductComponent } from "../../components/productComponent";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { getRecommendedHome, getRecommendedHomeFav, getNewCollections, images_products_route } from "../../api/productApi";
import { getGallery } from "../../api/galleryApi";
import { useApi } from "../../context/apiContext";
import { Migas } from "../../components/Migas"
import { FacebookShareButton, TwitterShareButton } from "react-share";


import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';


import { useAuth } from '../../authContext';
import ModalLogUserComponent from "../../components/ModalLogUserComponent";
import { getBlog } from "../../api/blogApi";
import {createDateFormatTr, createDatetime} from "../../helpers/dateHelper";
import Picture from "../../components/PictureComponent";
import TextBlock from "../../components/TextBlockComponent";
import TittleH4 from "../../components/TittleH4Component";
import TittleH5 from "../../components/TittleH5Component";
import styleNews from '../../scss/news.module.scss'
import NewsComponent from "../../components/NewsComponent";


export default function AboutSingularBlogPage() {
    const { getTextByKey, currentLocale } = useApi();
    const [blog, setBlog] = useState();
    const [datetime, setDatetime] = useState();
    const [resDate, setResDate] = useState('')
    const [banner, setBanner] = useState('')
    const params = useParams();



    const getBreadcrumps = () => {
        const paths = [
            { to: '/noticias', name: 'Blogs' }
        ];
        if (blog) {
            paths.push({ to: '', name: blog.title })
        }
        return paths;
    };

    useEffect(() => {
        setBlog('');
        getBlog(params.id, currentLocale).then(data => {
            setBlog(data);
            if(data.images.length>0){
                let banner=data.images.find((image)=>image.type=='secundary')
                setBanner(banner);
            }
            setDatetime(createDatetime(data.created_at.date));
        });
    }, [params.id]);

    if (!blog)
        return (
            <div className='container'>
                <div className="text-center-c">
                    <div className="loader-box">
                        <img src="/img/SVG/loader.svg" alt="" />
                    </div>
                </div>
            </div>
        );

    return (
        <Fragment>
            <div className="internaBlog pb-5">
            
                <div className={`${styleNews.imgRecipeInternal}`}>
                    {/* <img src={blog.images[0].image} alt="Recipe" /> */}
                    <Picture src={banner?.image} alt="Rosmi" />
                </div>
                
                <div className="container py-2 py-lg-5">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className={`${styleNews.int}`}>
                                <TextBlock styleGroup={`gray-1-color w-100 fw-normal mb-0`} >
                                    <small>{createDateFormatTr(blog.created_at.date, getTextByKey)}</small>
                                </TextBlock>
                                <TittleH4 styleGroup={`mb-2 primary-color fw-600`}>{blog.title}</TittleH4>
                                <TittleH5 styleGroup={`mb-4 black-color fw-600`}>{blog.subtitle}</TittleH5>
                                <p className="black-color w-100 fw-normal" dangerouslySetInnerHTML={{__html:blog.content}}></p>
                               {/*} <TextBlock styleGroup={`black-color w-100 fw-normal`}>
                                    {blog.content}
                            </TextBlock>*/}
                            </div>

                            <div className={`${styleNews.pctrIntrn}`}>
                                <Picture src={blog.images[0].image} styleGroup={`mb-2 mb-lg-4`} alt="Rosmi"/>
                            </div>
                            {/*<TextBlock styleGroup={`black-color w-100 fw-normal`}>
                                Amet cras in tincidunt nunc ante tempor ut tincidunt. Mi phasellus vitae convallis purus velit risus in egestas. Tristique risus justo feugiat gravida facilisis. Lectus egestas augue erat in eleifend. Malesuada sed auctor duis vitae amet tellus iaculis.
                                Consequat pharetra elementum eget id tristique nunc. Leo duis aliquet cursus egestas semper proin. Auctor interdum curabitur pharetra tincidunt. Dignissim et porttitor at mauris. Habitasse sed sollicitudin libero nullam sit orci. Quam risus sodales sed facilisi non. Commodo eu sodales augue venenatis id arcu tortor eu scelerisque. Lacinia scelerisque semper erat sagittis ornare aliquet tortor turpis tristique. Quam id ridiculus nisl auctor augue tortor aenean.
                                Quis dolor ipsum ullamcorper egestas. Sagittis viverra dui nibh at bibendum maecenas leo at dignissim. Sagittis ut metus nisi suspendisse bibendum. Feugiat morbi odio sit adipiscing semper condimentum.
                         </TextBlock>*/}
                            
                        </div>
                        <div className="col-lg-3 offset-lg-2">
                            <article className={`${styleNews.artcl}`}>
                                <TextBlock styleGroup={`gray-1-color w-100 fw-normal pb-2 pb-lg-3 mb-0`} >
                                    Noticias relacionadas
                                </TextBlock>
                                <div className="container">
                                    <div className="row">
                                    {
                                        blog.relations.map(rel => (
                                            <div className="col-12 col-md-4 col-lg-12">
                                                <NewsComponent
                                                img={rel.image}
                                                title={rel.title}
                                                date={createDateFormatTr(rel?.created_at?.date,getTextByKey)}
                                                link={`/noticia/${rel.id}`}>
                                                <div dangerouslySetInnerHTML={{ __html: rel?.content?.slice(0, 100) + '...' }}></div>
                                                </NewsComponent>
                                            </div>
                                        ))
                                    }        
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="blog my-4">
                <div className="container">
                    <Migas paths={getBreadcrumps()} />
                </div>
                <div className="banner-entry-blog mb-3 mb-lg-5">
                    <div className="container-fluid gx-0">
                        <div className="row gx-0">
                            <div className="col-12 col-lg-5">
                                <div className="p-3 p-sm-5">
                                    <h1 className="primary-color fw-bold text-center text-lg-start">{blog.title}</h1>
                                    {
                                        datetime && <p className="mb-2">{getTextByKey(datetime.month)} {datetime.day}, {datetime.year}</p>
                                    }
                                    <p className="quaternary-color">{blog.subtitle}</p>
                                    <ul className="p-0 d-flex align-items-center mt-4 list-style-none social-media-sharing">
                                        <li className="me-3">
                                            <FacebookShareButton
                                                url={window.location.href}
                                            >
                                                <img src="/img/SVG/facebook-blue.svg" alt="Facebook icon" className="icon" />
                                            </FacebookShareButton>
                                        </li>
                                        <li className="me-3">
                                            <TwitterShareButton
                                                url={window.location.href}
                                            >
                                                <img src="/img/SVG/twitter-blue.svg" alt="Twitter icon" className="icon" />
                                            </TwitterShareButton>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-lg-7 px-3 px-lg-0">
                                <img src={images_products_route + blog.images[0].image} alt="Recipe" className="w-100 img-blog" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="txt-about-entry my-lg-5 my-3">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-11 col-lg-8" dangerouslySetInnerHTML={{ __html: blog.content }}>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="related entries my-lg-5 my-3 px-lg-5 px-3">
                    <div className="container">
                        <hr className="hr-color py-lg-3 py-2" />
                        <div className="text-center py-lg-3 py-2">
                            <h3 className="primary-color carrefour-sans-bold">{getTextByKey('noticias-relacionadas')}</h3>
                        </div>
                        <div className="row">
                            <div className="col-12 offset-lg-1 col-lg-10">
                                <Swiper modules={[Autoplay]}
                                    autoplay={{ delay: 4000, disableOnInteraction: true }}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                            spaceBetween: 0,
                                        },
                                        576: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        992: {
                                            slidesPerView: 3,
                                            spaceBetween: 15,
                                        },
                                    }}>
                                    {
                                        blog.relations.map(rel => (
                                            <SwiperSlide>
                                                <div>
                                                    <div>
                                                        <img src={images_products_route + rel.image} alt={rel.title} className="w-100" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <p className="mb-0 carrefour-sans-bold gray-1-color">{rel.title}</p>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </section>
            </div> */}
        </Fragment>
    );
}
