import React from "react";
import styleText from "../scss/text.module.scss";


const TextBlock = ({ children, styleGroup }) => {
  
  return (
    <p className={`${styleGroup} ${styleText.p}`}>{children}</p>
  );
};

export default TextBlock;