import { Fragment, useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { getRecommendedHome, getRecommendedHomeFav, getNewCollections, images_products_route,getProductDetail } from "../../api/productApi";

import { useApi } from "../../context/apiContext";

import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { getRecipe, getRecipeCategorie, getRelacionadas } from "../../api/recipesApi";
import { useCart } from "../../context/cartContext";
import { createDatetime, getMonthName } from "../../helpers/dateHelper";
import RecipeComponent from "../../components/recipeComponent";


import TittleH1 from "../../components/TittleH1Component";
import TittleH2 from "../../components/TittleH2Component";
import TextBlock from "../../components/TextBlockComponent";
import StyleText from "../../scss/text.module.scss";
import StyleRecipe from '../../scss/recipe.module.scss';
import { useAuth } from '../../authContext';
import ReactGA from 'react-ga4';
import { AlertComponent } from "../../components/AlertComponent";


const hiddenStyle = {overflow: 'hidden', maxHeight: '200px'};

export default function RecipePage() {

    const { getTextByKey, currentLocale } = useApi();
    const [recipe, setRecipe] = useState(false);
    const [categories, setCategories] = useState(false);
    const [datetime, setDatetime] = useState(false);
    const { addSeveralToCart, showCart, checkIfExistInCart,addToCart,tiendaGeneral,addToCartOld } = useCart();
    const [showInfo, setShowInfo] = useState(false);
    const [recipes, setRecipes] = useState([]);
    const { currentUser } = useAuth();

    const [errors, setErrors] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [disable, setDisabled] = useState(0);
    const handleShowModal = () => setShowAlert(true);
    const handleCloseModal = () => setShowAlert(false);

    const params = useParams();

    useEffect(() => {
        getRecipe(params.id, currentLocale).then((data)=>{
             setRecipe(data);
        }).catch((error)=>{
       
        })
        /*const serverData = [];
        serverData.push(getRecipe(params.id, currentLocale))
        serverData.push( getRecipeCategories(currentLocale))

        Promise.all(serverData).then(data => {
            console.log("data received--> ", data);
            const recipe = data[0];
            setRecipe(recipe);
            setDatetime(createDatetime(recipe.created_at.date));
            
            const recipeCategories = data[1];
            getCategories(recipe, recipeCategories);
        })*/
    }, [params.id]);


    useEffect(() => {
        localStorage.removeItem('volver-sitio');
        getRelacionadas(params.id, currentLocale).then((data) => {
            setRecipes(data);
        }).catch((error)=>{
        });
    },[params.id]);

    const getCategories = (recipe_, recipeCategories) => {
        const categoriesIds = recipe_.categories.map(cat => cat.id);
        const recipeFilteredCategories = recipeCategories.filter(cat => categoriesIds.includes(cat.id));
        setCategories(recipeFilteredCategories);
    }

    const getBreadcrumps = () => {
        const paths = [
            {to: '/recetas', name: 'Recetas'}
        ];
        if (recipe) {
            paths.push({to: '', name: recipe.title})
        }
        return paths;
    };

    const showToggle = () => {
        setShowInfo(!showInfo);
    }

    
  const addCart = (productData,cant) => {
       /* console.log("addCart", productData); */
    ReactGA.event({
      category:'ecommerce',
      action: 'add_to_cart',
      value: (productData.price?.precio)*cant,
      currency: "COP",
      items :[
        {
          item_id:productData?.plu,
          item_name: productData.name?.nombre,
          item_category: productData.categorias?.[0]?.nombre,
          price: productData.price?.precio,
          quantity: cant
        }
      ]
    });
    let sigue=0;
    if(currentUser){
      if(currentUser.isAnonymous){ 
        if (!tiendaGeneral) {
          sigue=1;
        }
       
      }else{
        if (!tiendaGeneral) {
                
          sigue=2;
        }
      }
    }else{
     
      sigue=1;
    }
    productData.type = "product";
    if(sigue!=0){

      addToCartOld(productData, cant);
      localStorage.setItem('volver-sitio',window.location.href)
      if(sigue==1){
        location.href = "/checkout-a";
      }else{
        location.href = "/direccion-envio";
      }
      return;
    }
    setDisabled(0);
    addToCart(productData, cant);
    showCart();
  }



    const addCant = (e) => {
       
     
    if(e.target.checked){
        const recipex=recipe?.ingredientes.find((item)=>item.id==e.target.value);
       
       if(recipex){
        if(recipex.producto){
            setDisabled(e.target.value)
            console.log(recipex.producto); 
            getProductDetail(recipex.producto,tiendaGeneral).then((data) => {
                console.log(data,recipex.cantidad);
                if(data?.cantidad){
                   if(data.cantidad==0){ 
                    localStorage.removeItem('volver-sitio');
                    setErrors('El producto no se encuentra disponible');
                    handleShowModal();
                    setDisabled(0);
                     return;
                   }
                   if(recipex.cantidad>data.cantidad){
                    recipex.cantidad=data.cantidad;
                   }
                   addCart(data,recipex.cantidad);
                }else{
                    localStorage.removeItem('volver-sitio');
                    setErrors('El producto no se encuentra disponible');
                    handleShowModal();
                    setDisabled(0);
                    console.log('unoo');
                }
            }).catch((error)=>{
                console.log(error);
                localStorage.removeItem('volver-sitio');
                setErrors('El producto no se encuentra disponible');
                setDisabled(0);
                handleShowModal();
            })
        }
       }
    }
   
    }

    if(!recipe)
        return (
        <div className='container'>
            <div className="text-center-c">
            <div className="loader-box">
                <img src="/img/SVG/loader.svg" alt="" />
            </div>
            </div>
        </div>
        );

    return (
        <Fragment>
            {recipe &&
            <div className="recipe-page">
                
                    <div className="container-fluid g-0">
                        <div className={`row align-items-end g-0 position-relative ${StyleRecipe.rowPosition}`}>
                            <div className="col-12 col-xl-8">
                                <div className={`${StyleRecipe.imgRecipeInternal}`}>
                                    <img src={images_products_route + recipe.images[0].image} alt="Recipe"/>
                                </div>
                            </div>
                            <div className={`secundary-bg-color white-color p-3 p-lg-4 ${StyleRecipe.boxRecipeInternal}`}>
                                <div className="d-flex align-items-center mb-3">
                                    <img src="/img/SVG/clock-blanco.svg" alt="" />
                                    <span className="ms-2">{recipe.tiempo}</span>
                                </div>
                                <TittleH1>{recipe.title}</TittleH1>
                                <p className={`${StyleText.p}`} dangerouslySetInnerHTML={{__html: recipe.resumen}}></p>
                               </div>
                        </div>
                    </div>
                

                <div className="ingredients-preparation-recipe my-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-6">

                              
                              
                                {recipe?.ingredientes && recipe.ingredientes.length > 0 && (
                                <div className={`p-4 my-2 border ${StyleText.contentAdmin}`}>
                                    <h2 className="primary-color fw-bold">{getTextByKey('ingredientes')}</h2>
                                    {recipe.ingredientes.map((ingrediente, index) => (
                                        <Fragment>
                                            <div className="d-flex justify-content-start align-items-center">
                                            <label className={StyleRecipe.containerCheckS}>
                                            <input type='checkbox' id={`ingrediente${index}`} disabled={ingrediente.id==disable} value={ingrediente.id} onChange={(event) => {addCant(event)}}/>
                                                <small className="white-black">{ingrediente.titulo}</small>
                                                <span className={StyleRecipe.checkmarkS}></span>
                                            </label>
                                            
                                            {ingrediente.id== disable ?
                                                <>
                                                    <img className={StyleRecipe.loaderIngredient} src="/img/SVG/loader.svg" alt=""/>
                                                </> 
                                                :
                                                <></>
                                            }
                                            </div>
                                        </Fragment>                          
                                   ))}
                                   
                                </div>
                                )}

                               {recipe?.preparaciones && recipe.preparaciones.length > 0 && (
                                <div className={`p-4 my-2 border ${StyleText.contentAdmin}`}>
                                    <h2 className="primary-color fw-bold">{getTextByKey('preparacion')}</h2>
                                    {recipe.preparaciones.map((preparacion, index) => (
                                    <label className={StyleRecipe.containerCheckS}>
                                        <input type='checkbox' id={`preparacion${index}`}/>
                                        <small className="white-black">{preparacion.titulo}</small>
                                        <span className={StyleRecipe.checkmarkS}></span>
                                    </label>
                                   ))}
                                   
                                </div>)
                                }  
                             
                            </div>
                        </div>
                    </div>
                </div>

                {recipe?.video && recipe.video.length > 0 &&
                    <div className="video-recipe">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10">
                                    <iframe className={`${StyleRecipe.video}`} src={recipe.video} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
                <AlertComponent title="Ups" msn={errors} type="error"
                                            showAlert={showAlert}
                                             handleShowModal={handleShowModal}
                                            handleCloseModal={handleCloseModal}/>

                { recipes.length && 
                <div className="recipes-interesting quinaryA-bg-color mt-5 py-5">
                    <TittleH2 styleGroup="quinary-color fw-bold text-center mb-4">Recetas que podrian interesarte</TittleH2>
                    <div className="pb-3 pb-xl-5">
                        <Swiper modules={[Navigation, Autoplay]}
                                    navigation
                                    autoplay={{ delay: 6000, disableOnInteraction: true }}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                            spaceBetween: 0,
                                        },
                                        576: {
                                            slidesPerView: 2,
                                            spaceBetween: 0,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 0,
                                        }
                                    }}>
                                {recipes.length? recipes.map((recipe, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="p-3">
                                            <RecipeComponent key={index} recipe={recipe} />
                                        </div>
                                    </SwiperSlide>
                                ))
                                : null}
                        </Swiper>
                    </div>
                </div>
                }
        </div>
       
       
       }

        
        </Fragment>
    );
}
