import React from "react";
import styleTittle from "../scss/text.module.scss";


const TittleH6 = ({ children, styleGroup }) => {
  
  return (
    <h6 className={`${styleGroup} ${styleTittle.h3}`}>{children}</h6>
  );
};

export default TittleH6;