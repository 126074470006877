import { Fragment } from "react";
import { Icon } from '@iconify/react';
import { NavComponent } from "./components/NavComponent";
import { useApi } from "../../context/apiContext";
import { useAuth } from "../../authContext";
import { useState } from "react";
import {createAddressForUser, listAddressUser} from "../../api/addressApi";
import { useEffect } from "react";

import StyleGeneral from "../../scss/general.module.scss";
import StyleButton from "../../scss/btn.module.scss";

import TittleH1 from "../../components/TittleH1Component";
import TextBlock from "../../components/TextBlockComponent";
import StyleText from "../../scss/text.module.scss";
import {useForm} from "../../hooks/useForm";
import {getCiudades, getDepartamentos} from "../../api/productApi";
import { useCart } from '../../context/cartContext';
import { getStore } from '../../api/storeApi';

import { Collapse } from "react-bootstrap";
import { AlertComponent } from "../../components/AlertComponent";


const initialState = {
    departamento: 0,
    city: 0,
    direccion: "",
    complement: ""
}


export default function DireccionesPage() {
    const { currentUser } = useAuth();
    const { updateDireccion,validarCart,updateTienda } = useCart();
    const [addressList, setAddressList] = useState([]);
    
    const { getTextByKey, getTextBigByKey, currentLocale } = useApi();
    
    const {formState,departamento,city,complement,direccion, onInputChange} = useForm(initialState)
    const [ciudades, setCiudades] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [ dptoId, setDptoId ] = useState('');
    const [ dirsel, setDirSel ] = useState(localStorage.getItem('cart_shido_direccion',0));
    
   
    const [errorDpto, setErrorDpto] = useState('');
    const [errorCiudad, setErrorCiudad] = useState('');
    const [errorDireccion, setErrorDireccion] = useState('');
    const [errors, setErrors] = useState("");
    const [load, setLoad] = useState(false);
    const [loadingDir, setLoadDir] = useState(false);

    const changedir =async(e)=>{
        e.preventDefault();
        let dirsel=e.target.value;
        setDirSel(dirsel);
    }
    const selectDir =async(e)=>{
        e.preventDefault();
        let dirsel=e.target.elements[0].value;
        let dir= addressList.find((address)=>address.id==dirsel);
        console.log(dir);
        setLoadDir(true);
        if(dir){
          updateDireccion(dirsel);
          let city=dir.ciudades[0].id;
          let tienda= await getStore(parseInt(city)) 
          if(tienda.id){
            updateTienda(tienda.id);
            let carritoajustar =await validarCart(tienda.id);
            console.log(carritoajustar);
            if(carritoajustar.ok){
             if(carritoajustar.total>0){
                let mensaje=getTextByKey('productos-eliminados-car')+': ';
                mensaje += carritoajustar.eliminados.map((eliminado) => eliminado.name.nombre).join(', ');
                setErrors(mensaje);
                handleShowModal();
              }
              
            }
            
            setTimeout(() => {
                if(carritoajustar.total==0){
                    if(localStorage.getItem('volver-sitio')){
                        location.href=localStorage.getItem('volver-sitio');
                    }else{
                    location.href='/tienda';
                    }
                }
           }, 4000);
          
          }else{
           setErrors('No tiene cobertura');
           handleShowModal();
           setLoadDir(false);
          }
        }
       
       
    }
    const resertError=()=>{
       setErrorDpto('');
       setErrorCiudad('');
       setErrorDireccion('');
              
    }
    const submit = async (e) => {
        e.preventDefault();
        setLoad(true);
        resertError();
        const user = currentUser.uid;
        const validate= await validaForm();
        if(!validate){
        const dataToSend = {...formState, user};
              const data = await createAddressForUser(dataToSend).then(async(response)=>{
                try{
                if(response.id){
                    let tienda= await getStore(parseInt(city)) 
                   if(tienda.id){
                     updateTienda(tienda.id);
                     let carritoajustar =await validarCart(tienda.id);
                     console.log(carritoajustar);
                     if(carritoajustar.ok){
                      if(carritoajustar.total>0){
                         let mensaje=getTextByKey('productos-eliminados-car')+': ';
                         mensaje += carritoajustar.eliminados.map((eliminado) => eliminado.name.nombre).join(', ');
                         setErrors(mensaje);
                         handleShowModal();
                       }
                       
                     }
                     updateDireccion(response.id);
                     setLoad(false);   
                     setTimeout(() => {
                       
                        if(carritoajustar.total==0){
                          location.href='/direccion-envio';
                        }
                    }, 4000);
                   
                   }else{
                    setLoad(false);
                    setErrors('No tiene cobertura');
                    handleShowModal();
                   }
                   
                   //window.location.href='/checkout-c';
                 }else{
                     location.href='/direccion-envio';
                 }
             
            }catch(error){
                console.log(error);
                setLoad(false);

            }   
                //location.href = "/direccion-envio";
          }).catch((error)=>{
            setErrors(error.message);
            setLoad(false);
            handleShowModal();
          });
        }else{
            setLoad(false);
        }
        //resetError();
    }
    const validaForm =()=>{
     
       let valida=false;
       console.log(departamento);
       if(departamento==0){
        setErrorDpto('Seleccione un departamento');
        valida=true;
       }
       if(city==0){
        setErrorCiudad('Seleccione una ciudad');
        valida=true;
       }
       if(!direccion){
        setErrorDireccion('La dirección es obligatoria');
        valida= true;
       }
       return valida;
  
    }

    const handleDpto = (event)=>{
        setDptoId(event.target.value)
        onInputChange(event);
    }

    useEffect( () => {
        getDepartamentos(currentLocale).then(data => {
           if (data && Array.isArray(data)) {
                setDepartamentos(data);
            }
        });

        listAddressUser(currentUser.uid).then((addressResponse) => {
            setAddressList(addressResponse);
           
        })
    }, [] )

    useEffect(()=>{
        if(dptoId){
            getCiudades(dptoId).then((data) => {
                console.log('data', data);
                setCiudades(data);
            });
        }
    },[dptoId])

    const [openItem, setOpenItem] = useState({
        1: false,
    });

    const toggleOpenItem = (itemId) => {
        setOpenItem((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };

    const [showAlert, setShowAlert] = useState(false);

    const handleShowModal = () => setShowAlert(true);
    const handleCloseModal = () => setShowAlert(false);

    return (
        <Fragment>
            <div className="py-5 gray-2-bg-color">
                <div className="container-fluid pb-5">
                    <div className="row">
                        <div className="col-12 text-center mb-4">
                            <TittleH1 styleGroup={`primary-color autography ${StyleText.h4Special}`}>{getTextByKey('perfil')}</TittleH1>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4 px-xl-5 mb-4">
                            <NavComponent />
                        </div>
                        <div className="col-12 col-lg-8 px-xl-5 mb-4">
                            <div className="border white-bg-color p-4 p-xl-5">
                                <div className="row">
                                    <div className="col-12 col-xl-10">
                                    {addressList.length === 0 ?
                                        <Fragment>
                                            <div className="text-center text-lg-start">
                                                <p className="">{getTextByKey('no_tienes_direccion')}</p>
                                            </div> 
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <label htmlFor="s-direcciones" className="mb-3">Selecciona una dirección para tus envíos</label>
                                            <form className="border p-3 p-xl-4" onSubmit={selectDir}>
                                                <div className="row">
                                                    <div className="col-xl-10">
                                                        <label htmlFor="s-direcciones" className="mb-3">Usa una de estás direcciones</label>
                                                        <div className="d-flex align-items-center">
                                                            <select name="dir" id="dir"  value={dirsel} onChange={changedir} className={`p-2 pe-4 ${StyleGeneral.input}`}>
                                                            {
                                                                addressList.map((dir) => (
                                                                    <option key={dir.id} value={dir.id}>{dir.address} {dir.ciudades[0].nombre}</option>
                                                                ))
                                                            }
                                                            </select>                                                            
                                                        </div>
                                                        <div className="mt-4">
                                                            <button type="submit" disabled={loadingDir} className={`${StyleButton.btn} ${loadingDir ? StyleButton.active :''}`}>
                                                                {getTextByKey('enviar_direccion')}
                                                                        <span className={StyleButton.loadingContainer}>
                                                                    <span className={StyleButton.dot}>.</span>
                                                                    <span className={StyleButton.dot}>.</span>
                                                                    <span className={StyleButton.dot}>.</span>
                                                                    </span>
                                                                                                                 
                                                                </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </Fragment>
                                        }
                                        <div className="py-4">
                                            <button className={`${openItem[1] == true ?  StyleButton.btnSecundary : StyleButton.btn}`}
                                                onClick={() => toggleOpenItem(1)}
                                                aria-controls="collapseOne"
                                                aria-expanded={openItem[1]}>{openItem[1] == true ? 'Cancelar' : 'Crear dirección'}</button>
                                        </div>

                                        <AlertComponent title="Ups" msn={errors} type="error"
                                            showAlert={showAlert}
                                            handleShowModal={handleShowModal}
                                            handleCloseModal={handleCloseModal}/>

                                        <Collapse in={openItem[1]}>
                                            <form onSubmit={submit}>
                                                <div className="py-4">
                                                    <TextBlock>Añadir nueva dirección</TextBlock>
                                                    {/* {errors ? <div className='err_login'>{errors}</div> : null} */}
                                                    {/* {errors ? handleShowModal() : null } */}
                                                    <div className="mb-4">
                                                        <select  name="departamento" id="departamento" onChange={handleDpto} className={`p-2 ${StyleGeneral.input} ${StyleText.input}`} >
                                                            <option value={0}>Departamento</option>
                                                            {
                                                                departamentos.map(dpto => (
                                                                    <option key={dpto.id} value={dpto.id}>{dpto.nombre}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        {errorDpto ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorDpto}</div>
                                                        </span> : null}
                                                    </div>
                                                    <div className="mb-4">
                                                        <select  name="city" id="city" onChange={onInputChange} className={`p-2 ${StyleGeneral.input} ${StyleText.input}`} >
                                                            <option value={0}>Ciudad</option>
                                                            {
                                                                ciudades.map(ciudad => (
                                                                    <option key={ciudad.id} value={ciudad.id}>{ciudad.nombre}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        {errorCiudad ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorCiudad}</div>
                                                        </span> : null}
                                                    </div>
                                                    <div className="mb-4">
                                                            <input type="text"
                                                                    className={`p-2 ${StyleGeneral.input} ${StyleText.input}`}
                                                                    placeholder="dirección"
                                                                    value={direccion}
                                                                    name="direccion"
                                                                    onChange={onInputChange}
                                                            />
                                                            {errorDireccion ?
                                                            <span>
                                                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                                <div className='err_login'>{errorDireccion}</div>
                                                            </span> : null}
                                                    </div>
                                                    <div className="mb-4">
                                                        <input type="text"
                                                                className={`p-2 ${StyleGeneral.input} ${StyleText.input}`}
                                                                placeholder="Datos adicionales"
                                                                onChange={onInputChange}
                                                                name='complement'
                                                                value={complement}
                                                        />
                                                    </div>
                                                    <div className="text-center text-lg-start">
                                                        <button type="submit"  disabled={load} className={`${StyleButton.btn} ${load ? StyleButton.active :''}`}>{ load ? 'Guardando' : 'Guardar dirección'}
                                                        <span className={StyleButton.loadingContainer}>
                                                            <span className={StyleButton.dot}>.</span>
                                                            <span className={StyleButton.dot}>.</span>
                                                            <span className={StyleButton.dot}>.</span>
                                                            </span>
                                                        </button>
                                                        {errors && <p className="mt-4 err_login">{errors}</p>}
                                                    </div>
                                                </div>
                                            </form>
                                        </Collapse>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </Fragment>
    )
}