import React from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from "../../authContext";
import { useEffect, useState } from 'react';
import { getCiudades , getDepartamentos} from "../../api/productApi";
import { getTiposDocumentos } from "../../api/productApi";
import { createUser } from '../../helpers/userHelper';
import { useApi } from '../../context/apiContext';
import { useCart } from '../../context/cartContext';
import { createAddressForUser } from '../../api/addressApi'
import { getStore } from '../../api/storeApi';
import { useForm } from '../../hooks/useForm';
import { Icon } from '@iconify/react';
import { getGallery } from '../../api/galleryApi';
import { getImages } from "../../api/imageApi";
import { images_products_route } from "../../api/productApi";
import styleText from "../../scss/text.module.scss";
import TittleH4 from '../../components/TittleH4Component';
import StyleGeneral from '../../scss/general.module.scss';
import StyleBtn from '../../scss/btn.module.scss';

const registerFormFields = {
    email: "",
    password: "",
    password_confirm: "",
    nombre: "",
    nombredos: "",
    apellido: "",
    apellidodos: "",
    nit: "",
    contacto:"",
    documento: "",
    celular: "",
    tipopersona: "",
    direccion: "",
    tipoDocumento: 0,
    departamento:0,
    ciudad: 0,
    barrio: "",
    juridico: 0,
    uid: "unset",
    notas: "",
    politicas: false
}


export default function RegisterPage() {
    const [ciudades, setCiudades] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [ dptoId, setDptoId ] = useState('');
    const [tiposDocumentos, setTiposDocumentos] = useState([]);
    const { getTextByKey, getTextBigByKey, currentLocale } = useApi();
    const { tiendaGeneral,updateTienda,validarCart,updateDireccion } = useCart();
    const {
        onInputChange: onRegisterInputChange,
        formState,
        emailRegex,
        nameRegex,
        nitRegex,
        passwordRegex,
        phoneRegex
    } = useForm(registerFormFields);

    const [loading, setloading] = useState(false)
    const [errors, setErrors] = useState()
    const [errorPass, setErrorPass] = useState();
    const [errorTel, setErrorTel] = useState();
    const [errorEmail, setErrorEmail] = useState();
    const [errorCiudad, setErrorCiudad] = useState();
    const [errorNombre, setErrorNombre] = useState();
    const [errorPrimerNombre, setErrorPrimerNombre] = useState();
    const [errorPrimerApellido, setErrorPrimerApellido] = useState();
    const [errorSegundoApellido, setErrorSegundoApellido] = useState();
    const [errorNit, setErrorNit] = useState();
    const [errorContacto, setErrorContacto] = useState();
    const [errorNombreEmpresa, setErrorNombreEmpresa] = useState();
    const [errorTipoDoc, setErrorTipoDoc] = useState();
    const [errorNumDoc, setErrorNumDoc] = useState();
    const [errorBarrio, setErrorBarrio] = useState();
    const [errorDireccion, setErrorDireccion] = useState();
    const [listTratamiento, setListTratamiento] = useState([])
    const [typePassword, settypePassword] = useState('password')
    const [tipopersona, setTipopersona] = useState("natural")
    const [bgImageLoginRegister, setBgImageLoginRegister] = useState([]);

    


    const { email, email_confirm, documento, tipoDocumento,
         password, password_confirm, celular, notas, nombre,
          nombredos, nit,contacto, nombre_empresa, apellido, apellidodos,
           politicas, ciudad, barrio, direccion,departamento, uid, providerData } = formState;

    //Check the Auth Session
    const { currentUser } = useAuth();
    useEffect(() => {
        if (currentUser) {
            if(!currentUser.isAnonymous)
                location.href = "/";
        }
         getCiudades().then((data) => {
            setCiudades(data);
        });
        getTiposDocumentos().then((data) => {
            setTiposDocumentos(data);
        });
    }, []);


    const handleDpto = (event)=>{
        setDptoId(event.target.value)
        onRegisterInputChange(event);
    }
    useEffect( () => {
        getDepartamentos(currentLocale).then(data => {
            if (data && Array.isArray(data)) {
                setDepartamentos(data);
            }
        });
    },[]);

    useEffect(()=>{
        if(dptoId){
            getCiudades(dptoId).then((data) => {
                setCiudades(data);
            });
        }
    },[dptoId])

    useEffect(() => {
        getGallery("title-form-register").then((data) => {
            const listadoAux = [];
            data.forEach(element => {
                element.names.forEach(content => {
                    if (content.locale === currentLocale) {
                        listadoAux.push(content.titulo)
                    }
                })
            });
            setListTratamiento(listadoAux);
        });
    }, [currentLocale])

    const onValidateEmailConfirm = (e) => {
        if (email !== email_confirm) {
            setclassEmailConfirm('border-danger');
        } else {
            setclassEmailConfirm('');
        }
    }

    const showPass = () => {
        if (typePassword === 'text') {
            settypePassword('password')
        } else {
            settypePassword('text')
        }
    }
    const resetErrors=()=>{
        setErrorNombreEmpresa('');
        setErrorNit('');
        setErrorContacto('');
        setErrorPrimerNombre('');
        setErrorPrimerApellido('');
        setErrorSegundoApellido('');
        setErrorTipoDoc('');
        setErrorNumDoc('');
        setErrorEmail("");
        setErrorPass("");
        setErrorPass("");
        setErrorDireccion('');
        setErrorBarrio('');
        setErrorCiudad("");
        setErrorTel("");
        setErrors('');
        
    }

    const onRegisterSubmit = async (event) => {
        event.preventDefault();
        setloading(true)
          resetErrors();
        const validate = await validateForm();
        
        if (tipopersona == 'juridica') {
            formState.tipopersona = tipopersona;
            formState.juridico = 1;
            formState.tipoDocumento=1;
            formState.nombre=nombre_empresa;
            formState.documento=nit;
        }

        //If empty errors, should create a new user through firebase and database
        if (!validate) {
            setloading(true)
            setErrors('');
            const newUser = await createUser(formState, setErrors, setloading);
            try{
                if(newUser.uid){
                    let dire=[];
                    dire.city=parseInt(ciudad);
                    dire.departamento=parseInt(departamento);
                    dire.direccion=direccion;
                    dire.complement=notas;
                    dire.barrio=barrio;
                    dire.user=newUser.uid;
                    const  datasend={...dire}
                    const data =  createAddressForUser(datasend).then(async(response)=>{
                      if(response.id){
                        let tienda= await getStore(parseInt(ciudad)) 
                        if(tienda.id){
                            updateTienda(tienda.id);
                            let carritoajustar =await validarCart(tienda.id);
                            console.log(carritoajustar);
                            if(carritoajustar.ok){
                                if(carritoajustar.total>0){
                                    let mensaje=getTextByKey('productos-eliminados-car')+': ';
                                    mensaje += carritoajustar.eliminados.map((eliminado) => eliminado.name.nombre).join(', ');
                                    //setErrors(mensaje);
                                    //handleShowModal();
                                }
                            
                            }
                            setTimeout(() => {
                                updateDireccion(response.id);
                                if(localStorage.getItem('volver-sitio')){
                                    location.href=localStorage.getItem('volver-sitio');
                                  }else{
                                     location.href='/tienda';
                                  }
                            }, 4000);
                        
                        }else{
                            if(localStorage.getItem('volver-sitio')){
                                location.href=localStorage.getItem('volver-sitio');
                              }else{
                                 location.href='/tienda';
                              }
                        }
                        
                        //window.location.href='/checkout-c';
                        }else{
                            if(localStorage.getItem('volver-sitio')){
                                location.href=localStorage.getItem('volver-sitio');
                              }else{
                                 location.href='/tienda';
                              }
                        }
                    }).catch((error)=>{
                      
                        console.log(error);
                        if(localStorage.getItem('volver-sitio')){
                            location.href=localStorage.getItem('volver-sitio');
                          }else{
                             location.href='/tienda';
                          }
                        // handleShowModal();
                        });
                }else{
                    console.log('error',error);
                       
                } 
            }catch(error){
                 console.log('error',error);
                 
            }
        }else{
            setloading(false);
        }
    }

    const validateForm = async () => {
        let errorFound = false;


        if(tipopersona == 'juridica'){
            
            if (!nombre_empresa) {
                errorFound = true;
                setErrorNombreEmpresa('El campo es requerido');
            }
            if (!nit) {
              
                errorFound = true;
                setErrorNit('El nit es requerido');
            }else{
                if(!nit.match(nitRegex)){
                    errorFound = true;
                     setErrorNit('Si el nit cuenta con código de verificación debes colocar (-) seguido del número');
                } 
            }
            if (!contacto) {
              
                errorFound = true;
                setErrorContacto('El contacto es requerido');
            }else{
                if(!contacto.match(nameRegex)){
                    errorFound = true;
                     setErrorContacto('El contacto solo debe contener letras');
                } 
            }
        }else{
            if (!nombre) {
                errorFound = true;
                setErrorPrimerNombre('El nombre es requerido');
            }else{
                if(!nombre.match(nameRegex)){
                    errorFound = true;
                  setErrorPrimerNombre('El nombre solo debe contener letras');
                }
            }
            if (!apellido) {
                errorFound = true;
                setErrorPrimerApellido('El apellido es requerido');
            }else{
                if(!apellido.match(nameRegex)){
                    errorFound = true;
                    setErrorPrimerApellido('El apellido solo debe contener letras');
                } 
            }
            if (!apellidodos) {
                errorFound = true;
                setErrorSegundoApellido('El apellido es requerido');
            }else{
                if(!apellidodos.match(nameRegex)){
                    errorFound = true;
                    setErrorSegundoApellido('El apellido solo debe contener letras');
                }  
            }
            if (tipoDocumento==0) {
                errorFound = true;
                setErrorTipoDoc('Selecciona un tipo de documento');
            }
            if (!documento) {
                errorFound = true;
                setErrorNumDoc('Este campo es requerido');
            }

        }
        if (!email.match(emailRegex)) {
            errorFound = true;
            setErrorEmail("El email no tiene un formato válido");
        }

        if (password.length < 6 || password_confirm.length<6) {
            errorFound = true;
            setErrorPass("La contraseña debe tener al menos 6 caracteres");
        }

        if (password_confirm !== password) {
            errorFound = true;
            setErrorPass("La contraseña no coincide");
        }
              
        if (!direccion) {
            errorFound = true;
            setErrorDireccion('Este campo es requerido');
        }
        if (!barrio) {
            errorFound = true;
            setErrorBarrio('Este campo es requerido');
        }

        if (ciudad==0) {
            errorFound = true;
            setErrorCiudad("Debe seleccionar una ciudad");
        }

        //Validate the phone format
        if (!celular.match(phoneRegex)) {
            errorFound = true;
            setErrorTel("El teléfono no tiene un formato válido");
        }
    
     
        return errorFound;
    }


    const onOptionChange = e => {
        setTipopersona(e.target.value)
    }

    useEffect(() => {
        getImages("bg-login-register").then((data) => {
            setBgImageLoginRegister(data);
            console.log("bgImageLoginRegister ", bgImageLoginRegister);
        });
    }, [])

    
    // estos componente se crearon para poder ubicar los en diferentes partes del formulario por responsive y que sea coherente con el diseño
    
    function NombreDos(){
        return(
            <Fragment>
                <div className={`form-group mb-4 ${errorNombre ? 'danger-alert' : ''}`}>
                    <input
                        type="text"
                        className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                        name="nombredos"
                        onChange={onRegisterInputChange}
                        placeholder={`${getTextByKey('segundo_nombre')}`}
                        value={nombredos}
                    />
                    {errorNombre ?
                    <span>
                        <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                        <div className='err_login'>{errorNombre}</div>
                    </span> : null}
                </div>
            </Fragment>
        )
    }

    function ApellidoDos(){
        return(
            <Fragment>
                <div className={`form-group mb-4 ${errorSegundoApellido ? 'danger-alert' : ''}`}>
                    <input
                        type="text"
                        className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                        name="apellidodos"
                        onChange={onRegisterInputChange}
                        placeholder={`${getTextByKey('segundo_apellido')}*`}
                        value={apellidodos}
                    />
                    {errorSegundoApellido ?
                        <span>
                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                            <div className='err_login'>{errorSegundoApellido}</div>
                        </span> : null}
                </div>
            </Fragment>
        )
    }


    return (
        <Fragment>
            {
                bgImageLoginRegister.length > 0 &&
                <div className={`login-register pt-lg-5 pt-3 pb-5 px-lg-5 px-2 ${StyleGeneral.backgroundSize}`} style={{ backgroundImage: `url(${images_products_route + bgImageLoginRegister[0].image})`}}>
                    <div className='container pb-5 mb-lg-5'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-md-11 col-xl-7'>
                                <form className='py-2 px-3 px-xl-5 white-color row' onSubmit={onRegisterSubmit}>
                                    <TittleH4 styleGroup={`text-center carrefourMetis-bold mb-0 autography specialColorC-color
                                    ${styleText.h4Special}`}>
                                        {getTextByKey('registrarse-titulo')}
                                    </TittleH4>
                                    <div className='d-flex justify-content-center my-3 my-md-lg-3 my-xl-4 group-radio-type-person'>
                                        <label className={`w-auto me-5 ${StyleGeneral.containerCheckS} ${StyleGeneral.containerCheckSB}`}>
                                            <small className='ms-2'>{getTextByKey('natural')}</small>
                                            <input type='radio' 
                                                value="natural" 
                                                checked={tipopersona === "natural"}
                                                onChange={onOptionChange} name="tipopersona"/>
                                            <span className={StyleGeneral.checkmarkSB}></span>
                                        </label>
                                        <label className={`w-auto ${StyleGeneral.containerCheckS} ${StyleGeneral.containerCheckSB}`}>
                                            <small className='ms-2'>{getTextByKey('juridica')}</small>
                                            <input type='radio' 
                                                value="juridica" 
                                                checked={tipopersona === "juridica"}
                                                onChange={onOptionChange} name="tipopersona"/>
                                            <span className={StyleGeneral.checkmarkSB}></span>
                                        </label>
                                    </div>
                                    <div className='container'>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                {tipopersona == 'juridica' &&
                                                    <fragment>
                                                        <div className={`form-group mb-4 ${errorNombreEmpresa ? 'danger-alert' : ''}`}>
                                                            <input
                                                                type="text"
                                                                name="nombre_empresa"
                                                                className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                                onChange={onRegisterInputChange}
                                                                placeholder={`${getTextByKey('nombre_empresa')}*`}
                                                                value={nombre_empresa}
                                                            />
                                                            {errorNombreEmpresa ?
                                                            <span>
                                                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                                <div className='err_login'>{errorNombreEmpresa}</div>
                                                            </span> : null}
                                                        </div>
                                                        <div className={`form-group mb-4 ${errorNit ? 'danger-alert' : ''}`}>
                                                            <input
                                                                type="text"
                                                                name="nit"
                                                                className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                                onChange={onRegisterInputChange}
                                                                placeholder={`${getTextByKey('nit')}*`}
                                                                value={nit}
                                                            />
                                                            {errorNit ?
                                                            <span>
                                                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                                <div className='err_login'>{errorNit}</div>
                                                            </span> : null}
                                                        </div>

                                                        <div className={`form-group mb-4 ${errorContacto ? 'danger-alert' : ''}`}>
                                                            <input
                                                                type="text"
                                                                name="contacto"
                                                                className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                                onChange={onRegisterInputChange}
                                                                placeholder={`${getTextByKey('contacto-juridico')}*`}
                                                                value={contacto}
                                                            />
                                                            {errorContacto ?
                                                            <span>
                                                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                                <div className='err_login'>{errorContacto}</div>
                                                            </span> : null}
                                                        </div>
                                                        
                                                    </fragment>
                                                }
                                                {tipopersona == 'natural' &&
                                                    <Fragment>
                                                        <div className={`form-group mb-4 ${errorPrimerNombre ? 'danger-alert' : ''}`}>
                                                            <input
                                                                type="text"
                                                                className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                                name="nombre"
                                                                onChange={onRegisterInputChange}
                                                                placeholder={`${getTextByKey('primer_nombre')}*`}
                                                                value={nombre}
                                                            />
                                                            {errorPrimerNombre ?
                                                            <span>
                                                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                                <div className='err_login'>{errorPrimerNombre}</div>
                                                            </span> : null}
                                                        </div>
                                                        <div className='d-md-none'>
                                                        <div className={`form-group mb-4 ${errorNombre ? 'danger-alert' : ''}`}>
                                                            <input
                                                                type="text"
                                                                className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                                name="nombredos"
                                                                onChange={onRegisterInputChange}
                                                                placeholder={`${getTextByKey('segundo_nombre')}`}
                                                                value={nombredos}
                                                            />
                                                            {errorNombre ?
                                                            <span>
                                                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                                <div className='err_login'>{errorNombre}</div>
                                                            </span> : null}
                                                        </div>
                                                        </div>
                                                        <div className={`form-group mb-4 ${errorPrimerApellido ? 'danger-alert' : ''}`}>
                                                            <input
                                                                type="text"
                                                                className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                                name="apellido"
                                                                onChange={onRegisterInputChange}
                                                                placeholder={`${getTextByKey('primer_apellido')}*`}
                                                                value={apellido}
                                                            />
                                                            {errorPrimerApellido ?
                                                                <span>
                                                                    <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                                    <div className='err_login'>{errorPrimerApellido}</div>
                                                                </span> : null}
                                                        </div>
                                                        <div className='d-md-none'>
                                                            <div className={`form-group mb-4 ${errorSegundoApellido ? 'danger-alert' : ''}`}>
                                                                <input
                                                                    type="text"
                                                                    className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                                    name="apellidodos"
                                                                    onChange={onRegisterInputChange}
                                                                    placeholder={`${getTextByKey('segundo_apellido')}*`}
                                                                    value={apellidodos}
                                                                />
                                                                {errorSegundoApellido ?
                                                                    <span>
                                                                        <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                                        <div className='err_login'>{errorSegundoApellido}</div>
                                                                    </span> : null}
                                                            </div>
                                                        </div>

                                                    </Fragment>
                                                }
                                                <div className={`form-group mb-4 ${errorEmail ? 'danger-alert' : ''}`}>
                                                    <input
                                                        type="email"
                                                        className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                        name="email"
                                                        onChange={onRegisterInputChange}
                                                        placeholder={`${getTextByKey('correo_electronico_form')}*`}
                                                        value={email}
                                                    />
                                                    {errorEmail ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorEmail}</div>
                                                        </span> : null}
                                                </div>
                                                <div className='form-group mb-4 position-relative'>
                                                    <input
                                                        type={typePassword}
                                                        className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input} ${errorPass ? 'border-danger' : ''}`}
                                                        name="password"
                                                        onChange={onRegisterInputChange}
                                                        placeholder={`${getTextByKey('contrasena_form')}*`}
                                                        value={password}
                                                    />
                                                    <a className="btn-show-pass" onClick={showPass}>
                                                        <Icon icon="bi:eye-slash" />
                                                    </a>
                                                    {errorPass ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorPass}</div>
                                                        </span> : null}
                                                </div>
                                                <div className='form-group mb-4 position-relative'>
                                                    <input
                                                        type={typePassword}
                                                        className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                        onChange={onRegisterInputChange}
                                                        name="password_confirm"
                                                        placeholder={`${getTextByKey('confirmar_contrasena_form')}*`}
                                                        value={password_confirm}
                                                    />
                                                    <a className="btn-show-pass" onClick={showPass}>
                                                        <Icon icon="bi:eye-slash" />
                                                    </a>
                                                    {errorPass ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorPass}</div>
                                                        </span> : null}
                                                </div>
                                                {tipopersona == 'natural' &&
                                                    <>
                                                        <div className={`form-group mb-4 ${errorTipoDoc ? 'danger-alert' : ''}`}>
                                                            <select
                                                                className={`px-3 py-2 white-border-color gray-1-color select-gris 
                                                                ${StyleGeneral.input} ${styleText.input} cursor-pointer`}
                                                                name="tipoDocumento"
                                                                value={tipoDocumento}
                                                                onChange={onRegisterInputChange}>
                                                                <option value="0" >
                                                                    {`${getTextByKey('tipo_documento')}*`}
                                                                </option>
                                                                {tiposDocumentos.map((tipoDocumento, index) => (
                                                                    <option key={index} value={tipoDocumento.id}>{tipoDocumento.ref + " (" + tipoDocumento.translations[0].name + ")"}</option>
                                                                ))}
                                                            </select>
                                                            {errorTipoDoc ?
                                                                <span>
                                                                    <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                                    <div className='err_login'>{errorTipoDoc}</div>
                                                                </span> : null}
                                                        </div>
                                                        <div className={`form-group mb-4 ${errorNumDoc ? 'danger-alert' : ''}`}>
                                                            <input
                                                                type="text"
                                                                className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                                name="documento"
                                                                onChange={onRegisterInputChange}
                                                                placeholder={`${getTextByKey('numero_documento')}*`}
                                                                value={documento}
                                                            />
                                                            {errorNumDoc ?
                                                                <span>
                                                                    <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                                    <div className='err_login'>{errorNumDoc}</div>
                                                                </span> : null}
                                                        </div>
                                                        
                                                    </>
                                                }
                                                <div className={`form-group mb-4 ${errorTel ? 'danger-alert' : ''}`}>
                                                            <input
                                                                type="tel"
                                                                className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input} ${errorTel ? 'border-danger' : ''}`}
                                                                name="celular"
                                                                onChange={onRegisterInputChange}
                                                                placeholder={`${getTextByKey('telefono')}*`}
                                                                value={celular}
                                                            />
                                                            {errorTel ?
                                                                <span>
                                                                    <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                                    <div className='err_login'>{errorTel}</div>
                                                                </span> : null}
                                                        </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                
                                                <div className='d-none d-md-block'>
                                                    {tipopersona == 'natural' &&
                                                        <Fragment>
                                                        
                                                        <div className={`form-group mb-4 ${errorNombre ? 'danger-alert' : ''}`}>
                                                            <input
                                                                type="text"
                                                                className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                                name="nombredos"
                                                                onChange={onRegisterInputChange}
                                                                placeholder={`${getTextByKey('segundo_nombre')}`}
                                                                value={nombredos}
                                                            />
                                                            {errorNombre ?
                                                            <span>
                                                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                                <div className='err_login'>{errorNombre}</div>
                                                            </span> : null}
                                                        </div>
                                                            
                                                    <div className={`form-group mb-4 ${errorSegundoApellido ? 'danger-alert' : ''}`}>
                                                        <input
                                                            type="text"
                                                            className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                            name="apellidodos"
                                                            onChange={onRegisterInputChange}
                                                            placeholder={`${getTextByKey('segundo_apellido')}*`}
                                                            value={apellidodos}
                                                        />
                                                        {errorSegundoApellido ?
                                                            <span>
                                                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                                <div className='err_login'>{errorSegundoApellido}</div>
                                                            </span> : null}
                                                    </div>
                                                        
                                                        </Fragment>
                                                    }
                                                </div>

                                                <div className={`form-group mb-4 ${errorCiudad ? 'danger-alert' : ''}`}>
                                                    <select className={`px-3 py-2 white-border-color gray-1-color select-gris 
                                                        ${StyleGeneral.input} ${styleText.input} cursor-pointer`}
                                                        name="departamento" value={departamento} onChange={handleDpto}>
                                                        <option value="0" >
                                                            {`${getTextByKey('departamento')}*`}
                                                        </option>
                                                        {departamentos.map((ciudad, index) => (
                                                            <option key={index} value={ciudad.id}>
                                                                {ciudad.nombre}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errorCiudad ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorCiudad}</div>
                                                        </span> : null}
                                                </div>
                                                <div className={`form-group mb-4 ${errorCiudad ? 'danger-alert' : ''}`}>
                                                    <select className={`px-3 py-2 white-border-color gray-1-color select-gris 
                                                        ${StyleGeneral.input} ${styleText.input} cursor-pointer`}
                                                        name="ciudad" value={ciudad} onChange={onRegisterInputChange}>
                                                        <option value="0"  >
                                                            {`${getTextByKey('ciudad')}*`}
                                                        </option>
                                                        {ciudades.map((ciudad, index) => (
                                                            <option key={index} value={ciudad.id}>
                                                                {ciudad.nombre}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errorCiudad ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorCiudad}</div>
                                                        </span> : null}
                                                </div>
                                                <div className={`form-group mb-4 ${errorBarrio ? 'danger-alert' : ''}`}>
                                                    <input
                                                        type="text"
                                                        className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                        name="barrio"
                                                        onChange={onRegisterInputChange}
                                                        placeholder={`${getTextByKey('barrio')}*`}
                                                        value={barrio}
                                                    />
                                                    {errorBarrio ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorBarrio}</div>
                                                        </span> : null}
                                                </div>
                                                <div className={`form-group mb-4 ${errorDireccion ? 'danger-alert' : ''}`}>
                                                    <input
                                                        type="text"
                                                        className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                        onChange={onRegisterInputChange}
                                                        name="direccion"
                                                        placeholder={`${getTextByKey('direccion')}*`}
                                                        value={direccion}
                                                    />
                                                    {errorDireccion ?
                                                        <span>
                                                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                            <div className='err_login'>{errorDireccion}</div>
                                                        </span> : null}
                                                </div>
                                                <div className='form-group mb-4'>
                                                    <input
                                                        type="text"
                                                        className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input}`}
                                                        onChange={onRegisterInputChange}
                                                        name="notas"
                                                        placeholder={`${getTextByKey('notas')}`}
                                                        value={notas}
                                                    />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group d-flex justify-content-center text-center'>
                                        
                                        <label className={`w-auto ${StyleGeneral.containerCheckS} ${StyleGeneral.containerCheckSC}`}>
                                            <small className='ms-2 mb-0' dangerouslySetInnerHTML={{ __html: getTextBigByKey('nota_registro') }}></small>
                                            <input type='checkbox' 
                                                value={politicas} 
                                                onChange={onRegisterInputChange} name="politicas"/>
                                            <span className={StyleGeneral.checkmarkSC}>
                                                <img src='/img/SVG/check-white.svg'/>
                                            </span>
                                        </label>
                                    </div>
                                    {errors ? <div className='error-msg'>{errors}</div> : null} 
                                    <div className='form-group text-center mt-4'>
                                        <button type="submit" disabled={loading}
                                            className={`btn-g py-2 px-5 ${StyleBtn.btn}`}
                                            >
                                            {loading ? `${getTextByKey('registrando')}` : `${getTextByKey('registrarme')}`}
                                        </button>
                                    </div>
                                    <div className='text-center mt-3'>
                                        <p className='md-p'>
                                            <span>{getTextByKey('ya-tienes-cuenta')}</span>
                                            <Link to="/login" className='ms-2 white-color text-underline'>{getTextByKey('inicia-sesion')}</Link>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}
