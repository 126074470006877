import React, { Fragment, useEffect, useState } from 'react'
import SelecctionsComponent from './components/SelecctionsComponent'
import { useApi } from '../../context/apiContext'
import { getImages } from '../../api/imageApi';
import { getGallery } from '../../api/galleryApi';

export default function SelectionPage() {

    
    const [imgSelecciones, setImgSelecciones] = useState({names: [{titulo: "Cargando...", descripcion: "Cargando..."}], image: "/img/imgWhite.jpg"});

    useEffect(() => {
        getGallery('selecciones').then(
            (data) => {
                // console.log("data: ", data[0]);
                setImgSelecciones(data);
            }
        )
    }, [])

    const { getTextByKey } = useApi();
    return (
        <Fragment>
            {imgSelecciones.length > 0 &&
                <Fragment>
                    <SelecctionsComponent link="/noticias"
                        bgColor="quaternary-bg-color"
                        title={imgSelecciones[0].names[0].titulo}
                        text={{__html: imgSelecciones[0].names[0].descripcion}}
                        img={imgSelecciones[0].image}>
                            <div dangerouslySetInnerHTML={{__html: imgSelecciones[0].names[0].descripcion}}></div>
                    </SelecctionsComponent>

                    <SelecctionsComponent link="/recetas"
                        bgColor="specialA-bg-color"
                        title={imgSelecciones[1].names[0].titulo}
                        text={imgSelecciones[1].names[0].descripcion}
                        img={imgSelecciones[1].image}
                        styleGroup="flex-row-reverse my-3 my-lg-4">
                            <div dangerouslySetInnerHTML={{__html: imgSelecciones[1].names[0].descripcion}}></div>
                    </SelecctionsComponent>

                    <SelecctionsComponent link="/tips"
                        bgColor="specialB-bg-color"
                        title={imgSelecciones[2].names[0].titulo}
                        text={imgSelecciones[2].names[0].descripcion}
                        img={imgSelecciones[2].image}>
                            <div dangerouslySetInnerHTML={{__html: imgSelecciones[2].names[0].descripcion}}></div>
                    </SelecctionsComponent>

                </Fragment>
            }
        </Fragment>
    )
}
