import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from "../../authContext";
import { useEffect } from 'react';
import { useForm } from '../../hooks/useForm';
import { loginUser } from '../../helpers/userHelper';
import {listAddressUser} from "../../api/addressApi";
import { useApi } from '../../context/apiContext';
import { useCart } from '../../context/cartContext';
import { getStore } from '../../api/storeApi';
import { getImages } from "../../api/imageApi";
import { images_products_route } from "../../api/productApi";
import { Icon } from '@iconify/react';
import styleText from "../../scss/text.module.scss";
import styeleTittle from "../../scss/text.module.scss";
import StyleGeneral from '../../scss/general.module.scss';
import StyleBtn from '../../scss/btn.module.scss';


import "../../scss/login-register.scss";
import TittleH4 from '../../components/TittleH4Component';
import Banner from '../../components/BannerComponent';

const loginFormFields = {
    email: "",
    password: "",
}

export default function LoginPage() {
    const { getTextByKey, getTextBigByKey } = useApi();
    // const { getTextBigByKey, getTextByKey } = useApi();
    const navigate = useNavigate();
    //Check the Auth Session
    const { currentUser } = useAuth();
    const [bgImageLoginRegister, setBgImageLoginRegister] = useState([]);
    const { tiendaGeneral,updateTienda,validarCart,updateDireccion } = useCart();
    const [typePassword, settypePassword] = useState('password')

    useEffect(() => {
        if (currentUser) {
            if(!currentUser.isAnonymous)
            //User is logged in redirect to home
              location.href = "/";
        }

        getImages("bg-login-register").then((data) => {
            setBgImageLoginRegister(data);
            console.log("bgImageLoginRegister ", bgImageLoginRegister);
        });
    }, []);

    //State the error message and the loading state
    const [errors, setErrors] = useState('')
    const [loading, setloading] = useState(false)
    const {
        onInputChange: onLoginInputChange,
        formState,
    } = useForm(loginFormFields);
    const { email, password } = formState;
    //Function to handle the login
    const onLoginSubmit = async e => {
        e.preventDefault();
        setloading(true);

        const validate = await validateForm(formState)

        //If empty errors, should login through firebase
        if (!validate) {
            setloading(true)
            setErrors()
            const login = await loginUser(formState, setErrors, setloading)
            try{
                console.log(login,login.uid);
                let direcciones= await listAddressUser(login.uid);
                if(direcciones.length==0){
                    location.href='/direccion-envio';
                }
                direcciones.sort((a, b) => b.id - a.id); 
                let predeterminada = direcciones.shift();
                let city=predeterminada.ciudades[0].id;
                let tienda= await getStore(parseInt(city)) 
                if(tienda.id){
                    updateTienda(tienda.id);
                    let carritoajustar =await validarCart(tienda.id);
                    console.log(carritoajustar);
                    if(carritoajustar.ok){
                        if(carritoajustar.total>0){
                            let mensaje=getTextByKey('productos-eliminados-car')+': ';
                            mensaje += carritoajustar.eliminados.map((eliminado) => eliminado.name.nombre).join(', ');
                            //setErrors(mensaje);
                            //handleShowModal();
                        }
                    
                    }
                    setTimeout(() => {
                        updateDireccion(predeterminada.id);
                        if(localStorage.getItem('volver-sitio')){
                            location.href=localStorage.getItem('volver-sitio');
                          }else{
                             location.href='/tienda';
                          }
                    }, 4000);
                }else{
                     if(localStorage.getItem('volver-sitio')){
                        location.href=localStorage.getItem('volver-sitio');
                      }else{
                         location.href='/tienda';
                      }
                }
            }catch(error){
                setloading(false);
              console.log(error);
            }
        }

    };

    const validateForm = async (formData) => {
        let errorFound = false;

        //Validate the email format
        if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
            errorFound = true;
        setErrors("El email no tiene un formato válido");

        //validate empty values
        for (const [key, value] of Object.entries(formData))
            //TODO make whitespaces validation
            if (value === "")
                errorFound = true;
        setErrors("Todos los campos son obligatorios");

        setloading(false)
        return errorFound;
    }

    const showPass = () => {
        if (typePassword === 'text') {
            settypePassword('password')
        } else {
            settypePassword('text')
        }
    }

    return (
        <Fragment>
            {/* <div className='content-user' style={{ backgroundImage: `url(${images_products_route + bgImageLoginRegister[0].image})` }}> */}
            {
                bgImageLoginRegister.length > 0 &&
                <div className='login-register bg-options-repeat pt-3 pb-5 '
                    style={{ backgroundImage: `url(${images_products_route + bgImageLoginRegister[0].image})` }}>
                    <div className="pt-md-5 pt-3 pb-5 mb-5">
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='col-12 col-md-10 col-lg-6 col-xl-5'>
                                    <form className='px-3 px-xl-4 white-color' onSubmit={onLoginSubmit}>
                                        <TittleH4 styleGroup={`text-center mb-5 py-2 py-lg-4 autography specialColorC-color
                                    ${styleText.h4Special}`}>
                                            {bgImageLoginRegister.length > 0 && bgImageLoginRegister[0].names[0].titulo}
                                        </TittleH4>
                                        <div className='container px-lg-5 px-2'>
                                            <div className='form-group mb-3'>
                                                <input
                                                    type="email"
                                                    name="email"
                                                        className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styeleTittle.input} ${errors ? 'is-invalid' : ''}`}
                                                        placeholder={getTextByKey('login_email')}
                                                    value={email}
                                                    onChange={onLoginInputChange}
                                                />
                                                {errors ? <span><Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                                    <div className='err_login'>{errors}</div></span> : null}
                                            </div>
                                            <div className='form-group mb-3 position-relative'>
                                                <input
                                                    type={typePassword}
                                                        className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styeleTittle.input}`}
                                                    name="password"
                                                        placeholder={getTextByKey('login_contrasenha')}
                                                    onChange={onLoginInputChange}
                                                    value={password}
                                                />
                                                <a className="btn-show-pass" onClick={showPass}>
                                                    <Icon icon="bi:eye-slash" />
                                                </a>
                                            </div>
                                            <div className='form-group text-center'>
                                                <button type="submit"
                                                    className={`${StyleBtn.btn}`}
                                                    disabled={loading}>
                                                    {loading ? `Autenticando...` : `${getTextByKey('iniciar-sesion')}`}
                                                </button>
                                            </div>
                                            <div className='mt-3 text-center'>
                                                <Link to="/reset-password"><p className='white-color md-p'>
                                                    {getTextByKey('olvidar-contrasena')}
                                                </p></Link>
                                            </div>
                                            <div className='text-center mt-4'>
                                                <p className='white-color md-p'>
                                                    <span>{getTextByKey('no-tienes-cuenta')}</span>
                                                    <Link to="/register" className='ms-2 text-underline white-color'>
                                                        {getTextByKey('registrate')}
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }


        </Fragment>
    )
}
