import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { default as ModalConfirmarEntrega } from './ModalConfirmarEntrega';
import { default as ModalNuevaDireccion } from './ModalNuevaDireccion';




export default function ModalDireccionEnvio({ showModalDireccion, setShowModalDireccion, setShowModalCompra }) {

    const { getTextByKey } = useApi();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowModalDireccion(false);
        gotoTop();
    }

    const volverModal = () => {
      setShowModalDireccion(false);
      setShowModalCompra(true);
    }

    const [ modalConfirmar, setModalConfirmar ] = useState(false);
    const [ modalNuevaDireccion, setModalNuevaDireccion ] = useState(false);

    const confirmModal = () => {
      setShowModalDireccion(false);
      setModalConfirmar(true);
    }

    const nuevaDireccion = () => {
      setShowModalDireccion(false);
      setModalNuevaDireccion(true);
    }

    return (
        <Fragment>
            <Modal className="modal-compra" show={showModalDireccion } onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-1-color' icon="material-symbols:close-rounded" width="30" height="30" onClick={handleClose}></Icon>
                    </div>
                    <div className="px-3">
                      <div className="text-center">
                        <h4 className='gray-1-color'>{getTextByKey('direccion-envio-titulo')}</h4>
                        <p className='gray-1-color'>{getTextByKey('selecciona-modal-direccion')}</p>
                      </div> 
                      <form action="" className='pb-4 pt-3'>
                        <div className="row">
                        <div className="col-12">
                          <div className="form-group mb-2">
                            <select className='p-2 gray-1-color' name="ciudad">
                              <option  defaultValue="ciudad">{getTextByKey('selecciona_direccion')}</option>
                            </select>
                          </div>
                        </div>
                        </div>
                      </form>
                      <div className="text-center pt-3">
                          <button className='btn-g bb px-5' onClick={volverModal}>{getTextByKey('volver')}</button>
                          <button className='btn-g bb px-3' onClick={nuevaDireccion}>{getTextByKey('agregar_direccion')}</button>
                          <button className='btn-g' onClick={confirmModal}>{getTextByKey('confirmar')}</button>
                      </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ModalNuevaDireccion modalNuevaDireccion={modalNuevaDireccion} setModalNuevaDireccion={setModalNuevaDireccion} setShowModalDireccion={setShowModalDireccion}/>
            <ModalConfirmarEntrega modalConfirmar={modalConfirmar} setModalConfirmar={setModalConfirmar}/>
        </Fragment>
    )
}
