import { Fragment, useState, useEffect } from "react";
import { Link, } from "react-router-dom";
import { getGallery } from "../../api/galleryApi";
import { useApi } from "../../context/apiContext";
import { images_products_route } from "../../api/productApi";
import { getRecipeCategories, getRecipes } from "../../api/recipesApi";

import TittleH1 from "../../components/TittleH1Component";
import TextBlock from "../../components/TextBlockComponent";
import StyleText from "../../scss/text.module.scss";

import { useAuth } from '../../authContext';
import { getEnvVariables } from "../../helpers";
import RecipeComponent from "../../components/recipeComponent";
import Pagination from "../../components/PaginationComponent";
import {usePage} from "../../hooks/usePage";

const MAX_ITEMS_PER_PAGE = 3;
export default function RecipesPage({styleGroup}) {
    const { getTextByKey, getTextBigByKey, getPropiertyByLocale, currentLocale } = useApi();
    const [ recetas, setRecetas ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ banner, setBanner ] = useState([]);
    const {changePage, page, pages, setPages} = usePage(1);

    useEffect(() => {
        getRecipeCategories(currentLocale).then((data) => {
            setCategories(data)
        })

      getGallery('recipes-banner').then((images) => {
        setBanner(images_products_route + images[0].image);
      })
    
    }, [])

    useEffect(() => {
        getRecipes({locale: currentLocale, limit: MAX_ITEMS_PER_PAGE, page}).then(({list, pages: pages_})=>{
            setRecetas(list);
            setPages(pages_);
        })
    }, [page]);

    if(!categories.length)
        return (
        <div className='container'>
            <div className="text-center-c">
            <div className="loader-box">
                <img src="/img/SVG/loader.svg" alt="" />
            </div>
            </div>
        </div>
        );
    
    return (
        <Fragment>
            <div className="recipes-page">
                <div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-10">
                                <div className="text-center">
                                    <TittleH1 styleGroup={`my-3 my-xl-4 quinary-color autography ${StyleText.h4Special}`}>{getTextByKey('receta_titulo')}</TittleH1>
                                    <TextBlock styleGroup="d-flex justify-content-center align-items-center">
                                        <div dangerouslySetInnerHTML={{ __html: getTextBigByKey("receta_parrafo") }}></div>
                                    </TextBlock>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="recipes mb-5">
                    <div className="container">
                        <div className="row">
                            { recetas.map((receta)=>(
                                <div className="col-12 col-md-6 col-lg-4" key={receta.id}>
                                    <RecipeComponent recipe={receta} />
                                </div>
                            )) }
                        </div>
                        <div className="text-center mt-3 mt-xl-5 pb-5">
                            <Pagination
                                pages={pages}
                                currentPage={page}
                                onPageChange={changePage}
                                onNext={ () => {changePage( page + 1 )} }
                                onPrevious={ () => {changePage( page - 1 )} }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
