import React from "react";
import { Fragment, useState } from "react";
import { useApi } from "../context/apiContext";
import styleText from "../scss/text.module.scss";
import styles from "../scss/header.module.scss";
import { Icon } from "@iconify/react";
import { Link, NavLink } from "react-router-dom";
import { useCart } from "../context/cartContext";

import { logoHide, wpHide, btnHide, backgroundMenuHide, hideMenu } from "./headerFunctionComponent";

import scrollToTop from "./GlobalFunction";

function MenuHeader() {
    const { getTextByKey,currentCategories,extractNameFromLocate } = useApi();
    const { hideCart } = useCart();

    const element = document.getElementById('menu-mobile');
    const backgrounMenu = document.getElementById('backGround');
    const menu = document.getElementById('wpMobile');
    const logoMenu = document.getElementById('logoMenu');
    const btnClose = document.getElementById('btnClose');
    const timeOut = 500;

    const closeDrop = event => {
        /* window.scrollTo(0, 0); */
        console.log('cerrando drop');
        logoHide(logoMenu);
        wpHide(menu);
        btnHide(btnClose);
        backgroundMenuHide(backgrounMenu);
        setTimeout(() => {
            hideMenu(element);    
        }, timeOut);

        hideCart();
        scrollToTop();
    };

    return (
        <Fragment>
            <ul className={ styles.menu }>
                <li>
                    <NavLink to="/tienda" onClick={closeDrop} className={styles.menuItem}>
                        {getTextByKey("menu_tienda")}
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/nosotros" onClick={closeDrop} className={styles.menuItem}>
                        {getTextByKey('menu_nosotros')}
                    </NavLink>
                </li>
                <li>
                    {/* <NavLink to="/estilo-de-vida" onClick={closeDrop} className={styles.menuItem}>Selecciones</NavLink> */}
                    <Link to="/estilo-de-vida" onClick={closeDrop} className={styles.menuItem}>
                        {getTextByKey('menu_selecciones')}
                    </Link>
                </li>
                <li>
                    <NavLink to="/noticias" onClick={closeDrop} className={styles.menuItem}>
                        {getTextByKey("menu_noticias")}
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/contacto" onClick={closeDrop} className={styles.menuItem}>
                        {getTextByKey("menu_contact")}
                    </NavLink>
                </li>
            </ul>
        </Fragment>
    );
}

export default MenuHeader;
