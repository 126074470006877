import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { default as ModalProgramarPedido } from './ModalProgramarPedido';


export default function ModalCompraRecoger({ showModalRecoger, setShowModalRecoger, setShowModalCompra }) {

    const { getTextByKey } = useApi();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowModalRecoger(false);
        gotoTop();
    }

    const volverModal = () => {
      setShowModalRecoger(false);
      setShowModalCompra(true);
    }

    const [ showProgramar, setShowProgramar ] = useState(false);

    const confirmModal = () => {
      setShowModalRecoger(false);
      setShowProgramar(true);
    }

    return (
        <Fragment>
            <Modal className="modal-compra" show={showModalRecoger} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-1-color' icon="material-symbols:close-rounded" width="30" height="30" onClick={handleClose}></Icon>
                    </div>
                    <div className="px-3">
                      <div className="text-center">
                        <h4 className='gray-1-color'>{getTextByKey('modal-recoger-titulo')}</h4>
                        <p className='gray-1-color'>{getTextByKey('modal-recoger-elegir')}</p>
                      </div> 
                      <form action="" className='pb-4 pt-3'>
                        <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <select className='p-2 gray-1-color' name="ciudad">
                              <option  defaultValue="ciudad">{getTextByKey("ciudad")}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <select className='p-2 gray-1-color' name="tienda">
                              <option defaultValue="tienda">{getTextByKey("tienda")}</option>
                            </select>
                          </div>
                        </div>
                        </div>
                      </form>
                      <div className="text-center pt-3 d-flex justify-content-center align-items-center gap-2">
                          <button className='btn-g bb px-5' onClick={volverModal}>{getTextByKey('volver')}</button>
                          <button className='btn-g' onClick={confirmModal}>{getTextByKey('confirmar')}</button>
                      </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ModalProgramarPedido showProgramar={showProgramar} setShowProgramar={setShowProgramar} setShowModalRecoger={setShowModalRecoger} />      
        </Fragment>
    )
}
