import React from "react";
import { NavLink } from "react-router-dom";
import styleButton from "../scss/btn.module.scss";
import styleTittle from "../scss/text.module.scss";

const TittleH1 = ({ children, styleGroup }) => {
  
  return (
    <h2 className={`${styleGroup} ${styleTittle.h2}`}>{children}</h2>
  );
};

export default TittleH1;