import { Icon } from '@iconify/react'
import React from 'react'
import StyleControl from '../scss/controlQuantity.module.scss';

export function QuantyControls ({styleGroup, quantity = 1, onMinus, onPlus, onChange}) {
  return (
    <div className={`${StyleControl.controls} ${styleGroup}`}>
      <button onClick={onMinus} type='button' className={`${StyleControl.control} ${StyleControl.Minus}`}>
        <Icon icon="ic:baseline-minus"/>
      </button>
      <input type="number" min={0} onChange={onChange} value={quantity} className={`fw-bold ${StyleControl.input}`} />
      <button type='button' onClick={onPlus} className={`${StyleControl.control} ${StyleControl.Plus}`}>
        <Icon icon="ic:baseline-plus"/>
      </button>
    </div>
  )
}

export default QuantyControls
