//import { useMemo } from "react";
import { GoogleMap, MarkerF, useLoadScript, InfoWindowF } from "@react-google-maps/api";
import { useState } from "react";
import { useEffect } from "react";
import { getInfoMap } from "../api/mapApi";
import { getEnvVariables } from "../helpers";
//import { closest } from "dom-helpers";

const libraries = ['geometry'];

export function MapComponent({ locationMarker, updateAddressForm, navigator,setNearestLocation, locations }) {
  const { GOOGLE_API_KEY } = getEnvVariables()
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries
  });
  
  if (!isLoaded) return <div>Loading...</div>;
  return (<Map 
    locationMarker={locationMarker} 
    updateAddressForm={updateAddressForm} 
    nav={navigator}
    setNearestLocation={setNearestLocation} 
    locations={locations} 
    />);
  }
  
  function Map( {locationMarker, updateAddressForm,  nav,setNearestLocation, locations} ) {
  const [informationBoxes, setInformationBoxes] = useState({});
  const [ubicacion, setUbicacion] = useState(nav);
  const [position, setPosition] = useState(locationMarker);

  
  /*useEffect(() => {
    if( !locationMarker ) {
      locationMarker = {
        lat: 3.4302921132901742,
        lng: -76.52092111730114
      }
      setPosition( locationMarker )
    }
    setPosition( locationMarker )

  }, [locationMarker]);

  useEffect(() => {
    if (setNearestLocation && locations) {
        setNearestLocation(getNearestLocation());
    }
  }, [locations, locationMarker]);*/

  useEffect(() => {
    const infoBoxes = {};
    locations.forEach(location => {
      infoBoxes[location.id] = false;
    });
    setInformationBoxes(infoBoxes);
  }, [locations]);
  
 /* const getNearestLocation = () => {
    let closest = [];
    for (const location_ in locations) { 
      const location = locations[location_];
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        locationMarker, 
        {
          lat: Number(location.lat), 
          lng: Number(location.lng)
        }
      );
      closest.push({location, distance});
    }
    closest = closest.sort((a,b) => a.distance - b.distance );

    return closest[0]?.location;
  }*/

  const toggleInfobox = (id, state) => {
    setInformationBoxes({...informationBoxes, [id]: state});
  }


  const handleChangeMarker = async(e) => {
    setPosition(
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      }
    )
    const { data } = await getInfoMap(`${e.latLng.lat()},${e.latLng.lng()}`);
    const resultsAddress = data.results[0].address_components;
    const sendToFrom = {
      postal_code: "",
      country: "",
      route: "",
      street_number: ""
    }

    resultsAddress.forEach(element => {
      if( element.types.indexOf('postal_code') >=0 ) {
        sendToFrom.postal_code = element.long_name
      }

      if( element.types.indexOf('country') >=0 ) {
        sendToFrom.country = element.long_name
      }

      if( element.types.indexOf('route') >=0 ) {
        sendToFrom.route = element.long_name.split(' ')[0]
      }

      if( element.types.indexOf('route') >=0 ) {
        sendToFrom.street_number = element.long_name.split(' ')[1]
      }
      
      if( element.types.indexOf('street_number') >=0 ) {
        sendToFrom.street_number += element.long_name
      }
    });
    updateAddressForm(sendToFrom);

  }

  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  return (
        <GoogleMap 
          mapContainerStyle={containerStyle} 
          options={{ gestureHandling: 'greedy'}} 
          zoom={6} 
          center={position} 
          mapContainer 
          ClassName=""
          >
            {ubicacion &&(
          <MarkerF
            position={position}
            icon={{
              url: '/img/pin_gray.png', 
              
            }}
            title='Mi ubicación'
          />
            )}
            {locations.map(location => (
            <MarkerF key={location.id} title={location.nombre} position={{
                lat: Number(location.lat), 
                lng: Number(location.lng),
              }}
              onClick={() => toggleInfobox(location.id, true)}
            >
              {
                informationBoxes[location.id] && (
                  <InfoWindowF position={{
                      lat: Number(location.lat), 
                      lng: Number(location.lng),
                    }}
                    onCloseClick={() => toggleInfobox(location.id, false)}
                    >
                      <div>
                        <strong>{location.nombre}</strong>
                        <p>{location.direccion}</p>
                        <p>{location.telefono}</p>
                      </div>
                  </InfoWindowF>
                )
              }
          </MarkerF>
          )
        )}
            
        </GoogleMap>
  );
}