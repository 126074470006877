import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useApi } from '../../../context/apiContext';
import { Icon } from '@iconify/react';

import StyleBtn from '../../../scss/btn.module.scss';
import TextBlock from '../../../components/TextBlockComponent';
import TittleH6 from '../../../components/TittleH6Component';

 
export function ModalGuardar({showModal, setShowModal,saveFn,load}) {
    const { getTextByKey } = useApi();
    
    return (
      
            <Modal show={showModal}  className="modal modal-user" id="guardarModal">
                   <Modal.Header className="text-end">
                     <Icon className='cursor-pointer primary-color close-modal' icon="material-symbols:close-rounded" data-bs-dismiss="modal"   onClick={() => setShowModal( false )}></Icon>
                    </Modal.Header>
                    <Modal.Body className="modal-body pt-4">
                      <div className="text-center pt-2">
                              <TittleH6 styleGroup="primary-color" id="guardarCuentaModal">{getTextByKey('guardar_cambios')}</TittleH6>
                              <TextBlock styleGroup="mb-0">{getTextByKey('alerta_modal_guardar_cambios')}</TextBlock>
                        </div>
                     </Modal.Body>
                     <Modal.Footer className="modal-footer justify-content-center border-0 pb-3">
                            <button type="button" className={StyleBtn.btn} onClick={() => setShowModal( false )} data-bs-dismiss="modal">{getTextByKey('cancelar')}</button>
                              <button type="button" onClick={saveFn} disabled={load}  className={`${StyleBtn.btn} ${load ? StyleBtn.active :''}`}>{getTextByKey('guardar')}
                              <span className={StyleBtn.loadingContainer}>
                              <span className={StyleBtn.dot}>.</span>
                              <span className={StyleBtn.dot}>.</span>
                              <span className={StyleBtn.dot}>.</span>
                            </span>
                              </button>
                  
                     </Modal.Footer>
            </Modal>
           
        
    )
}