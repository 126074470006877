import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';




export default function ModalAlerta({ showAlerta, setShowAlerta }) {

    const { getTextByKey, getTextBigByKey } = useApi();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowAlerta(false);
        gotoTop();
    }
    
    return (
        <Fragment>
            <Modal show={showAlerta} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2">
                      <Icon className='cursor-pointer' icon="bi:x-lg" onClick={handleClose}></Icon>
                    </div>
                    <div className="container">
                      <div className="text-center">
                        <p className='gray-1-color '>{getTextByKey('modal-alerta-titulo')}</p>
                        <p className='gray-1-color d-none'>{getTextByKey('alerta-limite-compra')}</p>
                        <p className='gray-1-color d-none'>{getTextByKey('alerta-tope')}</p>
                        <p className='gray-1-color d-none'>{getTextByKey('alerta-descuento-limite')}</p>
                        <p className='gray-1-color '>{getTextByKey('alerta-limite-unidades')}</p>
                      </div> 
                      <div className="text-center pt-3">
                          <button className='btn-g px-4 mx-3' onClick={handleClose}>{getTextByKey('entendido')}</button>
                      </div>
                    </div>
                </Modal.Body>
            </Modal>      
        </Fragment>
    )
}