import React, { Fragment, useState } from 'react';
import StylePagination from '../scss/pagination.module.scss';
import StyleGeneral from '../scss/general.module.scss';
import { Icon } from '@iconify/react';
import scrollToTop from './GlobalFunction';


function Pagination(
    {
        pages = 1,
        currentPage = 1,
        onPageChange = () => {},
        onNext = () => {},
        onPrevious = () => {}
    }
    ) {

    const isCurrentPage = page => page + 1 == currentPage;

    const handlePrevious = () => {
      onPrevious();
      scrollToTop();
    };
  
    const handleNext = () => {
      onNext();
      scrollToTop();
    };
  
      
    return(
      <Fragment>
        <ul className={StylePagination.pagination}>
          {/* prev */}
          <li className='me-2'>
            <button onClick={handlePrevious} className={`${StylePagination.arrow} ${StyleGeneral.radiusCircle}`}>
              <Icon icon="material-symbols:keyboard-arrow-left"/>
            </button>
          </li>
          {/* pages */}
          {
            [...Array(pages).keys()].map(page => (
                <li key={page}><button
                    onClick={() => {
                        onPageChange(page + 1);
                        scrollToTop();
                    }}
                    className={`${StylePagination.btn}`}
                    style={isCurrentPage(page) ? {textDecoration: 'underline', fontWeight: '700'} : { } }
                >
                    {page + 1}
                </button></li>
            ))
          }
          {/* next */}
          <li className='ms-2'>
            <button onClick={handleNext} className={`${StylePagination.arrow} ${StyleGeneral.radiusCircle}`}>
              <Icon icon="material-symbols:keyboard-arrow-right"/>
            </button>
          </li>
        </ul>
      </Fragment>
    );
}

export default Pagination;
