import { Fragment, useState } from "react"
import React from "react"
import { useEffect } from 'react';
import { Modal, Button } from "react-bootstrap"

import StyleBtn from "../../src/scss/btn.module.scss"
import TextBlock from "./TextBlockComponent";
import TittleH6 from "./TittleH6Component";

import { useCart } from "../context/cartContext";
import { useApi } from "../context/apiContext";

import ButtonLink from "./ButtonLinkComponent";
import { Link } from "react-router-dom";
/* const { total, envio, compraMinima,creditBenefitType,subtotal,impuesto,coupon,descuento, direccion, 
    currentCart, clearCart, applyCoupon,tiempoentrega,tiendaGeneral } = useCart(); */

    

export function AlertComponent(props) {
    const { getTextByKey, convertCurrency } = useApi();
    const msn = props.msn;
    const [showalert, setShowAlert]=useState(props.showAlert);
    const title = props.title;
    const type = props.type;
    const valorMinimo = props.compraMinima;
    const total = props.totalCompra;
    const [mandatory, setMandatory] = useState(props.mandatory|| null);

    const changemandatory = (e) => {
        setMandatory(false);
        location.href='/checkout-c';
        setShowAlert(false);
     }

    useEffect(() => {
        console.log('total',total);
        if(type=='continuarCompra'){
            if(total < valorMinimo || msn){
               setShowAlert(true);
                    
            }else{
                setShowAlert(false);
            }
        }
                    
          
      },[total,type]);


      const close = (e) => {
       e.preventDefault();
        console.log('entro cerrar',localStorage.getItem('volver-sitio'));
       if(localStorage.getItem('volver-sitio')){
        location.href=localStorage.getItem('volver-sitio');
       }
       setShowAlert(false);
     }

      
    useEffect(() => {
       setShowAlert(props.showAlert);
          
      },[props.showAlert]);
    return (
        <Fragment>
            <Modal show={showalert} onHide={mandatory ? null : props.handleCloseModal} centered={true}>
                <Modal.Header /* closeButton */ className="border-0">
                    <TittleH6 styleGroup="w-100 mb-0 text-center fw-bold primary-color">{title}</TittleH6>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        {type === "error" ? 
                            <Fragment>
                                <TextBlock styleGroup="mb-0">{msn}</TextBlock>
                            </Fragment>
                        : null}
                        {type === "continuarCompra" ?
                            <Fragment>
                                <TextBlock styleGroup="mb-0">{msn}</TextBlock>
                                {total < valorMinimo?
                                    <TextBlock styleGroup="mt-2">**El valor de tu compra es de {convertCurrency(total)} y el valor  mínimo debe ser {convertCurrency(valorMinimo)} </TextBlock>
                                : null}
                            </Fragment>
                        : null}
                        
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center border-0">

                    {type === "continuarCompra"  ?
                        <Link to="/tienda" className={StyleBtn.btn} onClick={props.handleCloseModal}>Volver a la tienda</Link>
                    :
                        null
                    }

                    {type === "continuarCompra" && total >= valorMinimo ?
                     <button className={StyleBtn.btn} onClick={changemandatory}>Continuar Pago</button>
                  
                    : null
                    }
            
                    {type === "error" ? 
                        <button className={StyleBtn.btn} onClick={close}>Aceptar</button>
                    : null}

                    {type === "success" ?
                        <button className={StyleBtn.btn} onClick={close}>Aceptar</button>
                    : null}
                    {type === "warning" ?
                        <button className={StyleBtn.btn} onClick={close}>Aceptar</button>
                    : null}
                    {type === "save" ?
                        <button className={StyleBtn.btn} onClick={close}>Guardar</button>
                    : null}
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}