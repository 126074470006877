import React, { Fragment, useEffect, useState } from "react";
import { useApi } from "../context/apiContext";
import { useCart } from '../context/cartContext';
import TextBlock from "./TextBlockComponent";
import StyleButton from "../scss/btn.module.scss";
import { NavLink, Link } from "react-router-dom";
import styles from "../scss/header.module.scss";

export const DatosEnvio = (props) => {
	const { getTextByKey } = useApi();
	const { updateDireccion } = useCart();
	const [dirUser, setDirUser] = useState("");
	const [envio, setEnvio] = useState([]);
	const { user } = props;	

	
	
	const goToCheckoutC = () => {
		updateDireccion('');
		location.href = '/checkout-c';
	}

	if (user==0)
    return (
      <div className='container'>
        <div className="text-center-c">
          <div className="loader-box">
            <img src="/img/SVG/loader.svg" alt="" />
          </div>
        </div>
      </div>
    );

	return (
		<Fragment>
			<div className="border px-2 py-3">
				{/* <ul>
					{envio.map((item, index) =>(
					<li>{index}</li>
				))}
				</ul> */}
				<div className="content">
					<TextBlock styleGroup="d-flex justify-content-start align-items-center px-3 px-lg-4 mb-2">
						<span className="fw-bold">{user.nombre} {!user.juridico ? user?.nombredos:''} {!user.juridico? user.apellido: ''} {!user.juridico ? user.apellidodos:''}</span>
					</TextBlock>
					<TextBlock styleGroup="d-flex justify-content-start align-items-center px-3 px-lg-4 mb-2">
						<span className="pe-2">{getTextByKey("envio_ciudad")}</span>
						<span className="">{user.city}</span>
					</TextBlock>
					<TextBlock styleGroup="d-flex justify-content-start align-items-center px-3 px-lg-4 mb-2">
						<span className="pe-2">{getTextByKey("envio_dir")}</span>
						<span className="">{user.direccion}</span>
					</TextBlock>
					<TextBlock styleGroup="d-flex justify-content-start align-items-center px-3 px-lg-4 mb-2">
						<span className="">{user.barrio}</span>
					</TextBlock>
					<button className={`ms-3 mt-4 ${StyleButton.btn}`} onClick={() => goToCheckoutC()}>
							Cambiar información
					</button>
				</div>
			</div>
		</Fragment>
	);
};
