import { Fragment } from "react";
import { NavComponent } from "./components/NavComponent";
import { NavsupComponent } from "./components/NavsupComponent";
import MetodoPagoComponent from "./components/MetodoPagoComponent";
import { useApi } from "../../context/apiContext";
import { useEffect } from "react";
import { getTarjetas } from "../../api/productApi";
import { useState } from "react";
import Modalmetodo from "./components/Modalmetodo";
import { useAuth } from "../../authContext";


export default function DetallescuentaPage() {

    const { getTextByKey } = useApi();
    const [tarjetas, setTarjetas] = useState([]);
    const [tarjetaEdit, setTarjetaEdit] = useState({});
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();

    useEffect (() => {
        setTarjetas([]);
        getTarjetas(currentUser.uid).then((response) => {
            console.log(response);
            setTarjetas(response);
        });
    }, []);

    const updateTarjetas = async () => {
        try {
            const listTarjetas = await getTarjetas(currentUser.uid);
            setTarjetas(listTarjetas)
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    const handleTarjeta = (idTarjetaSelected) => {
        tarjetas.forEach( tarjeta => {
            if( tarjeta.id === idTarjetaSelected ) {
                console.log(tarjeta);
            }
        } )
    }

    return (
        <Fragment>
            <div className="quaternary-bg-color py-4 head-perfil">
                <div className="container">
                    <div className="text-center">
                        <h1 className="text-uppercase">{getTextByKey("account-title")}</h1>
                        <NavsupComponent />
                    </div>
                </div>
            </div>
            <div className="container-fluid px-4 py-5">
                <div className="d-lg-flex">
                    <div className="col-nav">
                        <NavComponent />
                    </div>
                    <div className="col-content-perfil">
                        {tarjetas.length > 0 ? tarjetas.map((tarjeta, index) => (
                            <MetodoPagoComponent tarjeta={tarjeta} key={index} selected={0} handleTarjeta={handleTarjeta} updateTarjetas={updateTarjetas} />
                        )) : <h2 className="text-uppercase">{getTextByKey("no-payment-methods")}</h2>}
                        <a className="btn-g bb py-2 px-4 text-uppercase" onClick={ () => setShowModal( true ) }>{getTextByKey("new-button-method")}</a>
                    </div>
                </div>
            </div>

            <Modalmetodo updateTarjetas={updateTarjetas} showModal={showModal} setShowModal={setShowModal} />
        </Fragment>
    )
}