import { Fragment } from "react";
import { ModaleliminarcuentaComponent } from "./components/ModaleliminarcuentaComponent";
import { NavComponent } from "./components/NavComponent";
import { NavsupComponent } from "./components/NavsupComponent";
import PedidoComponent from "./components/PedidoComponent";
import { useEffect } from "react";
import { listOrdersUser } from "../../api/ordersApi";
import { useAuth } from "../../authContext";
import { useApi } from "../../context/apiContext";
import { useState } from "react";
import { Link } from "react-router-dom";


import StyleGeneral from "../../scss/general.module.scss";
import StylePerfil from "../../scss/perfil.module.scss";
import StyleButton from "../../scss/btn.module.scss";
import ButtonLink from "../../components/ButtonLinkComponent";
import ButtonLinkSecundary from "../../components/ButtonLinkSecundaryComponent";

import TittleH1 from "../../components/TittleH1Component";
import TittleH2 from "../../components/TittleH2Component";
import TextBlock from "../../components/TextBlockComponent";
import StyleText from "../../scss/text.module.scss";
import {createDateFormatTr} from "../../helpers/dateHelper";

export default function PedidosPage() {


    const { currentUser } = useAuth();
    const { currentLocale, getTextByKey,convertCurrency } = useApi();
    const [listOrders, setlistOrders] = useState([])

    useEffect(() => {
        async function getListOrderUsers() {
            try {
                console.log(currentUser)
                const listOrderss = await listOrdersUser(currentUser.uid, currentLocale);
                if (listOrderss) {
                    setlistOrders(listOrderss)
                    console.log("compras ", listOrders);
                }
            } catch (error) {
                console.log('Error: ', error)
            }
        }

        getListOrderUsers();
    }, [])
    

  return (
    <Fragment>
        <div className={`py-5 gray-2-bg-color ${StyleGeneral.minHeight}`}>
            <div className={`container ${StyleGeneral.container95}`}>
                <div className="row">
                        <div className="col-12 text-center mb-4">
                            <TittleH1 styleGroup={`primary-color autography ${StyleText.h4Special}`}>{getTextByKey('perfil')}</TittleH1>
                        </div>
                    </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 col-lg-4 px-xl-5 mb-4">
                        <NavComponent/>
                    </div>
                    <div className="col-12 col-lg-8 px-xl-5 mb-5">
                        <div className="border white-bg-color p-4 p-xl-5">
                            {/* { listOrders.map( order => <PedidoComponent key={order.id} dataOrder={order} nameUser={currentUser.displayName} /> ) }
                            {listOrders.length === 0 &&
                                <div className="text-center text-lg-start">
                                    <p>{getTextByKey('no_hay_pedidos')}</p>
                                    <div className="mt-4">
                                        <Link to="/tienda" className="btn-g py-2 px-3 px-xl-4">{getTextByKey('ir-a-tienda')}</Link>
                                    </div>
                                </div> 
                            } */}
                            <div className="row">
                                <TextBlock>Órdenes realizadas</TextBlock>
                                <div className="col-12 col-xl-10">
                                    {
                                        listOrders.map(order => (
                                            <div className={`border ${StylePerfil.itemPerfil}`}>
                                                <div className="d-md-flex justify-content-between align-items-center">
                                                    <div className={StylePerfil.colPedido}>
                                                        <TextBlock styleGroup='mb-2'><span className={`fw-bold ${StylePerfil.smallTitle}`}>Orden: </span> #{order.id}</TextBlock>
                                                        <TextBlock styleGroup='mb-2'><span className={`fw-bold ${StylePerfil.smallTitle}`}>Fecha: </span> {createDateFormatTr(order.orderDate, getTextByKey)}</TextBlock>
                                                        <TextBlock styleGroup='mb-2'><span className={`fw-bold ${StylePerfil.smallTitle}`}>Estado: </span> {order.estadoCompra}</TextBlock>
                                                        <TextBlock styleGroup='mb-3 mb-md-0'><span className={`fw-bold ${StylePerfil.smallTitle}`}>Total: </span> {convertCurrency(order.total)}</TextBlock>
                                                    </div>
                                                    <div className={`text-center text-md-end ${StylePerfil.colPedido}`}>
                                                        <ButtonLinkSecundary to={`/pedido/${order.id}`}>Ver pedido</ButtonLinkSecundary>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="container-fluid px-4 px-lg-0 py-5">
            <div className="d-lg-flex">
                <div className="col-nav mb-4 mb-lg-0">
                    <NavComponent/>
                </div>
                <div className="col-content-perfil">
                    <h2 className="text-uppercase">Pedidos</h2>
                    <div className="py-2 py-md-3 py-xl-4"> 
                        <div className='row'>
                            <div className='col-md-10 offset-md-1 col-lg-11 offset-lg-0 col-xl-9 offset-xl-0'>
                                { listOrders.map( order => <PedidoComponent key={order.id} dataOrder={order} nameUser={currentUser.displayName} /> ) }
                                {listOrders.length === 0 && <p className='text-center'>No hay pedidos</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <ModaleliminarcuentaComponent/>
    </Fragment>
  )
}
