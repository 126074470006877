import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDVYUyPV3GVwsCn3Kh98TvvZdkgRi58rVM",
  authDomain: "rosmi-3ef9a.firebaseapp.com",
  projectId: "rosmi-3ef9a",
  storageBucket: "rosmi-3ef9a.appspot.com",
  messagingSenderId: "756562261985",
  appId: "1:756562261985:web:2c3322b041a1283f9244a0",
  measurementId: "G-B5MC9SFT37"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
