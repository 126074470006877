import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ModalComponent from './components/ModalComponent'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from 'react';
import { useApi } from '../../context/apiContext';
import styleText from "../../scss/text.module.scss";
import StyleGeneral from '../../scss/general.module.scss';
import StyleBtn from '../../scss/btn.module.scss';
import { getImages } from "../../api/imageApi";
import { images_products_route } from "../../api/productApi";
import TittleH4 from '../../components/TittleH4Component';
import {useForm} from "../../hooks/useForm";
let initialState = {
    email: "",
  
};
export default function ResetPassPage() {

    const [error, seterror] = useState('');
    const { getTextByKey, getTextBigByKey } = useApi();
    const [bgImageLoginRegister, setBgImageLoginRegister] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const {
        onInputChange,
         email
          } = useForm(initialState)

    const resetPass = async e => {
        e.preventDefault();
        console.log(email);
         const auth = getAuth();
        if(!email){
            seterror('Ingrese su correo');
            return;
        }
        seterror('');
    
       
        await sendPasswordResetEmail(auth, email)
            .then((data) => {
                setShowModal(true);
            })
            .catch((error) => {
                const errorCode = error.code;
                if(errorCode=='auth/user-not-found'){
                    seterror('El usuario no se encuentra registrado');
                }else{
                    seterror('Ups! ocurrio un error por favor vuelva a intentarlo');
                }
               
            });
    };

    useEffect(() => {
        getImages("bg-login-register").then((data) => {
            setBgImageLoginRegister(data);
            console.log("bgImageLoginRegister ", bgImageLoginRegister);
        });
    }, [])

    return (
        <Fragment>
            {
                bgImageLoginRegister.length > 0 &&
                <div className={`login-register bg-options-repeat py-5 ${StyleGeneral.minHeight}`} style={{ backgroundImage: `url(${images_products_route + bgImageLoginRegister[0].image})` }}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-8 offset-md-2 col-lg-6 col-xl-8 offset-lg-3 offset-xl-2'>
                                <form className='py-3 px-4 py-xl-4 white-color' onSubmit={resetPass}>
                                    <TittleH4 styleGroup={`text-center carrefourMetis-bold mb-5 autography specialColorC-color
                                    ${styleText.h4Special}`}>
                                        {getTextByKey('olvidar-contrasena-titulo')}
                                    </TittleH4>
                                    {/* <h1 className='text-center carrefourMetis-bold mb-4'></h1> */}
                                    <div className={`text-center mb-3 ${styleText.paragraph}`}>
                                        <div dangerouslySetInnerHTML={{ __html: getTextBigByKey('resset-pass-text') }}></div>
                                    </div>
                                    {/* <div className='text-center mb-3' dangerouslySetInnerHTML={{ __html: getTextBigByKey('resset-pass-text') }}></div> */}
                                    {error ? <div className='error-msg'>{error}</div> : null}
                                    <div className='px-xl-5'>
                                        <div className='form-group mb-4'>
                                            {/* <label>{getTextByKey('correo_electronico_form')}*</label> */}
                                            {/* <input type="email" name="email" className='p-2' /> */}
                                            <input
                                                type="email"
                                                name="email"
                                                className={`px-3 py-2 white-border-color gray-1-color ${StyleGeneral.input} ${styleText.input} `}
                                                placeholder='Correo electrónico*'
                                                onChange={onInputChange}
                                                value={email}
                                              
                                            />
                                        </div>
                                        <div className='form-group text-center mt-3 mtlg-5'>
                                            {/* <button type="submit" className='btn-g py-2 px-4 px-xl-5' data-bs-toggle="modal" data-bs-target="#ModalConfirmation">{getTextByKey('restablecer')}</button> */}

                                            <button type="submit"
                                                className={`btn-g py-2 px-5 ${StyleBtn.btn}`}
                                             >
                                                {getTextByKey('restablecer')}
                                            </button>
                                        </div>
                                        <div className='form-group text-center mt-3'>
                                            <Link to="/login" className='white-color'>
                                                <p className='md-p mb-0'>
                                                    {getTextByKey('vuelve-iniciar-sesion')}
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <ModalComponent showModal={showModal} setShowModal={setShowModal} email={email} />
        </Fragment>

    )
}
