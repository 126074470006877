import React from "react";
import { NavLink } from "react-router-dom";
import styleButton from "../scss/btn.module.scss";

import scrollToTop from "./GlobalFunction";

const ButtonLink = ({ to, children, styleGroup }) => {

  /* const handleClick = scrollTop ? actionScrollToTop : null; */

  function actionScrollToTop(){
    if (to != null ||  to != undefined || to != '' ) {
      scrollToTop();
      console.log('auto scroll to top');
    }
  }

  return (
    <NavLink to={to} className={`${styleButton.btn} ${styleGroup}`} onClick={actionScrollToTop}>
      {children}
      <span className={styleButton.loadingContainer}>
        <span className={styleButton.dot}>.</span>
        <span className={styleButton.dot}>.</span>
        <span className={styleButton.dot}>.</span>
      </span>
    </NavLink>
  );
};

export default ButtonLink;