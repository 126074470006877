import React, { Fragment, useState } from 'react';
import { NavLink, useNavigate, Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import { useAuth } from "../authContext";
import { useCart } from "../context/cartContext";
import { useApi } from '../context/apiContext';

import { useEffect } from 'react';

import StyleHeader from '../scss/header.module.scss';
import { Button } from 'bootstrap';

import { showSearcher } from './headerFunctionComponent';
import TittleH6 from './TittleH6Component';

export default function OpctionsHeaderComponent(props) {

    const [logged, setLogged] = React.useState(false);
    const { getTextByKey } = useApi();
    const { clearAll } = useCart();


    //Check the Auth Session
    const { currentUser, logout } = useAuth();
    React.useEffect(() => {
        if (currentUser) {
            setLogged(true);
        } else {
            setLogged(false);
        }
    }, [currentUser]);

    //Logout Event
    const handleLogout = async () => {
        try {
            await logout();
            clearAll();
            location.href = "/";
        } catch {
        }
    };

    const openSearch = event => {
        const filter = document.getElementById('buscador');
        filter.classList.toggle('show');
    };

    const { showCart, getNumberProducts, calculateTotal, total, envio, descuento, coupon, updateCoupon, currentCart } = useCart();

    const openCar = event => {
        showCart();
    };

    const [numberProducts, setNumberProducts] = React.useState(0);

    useEffect(() => {
        setNumberProducts(getNumberProducts());
    }, [getNumberProducts, numberProducts]);



    const navigate = useNavigate();

    // const handleShowModal = (currentUser) => {
    //     if (currentUser) {
    //       sessionStorage.setItem('something-to-tell', 'feedback-a')
    //         navigate('/login');
    //       setShowModal(true);
    //     }else{
    //       console.log('no user');
    //     }
    // }

    const [menuVisible, setMenuVisible] = useState(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };


    return (
        <Fragment>
            
                <ul className={`d-flex align-items-center ${StyleHeader.options}`}>
                    
                    <li className={StyleHeader.itemOptions}>
                        <button onClick={props.searchClick}>
                            <Icon icon="material-symbols:search" />
                        </button>
                    </li>
                    
                    {/* <li className={StyleHeader.itemOptions}>
                        <NavLink to="/">
                            <Icon icon="mdi:map-marker"/>
                        </NavLink>
                    </li> */}
                    
                    <li className={StyleHeader.itemOptions}>
                      
                    {currentUser ?
                        (!currentUser.isAnonymous) ?
                           
                            <Fragment>
                                <button>
                                    <Icon icon="majesticons:user" />
                                    {/* {logged && <span className="name-user d-none d-md-block text-uppercase text-center">{currentUser.displayName.split(' ')[0]}</span>} */}
                                </button>
                                <ul className='opt-user'>
                                    <li>
                                    <TittleH6 styleGroup="fw-bold primary-color text-start">
                                        {getTextByKey('menu_titulo_usuario')}
                                    </TittleH6>
                                    </li>
                                    <li>
                                        <Link to="/detalles-cuenta">{getTextByKey('menu_perfil')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/mis-pedidos">{getTextByKey('menu_pedidos')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/direccion-envio">{getTextByKey('menu_direccion')}</Link>
                                    </li>
                                    <li onClick={handleLogout}>
                                        <Link onClick={handleLogout}>{getTextByKey('menu_cerrar_sesion')}</Link>
                                    </li>
                                </ul>
                            </Fragment>:<></>
                         :<></>
                        }
                        
                        
                        { !currentUser ?
                            <Fragment>
                                <NavLink to="/login">
                                    <Icon icon="ant-design:user-outlined" />
                                </NavLink>
                                <ul>
                                    <li>
                                        <Link to="/login">{getTextByKey('iniciar-sesion')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/register">{getTextByKey('register')}</Link>
                                    </li>
                                </ul>
                            </Fragment>
                        : currentUser.isAnonymous &&
                        <Fragment>
                                <Link to="/login">
                                    <Icon icon="ant-design:user-outlined" />
                                </Link>
                                <ul>
                                    <li>
                                        <Link to="/login">{getTextByKey('iniciar-sesion')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/register">{getTextByKey('register')}</Link>
                                    </li>
                                </ul>
                            </Fragment>
                        }
                    </li>
                    
                    <li className={StyleHeader.itemOptions}>
                        <a onClick={openCar}>
                            {/* <Icon icon="carbon:shopping-cart"/> */}
                            <img className='icoCart' src="/svg/cart.svg" alt="GoPicnic"/>
                            {/* {
                                numberProducts > 0 ? <div className="badge gray-1-bg-color white-color rounded-circle">{numberProducts}</div>
                                : <div className="badge gray-1-bg-color white-color rounded-circle">0</div>
                            }
                            <p className='gray-1-color price mb-0 d d-none d-lg-block'>${(total+envio)-(coupon ? coupon.descuento : 0)}</p> */}
                        </a>
                    </li>
                </ul>
            
        </Fragment>
    )
}
