import React, {useState} from 'react'
import TittleH6 from '../../components/TittleH6Component'
import { useApi } from '../../context/apiContext'
import { Fragment } from 'react';
import TittleH1 from '../../components/TittleH1Component';
import { Icon } from '@iconify/react';
import StyleText from '../../scss/text.module.scss';
import StyleGeneral from '../../scss/general.module.scss';
import { Collapse, Button } from 'react-bootstrap';
import { getGallery } from "../../api/galleryApi";
import scrollToTop from '../../components/GlobalFunction';

import { useEffect } from 'react';


export default function LegalPage() {
    const { getTextBigByKey, getTextByKey,getPropiertyByLocale } = useApi();
    const [legals, setLegals] = useState([]);
    const [openItem, setOpenItem] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10:false
    });
    
    const toggleOpenItem = (itemId) => {
        setOpenItem((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };

    const handleCloseItem = (itemId) => {
        scrollToTop();
        setTimeout(() => {
            setOpenItem((prevState) => ({
                ...prevState,
                [itemId]: false,
            }));    
        }, 500);
        
    };

    useEffect(() => {
        getGallery('section-legal').then(
            (data) => {
                setLegals(data);
            }
        )
    }, []);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash === '#collapseSix') {
          setOpenItem((prevState) => ({
            ...prevState,
            6: true,
          }));
        }
    }, []);

    return (
        <Fragment>
            <div className='quinaryB-bg-color pb-5'>
                <div className={`container ${StyleGeneral.minHeight}`}>
                    <div className="row">
                        <div className="col-12 col-lg-5 offset-lg-4">
                            <TittleH1 styleGroup={`primary-color autography text-center py-3 py-lg-4 ${StyleText.h4Special}`}> 
                                {getTextByKey('Legales_title')}</TittleH1>
                            <ol className='primary-color fw-bold pb-3 pb-lg-5'>
                            {legals.map((item, index) => (
                                <li className='mb-2 mb-lg-3'>
                                <div className="titlle" 
                                    onClick={() => toggleOpenItem(index)}
                                    aria-controls="collapseOne"
                                    aria-expanded={openItem[index]}>
                                    <TittleH6 styleGroup="primary-color fw-bold cursor-pointer" >{getPropiertyByLocale(item.names, 'titulo')}</TittleH6>
                                </div>
                                <Collapse in={openItem[index]}>
                                    <div id="collapseOne">
                                        <div className="d-flex fw-normal pe-3 pe-md-0">
                                            <div dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(item.names, 'descripcion') }}></div>
                                            <div className="d-flex justify-content-end align-items-end"
                                                onClick={() => handleCloseItem(index)}>
                                                <Icon className='cursor-pointer specialColorB-color ' width="25" height="25" icon="material-symbols:arrow-circle-up-rounded" ></Icon>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </li>
                                ))}
                               {/* <li className='mb-2 mb-lg-3'>
                                    <div className="titlle" 
                                        onClick={() => toggleOpenItem(1)}
                                        aria-controls="collapseOne"
                                        aria-expanded={openItem[1]}>
                                        <TittleH6 styleGroup="primary-color fw-bold cursor-pointer" >{getTextByKey('politica_garantia')}</TittleH6>
                                    </div>
                                    <Collapse in={openItem[1]}>
                                        <div id="collapseOne">
                                            <div className="d-flex fw-normal pe-3 pe-md-0">
                                                <div dangerouslySetInnerHTML={{ __html: getTextBigByKey('politicas-garantia') }}></div>
                                                <div className="d-flex justify-content-end align-items-end"
                                                    onClick={() => handleCloseItem(1)}>
                                                    <Icon className='cursor-pointer specialColorB-color ' width="25" height="25" icon="material-symbols:arrow-circle-up-rounded" ></Icon>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </li>
                                <li className='mb-2 mb-lg-3'>
                                    <div onClick={() => toggleOpenItem(2)}
                                        aria-controls="collapseTwo"
                                        aria-expanded={openItem[2]}>
                                        <TittleH6 styleGroup="primary-color fw-bold cursor-pointer" >{getTextByKey('politica_cambios')}</TittleH6>
                                    </div>
                                    <Collapse in={openItem[2]}>
                                        <div id="collapseTwo">
                                            <div className="d-flex fw-normal pe-3 pe-md-0">
                                                <div dangerouslySetInnerHTML={{ __html: getTextBigByKey('politicas-cambios') }}></div>
                                                <div className="d-flex justify-content-end align-items-end" onClick={() => handleCloseItem(2)}>
                                                    <Icon className='cursor-pointer specialColorB-color ' width="25" height="25" icon="material-symbols:arrow-circle-up-rounded" ></Icon>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </li>

                                <li className='mb-2 mb-lg-3'>
                                    <div onClick={() => toggleOpenItem(3)}
                                        aria-controls="collapseThree"
                                        aria-expanded={openItem[3]}>
                                        <TittleH6 styleGroup="primary-color fw-bold cursor-pointer" >{getTextByKey('politica_retracto')}</TittleH6>
                                    </div>
                                    <Collapse in={openItem[3]}>
                                        <div id="collapseThree">
                                            <div className="fw-normal pe-3 pe-md-0">
                                                <div dangerouslySetInnerHTML={{ __html: getTextBigByKey('politicas-retracto') }}></div>
                                                <div className="d-flex justify-content-end align-items-end" onClick={() => handleCloseItem(3)}>
                                                    <Icon className='cursor-pointer specialColorB-color ' width="25" height="25" icon="material-symbols:arrow-circle-up-rounded" ></Icon>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </li>

                                <li className='mb-2 mb-lg-3'>
                                    <div onClick={() => toggleOpenItem(4)}
                                        aria-controls="collapseFour"
                                        aria-expanded={openItem[4]}>
                                        <TittleH6 styleGroup="primary-color fw-bold cursor-pointer" >{getTextByKey('politica_reversion')}</TittleH6>
                                    </div>
                                    <Collapse in={openItem[4]}>
                                        <div id="collapseFour">
                                            <div className="fw-normal pe-3 pe-md-0">
                                                <div dangerouslySetInnerHTML={{ __html: getTextBigByKey('politicas-reversion') }}></div>
                                                <div className="d-flex justify-content-end align-items-end" onClick={() => handleCloseItem(4)}>
                                                    <Icon className='cursor-pointer specialColorB-color ' width="25" height="25" icon="material-symbols:arrow-circle-up-rounded" ></Icon>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </li>

                                <li className='mb-2 mb-lg-3'>
                                    <div onClick={() => toggleOpenItem(5)}
                                        aria-controls="collapseFive"
                                        aria-expanded={openItem[5]}>
                                        <TittleH6 styleGroup="primary-color fw-bold cursor-pointer" >{getTextByKey('politica_derechos')}</TittleH6>
                                    </div>
                                    <Collapse in={openItem[5]}>
                                        <div id="collapseFive">
                                            <div className="fw-normal pe-3 pe-md-0">
                                                <div dangerouslySetInnerHTML={{ __html: getTextBigByKey('politicas-derechos') }}></div>
                                                <div className="d-flex justify-content-end align-items-end" onClick={() => handleCloseItem(5)}>
                                                    <Icon className='cursor-pointer specialColorB-color ' width="25" height="25" icon="material-symbols:arrow-circle-up-rounded"></Icon>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </li>

                                <li className='mb-2 mb-lg-3'>
                                    <div onClick={() => toggleOpenItem(6)}
                                        aria-controls="collapseSix"
                                        aria-expanded={openItem[6]}>
                                        <TittleH6 styleGroup="primary-color fw-bold cursor-pointer" >{getTextByKey('politica_privacidad')}</TittleH6>
                                    </div>
                                    <Collapse in={openItem[6]}>
                                        <div id="collapseSix">
                                            <div className="fw-normal pe-3 pe-md-0">
                                                <div dangerouslySetInnerHTML={{ __html: getTextBigByKey('politicas-privacidad') }}></div>
                                                <div className="d-flex justify-content-end align-items-end" onClick={() => handleCloseItem(6)}>
                                                    <Icon className='cursor-pointer specialColorB-color ' width="25" height="25" icon="material-symbols:arrow-circle-up-rounded"></Icon>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </li>

                                <li className='mb-2 mb-lg-3'>
                                    <div onClick={() => toggleOpenItem(7)}
                                        aria-controls="collapseSeven"
                                        aria-expanded={openItem[7]}>
                                        <TittleH6 styleGroup="primary-color fw-bold cursor-pointer" >{getTextByKey('politica_datos')}</TittleH6>
                                    </div>
                                    <Collapse in={openItem[7]}>
                                        <div id="collapseSeven">
                                            <div className="fw-normal pe-3 pe-md-0">
                                                <div dangerouslySetInnerHTML={{ __html: getTextBigByKey('politicas-datos') }}></div>
                                                <div className="d-flex justify-content-end align-items-end" onClick={() => handleCloseItem(7)}>
                                                    <Icon className='cursor-pointer specialColorB-color ' width="25" height="25" icon="material-symbols:arrow-circle-up-rounded"></Icon>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </li>

                                <li className='mb-2 mb-lg-3'>
                                    <div onClick={() => toggleOpenItem(8)}
                                        aria-controls="collapseEight"
                                        aria-expanded={openItem[8]}>
                                        <TittleH6 styleGroup="primary-color fw-bold cursor-pointer" >{getTextByKey('politica_seguridad')}</TittleH6>
                                    </div>
                                    <Collapse in={openItem[8]}>
                                        <div id="collapseEight">
                                            <div className="fw-normal pe-3 pe-md-0">
                                                <div dangerouslySetInnerHTML={{ __html: getTextBigByKey('politicas-seguridad') }}></div>
                                                <div className="d-flex justify-content-end align-items-end" onClick={() => handleCloseItem(8)}>
                                                    <Icon className='cursor-pointer specialColorB-color ' width="25" height="25" icon="material-symbols:arrow-circle-up-rounded"></Icon>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </li>

                                <li className='mb-2 mb-lg-3'>
                                    <div onClick={() => toggleOpenItem(9)}
                                        aria-controls="collapseNine"
                                        aria-expanded={openItem[9]}>
                                        <TittleH6 styleGroup="primary-color fw-bold cursor-pointer" >{getTextByKey('politica_manual')}</TittleH6>
                                    </div> 
                                    <Collapse in={openItem[9]}>
                                        <div id="collapseNine">
                                            <div className="fw-normal pe-3 pe-md-0">
                                                <div dangerouslySetInnerHTML={{ __html: getTextBigByKey('politicas-manual') }}></div>
                                                <div className="d-flex justify-content-end align-items-end" onClick={() => handleCloseItem(9)}>
                                                    <Icon className='cursor-pointer specialColorB-color ' width="25" height="25" icon="material-symbols:arrow-circle-up-rounded"></Icon>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </li>

                                <li className='mb-2 mb-lg-3'>
                                    <div onClick={() => toggleOpenItem(10)}
                                        aria-controls="collapseTen"
                                        aria-expanded={openItem[10]}>   
                                        <TittleH6 styleGroup="primary-color fw-bold cursor-pointer" >{getTextByKey('politica_programas')}</TittleH6>
                                    </div>
                                    <Collapse in={openItem[10]}>
                                        <div id="collapseTen">
                                            <div className="fw-normal pe-3 pe-md-0">
                                                <div dangerouslySetInnerHTML={{ __html: getTextBigByKey('politicas-programas') }}></div>
                                                <div className="d-flex justify-content-end align-items-end" onClick={() => handleCloseItem(10)}>
                                                    <Icon className='cursor-pointer specialColorB-color ' width="25" height="25" icon="material-symbols:arrow-circle-up-rounded"></Icon>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse> 
                            </li>*/}
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
