import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../context/apiContext';
import Modal from 'react-bootstrap/Modal';




export default function ModalConfirmarEntrega({ modalConfirmar, setModalConfirmar }) {

    const { getTextByKey, getTextBigByKey } = useApi();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setModalConfirmar(false);
        gotoTop();
    }

    return (
        <Fragment>
            <Modal className="modal-compra" show={modalConfirmar} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-1-color' icon="material-symbols:close-rounded" width="30" height="30" onClick={handleClose}></Icon>
                    </div>
                    <div className="container">
                      <div className="text-center">
                        <h4 className='gray-1-color'>{getTextByKey('confirmacion-metodo-entrega')}</h4>
                        <p className='gray-1-color'>{getTextByKey('hemos-guardado')}</p>
                        <div className="gray-1-color sm pb-4" dangerouslySetInnerHTML={{ __html: getTextBigByKey("modal-confirmacion-texto") }}></div>
                      </div> 
                      <div className="text-center pt-3">
                          <button className='btn-g px-4 mx-3' onClick={handleClose}>{getTextByKey('aceptar')}</button>
                      </div>
                    </div>
                </Modal.Body>
            </Modal>      
        </Fragment>
    )
}