import sellyApi from "./sellyApi"


export const getFaqs = async (locale) => {
    try {
        const { data } = await sellyApi.get(`/faq`, {params: {locale}});
        return data;
    } catch (error) {
        console.error('Error obteniendo faqs', error);
        return {list: [], pages: 1};
    }
}
