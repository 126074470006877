import React from "react";
import styleTittle from "../scss/text.module.scss";


const TittleH4 = ({ children, styleGroup }) => {
  
  return (
    <h4 className={`${styleGroup} ${styleTittle.h1}`}>{children}</h4>
  );
};

export default TittleH4;