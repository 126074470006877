import React, { Fragment } from 'react';


import { Icon } from '@iconify/react';
import { NavLink, withRouter } from 'react-router-dom';

import { auth } from '../../../firebaseConfig';
import { useApi } from '../../../context/apiContext';

import StyleNavPerfil from '../../../scss/navPerfil.module.scss';

export const NavComponent = () => {

  const { getTextByKey } = useApi();

    //Logout Event
    const handleLogout = async () => {
      try {
        await auth.signOut();
        location.href = "/";
      } catch {
      }
    };

    const scrollTop = () => {
      window.scroll(0, 0);
    }

  return (
    <Fragment>
      <ul className={`list-style-none border white-bg-color p-4 p-xl-5 ${StyleNavPerfil.navPerfil}`}>
        <li>
          <NavLink onClick={() => scrollTop()} to="/detalles-cuenta" className={`${location.pathname === '/detalles-cuenta' ? StyleNavPerfil.active : ''}`}>
            <Icon icon="ant-design:user-outlined" className='me-3 icon'/>
            <span className="text-uppercase">{getTextByKey('detalles-cuenta')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink onClick={() => scrollTop()} to="/mis-pedidos" className={`${location.pathname === '/mis-pedidos' ? StyleNavPerfil.active : ''}`}>
            <Icon icon="ri:file-list-2-line" className='me-3 icon'/>
            <span className="text-uppercase">MIS PEDIDOS</span>
          </NavLink>
        </li>
        <li>
          <NavLink onClick={() => scrollTop()} to="/direccion-envio" className={`${location.pathname === '/direccion-envio' ? StyleNavPerfil.active : ''}`}>
            <Icon icon="bi:send" className='me-3 icon'/>
            <span className="text-uppercase">DIRECCIÓN DE ENVÍO</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/" onClick={handleLogout}>
            <Icon icon="bx:bx-log-out" className='me-3 icon'/>
            <span className="text-uppercase">{getTextByKey('cerrar-sesion')}</span>
          </NavLink>
        </li>
      </ul>
    </Fragment>
  )
}


