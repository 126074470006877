import React, { Fragment, useState, useEffect } from 'react'
import Picture from './PictureComponent'
import styleNews from '../scss/news.module.scss'
import StyleText from '../scss/text.module.scss'
import TextBlock from './TextBlockComponent'
import { getImages } from '../api/imageApi';
import TittleH5 from './TittleH5Component';

import { images_products_route } from "../api/productApi";
import { NavLink } from 'react-router-dom'

//funcion para volver al top
import scrollToTop from "./GlobalFunction";

export default function NewsComponent({ img, title, date, children, link }) {
    const [imgNews, setImgNews] = useState([]);

    // console.log("img ", img);
    // console.log("date ", date);

    useEffect(() => {
        getImages(img).then((images) => {
            // console.log("images: " + JSON.stringify(images));
            setImgNews(images)
        })
       
    }, []);

    return (
        <Fragment>
            <div className={`position-relative ${styleNews.item}`}>
                <div className={styleNews.contentImg}>
                    <Picture src={img} alt="Rosmi" styleGroup={`${styleNews.pctr}`} />
                </div>
                
                <TextBlock styleGroup={`gray-1-color w-100 fw-normal py-2 py-lg-3 mb-0`} >
                    <small>{date}</small>
                </TextBlock>
                <TittleH5 styleGroup={`primary-color fw-600`}>{title}</TittleH5>
                <TextBlock styleGroup={`black-color w-100 fw-normal`}>{children}</TextBlock>
                <NavLink to={link} onClick={scrollToTop} className={`${styleNews.navLink}`}></NavLink>
            </div>
        </Fragment>
    )
}
