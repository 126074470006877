import sellyApi from "./sellyApi"


export const listOrdersUser = async (uidUser, currentLocale,compra='') => {
    try {
        const { data } = await sellyApi.get(`user/pedidos/historial?usuario=${uidUser}&lang=${currentLocale}&compra=${compra}`);
        console.log(data);
        return data;
    } catch (error) {
        console.log('Error obteniendo historial de pedidos')
    }
}

export const StatusOrdersUser = async (compra) => {
    try {
        const { data } = await sellyApi.get(`cart/compra/${compra}/status`);
        console.log(data);
        return data;
    } catch (error) {
        console.log('Error obteniendo esatdos compra')
    }
}