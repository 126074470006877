import sellyApi from './sellyApi'

export const getBlogs = async ({page = null, limit = null, locale = null}) => {
  try {
    const {data} = await sellyApi.get(`/blog_posts/`, {params: {limit, page, locale}})
    return data;
  } catch (error) {
    return error;
  }
}

export const getBlog = async (id, locale = '') => {
  try {
    const data = await sellyApi.get(`/blog_posts/${id}`, {params: {locale}})
    return data.data;
  } catch (error) {
    return error;
  }
}

export default { getBlogs }
