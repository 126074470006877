import  React, { Fragment, useState, useEffect, useRef, Suspense } from "react";
import { getRecommendedHome, images_products_route } from "../../api/productApi";
import { getGallery } from "../../api/galleryApi";
import { useApi } from "../../context/apiContext";
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import StyleSwiper from "../../scss/swiper.module.scss";
import Banner from "../../components/BannerComponent";
import TittleH4 from "../../components/TittleH4Component";
import styleText from "../../scss/text.module.scss";
import StyleGeneral from "../../scss/general.module.scss";
import TittleH2 from "../../components/TittleH2Component";
import ButtonLink from "../../components/ButtonLinkComponent";
import { getRecipes } from "../../api/recipesApi";
import ModalNewsletter from "../../components/ModalNewsletterComponent";
import { Icon } from "@iconify/react";
import Loader from "../../components/LoaderComponent";

import { isIOS, isAndroid } from "react-device-detect";
import { Link, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { usePage } from "../../hooks/usePage";
import { useCart } from "../../context/cartContext";
import TittleH6 from "../../components/TittleH6Component";
import { ProductComponent } from "../../components/productComponent";


const LazyBanner = React.lazy(() => import('../../components/BannerComponent'));
const LazyHorizonScroll = React.lazy(() => import('./components/HorizonComponent'));
const LazyRecipeComponent = React.lazy(() => import('../../components/recipeComponent'));
const LazyCategory = React.lazy(() => import('../../components/CategoryComponent'));

const MAX_ITEMS_PER_PAGE = 12;

export default function HomePage() {

    const [products, setProducts] = useState([]);

    const { getTextByKey, getTextByKeyLink,getPropiertyByLocale, currentLocale, currentCategories } = useApi();

    const [ recetas, setRecetas ] = useState([]);
  
    const [imgBannerInnovador, setImgBannerInnovador] = useState([]);
    const [imgBannerInnovadorMobile, setImgBannerInnovadorMobile] = useState([]);

    const [imgBannerBienestar, setImgBannerBienestar] = useState([]);

    
    const [loading, setLoading] = useState(true);

    const [imgBanner, setImgBannerHome] = useState(() => {
        const cachedData = localStorage.getItem('imgBannerCache');
        return cachedData ? JSON.parse(cachedData) : [];
    });

    const [imgBannerMobile, setImgBannerHomeMobile] = useState(() => {
        const cachedData = localStorage.getItem('imgBannerCache');
        return cachedData ? JSON.parse(cachedData) : [];
    });

    
    const {currentDelivery, tiendaGeneral } = useCart();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const {changePage, page, pages, setPages} = usePage(1);
    const [isFilter, setIsFilter] = React.useState(false);
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        Promise.all([
            getGallery('banner_home'),
            getGallery('banner_home_mobile'),
            getRecommendedHome(tiendaGeneral),
            getGallery('productos_bienestar_home'),
            getGallery('banner-Innovador'),
            getGallery('banner-Innovador-mobile'),
            getRecipes({locale: currentLocale}),

      ])
        .then(([bannerData,bannerMobData,recomendadoData,productoData,innovadorData,innovadorMobile,recetaData]) => {
            setImgBannerHome(bannerData);
            setImgBannerHomeMobile(bannerMobData);
            setLoading(false);
            setProducts(recomendadoData?.productos);
            setImgBannerBienestar(productoData);
            setImgBannerInnovador(innovadorData);
            setImgBannerInnovadorMobile(innovadorMobile);
            setRecetas(recetaData?.list);
           
        })
        .catch(error => {
            console.error("Error al cargar datos:", error);
        });
    }, []);
    /*useEffect(() => {
        getGallery('banner_home').then(
            (data) => {
                setImgBannerHome(data);
                setLoading(false);
                console.log('data banner home', data);
            }
        )
    }, []);

    useEffect(() => {
        getGallery('banner_home_mobile').then(
            (data) => {
                setImgBannerHomeMobile(data);
                setLoading(false);
                console.log('data banner home mobile', data);
            }
        )
    }, []);*/
    


    useEffect(() => {
        getRecommendedHome(tiendaGeneral).then((data) => {
            setProducts(data?.productos);
        });
    }, [tiendaGeneral]);

    /*useEffect(() => {
        getGallery('productos_bienestar_home').then(
            (data) => {
                setImgBannerBienestar(data);
            }
        )
    }, []);

    useEffect(() => {
        getGallery('banner-Innovador').then(
            (data) => {
                setImgBannerInnovador(data);
                console.log('inova', data);
            }
        )
    }, []);

    useEffect(() => {
        getGallery('banner-Innovador-mobile').then(
            (data) => {
                setImgBannerInnovadorMobile(data);
                console.log('inova Mobile', data);
            }
        )
    }, []);

    useEffect(() => {
        getRecipes({locale: currentLocale}).then((data) => {
            let recipes = data.list;
            setRecetas(recipes);
            console.log('recetas', recetas);
        });
    }, []);*/

   

    useEffect(() => {

        setTimeout(() => {
            setShowModal(true);      
        }, 15000);

        /* const handleScroll = () => {
            setTimeout(() => {
                setShowModal(true);      
            }, 30000);
            window.removeEventListener('scroll', handleScroll);
        };
      
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }; */

    }, [])

    const [logosFooter, setLogosFooter] = useState([]);

    React.useEffect(() => {
        getGallery("logos-footer").then((data)=> {
            if (data && Array.isArray(data)) {
                setLogosFooter(data);
            }
        });
    }, [])
    

    return (
        <Fragment>
            
            <div className="SliderHome white-bg-color">
            <Suspense fallback={<img className="w-100" src="/img/firstScreen.jpg" alt="Loading" />}>
                {loading ? (
                    <Fragment>
                        <div className="position-relative">
                            <img className="w-100" src="/img/firstScreen.jpg" alt="Loading" />
                            <div className={StyleGeneral.absoluteCenter}>
                                <Loader></Loader>
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                    {isIOS || isAndroid ? (
                        <Swiper className="mobile" modules={[Pagination, Autoplay]}
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={true}
                            pagination 
                            speed={2000}
                            autoplay={{ delay: 6000, disableOnInteraction: true }}>
                            {imgBannerMobile.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <Banner src={item.image} alt={getPropiertyByLocale(item.names, 'titulo')} styleGroup="col-lg-6 mb-lg-5" titleHead={getPropiertyByLocale(item.names, 'titulo')} titlecolor="white-color" vlink={item.link} textbutton={getTextByKey("ver-mas")} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        <Swiper modules={[Pagination, Autoplay]}
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={true}
                            pagination 
                            speed={2000}
                            autoplay={{ delay: 6000, disableOnInteraction: true }}>
                            {imgBanner.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <Banner src={item.image} alt={getPropiertyByLocale(item.names, 'titulo')} styleGroup="col-lg-6 mb-lg-5" titleHead={getPropiertyByLocale(item.names, 'titulo')} titlecolor="white-color" vlink={item.link} textbutton={getTextByKey("ver-mas")} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}
                    </Fragment>
                )}
                </Suspense>
            </div>
            
            <ModalNewsletter showModal={showModal} setShowModal={setShowModal}/>
            
            <div className="primary-bg-color">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col12 col-lg-9">
                        <div className="text-center py-3 py-lg-4">
                            {logosFooter.map(logo => (
                                <Link key={logo.id} to={logo.link}>
                                    <img key={logo.id} src={images_products_route + logo.image} alt={logo.id} className={StyleGeneral.logosMarcas}/>
                                </Link>
                            ))}
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-4">
                <div className="row justify-content-center">
                    {/* <Suspense fallback={<div>Loading...</div>}>
                        <LazyHorizonScroll />
                    </Suspense> */}
                <div className="col-12 justify-content-center">
                    <TittleH2 styleGroup="text-center primary-color py-4 mb-0 fw-bold">
                        {getTextByKey('titulo_recomendados_home')}
                    </TittleH2>
                </div>
                {products?.length > 0 ?
                    products.slice(0, 5).map((product, index) => <ProductComponent key={index} product={product} className="col-12 col-md-4 col-lg-3 col-xl-2" dummy="product" />)
                    : (
                        <div className="py-4">
                            <TittleH6 styleGroup="text-center quinary-color mb-0">{getTextByKey("no_products")}</TittleH6>
                        </div>
                    )
                }

                </div>
            </div>

            <div className="position-relative bg-white">
                {/* <div className="d-lg-none">
                    {imgBannerBienestar.length > 0 &&
                    <div className="primary-bg-color py-5 productosBienestar">
                        <img className="deco" src="/img/deco_bienestar.png"/>
                        <div className="container-fluid position-relative">
                            <div className="row">
                                <div className="col-12 col-lg-4 offset-lg-1 d-flex align-items-center justify-content-center">
                                    <TittleH4 styleGroup={`tertiary-color autography text-center ${styleText.h4Special}`}>{getTextByKey("titulo_productos_bienestar")}</TittleH4>
                                </div>
                                <div className="col-12 col-lg-7">
                                    <Swiper modules={[Navigation, Autoplay]}
                                        navigation
                                        autoplay={{ delay: 6000, disableOnInteraction: true }}
                                        breakpoints={{
                                            320: {
                                                slidesPerView: 1,
                                                spaceBetween: 0,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                                spaceBetween: 0,
                                            },
                                            1200: {
                                                slidesPerView: 1,
                                                spaceBetween: 0,
                                            }
                                        }}>
                                        {imgBannerBienestar.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <img src={images_products_route + item.image} alt={getPropiertyByLocale(item.names,'titulo')}/>
                                            <div className="text-center">
                                                <TittleH4 styleGroup={`tertiary-color text-center ${styleText.h2}`}>{getPropiertyByLocale(item.names,'titulo')}</TittleH4>
                                                <ButtonLink to={item.link}>{getTextByKey("ver-mas")}</ButtonLink>
                                            </div>
                                        </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                    }    
                </div> */}

                <div className="container-fluid">
                    <div className="row">
                        {currentCategories.filter(c => c.home).map(category => (
                            <div className="col-12 col-lg-4 py-3" key={category.id}>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <LazyCategory src={category.imagen} alt={getPropiertyByLocale(category.names, "nombre")} titleHead={getPropiertyByLocale(category.names, "nombre")} titlecolor="white-color" vlink={`/tienda/${category.id}/0/0/0/0/0/normal`}/>
                                </Suspense>
                            </div>
                        ))}
                    </div>
                </div>

                {/* cuando no sea un banner principal solo solo se le coloca la propiedad secundaryBanner, con esto ya solo modifica el titulo del banner*/}
                                

                { isIOS || isAndroid ? (
                    <Fragment>
                        {imgBannerInnovadorMobile.length > 0 &&
                            <Suspense fallback={<div>Loading...</div>}>
                                <LazyBanner src={imgBannerInnovadorMobile[0].image} 
                                styleGroup="col-lg-4 mb-3 mb-lg-5"
                                alt={imgBannerInnovadorMobile[0].names[0].titulo}
                                secundaryBanner
                                titleHead={imgBannerInnovadorMobile[0].names[0].titulo} 
                                titlecolor="white-color"
                                vlink={getTextByKeyLink('ver-mas-home')}
                                textbutton={getTextByKey('ver-mas-home')}
                                styleGroupButton="mt-3 mt-lg-5"
                                video="https://www.youtube.com/embed/Ttl8Gg-P-Ao"
                                mp4Mobile={imgBannerInnovadorMobile[0].video}
                                webmMobile={imgBannerInnovadorMobile[0].videoweb}
                                />
                            </Suspense>
                        }
                    </Fragment>
                ) : (
                    <Fragment>
                        {imgBannerInnovador.length > 0 &&
                            <Suspense fallback={<div>Loading...</div>}>
                                <LazyBanner src={imgBannerInnovador[0].image} 
                                styleGroup="col-lg-4 mb-3 mb-lg-5"
                                alt={imgBannerInnovador[0].names[0].titulo}
                                secundaryBanner
                                titleHead={imgBannerInnovador[0].names[0].titulo} 
                                titlecolor="white-color"
                                vlink={getTextByKeyLink('ver-mas-home')}
                                textbutton={getTextByKey('ver-mas-home')}
                                styleGroupButton="mt-3 mt-lg-5"
                                video="https://www.youtube.com/embed/Ttl8Gg-P-Ao"
                                mp4={imgBannerInnovador[0].video}
                                webm={imgBannerInnovador[0].videoweb}
                                />
                            </Suspense>
                        }
                    </Fragment>
                ) }
                
                <div className="position-relative">
                    <img className="deco_l_b" src="img/deco_l_b_recetas.png" alt="GoPicnic"/>
                    <img className="deco_r_t" src="img/deco_r_t_recetas.png" alt="GoPicnic"/>
                    <div className="container">
                        <TittleH2 styleGroup="text-center primary-color py-4 mb-0 fw-bold">
                            {getTextByKey('titulo_recetas_home')}
                        </TittleH2>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className={StyleSwiper.spaceArrow}>
                                <button className={`custom-prev-btn ${StyleSwiper.arrow}`}>
                                    <Icon icon="material-symbols:keyboard-arrow-left"/>
                                </button>
                            </div>
                            <div className={StyleSwiper.containerSwiper}>
                                <Swiper  className={StyleSwiper.mySwiper}
                                    modules={[Navigation, Autoplay]}
                                    autoplay={{ delay: 6000, disableOnInteraction: true }}
                                    navigation={{
                                        nextEl: '.custom-next-btn',
                                        prevEl: '.custom-prev-btn',
                                        disabledClass: StyleSwiper.myDisabledClass
                                    }}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                            spaceBetween: 0,
                                        },
                                        576: {
                                            slidesPerView: 2,
                                            spaceBetween: 0,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 0,
                                        }
                                    }}>
                                    {recetas.length? recetas.map((recipe, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="p-3">
                                                <Suspense fallback={<div>Loading...</div>}>
                                                    <LazyRecipeComponent key={index} recipe={recipe} />
                                                </Suspense>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                    : null}
                                </Swiper>
                            </div>
                            <div className={StyleSwiper.spaceArrow}>
                                <button className={`custom-next-btn ${StyleSwiper.arrow}`}>
                                    <Icon icon="material-symbols:keyboard-arrow-right"/>
                                </button>
                            </div>
                        </div>
                        <div className="text-center py-5 mb-lg-5">
                            <ButtonLink to={getTextByKeyLink('btn_todas_preparaciones')}>{getTextByKey('btn_todas_preparaciones')}</ButtonLink>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}