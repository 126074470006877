import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../context/apiContext';
import Modal from 'react-bootstrap/Modal';

import StyleBtn from '../scss/btn.module.scss';


export default function AlertaEliminarProducto({ showEliminar, setShowEliminar, onDelete }) {

    const { getTextByKey } = useApi();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowEliminar(false);
        gotoTop();
    }

    const deleteItem = () => {
        onDelete();
        handleClose();
    };

    return (
        <Fragment>
            <Modal show={showEliminar} onHide={handleClose}  centered className='alerta-eliminar'>
                <Modal.Body>
                    <div className="close pe-2">
                      <Icon className='cursor-pointer white-colo' icon="bi:x-lg" onClick={handleClose}></Icon>
                    </div>
                    <div className="text-center">
                    <p className='gray-1-color my-2 fw-bold'>{getTextByKey('eliminar-producto-titulo')}</p>
                      <p className='gray-1-color pb-2 pt-1'>
                        ¿{getTextByKey('eliminar-producto')} ? 
                        </p>
                        <button className={`me-3 ${StyleBtn.btn}`} onClick={handleClose}>{getTextByKey('cancelar')}</button>
                        <button className={StyleBtn.btn} onClick={deleteItem}>{getTextByKey('eliminar')}</button>
                    </div>
                </Modal.Body>
            </Modal>      
        </Fragment>
    )
}