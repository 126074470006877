import sellyApi from './sellyApi'

export const getRecipeCategories = async (locale = null) => {
  try {
    const data = await sellyApi.get(`/recipes/categories`, {params: {locale}})
    return data.data;
  } catch (error) {
    return error;
  }
}

export const getRecipes = async ({page = null, limit = null, category = null, locale = null}) => {
  try {
    const {data} = await sellyApi.get(`/recipes/`, {params: {page, limit, category, locale}})
    return data;
  } catch (error) {
    throw error;
  }
}

export const getRecipe = async (id, locale = null) => {
  try {
    const data = await sellyApi.get(`/recipes/${id}`, {params: {locale}})
    return data.data;
  } catch (error) {
    throw error;
  }
}

export const getRelacionadas = async (id, locale = null) => {
  try {
    const data = await sellyApi.get(`/recipes/relacionadas/${id}`, {params: {locale}})
    return data.data;
  } catch (error) {
    throw error;
  }
}
export default { getRecipes }
