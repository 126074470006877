import { sellyApi } from '../api'
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword
} from "firebase/auth";


export const createUser = async (formData, setErrors, setloading) => {
  try {
    const auth = getAuth();
    try{
      let data =  await createUserWithEmailAndPassword(auth, formData.email, formData.password)
       const user = auth.currentUser;
       try{  
        let update= await updateProfile(user, {
          displayName: formData.nombre + " " + formData.apellido,
          phoneNumber: formData.celular,
        });

          formData.uid = data.user.uid;
          formData.providerData = JSON.stringify(data.user.providerData);

          try{
            const newUser = await sellyApi.post(`/user/create`, formData);
            console.log("user create ", newUser,newUser.data);
              if (newUser?.status === 200) {
               // location.href = "/";
              }else{
                setloading(false);
                const delUser = auth.currentUser;
                await delUser.delete();
              }
              return newUser.data;
          }catch(error){
             
              setloading(false);
              setErrors(error.response.data.message);
              const delUser = auth.currentUser;
              await delUser.delete();
              return true;
          }
        }catch(error){
          setloading(false)
          const delUser = auth.currentUser;
          await delUser.delete();
          setErrors(error.response.data.message);
        };
      }catch(error){
        switch (error.code) {
          case 'auth/email-already-in-use':
            setloading(false)
            setErrors('Este correo se encuentra registrado')
            return true;
           
        }
      }

  } catch (error) {
    setloading(false)
    setErrors('Ha ocurrido un error, contacte al administrador')
    return true;
  }
}

export const createAnonymousUser = async (formData) => {
  try {
    const response= await sellyApi.post(`/user/create`, formData);
    return response;
  } catch (error) {
   throw error;
  }
}

export const loginUser = async (formData, setErrors, setloading) => {
  try {
    const auth = await getAuth();
    try{
    let logueo = await signInWithEmailAndPassword(auth, formData.email, formData.password)
        //Login success redirect to home
        //setTimeout(() => {
         
          localStorage.removeItem('cart_shido_direccion');
          localStorage.removeItem('cambio-datos');
          if( sessionStorage.getItem('something-to-tell') ) {
            const routeFeedback = sessionStorage.getItem('something-to-tell');
            sessionStorage.removeItem('something-to-tell');
            //location.href = `/${routeFeedback}`;
          
            return auth.currentUser;
          } else {
            return auth.currentUser;
             //location.href = "/tienda";
          }
        //s}, 1000);
      }catch(error){
        //Login error show the error message
        setloading(false)
        switch (error.code) {
          case 'auth/wrong-password':
            setErrors('La contraseña es incorrecta.')
            break;
          case 'auth/user-not-found':
            setErrors('El correo no se encuentra registrado.')
            break;
          case 'auth/too-many-requests':
            setErrors('Lleva demasiados intentos para acceder, intente más tarde.')
            break;
         
        }
      };
  } catch (error) {
    setloading(false)
    setErrors('Ha ocurrido un error, contacte al administrador');
    return true;
  }
}

export const getUser = async (uid) => {
  try {

    const { data } = await sellyApi.get(`/user?usuario=${uid}`,{id:"getUsuario",cache: {
      ttl: 10, // 10 millis.
    }})

    return {
      user: data,
    };
  } catch (error) {
    return error;
  }
}

export const updateUser = async (inputData) => {
  try {

    console.log('data to update', inputData);
    const { data } = await sellyApi.put(`/user/update`, inputData)

    return data;
  } catch (error) {
    throw error;
  }
}

export const deleteUser = async (inputData) => {
  try {

    console.log('data to delete', inputData);
    const { data } = await sellyApi.delete(`/user/${inputData.uid}`)

    return data;
  } catch (error) {
    return error;
  }
}

export default {
  createUser,
  getUser,
  updateUser,
  deleteUser,
  loginUser
}
