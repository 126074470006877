import React, { Fragment, useEffect, useState } from 'react'
import { Link } from "react-router-dom";

import { PrecioSinDcto } from './PrecioSinDcto';
import { useApi } from '../context/apiContext';
import { useCart } from '../context/cartContext';
import { useAuth } from '../authContext';

import StyleProduct from '../scss/product.module.scss';
import StyleButton from '../scss/btn.module.scss';
import TittleH6 from './TittleH6Component';
import Picture from './PictureComponent';
import { images_products_route } from "../api/productApi";
import PreviewModal from './PreviewComponent';
import TagProductComponent from './TagProductComponent';

import StyleText from '../scss/text.module.scss';
import ReactGA from 'react-ga4';

export function ProductComponent(product){
  const { addToCart, showCart, checkIfExistInCart ,tiendaGeneral,addToCartOld} = useCart();
  const dummy = product.dummy;
  const { currentUser } = useAuth();

  
  const [ imgHover, setImgHover ] = useState(0);
  const [ imgPrincipal, setImgPrincipal ] = useState('');
  const dct = Math.round(((product.product.price.precio_sin_descuento - product.product.price.precio) / product.product.price.precio_sin_descuento) * 100);

  let cad_dct_caja = 0;

  if (dct > 0) {
    cad_dct_caja = <span className="tag-discount">
      -{dct}%
    </span>
  }

  const { getTextByKey, getPropiertyByLocale } = useApi();
    
  const addCart = (e) => {
    e.stopPropagation();
    ReactGA.event({
      category:'ecommerce',
      action: 'add_to_cart',
      value: product?.product?.price?.precio,
      currency: "COP",
      items :[
        {
          item_id: product?.product?.plu,
          item_name: product?.product?.name?.nombre,
          item_category: product?.product?.categorias?.[0]?.nombre,
          price: product?.product?.price?.precio,
          quantity: 1
        }
      ]
    });
    product.product.type = "product";
    let sigue=0;
    if(currentUser){
      if(currentUser.isAnonymous){ 
        if (!tiendaGeneral) {
          sigue=1;
        }
       
      }else{
        if (!tiendaGeneral) {
                
          sigue=2;
        }
      }
    }else{
     
      sigue=1;
    }
    if(sigue!=0){

      addToCartOld(product.product, 1);
      if(sigue==1){
        location.href = "/checkout-a";
      }else{
        location.href = "/direccion-envio";
      }
      return;
    }
   
    addToCart(product.product,1);
    showCart();
  }

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  useEffect(() => {
    if(product.product.images && product.product.images.length>0){
      let hover=product.product.images.find(image=>image.hover==1);
      if(hover){
        setImgHover(images_products_route+hover.image)
      }
      let principal=product.product.images.find(image=>image.principal==1);
      if(principal)
      setImgPrincipal(principal.image);
    }
  },[product])

  return (
    <Fragment>

    <div className={product.className}>
        <div className={`${StyleProduct.singularProduct}`}>
            <div className={StyleProduct.imageProduct}>
              <div className={StyleProduct.wp}>
                <Link to={"/product/"+product.product.plu }>
                  <Picture styleGroup={`${StyleProduct.image} ${product.product.cantidad == 0 && StyleProduct.agotado}`} src={(product.product.images && imgPrincipal.length >0 ) ?  imgPrincipal : undefined} alt={product.product.name.nombre} dummy={dummy}/>
                </Link>
                {imgHover!=0 &&
                <div className={StyleProduct.imgHover}>
                  <Link to={"/product/"+product.product.plu }>
                    <img src={imgHover}/>
                  </Link>
                  <button onClick={handleOpenModal}>Vista rápida</button>
                </div>
                }
              </div>
              <div className={StyleProduct.tag}>
                {!product.product.cantidad && 
                  <TagProductComponent text="Agotado" type="soldout"/>
                }
                {product.product.nuevo && 
                  <TagProductComponent text="Nuevo" type="new"/>
                }
                {cad_dct_caja? 
                  <TagProductComponent text={cad_dct_caja} type="discount"/>
                :
                null}
              </div>
            </div>
            <div className='mt-2'>
              {product.short ?
                <TittleH6 styleGroup="black-color text-center w-100">{(product.product.name.nombre).slice(0, 17)+"..."}</TittleH6>
              :
                <TittleH6 styleGroup="black-color text-center w-100">{product.product.name.nombre}</TittleH6>
              }
              

              <div className={StyleText.contentAdmin}>
                <div dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(product.product.resumenes, "resumen")?.slice(0, 60) }}></div>
              </div>
              <PrecioSinDcto precio={product.product.price}/>
              {!product.noaddCart &&
                <div className='text-center'>
                {!!product.product.cantidad && 
                  <button onClick={addCart} className={StyleButton.btn}>
                    {product.short ? 
                      <Fragment>{getTextByKey('shop_add_car_short')}</Fragment>
                    :
                      <Fragment>{getTextByKey('shop_add_car')}</Fragment>
                    }
                  </button>
                }
                </div>
              }
            </div>
        </div>
    </div>

    <PreviewModal show={showModal} product={product.product} handleClose={handleCloseModal}/>

    </Fragment>
  );
}
