import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
export const usePage = (pages = 1) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pages_, setPages] = useState(pages);

    const changePage = (page) => {
        if (page > pages_ || 1 > page) {
            return;
        }
        setSearchParams(p => ( {
            ...Object.fromEntries([...p]),
            page
        } ));
    }


    return {changePage, page: (parseInt(searchParams.get('page')) || 1), pages: pages_, setPages};
}