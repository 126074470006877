import React, { Fragment } from 'react';
import { useApi } from '../../../context/apiContext';
import { Icon } from '@iconify/react';

export function ModalCambiarPasswordComponent() {
    const { getTextByKey } = useApi();
    return (
        <Fragment>
            <div className="modal fade modal-user" id="cambiarPasswordModal" tabIndex="-1" aria-labelledby="cambiarPasswordModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="text-end">
                          <Icon className='cursor-pointer gray-1-color close-modal' icon="material-symbols:close-rounded" data-bs-dismiss="modal"></Icon>
                        </div>
                        <div className="modal-body gray-1-color">
                            <div>
                                <div className="text-center pt-4">
                                    <p className="carrefour-sans-bold mb-3" id="guardarCuentaModal">{getTextByKey('cambiar_contrasena')}</p>
                                </div>
                                <form className='px-xl-5'>
                                    <div className="row">
                                        <div className="col-12 mb-4">
                                            <div className="form-group">
                                                <label htmlFor="">{getTextByKey('contrasena_actual')}</label>
                                                <input type="password"/>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <div className="form-group">
                                                <label htmlFor="">{getTextByKey('contrasena_nueva')}</label>
                                                <input type="password"  />
                                            </div>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <div className="form-group">
                                                <label htmlFor="">{getTextByKey('repetir_contrasena_nueva')}</label>
                                                <input type="password" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-0 pb-4">
                            <button type="button" className="btn-g bb py-1 px-4 mx-xl-2">{getTextByKey('cancelar')}</button>
                            <button type="button" className="btn-g py-1 px-3 mx-xl-2">{getTextByKey('actualizar_contrasena')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}