

export const getMonthName = (month) => {
    const monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];
    return monthNames[month];
}

export const createDatetime = (datetime_) => {
    const date = new Date(datetime_);
    console.log(datetime_,'date',date);
    return {
        month: getMonthName(date.getUTCMonth()),
        day: date.getUTCDate(),
        year: date.getUTCFullYear()
    }
}

export const createDateFormat = (datetime_) => {
    const date = createDatetime(datetime_);
    return `${date.day} de ${date.month}, ${date.year}`;
}

export const createDateFormatTr = (datetime_, fn) => {
    try {
        const date = createDatetime(datetime_);
         return `${date.day} ${fn(date.month)}, ${date.year}`;
    } catch (err) {
        return "...";
    }
}