import React, { Fragment } from 'react'
import { Icon } from '@iconify/react';

import StyleFilter from '../../../scss/filter.module.scss';
import CheckDropdownComponent from './CheckDropdownComponent';
import { useApi } from "../../../context/apiContext";

export default function FiltrosComponent() {

    const { getTextByKey } = useApi();

    return (
        <Fragment>
            <div className={StyleFilter.fixedFilter}>
                <form className={StyleFilter.productFilter} id="filter">
                    <CheckDropdownComponent/>
                </form>
            </div>
        </Fragment>
    )
}
