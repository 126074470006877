import sellyApi from './sellyApi'

export const getStores = async (locale = null) => {
  try {
    const data = await sellyApi.get(`/store/get-stores`, {params: {locale}});
    return data.data;
  } catch (error) {
    console.error('Error consultando tiendas', error)
  }
}

export const getAlmacenes = async (locale = null) => {
  try {
    const data = await sellyApi.get(`/almacen/`, {params: {locale}});
    return data.data.list;
  } catch (error) {
    console.error('Error consultando almacenes', error)
    throw error;
  }
}

export const getCategories = async (page) => {
  try {
    const data = await sellyApi.get(`/categorias?page=${page}`)
    console.log('categorias', data)
  } catch (error) {
    console.log('Error consultando categorias', error)
  }
}

export const getColors = async (page) => {
  try {
    const data = await sellyApi.get(`/colors?page=${page}`)
    console.log('colors', data)
  } catch (error) {
    console.log('Error consultando colores', error)
  }
}

export const getLines = async () => {
  try {
    const data = await sellyApi.get(`/store/line`)
    console.log('lineas', data)
  } catch (error) {
    console.log('Error consultando lineas', error)
  }
}

export const getStore = async (ciudad) => {
  try {
    const { data } = await sellyApi.get(`store/get-tienda?ciudad=${ciudad}`)
    return data;
  } catch (error) {
    return error;
  }
}

export default { getCategories, getColors, getLines }
