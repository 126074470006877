import sellyApi from "./sellyApi";


export const createGiftCard = async (dataGiftCard) => {
    try {
        const { data } = await sellyApi.post('/giftcard', dataGiftCard)
         return data;
    } catch (error) {
        console.log('Error creando gift card', error)
        return error;
    }
}