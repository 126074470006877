import React, { useState, useEffect, useContext, createContext } from 'react';
import { auth } from './firebaseConfig';
import { EmailAuthProvider,reauthenticateWithCredential, updatePassword } from 'firebase/auth';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    function logout() {
        return auth.signOut();
    }
    async function handleChangePassword (currentPassword,newPassword){
        try {
            const user = auth.currentUser;
            if (!user) {
              throw new Error('El usuario actual no está definido');
            }
        
            const credential = EmailAuthProvider.credential(user.email, currentPassword);
            if (!credential) {
              throw new Error('No se pudo obtener la credencial');
            }
                              
            await reauthenticateWithCredential(user,credential);
            await updatePassword(user,newPassword);
           return true;
          } catch (error) {
               throw new Error(error.code);
          }
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setLoading(false);
        })
        return unsubscribe;
    }, [])

    const value = {
        currentUser,
        logout,
        handleChangePassword
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}