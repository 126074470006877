import React, { Fragment, useEffect, useState } from 'react'
import { useApi } from '../../context/apiContext'
import { getImages } from '../../api/imageApi';
import { getGallery } from '../../api/galleryApi';

import StyleTips from '../../scss/tips.module.scss';
import TittleH1 from "../../components/TittleH1Component";
import TittleH2 from "../../components/TittleH2Component";
import TextBlock from "../../components/TextBlockComponent";
import StyleText from "../../scss/text.module.scss";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import {createDateFormat, createDateFormatTr, createDatetime} from "../../helpers/dateHelper";
import {images_products_route} from "../../api/productApi";
import {usePage} from "../../hooks/usePage";
import Pagination from "../../components/PaginationComponent";

const ITEMS_PER_PAGE = 12;
export default function TipsPage() {
    const [tips, setTips] = useState([]);
    const { getPropiertyByLocale, getTextByKey, getTextBigByKey } = useApi();
    const {changePage, page, pages, setPages} = usePage(1);

    useEffect(() => {
        getGallery("tips").then(data => {
            if (data && Array.isArray(data)) {
                setTips(data);
                console.log("tips loaded ", data);
            }
        })
    }, []);

    useEffect(() => {
        setPages(Math.ceil(tips.length / ITEMS_PER_PAGE) || 1);
    }, [tips]);

    return (
        <Fragment>
            <div className="my-5">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-xl-5 mb-5">
                            <div className={`${StyleTips.sticky}`}>
                                <TittleH1 styleGroup={`specialColorB-color autography mb-4 ${StyleText.h4Special}`}>
                                    {getTextByKey('tips_title_general')}</TittleH1>
                                <div dangerouslySetInnerHTML={{ __html: getTextBigByKey('tips_pagraphs') }}></div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6 mb-5">
                            {tips.slice(ITEMS_PER_PAGE * (page - 1),ITEMS_PER_PAGE * page).map(tip => (
                                        <div className="row py-4 py-xl-5 border-bottom" key={tip.id}>
                                            <div className="col-4 px-xl-0">
                                                <div>
                                                    <img src={images_products_route + tip.image} alt="Tip item" className='w-100'/>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div>
                                                    <TextBlock styleGroup={`mb-4 ${StyleTips.fecha}`}>{createDateFormatTr(tip?.created_at?.date, getTextByKey)}</TextBlock>
                                                    <TittleH2 styleGroup={`primary-color fw-bold mb-3`}>{getPropiertyByLocale(tip.names, "titulo")}</TittleH2>
                                                    {/* <TextBlock>{getPropiertyByLocale(tip.names, "descripcion")}</TextBlock> */}
                                                    
                                                    <div className={StyleText.contentAdmin} dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(tip.names, "descripcion") }}></div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                            <div className='py-4'>
                                <Pagination
                                    pages={pages}
                                    currentPage={page}
                                    onPageChange={changePage}
                                    onNext={ () => {changePage( page + 1 )} }
                                    onPrevious={ () => {changePage( page - 1 )} }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
