import { Fragment } from "react";
import { useEffect } from "react";
import { listOrdersUser,StatusOrdersUser } from "../../api/ordersApi";
import { useAuth } from "../../authContext";
import { useApi } from "../../context/apiContext";
import { useCart } from "../../context/cartContext";
import { useState } from "react";
import {getProductDetail } from "../../api/productApi";
import { AlertComponent } from "../../components/AlertComponent";

import { useParams } from 'react-router-dom';

import StyleGeneral from "../../scss/general.module.scss";
import StylePerfil from "../../scss/perfil.module.scss";
import StyleButton from "../../scss/btn.module.scss";

import TittleH1 from "../../components/TittleH1Component";
import TextBlock from "../../components/TextBlockComponent";
import StyleText from "../../scss/text.module.scss";
import {createDateFormatTr} from "../../helpers/dateHelper";
import ReactGA from 'react-ga4';


export default function PedidosPage() {


    const { currentUser } = useAuth();
    const { addToCartMas, showCart} = useCart();
    const { currentLocale, getTextByKey,convertCurrency } = useApi();
    const [listOrders, setlistOrders] = useState([])
    const [order, setOrder] = useState(0);
    const [status, setStatus] = useState([]);
    const [estados, setEstados] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [load, setLoad] = useState(false);
    const [errors, setErrors] = useState("");

    const params = useParams();

    useEffect(() => {
       
    listOrdersUser('', currentLocale,params.order).then((response)=>{
        setOrder(response);
    })                  
        .catch ((error)=> {
        console.log('Error: ', error);
    });
       
    }, [])

    
    useEffect(() => {
       StatusOrdersUser(params.order).then((response)=>{
           setStatus(response);
           console.log('estado',response);
           const ids = response.map(item => item.id);
           setEstados(ids);
        }).catch((error)=>{
            console.log('Error: ', error);
        });
    },[]);


    const pedir=()=>{
        setLoad(true);
        const sininv= order.product_items.filter(item=>item.inventario==0);
        if(sininv.length>0){
            setErrors('Existe productos sin inventario');
            handleShowModal();
            setLoad(false);
            //return;
        }
        const products= order.product_items.filter(item=>item.inventario>0);
       
        console.log('prods',products);  
    
        const promises = products.map((element) => {
            return getProductDetail(element.plu).then((data) => {
              return { data: data, cantidad: element.cantidad };
            }).catch((error)=>{
                console.log('error',error);
                return { error: true, plu: element.plu }
            });
          });
          
          Promise.all(promises).then((results) => {
          
            results.forEach(async(element) => {
            if (element.error) {
                setErrors('Ya no existen algunos productos');
                handleShowModal();
               
                }else{
                const productData = element.data;
                const cantidad = element.cantidad;
                let add = await addToCartMas(productData,cantidad);
                if(add){
                    showCart();
                }   
            }            
              });
              setLoad(false);
             
          }).catch((error) => {
            console.log('Error al obtener los detalles de los productos:', error);
            setLoad(false);
          });
    }
    const handleShowModal = () => setShowAlert(true);
    const handleCloseModal = () => setShowAlert(false);

 
    if (order==0)
    return (
      <div className='container'>
        <div className="text-center-c">
          <div className="loader-box">
            <img src="/img/SVG/loader.svg" alt="" />
          </div>
        </div>
      </div>
    );

    return (
        <Fragment>
            
    
            <div className={`py-5 gray-2-bg-color ${StyleGeneral.minHeight}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center mb-4">
                            <TittleH1 styleGroup={`primary-color autography ${StyleText.h4Special}`}>{getTextByKey('texto-tracking')}</TittleH1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-lg-2 col-lg-8 mb-4">
                            <div className="border white-bg-color p-4 p-xl-5">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="border p-2 pe-xl-4 mb-4 mb-md-0">
                                            <TextBlock styleGroup="primary-color fw-bold">Detalle del pedido</TextBlock>
                                            <div>
                                            <TextBlock styleGroup="d-flex justify-content-between">Orden: <span>#{order.id}</span></TextBlock> 
                                            
                                                {
                                                    order?.product_items?.map(product => (
                                                        <TextBlock styleGroup="d-flex justify-content-between"><span className="w-75">{product.nombre} x{product.cantidad}</span> <span>${product.precio}</span></TextBlock>
                                                    ))
                                                }
                                                {order.costo_domicilio!=0 && <TextBlock styleGroup="d-flex justify-content-between">Envío <span>{convertCurrency(order.costo_domicilio)}</span></TextBlock>}
                                                <TextBlock styleGroup="d-flex justify-content-between">Subtotal <span>{convertCurrency(order.subtotal)}</span></TextBlock>
                                                { order.descuentos!=0 && <TextBlock styleGroup="d-flex justify-content-between">Descuento <span>{convertCurrency(order.descuentos)}</span></TextBlock>}
                                                <TextBlock styleGroup="d-flex justify-content-between fw-bold">Total <span>{convertCurrency(order?.total)}</span></TextBlock>
                                            </div>
                                            <div className="mt-4">
                                                <TextBlock>¿Deseas volver a solicitar el mismo pedido?</TextBlock>
                                               
                                                     <button className={`border-0 ${StyleButton.btn} ${load ? StyleButton.active: ''}`} disabled={load}  onClick={pedir}>
                                                         Pedir
                                                         <span className={StyleButton.loadingContainer}>
                                                        <span className={StyleButton.dot}>.</span>
                                                        <span className={StyleButton.dot}>.</span>
                                                        <span className={StyleButton.dot}>.</span>
                                                        </span>
                                                    </button>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="border p-2 pe-xl-4 mb-2">
                                            <TextBlock styleGroup="primary-color fw-bold">Tracking del pedido</TextBlock>
                                            <ul className={`${StylePerfil.listTimelineEstadoPedido}`}>
                                                <li className={estados.includes(2) ? "fw-bold" :''}>Pedido Recibido</li>
                                                <li className={estados.includes(3) ? "fw-bold" :''}>En Alistamiento</li>
                                                <li className={estados.includes(4) ? "fw-bold" :''}>En Distribucion</li>
                                                <li className={estados.includes(5) ? "fw-bold" :''}>Pedido entregado</li>
                                            </ul>
                                        </div>
                                        <div className="border p-2 pe-xl-4">
                                            <TextBlock styleGroup="d-flex justify-content-between">Fecha: <span>{createDateFormatTr(order.orderDate, getTextByKey)}</span></TextBlock> 
                                            <TextBlock styleGroup="d-flex justify-content-between mb-0">Fecha de entrega: <span>{createDateFormatTr(order.fecha_entrega, getTextByKey)}</span></TextBlock>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AlertComponent title="Carrito" msn={errors} type="error"
                                            showAlert={showAlert}
                                            handleShowModal={handleShowModal}
                                            handleCloseModal={handleCloseModal}/>
        </Fragment>
    )
}
