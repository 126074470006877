export const getEnvVariables = () => {
  return {
    API_URL: 'https://api.rosmi.iridian.co/api/',
    API_URL_ALT: 'https://api.rosmi.iridian.co/api/',
    API_URL_TEST: 'http://127.0.0.1:8000/api',
    TokenAPI: '123456fabian',
    IMAGES_URL_PRODUCTS: "https://api.rosmi.iridian.co/uploads/products/",
    IMAGES_URL_COLLECTIONS: "https://api.rosmi.iridian.co/uploads/collections/",
    IMAGES_URL_CATEGORIES: "https://api.rosmi.iridian.co/uploads/category/",
    GOOGLE_API_KEY: 'AIzaSyABE76D8W5qc9tUwhZltOK0z2pfcFX4NKY'
  }
}
