import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useApi } from "../../context/apiContext";
import {getRelationProducts, images_products_route,validateCoupon} from '../../api/productApi';


import { useCart } from '../../context/cartContext';
import { useAuth } from '../../authContext';
import { QuantyControls } from '../../components/QuantyControls';
import { default as ModalProcederPago } from './components/ModalProcederPago';
import AlertaEliminarProducto from '../../components/AlertaEliminarProducto';


import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import StyleText from '../../scss/text.module.scss';
import StyleGeneral from '../../scss/general.module.scss';
import StyleCheckout from '../../scss/checkout.module.scss';

import TittleH1 from '../../components/TittleH1Component';
import TittleH5 from '../../components/TittleH5Component';
import TittleH6 from '../../components/TittleH6Component';
import ButtonLink from '../../components/ButtonLinkComponent';

import StyeleButton from '../../scss/btn.module.scss';
import StyleProductSummary from '../../scss/productSummary.module.scss';
import TextBlock from '../../components/TextBlockComponent';
import {ProductComponent} from "../../components/productComponent";


const CheckoutbPage = () => {
  const { getTextByKey, getTextBigByKey, getPropiertyByLocale,convertCurrency } = useApi();
  const {
      currentCart,
      total, 
      envio,
      compraMinima,
      subtotal,
      descuento,
      impuesto,
      impuestoBolsa,
      pesoTotal,
      pesoXbolsa,
      calculatePeso,
      calculateImpt,
      calculateSubtotal,
      calculateDescuento,
      clearCart,
      calculateTotal,
      coupon,
      tiendaGeneral,
      applyCoupon,
      updateCoupon,
      cartIsShowing,
      setCartQuantityValue,
      removeToCart
  } = useCart();
  const { currentUser } = useAuth();

  const [ showModal, setShowModal] = useState(false);
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const [couponMsg, setCouponMsg] = useState("");
  const [ selectedIndex, setSelectedIndex ] = useState(-1);
  const [ recommended, setRecommended ] = useState([]);

  const openModal = () =>{
    if(total > compraMinima){
      setShowModal(true);
    }
   
  }

  const [ carritoVacio, setCarritoVacio] = useState(false);
  const [ cupon, setCupon] = useState('');
  const [ showEliminar, setShowEliminar ] = useState(false);
  const [bolsa, setBolsa ] = useState(1);


  const vaciarCart = () => {
    setCarritoVacio(true);
    clearCart();
  }

  const removeItem = (index) => {
    setShowEliminar(true);
    setSelectedIndex(index);
  }

  useEffect(() => {
    console.log('currentCart', currentCart);
      getRelationProducts(currentCart.map(i => i.plu),tiendaGeneral).then( ({productos}) => {
          if (Array.isArray(productos)){
              let shuffleE = productos;
              console.log('shuffleE', shuffleE);
              setRecommended(shuffleE);
              
          }
          
      });
  }, [currentCart,tiendaGeneral]);


  /*useEffect (() => {
    setBolsa(Math.round(pesoTotal/pesoXbolsa));
    if(bolsa == 0 ){
      setBolsa(1);
    }
    console.log('bolsa', {bolsa, pesoTotal, pesoXbolsa});
  }, [pesoTotal, pesoXbolsa]);*/

  useEffect(()=>{
    //calculatePeso();
    /*calculateSubtotal(),
    calculateImpt();
    calculateDescuento();*/
  
  }, [currentCart,coupon]);

  const handlecupon=(event)=>{
    setCupon(event.target.value);
  }

  const validateCodeCoupon = (event) => {
    event.preventDefault();
    setLoadingCoupon(true);
    setCouponMsg("");
    if (cupon.length==0){
      setLoadingCoupon(false);
      setCouponMsg("Ingresa el cupón");
            return;
    }
    let email=currentUser.email;
     validateCoupon(cupon,email).then((res) => {
      setLoadingCoupon(false);
     
      if(Object.keys(res).length == 0){
        setLoadingCoupon(false);
        setCouponMsg("Cupón no valido para esta compra");
      }else{
        setCouponMsg("Cupón aplicado");
        updateCoupon({coupon: cupon, descuento: res.descuento, porcentaje: res.porcentaje});
      }
    }).catch((error)=>{
      console.log(error);
      setLoadingCoupon(false);
      if(error.response.data.message){
        setCouponMsg(error.response.data.message);  
      }else{
        setCouponMsg('Cupón no existe');
      }     
    });
  }

    const deleteCodeCoupon = () => {
      setCupon('');
      updateCoupon(0);
    }


  return (
    <Fragment>
      <div className="ckeckout gray-2-bg-color py-3 py-lg-5 ">
        <div className="container">
          
          <div className="text-center mb-3 mb-lg-5">
            <TittleH1 styleGroup={`primary-color  autography mb-0 ${StyleText.h4Special}`}>{getTextByKey('mi-carrito')}</TittleH1>
          </div>
          { currentCart.length==0 ?
            <div className="container">
              <div className="bg-white pb-4 px-3">
                <div className="pt-4 pb-5 px-5 text-center">
                  <div className='mb-3 mx-2 pt-3 px-4 pb-2' dangerouslySetInnerHTML={{ __html: getTextBigByKey("aviso-sin-productos-carrito") }}></div>
                  <ButtonLink to="/tienda">{getTextByKey('ir-a-tienda')}</ButtonLink>
                </div> 
              </div>
  
            </div>
          :
            <div className="row">
              <div className='col-12 col-lg-7'>
                <div className='border p-lg-4 bg-white h-100'>
                  <Fragment>
                    <TittleH6 styleGroup="primary-color fw-bold mb-2 mb-lg-4">Lista de productos</TittleH6>
                    <div className={`list-products border ${StyleCheckout.listProductsCheckout}`}>
                      <div className="pe-2">
                          {
                            currentCart.map((item, index) =>{
                              const principalImage = item.images.find((image) => image.principal === 1);                             
                            return(
                              
                              <div className={StyleProductSummary.product} key={index}>
                                <div className={StyleProductSummary.trash} onClick={() => removeItem(index)}>
                                  <Icon icon="ph:x-circle-light"/>
                                </div>
                                <div className={StyleProductSummary.img}>
                                  {item.images.length > 0 && <img src={images_products_route + principalImage?.image} />}
                                </div>
                                <div className={`p-2 ${StyleProductSummary.txt}`}>
                                  <TittleH6 styleGroup="primary-color">{item.name.nombre}</TittleH6>
                                  {/*{item.peso ? <p className='mb-0 gray-1-color'>Peso: <span>{item.peso}gr</span></p> : "" }*/}
                                  <p className="mb-0 my-2 my-md-0 "><span>{item.quantity}</span> x <span>{convertCurrency(item.price.precio)}</span></p>
                                </div>
                                <div className={StyleProductSummary.quantity}>
                                  <QuantyControls
                                      quantity={item.quantity}
                                      onChange={(e) => setCartQuantityValue(item, e.target.value)}
                                      onPlus={() => setCartQuantityValue(item, item.quantity +1)}
                                      onMinus={() => setCartQuantityValue(item, item.quantity -1)}
                                      styleGroup="w-100" />
                                </div>
                            </div>
                            )})}

                      </div>
                    </div>
                    <div className="my-4">
                      <button className={`border-0 ${StyeleButton.btn}`} onClick={vaciarCart} >{getTextByKey('vaciar-carrito')}</button>
                    </div>
                  </Fragment>
                
                </div>
              </div>
                <div className='col-12 col-lg-5'>
                  <div className='border p-3 p-lg-4 bg-white'>
                  { currentCart.length > 0 && 
                    <Fragment>
                      <TittleH6 styleGroup="fw-bold primary-color">{getTextByKey('total-carrito-titulo')}</TittleH6>
                      <div className="p-4 border mb-2 mb-lg-4">
                        <TextBlock styleGroup='d-flex justify-content-between align-items-center'>
                          <span className="w-50 fw-bold">{getTextByKey('subtotal')}</span>
                          <span className='w-50'>{convertCurrency(subtotal.toFixed(0))}</span>
                        </TextBlock>
                        {/* descuento>0 &&
                        <TextBlock styleGroup='d-flex justify-content-between align-items-center'>
                          <span className="w-50 fw-bold">{getTextByKey('descuento')}</span>
                          <span className='w-50 ms-2'>{convertCurrency(descuento)}</span>
                        </TextBlock>
                         */}
                         {coupon!=0 &&
                          <TextBlock styleGroup='d-flex justify-content-between align-items-center'>
                          <span className="w-50 fw-bold">{getTextByKey('cupon-descuento-text')}</span>
                          <span className='w-50 ms-2'>{convertCurrency(applyCoupon(total).toFixed(0))}</span>
                        </TextBlock>
                         }
                        <TextBlock styleGroup="d-flex justify-content-between align-items-center">
                          <span className="w-50 fw-bold">{getTextByKey('impuestos')}</span>
                          <span className='w-50'>{convertCurrency(impuesto.toFixed(0))}</span>
                        </TextBlock>
                        {/*<TextBlock styleGroup='d-flex justify-content-between align-items-center'>
                          <span className="w-50 fw-bold">{getTextByKey('impt-bolsa')}
                            <span className="fw-normal ms-2">x{bolsa}</span>
                          </span>
                          <span className='w-50'>${(impuestoBolsa)*(bolsa)}</span>
                  </TextBlock>*/}
                        <TextBlock styleGroup='d-flex justify-content-between align-items-center'>
                          <span className="w-50 fw-bold">{getTextByKey('envio')}</span>
                          <span className="w-50">{envio ? convertCurrency(envio) : getTextByKey('gratis-envio')}{/*{getTextByKey('alerta-costes-envio')}*/}</span>
                        </TextBlock>
                        
                        <TextBlock styleGroup='d-flex justify-content-between align-items-center'>
                          <span className="w-50 fw-bold">{getTextByKey('total')}</span>
                         
                          <span className='w-50 fw-bold'> {convertCurrency((total-applyCoupon(total) + envio).toFixed(0))}</span>
                        </TextBlock>
                      </div>

                      <div className='quaternary-bg-color p-2 rounded mb-2'>
                        <TextBlock styleGroup="white-color mb-0">
                          {getTextByKey('compra_minima_b')}
                        </TextBlock>

                      </div>

                      <div className='py-2 d-md-flex d-block justify-content-between align-items-strech'>
                      
                       
                        {coupon!=0 ? (
                          <input type="text" className={`w-50 border bg-white py-1 px-3 me-3 ${StyleGeneral.boxShadow} ${StyleGeneral.radius50}`}value={coupon.coupon} disabled/>
                          ):(<input className={`w-100 border bg-white py-1 px-3 me-3 ${StyleGeneral.boxShadow} ${StyleGeneral.radius50}`} value={cupon} onChange={handlecupon} type="text" placeholder='Ingresa el código de cupón'/>
                          )
                          }
                          {loadingCoupon ? (
                            <div className="btn-g bb py-2 px-3" role="button"><div className='spinner-border spinner-border-sm' role='status'></div></div>
                          ) : (
                            <>
                            {coupon!=0 ? (
                            <button className={`border-0 ${StyeleButton.btn} w-50 mt-4 mt-md-2`} onClick={deleteCodeCoupon}>{getTextByKey('borrar_cupon')}</button>
                          ):(
                            <button className={`border-0 ${StyeleButton.btn} w-50 mt-4 mt-md-2`} onClick={validateCodeCoupon}>{getTextByKey('aplicar_cupon')}</button>
                          )}
                          </>
                            )}
                         
                      </div>
                         {couponMsg != "" && (
                            <div className="form-group mb-2 position-relative">
                               {couponMsg}
                            </div>
                            )}
                      <div className="d-xl-flex justify-content-center align-items-center py-4">
                        <ButtonLink to='/tienda' styleGroup="px-3">{getTextByKey('seguir-comprando')}</ButtonLink>
                          
                        <button className={`border-0 ms-md-2 ms-0  px-3 mt-3 mt-lg-0 ${StyeleButton.btn} ${(total) < compraMinima ? StyeleButton.btnDisabled : ''}`} onClick={ openModal}>{getTextByKey('finalizar-compra')}</button>
                      </div>
                    </Fragment>
                  }
                  </div>
              </div>
              <AlertaEliminarProducto showEliminar={showEliminar} setShowEliminar={setShowEliminar} onDelete={() => removeToCart(selectedIndex)} />
            </div>
            
          }

  
        </div>

          {/* {
            recommended.map((product, index ) => (
              <ProductComponent product={product} key={index}/>
            ) )
          } */}

        
          <div className="container">
            <div className="add-other-car my-2 my-lg-5 py-3 py-lg-5 bg-white">
              <TittleH5 styleGroup="text-center primary-color">{getTextByKey('agregar-producto')}</TittleH5>
              <Swiper modules={[Autoplay]}
                autoplay={{ delay: 6000, disableOnInteraction: true }}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    1200: {
                        slidesPerView: 5,
                        spaceBetween: 15,
                    },
                }}>
                {
                  
                  recommended.map((product, index ) => (
                    <SwiperSlide key={index}>
                      <ProductComponent product={product} short/>
                    </SwiperSlide>     
                  ) )
                  
                }
              </Swiper>
            </div>
          </div>
         

      </div>

      <ModalProcederPago showModal={showModal} setShowModal={setShowModal} />
    </Fragment>



    // <Fragment>


    //   <StepbyStepComponent step={2}/>
    //   <div>
    //     <div className="container py-5 checkout">
    //       <div className="row justify-content-md-center">
  
    //         <div className="col-md-8 col-lg-5">
    //           <h2 className='text-uppercase mb-4'>{getTextByKey('resumen-carrito-titulo')}</h2>
    //             <div className='content-carrito'>
    //               {currentCart.map((item, index) => (
    //                   <ItemCarrito key={index} index={index} item={item} />
    //               ))}
    //               {currentCart.length === 0 && <p className='text-center-c'>{getTextByKey('notice-cart-without-products')}</p>}
    //           </div>
    //           <h2 className='text-uppercase my-4'>{getTextByKey('direccion-envio-titulo')}</h2>
  
    //           <form className='mb-4'>
    //             <SelectDireccionComponent/>
    //           </form>
  
    //           <button className='btn-g bb p-3 py-2 text-uppercase my-3' data-bs-toggle="collapse" data-bs-target="#collapseForm" aria-expanded="false" aria-controls="collapseForm">{getTextByKey('agregar-direccion-nueva')}</button>
              
    //           <div className="collapse" id="collapseForm">
    //             <FormularioDireccion user={currentUser} />
    //           </div>
    //         </div>
  
    //         <div className="col-md-4 col-lg-5 offset-lg-1">
    //           <div className="container">
    //             <BolsaComponent  />
  
    //             <div className='py-5 text-center'>
    //               <h3>{getTextByKey('metodo-pago')}</h3>
    //               <div className="d-flex justify-content-center align-items-center flex-wrap">
    //                 <a className='m-md-2 m-1 icono-metodo'><img src='/img/SVG/american.svg' /></a>
    //                 <a className='m-md-2 m-1 icono-metodo'><img className='visa' src="img/visa.png" alt="logo" /></a>
    //                 <a className='m-md-2 m-1 icono-metodo'><img src="img/mastercard.png" alt="logo" /></a>
    //                 {/* <a href="" className='m-md-2 m-1 icono-metodo'><img src="img/nequi.png" alt="logo" /></a> */}
    //                 <a className='m-md-2 m-1 icono-metodo'><img src="img/pse.png" alt="logo" /></a>
    //               </div>
    //             </div>
  
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </Fragment>
  )
}

export default CheckoutbPage