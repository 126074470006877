import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import Loader from './LoaderComponent';
import StyleGeneral from '../scss/general.module.scss';

const ImageLoader = ({ src, alt, styleGroup, dummy }) => {

  const dummyType = dummy;
  //console.log("dummyType", dummyType);

  const [isLoading, setIsLoading] = useState(true);

  const isValidImage = (file) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.webp)$/i;
    return allowedExtensions.test(file.name);
  };
  

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      setIsLoading(false);
    };
    image.onerror = () => {
      setIsLoading(false);
    };
  }, [src]);

  return (
    <Fragment>
      {isLoading ? (
        <Fragment>
            <Skeleton width="100%" height={300} />
            <div className={StyleGeneral.contentLoader}>
                <Loader></Loader>
            </div>
        </Fragment>
      ) : (
        <Fragment>
            <picture>
              <source srcSet={src +'?format=webp'} type="image/webp"/>
              <source srcSet={src +'?format=jpg'} type="image/jpeg"/>
              <img className={`w-100 ${styleGroup}`} src={src} alt={alt} loading="lazy"/>
            </picture>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ImageLoader;
