import React, { Component, Fragment, useEffect, useState } from 'react';

import { createAddressForUser } from '../api/addressApi'
import PropsTypes from 'prop-types';
import { useApi } from '../context/apiContext';
import { useCart } from '../context/cartContext';

import { getInfoMap } from '../api/mapApi';
import { useAuth } from '../authContext';
import { useForm } from '../hooks/useForm';
import { async } from '@firebase/util';
import { getTiposDocumentos } from "../api/productApi";

import {  updateUser } from '../helpers/userHelper';

import StyleGeneral from '../scss/general.module.scss';
import StyleButton from '../scss/btn.module.scss';
import StyleBtn from '../scss/btn.module.scss';
import TittleH6 from './TittleH6Component';
import TextBlockSmall from './TextBlockSmallComponent';
import TextBlock from './TextBlockComponent';
import { AddressComponent } from './AddressComponent';

import { Collapse } from 'react-bootstrap';
import { Button } from 'bootstrap';

import scrollToTop from './GlobalFunction';
import { AlertComponent } from "../components/AlertComponent";
import { Icon } from "@iconify/react";
import { getStore } from '../api/storeApi';

let addressFormFields = {
    nombre:"",
    nombredos:"",
    apellido:"",
    apellidodos:"",
    tipoDocumento:0,
    documento:"",
    empresa:"",
    nit:"",
    email:"",
    celular:"",
    departamento:0,
    ciudad:"",
    city:0,
    complement:"",
    barrio:"",
    direccion:"",
    uid:"",
}

export const FormularioDireccion = ({usuario,seccion=2, setCambioCarrito,handleShowModal,setErrors}) => {
  
   const [disabledDescDir, setDisabledDescDir] = useState(true);
  const [error, setError] = useState('')
  const [dir, setDir] = useState([])
  const [succesfull, setsuccesfull] = useState('');
  const [loading, setloading] = useState(false);
  //const [colombiaAddress, setColombiaAddress] = useState(false)
  const { currentUser } = useAuth();
  const { getTextByKey } = useApi();
  const [errorDpto, setErrorDpto] = useState('');
  const [errorCiudad, setErrorCiudad] = useState('');
  const [errorNombre, setErrorNombre] = useState('');
  const [errorNombreDos, setErrorNombreDos] = useState('');
  const [errorApellido, setErrorApellido] = useState('');
  const [errorApellidoDos, setErrorApellidoDos] = useState('');
  const [errorTipoDoc, setErrorTipoDoc] = useState('');
  const [errorDocumento, setErrorDocumento] = useState('');
  const [errorCelular, setErrorCelular] = useState('');
  const [errorEmpresa, setErrorEmpresa] = useState('');
  const [errorContacto, setErrorContacto] = useState('');
  const [errorNIT, setErrorNIT] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorDireccion, setErrorDireccion] = useState('');
  const [errorBarrio, setErrorBarrio] = useState('');
  const [order, setOrder] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const { updateDireccion,updateTienda,validarCart } = useCart();
  const [selectedValue, setSelectedValue] = useState("natural");
  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [cargauser,setCargaUser ] = useState(true);
  const user=usuario;
  



  /*const [nombresApellidos, setnombresApellidos] = useState({
    nombres: '',
    apellidos: ''
  });*/

  /*const [addressSearch, setAddressSearch] = useState({
    tipo_direccion: '',
    desc_dir_1: '',
    desc_dir_2: '',
    desc_dir_3: '',
    direccionLarga: '',
    city: 'Bogota',
    country: ''
  })
*/
  const {
    onInputChange: onAddressInputChange,
    formState,
    setFormState,
    nameRegex,
    emailRegex,
    nitRegex,
    phoneRegex
  } = useForm(addressFormFields);

  const {
    nombre,
    nombredos,
    apellido,
    apellidodos,
    tipoDocumento,
    documento,
    empresa,
    nit,
    contacto,
    email,
    celular,
    departamento,
    city,
    complement,
    barrio,
    direccion,
  
  } = formState;

  useEffect(() => {
    getTiposDocumentos().then((data) => {
        setTiposDocumentos(data);
    });
  }, []);
  
   const resetError=()=>{
    setError("");
    setErrorNombre("");
    setErrorNombreDos("");
    setErrorApellido("");
    setErrorApellidoDos("");
    setErrorTipoDoc("");
    setErrorDocumento("");
    setErrorCelular("");
    setErrorEmail("");
    setErrorNIT("");
    setErrorContacto("");
    setErrorEmpresa("");
    setErrorDpto("");
    setErrorCiudad("");
    setErrorDireccion("");
    setErrorBarrio("");
   }


   
  useEffect(() => {
    setCargaUser(true);
     if(currentUser?.isAnonymous && user){
       if(localStorage.getItem('cambio-datos')){
        const {nombre,nombredos, apellido, apellidodos,celular, email,emailnotifica, departamento, ciudad,direccion, tipodoc,barrio,documento,juridico,notas,contacto} = user;
         console.log('juridico',juridico);
        if(!juridico)
          setSelectedValue('natural');
        else
         setSelectedValue('juridica');
        setFormState({
            ...addressFormFields,
            nombre,
            empresa:nombre,
            nombredos,
            apellido,
            apellidodos,
            celular,
            documento,
            tipoDocumento:tipodoc,
            email:emailnotifica,
            departamento,
            city: ciudad,
            complement:notas,
            direccion,
            barrio,
            contacto,
          nit: documento,
          
        })
      }else{
        setFormState({
          ...addressFormFields,
          nombre,
          nombredos,
          apellido,
          apellidodos,
          celular,
          documento,
          tipoDocumento,
          email,
          departamento:localStorage.getItem('departamento'),
          city: localStorage.getItem('city'),
          direccion,
          barrio,
          nit,
          contacto
        
        })
     
      }
   
 
    }else{
      
      setSelectedValue('juridica');
      setFormState({
        ...addressFormFields,
        empresa:nombre,
        celular,
        email,
        departamento,
        city: 0,
        direccion,
        barrio,
       documento,
    
  })

   }
   if(user!=0)
     setCargaUser(false);
  
  }, [user]);
  
 

  

  const onRegisterAddress = async ( event ) => {
    event.preventDefault();
    setloading(true);

    const validate = await validateForm(formState);
    if(!validate){
    
      if(currentUser?.isAnonymous){
        if(seccion!=1){
          let dataenvio='';
           if(selectedValue=='juridica'){
            dataenvio={...formState, nombre:empresa, juridico:1, nombredos:'' ,apellido:'', apellidodos:'' ,ciudad:city, notas:complement, tipoDocumento:1 ,documento:nit,uid:currentUser.uid}
          }else{
            dataenvio={...formState, juridico:0, notas:complement, ciudad:city,uid:currentUser.uid}
          }
          updateUser(dataenvio).then((response) => {
            let dire=[];
            dire.city=parseInt(city);
            dire.departamento=parseInt(departamento);
            dire.direccion=direccion;
            dire.complement=complement;
            dire.barrio=barrio;
            dire.user=currentUser.uid;
            const  datasend={...dire}
          const data =  createAddressForUser(datasend).then(async(response)=>{
           
            if(response.id){
              localStorage.setItem('cambio-datos',1);
              let tienda= await getStore(parseInt(city)) 
              if(tienda.id){
                updateTienda(tienda.id);
                let carritoajustar =await validarCart(tienda.id);
                console.log(carritoajustar);
                if(carritoajustar.ok){
                 if(carritoajustar.total>0){
                    let mensaje=getTextByKey('productos-eliminados-car')+': ';
                    mensaje += carritoajustar.eliminados.map((eliminado) => eliminado.name.nombre).join(', ');
                    setErrors(mensaje);
                    handleShowModal();
                  }
                  
                }
                 updateDireccion(response.id);
                 setCargaUser(true);
                 if(carritoajustar.total==0)
                  location.href='/checkout-c';
                 setloading(false);
                   //
              
              
              }else{
                setErrors('No tiene cobertura');
                handleShowModal();
                setCargaUser(true);
                location.href='/checkout-c';
                setloading(false);
              }
              
              //window.';
            }
          }).catch((error)=>{
              setloading(false);
              console.log(error);
              if(error.response.data.message){
                setErrors(error.response.data.message);
              }else{
              setErrors('Ha ocurrido un error al crear la dirección');
              }
              handleShowModal();
            });
                  
          }).catch((error)=>{
            setloading(false);
            if(error.response.data.message){
              setErrors(error.response.data.message);
            }else{
            setErrors('Ha ocurrido un error al actualizar la iformación');
            }
            handleShowModal();
          })
        }else{
          let tienda= await getStore(parseInt(city)) 
          if(tienda.id){
             updateTienda(tienda.id);
             localStorage.setItem('city',city);
             localStorage.setItem('departamento',departamento);
             let carritoajustar =await validarCart(tienda.id);
              console.log(carritoajustar);
              if(carritoajustar.ok){
               if(carritoajustar.total>0){
                  let mensaje=getTextByKey('productos-eliminados-car')+': ';
                  mensaje += carritoajustar.eliminados.map((eliminado) => eliminado.name.nombre).join(', ');
                  setErrors(mensaje);
                  handleShowModal();
                }
                
              }
              if(carritoajustar.total==0){
                if(localStorage.getItem('volver-sitio')){
                  location.href=localStorage.getItem('volver-sitio');
                }else{
                 location.href='/tienda';
                }
              }
              setloading(false);
          }else{
             setErrors('No tiene cobertura');
             handleShowModal();
             setloading(false);
          }
        }
       
      }else{  
        let dir=[];
        dir.city=parseInt(city);
        dir.departamento=parseInt(departamento);
        dir.direccion=direccion;
        dir.complement=complement;
        dir.barrio=barrio;
        dir.user=currentUser.uid;
        const datasend={...dir}

        console.log(datasend);
         const data = await createAddressForUser(datasend).then(async(response)=>{
         
          if(response.id){
            let tienda= await getStore(parseInt(city)) 
            if(tienda.id){
              updateTienda(tienda.id);
              let carritoajustar =await validarCart(tienda.id);
              console.log(carritoajustar);
              if(carritoajustar.ok){
               if(carritoajustar.total>0){
                  let mensaje=getTextByKey('productos-eliminados-car')+': ';
                  mensaje += carritoajustar.eliminados.map((eliminado) => eliminado.name.nombre).join(', ');
                  setErrors(mensaje);
                  handleShowModal();
                }
                
              }
              
                 updateDireccion(response.id);
                 setCargaUser(true);
                 if(carritoajustar.total==0)
                  location.href='/checkout-c';
                 setloading(false);
                //window.location.href='/checkout-c';
           
      
            }else{
              setErrors('No tiene cobertura');
              handleShowModal();
              setCargaUser(true);
              location.href='/checkout-c';
              setloading(false);
            }
          }
            
        }).catch((error)=>{
          setloading(false);
          if(error.response.data.message){
            setErrors(error.response.data.message);
          }else{
          setErrors('Ha ocurrido un error al crear la dirección');
          }
         
        });
      }
   
    } else{
      setloading(false);
      return;
    }
  }

  const validateForm = async (formState) => {
    resetError();
    let errorFound = false;
    if(currentUser.isAnonymous && seccion!=1){
      if(selectedValue=='natural'){
        if(!nombre){
          errorFound = true;
          setErrorNombre('Campo obligatorio');
        }else{
          if(!nombre.match(nameRegex)){
            errorFound = true;
            setErrorNombre('El campo solo debe contener letras');
          }
        }
        if(nombredos){
          if(!nombredos.match(nameRegex)){
            errorFound = true;
            setErrorNombre('El campo solo debe contener letras');
          
          }
        }
        if(!apellido){
          errorFound = true;
          setErrorApellido('Campo obligatorio');
        }else{
          if(!apellido.match(nameRegex)){
            errorFound = true;
            setErrorApellido('El campo solo debe contener letras');
          }
        }
        if(!apellidodos){
          errorFound = true;
          setErrorApellidoDos('Campo obligatorio');
        }else{
          if(!apellidodos.match(nameRegex)){
            errorFound = true;
            setErrorApellidoDos('El campo solo debe contener letras');
          }
        }
        if(tipoDocumento==0){
          errorFound = true;
          setErrorTipoDoc('Campo obligatorio');
        }
        if(!documento){
          errorFound = true;
          setErrorDocumento('Campo obligatorio');
        }
        
      }else{
        if(!empresa){
          errorFound = true;
          setErrorEmpresa('Campo obligatorio');
        }
        if(!nit){
          errorFound = true;
          setErrorNIT('Campo obligatorio');
        }else{
          if(!nit.match(nitRegex)){
            errorFound = true;
            setErrorNIT('Si el nit cuenta con código de verificación debes colocar (-) seguido del número');
          }
        }
        if(!contacto){
          errorFound = true;
          setErrorContacto('Campo obligatorio');
        }else{
          if(!contacto.match(nameRegex)){
            errorFound = true;
            setErrorContacto('El campo solo debe contener letras');
          }
        }
      }

      if(!celular){
        errorFound = true;
        setErrorCelular('Campo obligatorio');
      }else{
        if(!celular.match(phoneRegex)){
          errorFound = true;
          setErrorCelular('El telefonó no es valido'); 
        }
      }
      if(!email){
        errorFound = true;
        setErrorEmail('Campo obligatorio');
      }else{
        if(!email.match(emailRegex)){
          errorFound = true;
          setErrorEmail('El email no es valido'); 
        }  
      }
    }
   
      
    if (departamento == 0) {
      errorFound = true;
      setErrorDpto("Debe seleccionar un departamento");
    }

    if (city == 0) {
      errorFound = true;
      setErrorCiudad("Debe seleccionar una ciudad");
    }
    if(seccion!=1){
      if(!direccion){
        errorFound = true;
        setErrorDireccion("El campo es obligatorio");
      }
      if(!barrio){
        errorFound = true;
        setErrorBarrio("El campo es obligatorio");
      }
    }
    return errorFound;
  }



  function handleRadioChange(event) {
    console.log(event.target.value);
    setSelectedValue(event.target.value);
  }



const [openItem, setOpenItem] = useState({
  1: false
});


const toggleOpenItem = (itemId) => {
    setOpenItem((prevState) => ({
        ...prevState,
        [itemId]: !prevState[itemId],
    }));
};

const handleCloseItem = (itemId) => {
    scrollToTop();
    setTimeout(() => {
        setOpenItem((prevState) => ({
            ...prevState,
            [itemId]: false,
        }));    
    }, 500);
    
};

  
if (cargauser)
return (
  <div className='container'>
    <div className="text-center-c">
      <div className="loader-box">
        <img src="/img/SVG/loader.svg" alt="" />
      </div>
    </div>
  </div>
);

  return (
    <Fragment>

      <form action="" onSubmit={onRegisterAddress}>
        
        {succesfull ? <div className='succesfull-msg'>{succesfull}</div> : null}
        
        {currentUser?.isAnonymous ?
            <Fragment>
              { seccion==2 && <Fragment>
                  {/* check radio para juridica o natural */}
                  <div className="form-group d-flex aling-items-center justify-conten-start mb-4">
                    <div className='d-flex aling-items-center justify-conten-start me-3'>
                      <input type="radio" id="natural" name="options" value="natural" className='me-2' checked={selectedValue === "natural"} onChange={handleRadioChange}/>
                      <label htmlFor="natural">
                        <TextBlock styleGroup="mb-0">Natural</TextBlock>
                      </label>
                    </div>
                    <div className='d-flex aling-items-center justify-conten-start'>
                      <input type="radio" id="juridica" name="options" value="juridica" className='me-2' checked={selectedValue === "juridica"}  onChange={handleRadioChange}/>
                      <label htmlFor="juridica">
                        <TextBlock styleGroup="mb-0">Juridica</TextBlock>
                      </label>
                    </div>
                  </div>

                  {selectedValue === "juridica" && 
                    <Fragment>
                      <div className="form-group mb-4">
                        <input type="text" className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} name="empresa" value={empresa} onChange={onAddressInputChange} placeholder={getTextByKey("nombre_empresa")} />
                        {errorEmpresa?
                            <span>
                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                <div className='err_login'>{errorEmpresa}</div>
                            </span> : null}
                      </div>        
                      <div className="form-group mb-4">
                        <input type="text" className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} name="nit" value={nit} onChange={onAddressInputChange} placeholder={getTextByKey("nit")}/>
                        {errorNIT?
                            <span>
                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                <div className='err_login'>{errorNIT}</div>
                            </span> : null}
                      </div>
                      <div className="form-group mb-4">
                        <input type="text" className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} name="contacto" value={contacto} onChange={onAddressInputChange} placeholder={getTextByKey("contacto-juridico")}/>
                        {errorContacto?
                            <span>
                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                <div className='err_login'>{errorContacto}</div>
                            </span> : null}
                      </div>
                    </Fragment>    
                  }

                  {selectedValue === "natural" &&
                    <Fragment>

                      <div className="form-group mb-4">
                        <input type="text" className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} name="nombre" value={nombre} onChange={onAddressInputChange} placeholder={getTextByKey("primer_nombre")} />
                        {errorNombre?
                            <span>
                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                <div className='err_login'>{errorNombre}</div>
                            </span> : null}
                      </div>
                    
                      <div className="form-group mb-4">
                        <input type="text" className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} name="nombredos" value={nombredos} onChange={onAddressInputChange} placeholder={getTextByKey("segundo_nombre")} />
                        {errorNombreDos?
                            <span>
                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                <div className='err_login'>{errorNombreDos}</div>
                            </span> : null}
                      </div>
                    
                      <div className="form-group mb-4">
                        <input type="text" className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} name="apellido" value={apellido} onChange={onAddressInputChange} placeholder={getTextByKey("primer_apellido")} />
                        {errorApellido?
                            <span>
                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                <div className='err_login'>{errorApellido}</div>
                            </span> : null}
                      </div>
                    
                      <div className="form-group mb-4">
                        <input type="text" className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} name="apellidodos" value={apellidodos} onChange={onAddressInputChange} placeholder={getTextByKey("segundo_apellido")} />
                        {errorApellidoDos?
                            <span>
                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                <div className='err_login'>{errorApellidoDos}</div>
                            </span> : null}
                      </div>
                    
                      <div className="form-group mb-4">
                        <select className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} value={tipoDocumento} onChange={onAddressInputChange} name="tipoDocumento">
                          <option value="0">{getTextByKey('tipo_documento')}</option>
                          {tiposDocumentos.map((tipo, index) => (
                          <option key={index} value={tipo.id}>{tipo.ref + " (" + tipo.translations[0].name + ")"}</option>
                          ))}
                      </select>       
                      {errorTipoDoc?
                            <span>
                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                <div className='err_login'>{errorTipoDoc}</div>
                            </span> : null}
                      </div>
                    
                      <div className="form-group mb-4">
                        <input type="number" className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} name="documento" value={documento} onChange={onAddressInputChange} placeholder={getTextByKey("numero_documento")} />
                        {errorDocumento?
                            <span>
                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                <div className='err_login'>{errorDocumento}</div>
                            </span> : null}
                      </div>
                    
                    </Fragment>
                  }
                  {/* los dos casos juridica o natural llevan estos campos en este componente */}


                  <div className="form-group mb-4">
                    <input type="number" className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} name="celular" value={celular} onChange={onAddressInputChange} placeholder={getTextByKey("telefono")} />
                    {errorCelular?
                            <span>
                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                <div className='err_login'>{errorCelular}</div>
                            </span> : null}
                  </div>

                  <div className="form-group mb-4">
                    <input type="text" className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`}name="email" value={email} onChange={onAddressInputChange} placeholder={getTextByKey("correo_electronico_form")} />
                    {errorEmail?
                            <span>
                                <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                                <div className='err_login'>{errorEmail}</div>
                            </span> : null}
                  </div>
                  </Fragment>}

              <AddressComponent onAddressInputChange={onAddressInputChange} direccion={direccion} barrio={barrio} ciudad={city} complement={complement} departamento={departamento} errorDpto={errorDpto} errorCiudad={errorCiudad}
                   errorDireccion={errorDireccion} errorBarrio={errorBarrio} seccion={seccion}/>
              <div className='text-center'>
                   <button type='submit' disabled={loading} className={`${StyleBtn.btn} ${loading ? StyleBtn.active:''}`}>
                        Continuar
                        <span className={StyleButton.loadingContainer}>
                        <span className={StyleButton.dot}>.</span>
                        <span className={StyleButton.dot}>.</span>
                        <span className={StyleButton.dot}>.</span>
                      </span>
                    </button>
                  
                   
              </div>
              {error ? <div className='error-msg'>{error}</div> : null}
            </Fragment>
          :
            <Fragment>
              <div className='form-group mb-4 text-center text-md-start'>
                <button className={StyleButton.btn} 
                  onClick={() => toggleOpenItem(1)}
                  aria-controls="collapseOne"
                  aria-expanded={openItem[1]}>{getTextByKey('agregar_nueva_direccion')}</button>
              </div>

              <Collapse in={openItem[1]}>
                <div id="collapseOne">
                <AddressComponent onAddressInputChange={onAddressInputChange} direccion={direccion} barrio={barrio} ciudad={city} departamento={departamento} errorDpto={errorDpto} errorCiudad={errorCiudad}
                   errorDireccion={errorDireccion} errorBarrio={errorBarrio} seccion={seccion}/>
                  <div className='text-center'>
                    <button className={`me-3 mb-4 ${StyleBtn.btn}`} onClick={() => handleCloseItem(1)}>Cancelar</button>
                    <button type='submit'  disabled={loading} className={`${StyleBtn.btn} ${loading ? StyleButton.active:''}`}>
                      {getTextByKey('guardar_direccion')}
                      <span className={StyleButton.loadingContainer}>
                        <span className={StyleButton.dot}>.</span>
                        <span className={StyleButton.dot}>.</span>
                        <span className={StyleButton.dot}>.</span>
                      </span>
                      
                      </button>
                  </div>
                </div>
              </Collapse>
            </Fragment>
        }

      </form>
      
    </Fragment>
  )
}
// FormularioDireccion.propTypes = {
//   uidUser: PropsTypes.string.isRequired
// }