import React, { useState, useEffect, useContext, createContext } from "react";
import { json } from "react-router-dom";
import sellyApi from "../api/sellyApi";
import { useAuth } from "../authContext";
import axios from "axios"

const ApiContext = createContext();

export function useApi() {
  return useContext(ApiContext);
}

export function ApiProvider({ children }) {
  //Set states

  const [currentTexts, setCurrentTexts] = useState(JSON.parse(localStorage.getItem("texts_shido_cache")) || []);
  const [currentTextsBig, setCurrentTextsBig] = useState([]);
  const [currentLocale, setCurrentLocale] = useState("es");
  const [currentCategories, setCurrentCategories] = useState([]);
  const [currentSubcategories, setCurrentSubcategories] = useState([]);
  const [currentActivities, setCurrentActivities] = useState([]);
  const [currentCombination, setCurrentCombination] = useState([]);
  const [currentColors, setCurrentColors] = useState([]);
  const [currentMarcas, setCurrentMarcas] = useState([]);
  const [currentSizes, setCurrentSizes] = useState([]);
  const [currentCollections, setCurrentCollections] = useState([]);
  const [currentLines, setCurrentLines] = useState([]);
  const [currentFav, setCurrentFav] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [currentCurrency, setCurrentCurrency] = useState("cop");
  const [cards, setCards] = useState([]);
  const [address, setAddress] = useState([]);
  const [lastPayment, setLastPayment] = useState(false);
  const [currentDepartamentos, setCurrentDepartamentos] = useState([]);
  const [currentCiudades, setCurrentCiudades] = useState([]);

  const { currentUser } = useAuth();


  //Api calls for general data

  const getTexts = async () => {
    try {
      const data = await sellyApi.get(`/text`);
      return data.data;
    } catch (error) {
      return error;
    }
  };

  const getTextsBig = async () => {
    try {
      const data = await sellyApi.get(`/textBig/`);
      return data.data;
    } catch (error) {
      return error;
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await sellyApi.get(`/store/categories`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getSubcategories = async () => {
    try {
      const { data } = await sellyApi.get(`/store/subcategories`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getMarcas = async () => {
    try {
      const { data } = await sellyApi.get(`/marcas`, {params: {locale: currentLocale}});
      if (Array.isArray(data)) {
        return data;
      }
      if (data['hydra:member']) return data['hydra:member'];
      return [];
    } catch (error) {
      console.error(error)
      return [];
    }
  };

  const getActivities = async () => {
    try {
      const { data } = await sellyApi.get(`/store/activity`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getCombinations = async () => {
    try {
      const { data } = await sellyApi.get(`/combinations`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getLines = async () => {
    try {
      const { data } = await sellyApi.get(`/store/line`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getCollections = async () => {
    try {
      const { data } = await sellyApi.get(`/collection/colecciones_nuevas`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getTallas = async () => {
    try {
      const { data } = await sellyApi.get(`/tallas`);
      if (data) {
        return data["hydra:member"].sort(((a, b) => a.orden - b.orden));
      }
    } catch (error) {
      return error;
    }
  };

  const getColors = async () => {
    try {
      const { data } = await sellyApi.get(`/colors`);
      if (data) {
        return data["hydra:member"];
      }
    } catch (error) {
      return error;
    }
  };

  const getFavs = async (uid) => {
    try {
      const { data } = await sellyApi.get(`/product/favoritos?usuario=${uid}`)
      return data;
    } catch (error) {
      return error;
    }
  };

  const getCurrencies = async () => {
    try {
      const { data } = await sellyApi.get(`/store/get-setting?llave=currency_convertion&formato=JSON`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getDptos = async () => {
    try {
      const { data } = await sellyApi.get(`/departamentos`)
      return data;
    } catch (error) {
      return error;
    }
  }
  
   const getCities = async () => {
    try {
      const { data } = await sellyApi.get(`/ciudad/`)
      return data;
    } catch (error) {
      return error;
    }
  }


  const getPropiertyByLocale = (array, propierty) => {
    if (!array) return "";
    if (array.length === 0) return "";
    let local_filter = array.find(e => e.locale == currentLocale);
    if (!local_filter) return "";
    return local_filter[propierty];
  }



  //Functions

  const getTextByKey = (key) => {
    let finded = currentTexts.find((text) => text.llave === key);
    if (finded) {
      let findedfinded = finded.names.find((text) => text.locale === currentLocale);
      if (findedfinded) {
        return findedfinded.valor;
      } else {
        return "...";
      }
    } else {
      return "...";
    }
  };

  const getTextByKeyLink = (key) => {
    let finded = currentTexts.find((text) => text.llave === key);
    if (finded) {
      if (finded.link) {
        return finded.link;
      } else {
        return "#";
      }
    } else {
      return "#";
    }
  };

  const getTextBigByKey = (key) => {
    let finded = currentTextsBig.find((text) => text.llave === key);
    if (finded) {
      let findedfinded = finded.names.find((text) => text.locale === currentLocale);
      if (findedfinded) {
        return findedfinded.valor;
      } else {
        return "...";
      }
    } else {
      return "...";
    }
  };

  const extractNameFromLocate = (object) => {
    let finded = [];
    if (object.hasOwnProperty("names")) {
      finded = object.names.find((text) => text.locale === currentLocale);
    } else {
      finded = object.name.find((text) => text.locale === currentLocale);
    }
    if (finded) {
      return finded.nombre;
    } else {
      return "...";
    }
  };

  const updateLocale = (locale) => {
    setCurrentLocale(locale);
  };

  const updateCurrency = (currency) => {
    setCurrentCurrency(currency);
  };

  const updateFavs = () => {
    if (currentUser) {
      getFavs(currentUser.uid).then((data) => {
        setCurrentFav(data);
      });
    } else {
      setCurrentFav([]);
    }
  }


  const convertCurrency = (amount) => {
    let priceLocale = Intl.NumberFormat('en-Us');
   // if (currentCurrency === "cop") {
      let priceOutput = priceLocale.format(amount)
      return `$ ${priceOutput}`;
    /*} else {
      return "$" + (amount*currencies[currentCurrency]).toLocaleString() + " USD";
    }*/
  };

  const getTarjetas = async (uid) => {
    try {
      const { data } = await sellyApi.get(`/card?uid=${uid}`)
      return data;
    } catch (error) {
      return error;
    }
  }

  const getAddress = async (uid) => {
    try {
      const { data } = await sellyApi.get(`/address?uid=${uid}`);
      return data;
    } catch (error) {
      return [];
    }
  }

  const getLastPayment = async (uid) => {
    try {
      const { data } = await sellyApi.get(`/user/last-payment?usuario=${uid}`)
      return data;
    } catch (error) {
      return error;
    }
  }

  const getActualLocation = async (ip) => {
    try {
      const { data } = await sellyApi.get(`/ip?ip=${ip}`)
      return data;
    } catch (error) {
      return error;
    }
  }

  const [ip, setIP] = useState('');


  const getIp = async () => {
    try {
      const { data } = await axios.get(`https://geolocation-db.com/json/`)
      return data;
    } catch (error) {
      return error;
    }
  }

  const getAllApis = async () => {
    let cacheTimes = localStorage.getItem("texts_shido_cache_times");
    if (cacheTimes) {
      let cacheTime = new Date(cacheTimes);
      let now = new Date();
      if (now.getTime() - cacheTime.getTime() > 1000) {

      const [textosData] = await Promise.all([
        getTexts(),
        ]);
        setCurrentTexts(textosData);
        localStorage.setItem("texts_shido_cache", JSON.stringify(textosData));
        localStorage.setItem("texts_shido_cache_times", now.getTime());
        setLoading(false);

          
      } else{
        setCurrentTexts(JSON.parse(localStorage.getItem("texts_shido_cache")));
        setLoading(false);
      }
    } else {

      
      getTexts().then((data) => {
        setCurrentTexts(data);
        localStorage.setItem("texts_shido_cache", JSON.stringify(data));
        localStorage.setItem("texts_shido_cache_times", new Date().getTime());
        setLoading(false);
      });
    }

    const [textosBigData, categoriasData,subcategoriasData,marcasData,dptoData,cityData] = await Promise.all([
      getTextsBig(),
      getCategories(),
      getSubcategories(),
      getMarcas(),
      getDptos(),
      getCities(),
    ]);

    setCurrentTextsBig(textosBigData);
    setCurrentCategories(categoriasData);
    setCurrentSubcategories(subcategoriasData);
    setCurrentMarcas(marcasData);
    localStorage.setItem('departamentos',JSON.stringify(dptoData));
    setCurrentDepartamentos(dptoData);
    setCurrentCiudades(cityData);
    localStorage.setItem('ciudades',JSON.stringify(cityData));

    /*getTextsBig().then((data) => {
      setCurrentTextsBig(data);
    });
    getCategories().then((data) => {
      setCurrentCategories(data);
    });
    getSubcategories().then((data)=>{
      setCurrentSubcategories(data);
    })
    
    getMarcas().then(data => {
      if(data)
        setCurrentMarcas(data);
    });
    getDptos().then((data)=>{
      localStorage.setItem('departamentos',JSON.stringify(data));
      setCurrentDepartamentos(data);
    });
    getCities().then((data)=>{
      setCurrentCiudades(data);
      localStorage.setItem('ciudades',JSON.stringify(data));
    });
    /*getCombinations().then((data) => {
      setCurrentCombination(data);
    });
    getLines().then((data) => {
      setCurrentLines(data);
    });
    getCollections().then((data) => {
      setCurrentCollections(data);
    });
    getTallas().then((data) => {
      setCurrentSizes(data);
    });
    getColors().then((data) => {
      setCurrentColors(data);
    });*/
    /*getCurrencies().then((data) => {
      setCurrencies(data);
    });*/
    //updateFavs();
    if(currentUser){
      getTarjetas(currentUser.uid).then((data) => {
        setCards(data);
      });
      getAddress(currentUser.uid).then((data) => {
        setAddress(data);
      });
      getLastPayment(currentUser.uid).then((data) => {
        setLastPayment(data);
      });
    }
  }

  useEffect(() => {
    setLoading(true);
    getAllApis();
    let data = {currency:'COP', lang:'es'};
    if(data.lang) setCurrentLocale(data.lang);
    if(data.currency) setCurrentCurrency((data.currency).toLowerCase());
  
  }, []);

  const value = {
    currentTexts,
    currentLocale,
    currentCategories,
    currentSubcategories, 
    currentActivities,
    currentCombination,
    currentColors,
    currentSizes,
    currentCollections,
    currentLines,
    currentMarcas,
    currentFav,
    getTextByKey,
    getTextByKeyLink,
    getTextBigByKey,
    updateLocale,
    extractNameFromLocate,
    updateFavs,
    getPropiertyByLocale,
    convertCurrency,
    loading,
    currencies,
    updateCurrency,
    currentCurrency,
    currentDepartamentos,
    cards,
    address,
    lastPayment
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}
