import { Fragment } from "react";
import StyleTag from "../scss/product.module.scss";

export default function TagProductComponent(props) {
    const type = props.type;
    return (
        <Fragment>
            <div className={`${StyleTag.tagItem}`}>
                <img className="w-100" src="/1x/imgTag.png"/>
                <div className={StyleTag.tagWp}>
                    <span className={`${StyleTag.tagText} ${type == 'discount' ? `${StyleTag.discount}` : '' }`}>
                        {props.text}
                    </span>
                </div>
            </div>
        </Fragment>
    )
}