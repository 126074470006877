import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { useApi } from "../context/apiContext";
import StyleGeneral from "../scss/general.module.scss";

import { useForm } from "../hooks/useForm";
import { getCiudades,getDepartamentos } from "../api/productApi";
import { Icon } from "@iconify/react";


/*const addressFormFields = {
    nombres: "",
    apellidos: "",
    tipo_documento: "",
    numero_documento: "",
    nombre_empresa: "",
    nit: "",
    email: "",
    telefono: "",
    departamento: "",
    ciudad: "",
    complemento: "",
    direccion: "",
}*/
  

export  function AddressComponent({onAddressInputChange, ciudad,departamento, direccion, barrio,complement,errorCiudad='',errorDpto='',errorDireccion='',errorBarrio='',seccion=1}) {    
    const { getTextByKey,currentDepartamentos } = useApi();
    const [ciudades, setCiudades] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [ dptoId, setDptoId ] = useState('');

    /*const {
       
    } = useForm(addressFormFields);

    const {
        complemento,
        direccion,
    } = formState;*/
    useEffect(() => {
        if(departamento)
            setDptoId(departamento)

    }, [departamento]);

    useEffect(() => {
          getDepartamentos().then((data) => {
          setDepartamentos(data);
           
         });
    }, [currentDepartamentos]);

    useEffect(()=>{
        if(dptoId){
            getCiudades(dptoId).then((data) => {
                setCiudades(data);
            });
        }
    },[dptoId])

    const handleDpto = (event)=>{
        setDptoId(event.target.value)
        onAddressInputChange(event);
     }


    return (
        <Fragment>
            <div className="form-group mb-4">
           
                <select className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} value={departamento} name="departamento" onChange={handleDpto} >
                    <option value="0">{getTextByKey("departamento")}</option>
                    {departamentos.map((departamento, index) => (
                    <option key={index} value={departamento.id}>{departamento.nombre}</option>
                    ))}
                </select>
                {errorDpto ?
                        <span>
                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                            <div className='err_login'>{errorDpto}</div>
                        </span> : null}
            </div>
               
            <div className="form-group mb-4">
                <select className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} value={ciudad} onChange={onAddressInputChange} name="city">
                    <option value="0">{getTextByKey("ciudad")}</option>
                    {ciudades.map((city, index) => (
                    <option key={index} value={city.id}>{city.nombre}</option>
                    ))}
                </select>
                {errorCiudad ?
                        <span>
                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                            <div className='err_login'>{errorCiudad}</div>
                        </span> : null}
            </div>
         { seccion!=1 ?
         <>
                     <div className="form-group mb-4">
                <input type="text" className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} onChange={onAddressInputChange} name="direccion"  value={direccion} placeholder={getTextByKey("direccion")} />
                {errorDireccion ?
                        <span>
                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                            <div className='err_login'>{errorDireccion}</div>
                        </span> : null}
              </div>

            <div className="form-group mb-4">
                <input type="text" className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} onChange={onAddressInputChange} name="barrio" value={barrio}  placeholder={getTextByKey("barrio")} />
                {errorBarrio ?
                        <span>
                            <Icon icon="bi:exclamation-circle-fill" className='exclamation' />
                            <div className='err_login'>{errorBarrio}</div>
                        </span> : null}
            </div>
        
            <div className="form-group mb-4">
                <input type="text" className={`py-2 px-3 primary-border-color ${StyleGeneral.input}`} value={complement} onChange={onAddressInputChange} name='complement' placeholder={getTextByKey('notas')}/>
            </div>
            </>:
            <></>
         }
        </Fragment>
    );
}

