import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function TarjetadPage() {
  return (
    <Fragment>
        <div className="banner tarjeta-regalo position-relative">
          <img className="w-100 fondo" src="img/Group-138.jpg"/>
          <div className="content-banner p-3 p-md-0" /* style={{ backgroundImage: `url("img/Group-138.jpg")`}} */>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 offset-lg-3">
                    <div className="p-3 p-lg-4 p-xl-5 text-center">
                      <h2 className="text-uppercase gotham-Bold mb-4">TU SALDO</h2>
                      <p className="gotham-Bold text-uppercase mb-0">saldo: $200.000</p>
                      <p className="mb-0 text-uppercase">DEBES UTILIZAR TODO EL SALDO</p>
                      <p className="mb-4 text-uppercase">nO SE GUARDA EL SALDO EN TU TARJETA</p>
                      <Link to="/tarjeta-b" className="btn-g py-2 px-4 text-uppercase">REGRESAR A TIENDA</Link>
                    </div>
                  </div>
                </div>
              </div>
              
          </div>
        </div> 
    </Fragment>
  )
}
