import React from 'react'
import PropTypes from 'prop-types'

/* import '../scss/recipe.module.scss'; */

import styleRecipe from '../scss/recipe.module.scss';
import StyleText from "../scss/text.module.scss";

import { Link } from 'react-router-dom';
import { images_products_route } from "../api/productApi";
import Picture from './PictureComponent';
import TittleH2 from './TittleH2Component';
import { Fragment } from 'react';

//scroll to top al ingresar a la pagina

import scrollToTop from "./GlobalFunction";

const RecipeComponent = ({recipe}) => {
    return (
        <Fragment>
            {/* <img src={images_products_route + recipe.images[0]?.image} alt="Recipe item" className="w-100 image" /> */}
            {
                recipe.images.length && (
                    <div className={`p-2 ${styleRecipe.recipeItem}`}>
                        <div className={styleRecipe.contentImg}>
                            <Picture src={recipe.images[0]?.image} alt={recipe.title}/>
                        </div>
                        <TittleH2 styleGroup={`text-start primary-color fw-600 mt-2 ${StyleText.h3}`}>{recipe.title}</TittleH2>
                        <Link to={"/receta/" + recipe.id} onClick={scrollToTop}></Link>
                    </div>
                )
            }
        </Fragment>
    )
}

RecipeComponent.propTypes = {
    recipe: PropTypes.object.isRequired
}

export default RecipeComponent