import React, { Fragment, useEffect } from 'react'
import { Link, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { ProductComponent } from '../../components/productComponent'
import FiltrosComponent from './components/FiltrosComponent'
import { getProducts } from "../../api/productApi";
import { useApi } from "../../context/apiContext";
import { useCart } from '../../context/cartContext';
import { Icon } from '@iconify/react'
import ModalAlerta from '../../components/ModalAlerta';
import { useState } from 'react';
import { useAuth } from '../../authContext';
import Pagination from '../../components/PaginationComponent';
import {shuffle} from "../../helpers/arrayTools";
import Banner from '../../components/BannerComponent';
import { getGallery } from "../../api/galleryApi";
import TextBlock from '../../components/TextBlockComponent';
import {usePage} from "../../hooks/usePage";

import StyleGeneral from '../../scss/general.module.scss';
import StyleFilter from '../../scss/filter.module.scss';
import StyleBtn from '../../scss/btn.module.scss';
import TittleH6 from '../../components/TittleH6Component';
import Loader from "../../components/LoaderComponent";
import { isIOS, isAndroid } from 'react-device-detect';


const MAX_ITEMS_PER_PAGE = 12;
export default function ShopPage() {

  const { getTextByKey, currentCategories, currentSubcategories, currentSizes, currentColors, currentLines, 
    currentActivities } = useApi();
    const {currentDelivery, tiendaGeneral } = useCart();

  const [searchParams, setSearchParams] = useSearchParams();
  const [products, setProducts] = React.useState([]);
  const {changePage, page, pages, setPages} = usePage(1);
  const [loading, setLoading] = React.useState(true);
  const [isFilter, setIsFilter] = React.useState(false);
  const { currentUser } = useAuth();

  const params = useParams();
  const route = useLocation();
  const paths = [{to: '/tienda', name: 'Tienda'}];
  
  const [ showAlerta, setShowAlerta ] = useState(false);
  const [imgBanner, setImgBanner] = useState([]);
  const [imgBannerMobile, setImgBannerMobile] = useState([]);


  /*useEffect(() => {
       if (!currentUser) {
        location.href = "/checkout-a";
      }
   
  },[])*/



  useEffect(() => {
      console.log('tienda',tiendaGeneral);
    
      /*if(currentUser){
        if(currentUser.isAnonymous){ 
          if (!tiendaGeneral) {
            location.href = "/checkout-a";   
          }
          
        }else{
          if (!tiendaGeneral) {
            location.href = "/direccion-envio";
          }
          
        }
      }else{
        location.href = "/checkout-a";
      }*/
    
    setLoading(true);
    if (Object.keys(params).length > 0) {
      if (params.categoria == 0 && params.subcategorias == 0 ) {
        setIsFilter(false);
      } else {
        setIsFilter(true);
      }
    } else {
      setIsFilter(false);
    }
    console.log('params',params,localStorage.getItem('cart_tienda'));
    params.tienda=localStorage.getItem('cart_tienda');
    getProducts({
          ...params,
          limit: MAX_ITEMS_PER_PAGE,
          page:page,
          ...Object.fromEntries([...searchParams])}
    ).then(({productos: data, pages: pages_}) => {

      setTimeout(() => {
        setLoading(false);
      }, 500);

      setPages(pages_);

      if (data) {
        //Show only new products
        if(route.pathname == "/sales") {
          data.filter(x => x.nuevo == true);
        } 
        //Show only products with discount
        if(route.pathname == "/offers") {
          data.filter(x => x.price.precio_sin_descuento != null);
        }
        //Order by filter
        if (params.orden == "price_asc") {
          data.sort((a, b) => a.price.precio - b.price.precio);
        } else if (params.orden == "price_desc") {
          data.sort((a, b) => b.price.precio - a.price.precio);
        } else if (params.orden == "random") {
          data = shuffle(data);
        }
       
        setProducts(data);
      } else {
        setProducts([]);
      }
    });
  }, [params.categorias,params.orden,params.marcas,page]);

  
  useEffect(() => {
    getGallery('banner-producto').then(
        (data) => {
            setImgBanner(data);
        }
    )
  }, []);

  useEffect(() => {
    getGallery('banner-producto-mobile').then(
        (data) => {
            setImgBannerMobile(data);
        }
    )
  }, []);


  const handleDelete = () => {
    // elimina el componente
    console.log('elimina el componente');
  }

  const [filter, setFilter] = useState(false);

  /* const openFilter = event => {
    console.log('openFilter');
    setFilter(true);
  }; */
  
  return (
    <Fragment>
      <div className='mb-3 mb-lg-5'>

        {isIOS || isAndroid ? (
          <Fragment>
            {imgBannerMobile.length > 0 &&
                <Banner src={imgBannerMobile[0].image} 
                styleGroup="col-lg-12 text-center"
                alt="Rosmi"
                titleHead={imgBannerMobile[0].names[0].titulo} 
                titlecolor="white-color"
                contentText={imgBannerMobile[0].names[0].descripcion}
                />
            }
          </Fragment>
        ) : (
          <Fragment>
            {imgBanner.length > 0 &&
                <Banner src={imgBanner[0].image} 
                styleGroup="col-lg-12 text-center"
                alt="Rosmi"
                titleHead={imgBanner[0].names[0].titulo} 
                titlecolor="white-color"
                contentText={imgBanner[0].names[0].descripcion}
                />
            }
          </Fragment>
        )}
        

      </div>

      <div className={`container pb-lg-5 ${StyleGeneral.minHeight}`}>
        <div className="row">
          <div className="col-12 col-lg-3">
            <div className={`d-lg-none d-flex justify-content-end ${StyleFilter.opcionMobile}`}>
                <button className={`d-flex align-items-center mb-0 ${StyleBtn.btn} ${ filter == true ? StyleBtn.btnSecundary : '' }`} onClick={() => setFilter(true)}>
                  <span className='me-2'>{getTextByKey("filtrar")}</span>
                  <Icon icon="material-symbols:filter-list" />
                </button>
            </div>
            <div className={`py-lg-3 py-3 py-md-0 d-lg-block ${StyleFilter.stickFilter} ${ filter == true ? StyleFilter.showFilter : StyleFilter.hideFilter }`}>
              <FiltrosComponent />
              <div className='d-lg-none text-center py-3'>
                <button className={StyleBtn.btn} onClick={() => setFilter(false)}>{getTextByKey("shop_hide")}</button>
              </div>
            </div>
        </div>

        <div className="col-12 col-lg-9">
          <div className='mb-5'>
              <div className='container-fluid productos'>
                <div className='counterProducts mb-4'>
                  <TextBlock>{products.length} {getTextByKey("shop_products")}</TextBlock>
                </div>
                <div className='row'>
                  {!loading ?
                    <>
                      {products.length > 0 ?
                        products.map((product, index) => <ProductComponent key={index} product={product} className="col-12 col-md-6 col-lg-4" dummy="product" />)
                        : (
                          <div className="col-12">
                            <TittleH6 styleGroup="text-center quinary-color">{getTextByKey("no_products")}</TittleH6>
                          </div>
                        )
                      }
                    </>
                    : (
                      <Loader></Loader>
                    )}
                </div>
              </div>
              
              </div>
              <div className='pb-3 mb-3 pb-lg-5 mb-lg-5'>
                {products.length > 0 &&
                  <Pagination
                      pages={pages}
                      currentPage={searchParams.get('page') || 1}
                      onPageChange={changePage}
                      onNext={ () => {changePage( page + 1 )} }
                      onPrevious={ () => {changePage( page - 1 )} }
                  />
                }
                
              </div>
          </div>
        </div>
      </div>

      <ModalAlerta showAlerta={showAlerta} setShowAlerta={setShowAlerta} />

    </Fragment>
  )
}
