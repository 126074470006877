import axios from "axios";
import { getEnvVariables } from "../helpers";

const { GOOGLE_API_KEY } = getEnvVariables()

export const getInfoMap = async (infoAddress) => {
    try {
        const URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}&address=${infoAddress}`
        const response = await axios.get(URL);
        return response;
    } catch (error) {
        console.log("Error al consultar informacion de direccion", error);
    }
}

export const getInfoFromAddress = async (address) => {
    try {
        address = decodeURI(address);
        const key = GOOGLE_API_KEY;
        const URL = `https://maps.googleapis.com/maps/api/geocode/json`
        const { data: {results} } = await axios.get(URL, {params: {key, address}});
        return results;
    } catch (error) {
        console.log("Error al consultar informacion de direccion", error);
    }
}

export const getCoordsFromAddress = async (address) => {
    try {
        address = decodeURI(address);
        const key = GOOGLE_API_KEY;
        const URL = `https://maps.googleapis.com/maps/api/geocode/json`
        const { data: {results} } = await axios.get(URL, {params: {key, address}});
        return results.map(item => item.geometry.location);
    } catch (error) {
        console.log("Error al consultar informacion de direccion", error);
    }
}

export default { getInfoMap };