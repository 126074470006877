import React, { useState } from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from "../../../context/apiContext";
import { useEffect } from 'react';
import { Link, useLocation, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

import StyleFilter from '../../../scss/filter.module.scss';
import TittleH6 from '../../../components/TittleH6Component';
import {useForm} from "../../../hooks/useForm";

export default function CheckDropdownComponent(props) {

    const { getTextByKey, currentCategories, currentSubcategories, currentSizes, currentColors, currentLines, currentMarcas, currentActivities, getPropiertyByLocale } = useApi();
    const [filters, setFilters] = React.useState([]);
    const [parametros, setParametros] = React.useState("/tienda");
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const navigate = useNavigate();
    const {formState, onInputChange: onInputChange_} = useForm(
        {
            categorias: params.categorias?.split(',') ?? 0,
            actividad: params.actividad ?? 0,
            colores: params.colores ?? 0,
            talla: params.talla ?? 0,
            linea: params.linea ?? 0,
            marcas: params.marcas?.split(',') ?? 0,
            orden: params.orden ?? "normal"
        }
    );
    const location = useLocation();

    var filtersorder = [
        {
            id: 1,
            nameFilter: 'ordenar',
            list: [
                {'option': 'Ninguno', key: "normal",},
                {'option': 'Aleatorio', key: "random",},
                {'option': 'Últimos', key: "last",},
                {'option': 'Más Vendidos', key: "popular",},
                { 'option': 'Precio: bajo a alto', key: 'price_asc' },
                { 'option': 'Precio: alto a bajo', key: 'price_desc' },
            ]
        }
    ]

    useEffect(() => {
        navigate(`/tienda/${generateQueryWithState()}` + location.search);
    }, [formState]);

    const verifyCheck = (value, stateName) => {

    };

    const onInputChange = (e) => {
        setSearchParams({})
        onInputChange_(e);
    }
    const onChangeRadio = (e) => {}
    const onChangeValue = ({target}) => {
        let values = formState[target.name];
        if (!Array.isArray(formState[target.name]) || formState[target.name][0] == 0) {
            values = [];
        }
        let updatedList = [...values];
        if (target.checked) {
            updatedList = [...values, target.value];
        } else {
            updatedList.splice(values.indexOf(target.value), 1);
        }
        if (!updatedList.length) {
            updatedList = 0;
        }
        onInputChange({target: {value: updatedList, name: target.name}});
    }

    const generateQueryWithState = () => {
        return Object.values(formState).map(v => v?.toString()).join('/');
    }

    const [categories, setCategories] = React.useState([]);
    const [subcategories, setSubCategories] = React.useState([]);

    const getCategoriesFromParent = (id = 0) => {
        if(id == 0) return [];
        return subcategories.filter((f)=> f.parent == id);
    }

    useEffect(() => {
        let getFilters = [];
        if (currentCategories) {
            let newFilters = {
                id: 1,
                key: "categoria",
                nameFilter: "Categoría",
                list: []
            };
            currentCategories.forEach((category) => {
                newFilters.list.push({
                    id: category.id,
                    name: category.names[0].nombre,
                });
            });
            getFilters.push(newFilters);
        }
        if (currentSubcategories) {
            let newFilters = {
                id: 2,
                key: "subcategorias",
                nameFilter: "Subcategoría",
                list: []
            };
            currentSubcategories?.forEach((subcategory) => {
                newFilters.list.push({
                    id: subcategory.id,
                    name: subcategory.names[0].nombre,
                });
            });
            getFilters.push(newFilters);
        }
        if (currentColors) {
            let newFilters = {
                id: 3,
                key: "colores",
                nameFilter: "Color",
                list: []
            };
            currentColors.forEach((color) => {
                newFilters.list.push({
                    id: color.id,
                    name: color.nombre,
                });
            });
            getFilters.push(newFilters);
        }
        if (currentSizes) {
            let newFilters = {
                id: 4,
                key: "talla",
                nameFilter: "Talla",
                list: []
            };
            currentSizes.forEach((size) => {
                newFilters.list.push({
                    id: size.id,
                    name: size.nombre,
                });
            });
            getFilters.push(newFilters);
        }
        if (currentLines) {
            let newFilters = {
                id: 5,
                key: "linea",
                nameFilter: "Linea",
                list: []
            };
            currentLines.forEach((line) => {
                newFilters.list.push({
                    id: line.id,
                    name: line.name[0].nombre,
                });
            });
            getFilters.push(newFilters);
        }
        if (currentLines) {
            let newFilters = {
                id: 5,
                key: "linea",
                nameFilter: "Linea",
                list: []
            };
            currentLines.forEach((line) => {
                newFilters.list.push({
                    id: line.id,
                    name: line.name[0].nombre,
                });
            });
            getFilters.push(newFilters);
        }
        if (currentActivities) {
            let newFilters = {
                id: 6,
                key: "actividad",
                nameFilter: "Actividad",
                list: []
            };
            currentActivities.forEach((actividad) => {
                newFilters.list.push({
                    id: actividad.id,
                    name: actividad.name[0].nombre,
                });
            });
            getFilters.push(newFilters);
        }
        setFilters(getFilters);
        setCategories(currentCategories);
        currentSubcategories.map((item)=> item.parent = item.categorias[0].id ? item.categorias[0].id : 0 );
        setSubCategories(currentSubcategories);
    }, [currentCategories, currentSubcategories, currentSizes, currentColors, currentLines, currentActivities]);

    const verifyCheckStatus = (name, id) => {
        if (Array.isArray(formState[name]))
            return formState[name].includes(id) || formState[name].includes(`${id}`);
        return false;
    }

    const [openItem, setOpenItem] = useState({
        1: false,
        2: false,
        3: false
    });

    const toggleOpenItem = (itemId) => {
        setOpenItem((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };

    const handleCloseItem = (itemId) => {
        scrollToTop();
        setTimeout(() => {
            setOpenItem((prevState) => ({
                ...prevState,
                [itemId]: false,
            }));    
        }, 500);
        
    };

    return (
        <Fragment>
            <div className='px-0'>
                <div className='form-group'>
                    
                    <div className={`${StyleFilter.itemFiltro}`}>
                        <div className={`d-flex align-items-center justify-content-between cursor-pointer`}
                            onClick={() => toggleOpenItem(1)}
                            aria-controls="collapseOne"
                            aria-expanded={openItem[1]}>
                            <TittleH6 styleGroup="fw-bold text-uppercase mb-0">{getTextByKey('titulo_categorias')}</TittleH6>
                            <Icon className={`${StyleFilter.arrow} ${openItem[1] ? StyleFilter.activeArrow : ''}`} icon="material-symbols:keyboard-arrow-down-rounded"></Icon>
                        </div>
                        <Collapse in={openItem[1]}>
                            <div id="collapseOne">
                                <div className='pt-3'>
                                {currentCategories.map((item, subkey)=>    
                                    <label className={`${StyleFilter.containerCheckS}`} key={subkey}>
                                        <small>{getPropiertyByLocale(item.names, "nombre")}</small>
                                        <input type='checkbox' 
                                            name={"categorias"} 
                                            value={item.id} 
                                            checked={verifyCheckStatus("categorias", item.id)} 
                                            onChange={onChangeValue}/>
                                        <span className={StyleFilter.checkmarkS}>
                                            <img src='/img/SVG/check.svg'/>
                                        </span>
                                    </label>
                                )}
                                </div>
                            </div>
                        </Collapse>
                    </div>

                    <hr className='quinary-border m-0'/>

                    <div className={`${StyleFilter.itemFiltro}`}>
                        <div className='d-flex align-items-center justify-content-between cursor-pointer'
                            onClick={() => toggleOpenItem(2)}
                            aria-controls="collapseTwo"
                            aria-expanded={openItem[2]}>
                            <TittleH6 styleGroup="fw-bold text-uppercase mb-0">{getTextByKey('titulo_marcas')}</TittleH6>
                            <Icon className={`${StyleFilter.arrow} ${openItem[2] ? StyleFilter.activeArrow : ''}`} icon="material-symbols:keyboard-arrow-down-rounded"></Icon>
                        </div>
                        <Collapse in={openItem[2]}>
                            <div id="collapseTwo">
                                <div className='pt-3'>
                                {currentMarcas.map((item, subkey)=>
                                    <label className={`${StyleFilter.containerCheckS}`} key={subkey}>
                                        <small>{item.nombre}</small>
                                        <input type='checkbox' 
                                            name={"marcas"} value={item.id} checked={verifyCheckStatus("marcas", item.id)} onChange={onChangeValue}/>
                                        <span className={StyleFilter.checkmarkS}>
                                            <img src='/img/SVG/check.svg'/>
                                        </span>
                                    </label>
                                )}
                                </div>
                            </div>
                        </Collapse>
                    </div>

                    <hr className='quinary-border m-0'/>

                </div>
            </div>
            
            {
                filtersorder && filtersorder.map((filter, index) => {
                    return (
                        <div key={index}>
                            <div className={StyleFilter.itemFiltro} key={filter.id}>
                                <div className='d-flex align-items-center justify-content-between cursor-pointer'
                                    onClick={() => toggleOpenItem(3)}
                                    aria-controls="collapseThree"
                                    aria-expanded={openItem[3]}>
                                    <TittleH6 styleGroup="fw-bold text-uppercase mb-0">{getTextByKey('titulo_ordenar')}</TittleH6>
                                    <Icon className={`${StyleFilter.arrow} ${openItem[3] ? StyleFilter.activeArrow : ''}`} icon="material-symbols:keyboard-arrow-down-rounded"></Icon>
                                </div>
                                <Collapse in={openItem[3]}>
                                    <div id="collapseThree">
                                        <div className='pt-3'>
                                        {filter.list.map((data, indexfilter) => {
                                            return (
                                                <label className={`${StyleFilter.containerCheckS}`} key={indexfilter}>
                                                    <small>{data.option}</small>
                                                    <input type="radio" name="orden" checked={formState['orden'] == data.key} value={data.key} onChange={onInputChange}/>
                                                    <span className={StyleFilter.checkmarkS}>
                                                        <img src='/img/SVG/check.svg'/>
                                                    </span>
                                                </label>   
                                            )
                                        })}
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    )
                })
            }
            
        </Fragment>
    )
}
