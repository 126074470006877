import React from "react";
import styleText from "../scss/text.module.scss";


const TextBlockSpecial = ({ children, styleGroup }) => {
  
  return (
    <blockquote className={`${styleGroup} ${styleText.specialText}`}><p>{children}</p></blockquote>
  );
};

export default TextBlockSpecial;