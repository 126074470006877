import React, { useState, useEffect, Fragment } from "react";
import { useApi } from "../../context/apiContext";
import { images_products_route } from "../../api/productApi";
import { getImages } from "../../api/imageApi";
import StyleGeneral from "../../scss/general.module.scss";
import TittleH6 from "../../components/TittleH6Component";


import "../../scss/404page.scss";


import { Link } from "react-router-dom";

export default function Error404Page() {
  const [errorPage, setErrorPage] = useState([]);
  const { getTextByKey, getTextBigByKey, currentLocale } = useApi();

  useEffect(() => {
    getImages("error_page404").then((data) => {
      setErrorPage(data);
      // console.log("error >>> ", data);
    });
  }, []);

  return (
    <Fragment>
      {errorPage.length > 0 && (
        <div
        className={`position-relative w-100 h-100 ${StyleGeneral.minHeightScale} ${StyleGeneral.backgroundSize}`}
        style={{
          backgroundImage: `url(${images_products_route + errorPage[0].image
          })`,
        }}
        >
          
          <div className="error-404-page">
            <div className="container">
              <div className="d-flex justify-content-center">
                <div className={`d-flex align-items-center ${StyleGeneral.d_f_100}`}>
                  <div className="d-flex flex-column">
                    <span className="white-color carrefourMetis-bold title-error mb-2 fw-normal">
                      <div dangerouslySetInnerHTML={{__html: errorPage[0].names[0].titulo,}}></div>
                    </span>
                    <span className="white-color carrefourMetis-bold text-ops mb-2 fw-bold">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: errorPage[0].names[0].descripcion,
                        }}
                      ></div>
                    </span>
                    <span className="white-color page-not-found mb-2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: errorPage[0].names[1].titulo,
                        }}
                      ></div>
                    </span>
                    <p className="mt-4 white-color">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: errorPage[0].names[1].descripcion,
                        }}
                      ></div>
                    </p>
                    <div className="mt-5">
                      <Link to="/" className="btn-g py-2">
                        {getTextByKey("error_salir")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
