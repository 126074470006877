import axios from 'axios'
import { setupCache,buildWebStorage  } from 'axios-cache-interceptor';
import { getEnvVariables } from '../helpers'


const { API_URL, TokenAPI } = getEnvVariables()

let sellyApi = axios.create({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': '*',
  'Access-Control-Allow-Headers': 'Content-Type',
  baseURL: API_URL,
  withCredentials: true,
  TokenAPI: TokenAPI,
  
})

sellyApi.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
  }
  return config
})

/* para deshabilitar el cache se puede hacer desde el request envianto el parametro ttl en millis, ejemplo

const { data } = await sellyApi.get(`/user?usuario=${uid}`,{id:"getUsuario",cache: {
      ttl: 10, // 10 millis.
    }})
*/
sellyApi = setupCache(sellyApi,{ 
  storage: buildWebStorage(localStorage, 'axios-cache:'),
  ttl: 1000 * 60 * 10,
  interpretHeader: false
  
});


export default sellyApi
