import sellyApi from './sellyApi'

export const getImages = async (key) => {
  try {
    const data = await sellyApi.get(`/image/${key}`).catch(() => {
      return false;
    })
    return data.data;
    console.log('images', data)
  } catch (error) {
    return false;
    console.log('Error obteniendo imagen', error)
  }
}

export default { getImages }
