import sellyApi from './sellyApi'

export const getGallery = async (key) => {
  try {
    const data = await sellyApi.get(`/gallery/${key}`)
    let ordened = data.data.sort((a, b) => a.order - b.order);
    return ordened;
  } catch (error) {
    return error;
  }
}

export default { getGallery }
