import { Fragment } from "react";
import { Link } from "react-router-dom";
import '../../scss/tarjeta_regalo.scss'

export default function TarjetaaPage() {
  return (
    <Fragment>
        <div className="banner tarjeta-regalo position-relative">
            <img className="w-100" src="img/Group-138.jpg"/>
            <div className="content-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="pb-2 pb-lg-5">
                                <h2 className="text-uppercase">TARJETA DE REGALO</h2>
                            </div>
                            <div className="pb-2 pb-lg-4 mb-3 mb-md-0">
                                <Link to="/tarjeta-b" className="btn-g py-2 px-4 text-uppercase border-0">compra una tarjeta de regalo virtual</Link>
                            </div>
                            <div className="pb-lg-4 mb-3 mb-md-0">
                                <Link to="/tarjeta-c" className="btn-g py-2 px-4 text-uppercase border-0">revisa tu saldo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </Fragment>
  )
}
