import React, { Component } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { validateCoupon } from '../api/productApi';
import { useApi } from "../context/apiContext";
import { useCart } from '../context/cartContext';
import { Icon } from '@iconify/react';
import { images_products_route } from '../api/productApi';
import { getImages } from '../api/imageApi';
import TextBlock from './TextBlockComponent';

import StyleGeneral from '../scss/general.module.scss';
import StyeleButton from '../scss/btn.module.scss';
import StyleFilter from '../scss/filter.module.scss';

import TittleH6 from './TittleH6Component';

export function BolsaComponent(){
  const { getTextByKey, getTextBigByKey, convertCurrency, getPropiertyByLocale } = useApi();
  const { calculateDescuento, total, envio, descuento, coupon, impuesto,updateTiempoEntrega,tiempoentrega ,calculateSubtotal,subtotal, impuestoBolsa, pesoTotal, pesoXbolsa, updateCoupon, currentCart, applyCoupon } = useCart();
  //const [loadingCoupon, setLoadingCoupon] = useState(false);
  //const [couponMsg, setCouponMsg] = useState("");
  const [logoEpayco, setLogoEpayco ] = useState([]);
  //const [ bolsa, setBolsa ] = useState(1);

  useEffect (() => {
    /*setBolsa(Math.round(pesoTotal/pesoXbolsa));
    if(bolsa == 0 ){
      setBolsa(1);
    }
    console.log('bolsa', {bolsa, pesoTotal, pesoXbolsa});*/
    calculateDescuento();
    calculateSubtotal();
 }, []);
  
 const handleTiempo=(e)=>{
  console.log('entro a cambiar');
   updateTiempoEntrega(e.target.value);
   console.log(tiempoentrega);
 }
 
  useEffect(()=>{
    getImages('epayco').then((data) =>{
      console.log('epayco', data);
      setLogoEpayco(data);
    });
  },[]);

  /*const validateCodeCoupon = () => {
    setLoadingCoupon(true);
    setCouponMsg("");
    const coupon = document.getElementById('coupon').value;
    validateCoupon(coupon).then((res) => {
      setLoadingCoupon(false);
      console.log(res);
      if(Object.keys(res).length == 0){
        setCouponMsg("Cupón no valido para esta compra");
      }else{
        setCouponMsg("Cupón aplicado");
        updateCoupon({coupon: coupon, descuento: res.descuento, porcentaje: res.porcentaje});
      }
    });
  }*/



  
   /*
  const deleteCodeCoupon = () => {
    updateCoupon(0);
  }*/

    return (
      <div className='box-check bg-white pt-3 px-4'>
        <div className='py-2'>
          <TittleH6 styleGroup="fw-bold primary-color">{getTextByKey('titulo_bolsa')}</TittleH6>
        </div>

        <div className="bolsa-checkout">

          <div className='border px-2 py-3'>
            <TextBlock styleGroup="d-flex justify-content-between align-items-center px-3 px-lg-4">
              <span className='fw-bold w-75'>{getTextByKey('producto-titulo-tabla')}</span>
              <span className='w-25'>{getTextByKey('precio-titulo-tabla')}</span>
            </TextBlock>
            
            <div className="products-table gray-2-bg-color">
              {
                  currentCart.map((item, index) => (

                    <TextBlock styleGroup="d-flex justify-content-between align-items-center px-3 px-lg-4" key={index}>
                      <span className='w-75 pe-4'>
                        <span className='fw-bold'>{item.name.nombre}</span>
                        <span><strong> x{item.quantity}</strong></span>
                      </span>
                      <span className='w-25'>{convertCurrency((item.price.precio)*item.quantity)}</span>
                    </TextBlock>
                  
                  ))
              }
            </div>
            
            <TextBlock styleGroup="d-flex justify-content-between align-items-center px-3 px-lg-4">
              <span className='fw-bold w-75'>{getTextByKey('subtotal')}</span>
              <span className='w-25'>{convertCurrency(subtotal.toFixed(0))}</span>
            </TextBlock>
            {/*descuento>0 &&
             <TextBlock styleGroup="d-flex justify-content-between align-items-center px-3 px-lg-4">
              <span className='fw-bold w-75'>{getTextByKey('descuento')}</span>
              <span className='w-25'> {convertCurrency(descuento)}</span>
            </TextBlock>
            */}
           
            {coupon!=0 &&
            <TextBlock styleGroup='d-flex justify-content-between align-items-center px-3 px-lg-4'>
            <span className="fw-bold w-75">{getTextByKey('cupon-descuento-text')}</span>
            <span className='w-25'>{convertCurrency(applyCoupon(total).toFixed(0))}</span>
          </TextBlock>
            }
            
            <TextBlock styleGroup="d-flex justify-content-between align-items-center px-3 px-lg-4">
              <span className='fw-bold w-75'>{getTextByKey('impuestos')}</span>
              <span className='w-25'> {convertCurrency((impuesto).toFixed(0))}</span>
            </TextBlock>
            
            <TextBlock styleGroup="d-flex justify-content-between align-items-center px-3 px-lg-4">
              <span className='w-75 fw-bold'>{getTextByKey('envio')}</span>
              <span className='w-25'>{(envio==0) ? getTextByKey('gratis-envio'): convertCurrency(envio)}</span>
            </TextBlock>
            
      

            <TextBlock styleGroup="d-flex justify-content-between align-items-center px-3 px-lg-4">
              <span className='fw-bold w-75'>{getTextByKey('total')}</span>
               <span className='fw-bold w-25'>{convertCurrency((total-applyCoupon(total)+envio).toFixed(0))}</span>
            </TextBlock>

          </div>
          
          
        {/* {coupon != 0 && (
          <tr>
            <td className='text-uppercase'>{getTextByKey('cupon-text')}</td>
            <td className='text-end'>-{convertCurrency(coupon.descuento)}</td>
          </tr>
        )} */}
          <div className='pt-3'>
            <form action="">
              <div className="form-group mb-4 position-relative d-flex justify-content-between aling-items-stretch">
                {/*{coupon != 0 ? (
                  <input type="text" className="p-3 input-cupon" value={coupon.coupon} disabled/>
                ):(
                  <input className={`w-100 border bg-white py-2 px-3 me-3 ${StyleGeneral.boxShadow} ${StyleGeneral.radius50}`} id="coupon" type="text" placeholder={getTextByKey('codigo_cupon')}/>                     
                )}*/}
                  
                {/* {loadingCoupon ? (
                  <div className="btn-g bb py-2 px-3" role="button"><div className='spinner-border spinner-border-sm' role='status'></div></div>
                ) : (
                  <>
                  {coupon != 0 ? (
                    <button className={`border-0 ${StyeleButton.btn}`} role="button" onClick={deleteCodeCoupon}>{getTextByKey('borrar_cupon')}</button>
                  ): (
                    <button className={`border-0 ${StyeleButton.btn}`} role="button" onClick={validateCodeCoupon}>{getTextByKey('aplicar_cupon')}</button>
                  )}
                  </>
                )} */}
              </div>
              {/*{couponMsg != "" && (
              <div className="form-group mb-2 position-relative">
                {couponMsg}
              </div>
              )}*/}

              <div className='py-2'>
                <TittleH6 styleGroup="fw-bold primary-color">{getTextByKey('titulo_tiempo_entrega')}</TittleH6>
              </div>

              <div className='frorm-group'>
                <label className={`w-auto py-1 ${StyleFilter.containerCheckS}`}>
                    <small className='ms-1'>1 {getTextByKey('texto-dia-checkout')}</small>
                    <input type='radio' checked={tiempoentrega==1}
                        name='entrega'
                        value='1' onChange={handleTiempo}/>
                    <span className={StyleFilter.checkmarkS}>
                        <img src='/img/SVG/check.svg'/>
                    </span>
                </label>
                <label className={`w-auto py-1 ${StyleFilter.containerCheckS}`}>
                    <small className='ms-1'>2 {getTextByKey('texto-dias-checkout')}</small>
                    <input type='radio' checked={tiempoentrega==2}
                        name='entrega'
                        value='2' onChange={handleTiempo} />
                    <span className={StyleFilter.checkmarkS}>
                        <img src='/img/SVG/check.svg'/>
                    </span>
                </label>
                <label className={`w-auto py-1 ${StyleFilter.containerCheckS}`}>
                    <small className='ms-1'>3 {getTextByKey('texto-dias-checkout')}</small>
                    <input type='radio' checked={tiempoentrega==3}
                        name='entrega' 
                        value='3' onChange={handleTiempo} />
                    <span className={StyleFilter.checkmarkS}>
                        <img src='/img/SVG/check.svg'/>
                    </span>
                </label>
              </div>

              <div className='py-2'>
                <TittleH6 styleGroup="fw-bold primary-color">{getTextByKey('titulo_metodo_pago')}</TittleH6>
              </div>

              <div className='form-group text-center'>
                <img src='/img/metodos_pago.jpg' className='w-75'/>
              </div>

            </form>
          </div>

        </div>
      </div>
    )
}