import React, { Fragment, useRef } from 'react'
import { Link } from 'react-router-dom';
import { BolsaComponent } from '../../components/Bolsa';
import SelectDireccionComponent from './components/SelectDireccionComponent';
import StepbyStepComponent from './components/StepbyStepComponent';
import MetodoPagoComponent from '../PerfilPage/components/MetodoPagoComponent';
import { useApi } from "../../context/apiContext";
import FormPagoComponent from './components/FormPagoComponent';
import PaypalComponent from './components/PaypalComponent';
import { useState } from 'react';
import { useEffect } from 'react';
import { createOrder, getBanks, getTarjetas, pagarOrderPSE, pagarOrderWompi } from '../../api/productApi';
import { useAuth } from '../../authContext';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../context/cartContext';
import Modalmetodo from '../PerfilPage/components/Modalmetodo';
import { FormularioDireccion } from '../../components/FormularioDireccion';
import { DatosEnvio } from '../../components/DatosEnvioComponent';

import { images_products_route } from '../../api/productApi';
import { getImages } from '../../api/imageApi';
import  {default as ModalConfimacion} from './components/ModalConfirmacion';
import { getUser } from '../../helpers/userHelper';

import TittleH1 from '../../components/TittleH1Component';
import TittleH6 from '../../components/TittleH6Component';
import StyleText from '../../scss/text.module.scss';
import StyleButton from '../../scss/btn.module.scss';
import TextBlock from '../../components/TextBlockComponent';
import { AlertComponent } from "../../components/AlertComponent";
import ButtonLink from '../../components/ButtonLinkComponent';

const CheckoutcPage = () => {
  //TODO revisar flujos de pagos

  const [tarjetas, setTarjetas] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selectedCard, setSelectedCard] = useState(0);
  const [order, setOrder] = useState({});
  const { getTextByKey, getTextBigByKey, currentCurrency, currentLocale } = useApi();
  const { currentUser } = useAuth();
  const { total, envio, creditBenefitType,subtotal,impuesto,coupon,descuento, direccion, currentCart, clearCart, applyCoupon,tiempoentrega } = useCart();
  const [method, setMethod] = useState("select");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [nombreUser, setNombreUser] = useState("");
  const [dirUser, setDirUser] = useState("");
  const [celularUser, setCelularUser] = useState("");
  const [emailUser, setEmailUser] = useState("");
  const [documentoUser, setDocumentoUser] = useState("");
  const [tipoDocUser, setTipoDocUser] = useState("");
  const [llavepublica, setLlavePublica] = useState("");
  const [secret, setSecret] = useState("");
  const [compraid, setCompraId] = useState("");

  const [cuotas, setCuotas] = useState(1);
  const [imgSecurity, setImgSecurity ] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const formRef = useRef(null);
  const protocolo = window.location.protocol;
  const dominio = window.location.hostname;
 

  const handleTarjeta = (card) => {
    setSelectedCard(card);
  }

  const changeTarjeta = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedCard(0);
  }

  useEffect(() => {
    getImages("checkout-pagos").then((data)=> {
      if (data) {
        setImgSecurity(data);
      }
    });
  },[])
  useEffect(() => {
    if (!currentUser) {
      location.href = "/checkout-a";
    }

  }, []);

  useEffect(() => {
  try {
    getUser(currentUser?.uid).then(async({ user }) => {
        console.log("Usuario: ", user);
        setNombreUser(user.nombre);
        setCelularUser(user.celular);
        setDirUser(user.direccion);
        setEmailUser(user.email);
        setTipoDocUser(user.tipodoc);
        setDocumentoUser(user.documento);
             
    })
  } catch (error) {
    console.log('error', error)
    }
  },[])

  // useEffect(() => {
  //   setTarjetas([]);
  //   getTarjetas(currentUser.uid).then((response) => {
  //     setTarjetas(response);
  //   });
  //   getBanks().then((response) => {
  //     console.log("Banks", response);
  //     setBanks(response);
  //   });
  // }, []);

  const createCheckoutOrder = async () => {
    setLoading(true);

    if(!direccion || direccion==0){
      setLoading(false);
      setErrors('Debe tener una dirección de envio');
      handleShowModal(true);
      return;
    }
    order.uid = currentUser.uid;
    order.delivery_cost = envio;
    order.subtotal = parseFloat(subtotal.toFixed(0));
    order.total = parseFloat((total-(applyCoupon(total))+envio).toFixed(0));
    order.address = parseInt(direccion);
    order.billing = parseInt(direccion);
    order.taxes= parseFloat(impuesto.toFixed(0));
    order.descuentos=descuento;
    order.descuento=coupon ? coupon.coupon :0;
    order.creditBenefitType = creditBenefitType;
    //order.card = selectedCard;
    order.method = 'ONLINE';
    order.buyItems = [];
    order.comments = "";
    order.currency = currentCurrency;
    order.tiempoentrega=tiempoentrega;

    currentCart.forEach((item) => {
      if (item.type === "giftcard") {
        order.buyItems.push({
          productId: item.id,
          type: item.type,
          price: item.price,
          quantity: item.quantity,
          comments: '',
          discount: 0,
          toppings: '',
          promotionId: '',
        });
      } else {
        order.buyItems.push({
          productId: item.plu,
          type: item.type,
          price: item.price.precio_sin_descuento ? item.price.precio_sin_descuento*item.quantity  : item.price.precio*item.quantity,
          quantity: item.quantity,
          comments: '',
          discount: item.price.precio_sin_descuento ? (item.price.precio_sin_descuento-item.price.precio)*item.quantity  :0,
          toppings: '',
          promotionId: '',
          products: item.products ? item.products : []
        });
      }

    });
     console.log('aca' ,order);
    createOrder(order).then((response) => {
      setLoading(false);
      // console.log('response: ', response)
      if (!response.compra.id) {
        setErrors("Error al crear la orden");
        handleShowModal(true);
      } else {
        setCompraId(response.compra.id);
        setLlavePublica(response.llavepub);
        setSecret(response.key);
      }
    }).catch((error) => {
      setLoading(false);
      console.log('error: ', error)
      setErrors(error.message);
      handleShowModal(true);
    })
  }
  const handleShowModal = () => setShowAlert(true);
  const handleCloseModal = () => setShowAlert(false);

  const handleResponse = (response) => {
    console.log("PAYPAL", response);
    if (response.status == "COMPLETED") {
      createCheckoutOrderPaypal(response);
    } else {
      setError("Error al pagar la orden por Paypal, intenta nuevamente");
    }
  }

  const [ showModal, setShowModal ] = useState(false);
  const openModal = () => {
    setShowModal(true);
  }

  return (
    <Fragment>
      
      <div className="py-lg-4 py-3 checkout gray-2-bg-color">
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className="text-center mb-3 mb-lg-5">
                  <TittleH1 styleGroup={`primary-color  autography mb-0 ${StyleText.h4Special}`}>{getTextByKey('realizar-pedido-titulo')}</TittleH1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
             
              <div className='bg-white border border-2 p-4 p-md-2 p-md-4 p-lg-5 mb-3 mb-lg-5'>
                <TittleH6 styleGroup="primary-color fw-bold mb-2 mb-lg-4">{getTextByKey('dir_send')}</TittleH6>
                { currentUser?.isAnonymous ? <></>:(
                  <div className='mb-4'>
                    <SelectDireccionComponent step={3} />
                  </div>)}
               
                  { currentUser?.isAnonymous ? (
                    !direccion ?
                    <DatosEnvio />
                   :
                   (<p>{direccion}</p>)

                 ):(
                  <FormularioDireccion />
                 )
                }
              

                
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="mt-lg-0 mt-5">
                <div className="bg-white mb-5 border border-2 p-2 p-md-4 p-lg-4 mb-3 mb-lg-5">
                  <BolsaComponent />
               
                <div className="p-3 rounded my-4">
                  <div className='quaternary-bg-color p-2 rounded d-flex align-items-start'>
                    <div className='icon-alert me-2 text-light'>
                      <Icon icon="la:exclamation-circle"></Icon>
                    </div>
                    <TextBlock styleGroup="text-light mb-0">
                      <small dangerouslySetInnerHTML={{ __html: getTextBigByKey("alerta-checkout-1") }}></small>
                    </TextBlock>
                  </div>
                  
                  <form action="" className='py-3'>
                    <label className="d-flex align-items-center radio-button-container">
                      {/* <input type="radio"/> */}
                      <Link to='/aviso-de-privacidad' className='d-flex'>
                        <div className='gray-1-color mb-0 sm ms-1 terminos' dangerouslySetInnerHTML={{__html: getTextBigByKey('he_leido') }}></div>
                      </Link>
                    </label>
                  </form>

                </div>

                <div className="text-center">
                  <button className={`border-0 ${StyleButton.btn}`} onClick={createCheckoutOrder}>{getTextByKey('realizar-pedido-titulo')}</button>
                  {/*<ButtonLink to="/payment-success" >{getTextByKey('realizar-pedido-titulo')}</ButtonLink>*/}
                </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>

        <form ref={formRef} action="https://checkout.wompi.co/p/" method="GET">
 
          <input type="hidden" name="public-key" value={llavepublica} />
          <input type="hidden" name="currency" value="COP" />
          <input type="hidden" name="amount-in-cents" value={(total*100)} />
          <input type="hidden" name="reference" value={compraid} />
          {/*<input type="hidden" name="signature:integrity" value={secret}/>*/}
          <input type="hidden" name="redirect-url" value={`${protocolo}//${dominio}/payment-success`}/>
          <input type="hidden" name="tax-in-cents:vat" value={(impuesto*100)} />
          <input type="hidden" name="customer-data:email" value={emailUser} />
          <input type="hidden" name="customer-data:full-name" value={nombreUser} />
          <input type="hidden" name="customer-data:phone-number" value={celularUser} />
          <input type="hidden" name="customer-data:legal-id" value={documentoUser} />
          <input type="hidden" name="customer-data:legal-id-type" value={tipoDocUser==2 ? 'CC' : 'NIT'} />
          <input type="hidden" name="shipping-address:address-line-1" value={dirUser} />
          <input type="hidden" name="shipping-address:country" value="CO" />
      
      

        </form>
        <AlertComponent title="Error" msn={errors} type="error"
                                            showAlert={showAlert}
                                            handleShowModal={handleShowModal}
                                            handleCloseModal={handleCloseModal}/>
        <Modalmetodo />

        {/* <ModalConfimacion showModal={showModal} setShowModal={setShowModal} /> */}

      </div>
    </Fragment>
  )
}

export default CheckoutcPage