import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../../context/apiContext";

export default function TarjetacPage() {

  const { getTextByKey } = useApi();

  return (
    <Fragment>
        <div className="banner tarjeta-regalo position-relative">
          <img className="w-100 fondo" src="img/Group-138.jpg"/>
          <div className="content-banner p-3 p-md-0" /* style={{ backgroundImage: `url("img/Group-138.jpg")`}} */>
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-lg-6">
                          <div className="pb-2 pb-lg-5">
                              <h2 className="text-uppercase">{getTextByKey('title-saldo-gitfcard')}</h2>
                          </div>
                          <div className="pb-2 pb-lg-4">
                              <form className="pe-lg-5">
                                <div className="form-group mb-2 mb-lg-4">
                                  <label>{getTextByKey('code-giftcard')}</label>
                                  <input type="text" className="p-2"/>
                                </div>
                                {/* <div className="form-group mb-2 mb-lg-4">
                                  <label>código</label>
                                  <input type="text" className="p-2"/>
                                </div> */}
                                <div className="form-group text-center">
                                  <Link to="/tarjeta-d" className="btn-g py-2 px-4 text-uppercase">{getTextByKey('checkt-balance-giftcard-btn')}</Link>
                                </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div> 
    </Fragment>
  )
}
