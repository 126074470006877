import React, { Fragment } from 'react';
import { Icon } from '@iconify/react';

import StyleBtn from '../scss/btn.module.scss';
import StylesQuickCar from '../scss/quickCar.module.scss';

import ItemCarrito from './ItemCarrito';
import QuantyControls from './QuantyControls';
import { Link } from 'react-router-dom'
import { useCart } from "../context/cartContext";
import { useEffect, useState } from 'react';
import { useApi } from "../context/apiContext";
import { useAuth } from '../authContext'
import CheckoutFastPage from '../pages/CheckoutPage/CheckoutFastPage'
import TittleH6 from './TittleH6Component'
import TextBlockSmall from './TextBlockSmallComponent';

import scrollToTop from './GlobalFunction';

export default function CarritoComponent() {

    const { getTextByKey, convertCurrency, address, cards } = useApi();
    const { currentCart, cartIsShowing, hideCart, total, descuento, impuesto,calculateSubtotal,tiendaGeneral, calculateTotal, calculateDescuento, calculateImpt } = useCart();
    const { currentUser } = useAuth();

    const [oneClick, setOneClick] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [cardss, setCardss] = useState([]);

    const closeCart = event => {
        hideCart();
        scrollToTop();
    };

    useEffect(() => {
        calculateImpt();
        calculateDescuento();
        calculateTotal();
        calculateSubtotal();
      }, [tiendaGeneral, currentCart]);

    return (
        cartIsShowing && (
            <div className={`p-4 ${StylesQuickCar.showQuickCar} ${StylesQuickCar.quickCar}`} id="carrito-rapido">
                
                <div className={`d-flex justify-content-between align-items-center p-2 ${StylesQuickCar.headCart}`}>
                    <TittleH6 styleGroup={`primary-color fw-bold mb-0 ${StylesQuickCar.title}`}>
                        {getTextByKey('titulo_compra')}
                    </TittleH6>
                    <a className={`close primary-color ${StylesQuickCar.close}`} onClick={closeCart}><Icon icon="carbon:close"/></a>
                </div>

                <div className={!oneClick ? `${StylesQuickCar.contentCart}` : 'content-carrito min'}>
                    {currentCart.map((item, index) => (
                        
                        <ItemCarrito key={index} index={index} item={item} min={oneClick} />
                    ))}
                    <div className='text-center'>
                        {currentCart.length === 0 && <p className='primary-color '>{getTextByKey('sin_productos')}</p>}
                    </div>
                </div>

                <div className={StylesQuickCar.footerCart}>
                    {
                        currentCart.length > 0 && (
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <TextBlockSmall styleGroup="gray-1-color mb-0">{getTextByKey('quote_codigos_descuento')}</TextBlockSmall>
                                    </div>
                                    <div className='col-12'>
                                        <TextBlockSmall styleGroup="gray-1-color mb-0">{getTextByKey('compra_minima')}</TextBlockSmall>
                                    </div>
                                    {/*<div className='col-12 d-flex justify-content-between align-items-center py-1'>
                                        <TextBlockSmall styleGroup="fw-bold gray-1-color mb-0">{getTextByKey('descuento')}</TextBlockSmall>
                                        <TextBlockSmall styleGroup="fw-bold gray-1-color mb-0">{convertCurrency(descuento)}</TextBlockSmall>
                                      </div>*/}
                                    <div className='col-12 d-flex justify-content-between align-items-center py-1'>
                                        <TextBlockSmall styleGroup="fw-bold gray-1-color mb-0">{getTextByKey('subtotal')}</TextBlockSmall>
                                        <TextBlockSmall styleGroup="fw-bold gray-1-color mb-0">{convertCurrency(total)}</TextBlockSmall>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className='d-flex justify-content-center align-items-center'>

                        {currentUser? 
                            <Fragment>
                                    <button onClick={closeCart} className={`me-2 ${StyleBtn.btnSecundary} ${StyleBtn.btnSmall}`}>{getTextByKey('continuar_comprando')}</button>
                                    <Link to="/checkout-b" onClick={closeCart} className={`text-center ${StyleBtn.btn} ${StyleBtn.btnSmall}`}>
                                            {getTextByKey('ver_carrito')}
                                    </Link>
                            </Fragment>
                        :
                            <Fragment>
                                    <button onClick={closeCart} className={`me-2 ${StyleBtn.btnSecundary} ${StyleBtn.btnSmall}`}>{getTextByKey('continuar_comprando')}</button>
                                    <Link to="/checkout-a" onClick={closeCart} className={`text-center ${StyleBtn.btn} ${StyleBtn.btnSmall}`}>
                                            {getTextByKey('ver_carrito')}
                                    </Link>
                            </Fragment>
                        }
                    
                    </div>

                </div>
            </div>
        )
    )
}
