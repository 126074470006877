import React, { Fragment } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { getOrderPSE,byStatus } from '../../api/productApi'
import RecomendadosSliderComponent from '../../components/RecomendadosSliderComponent'
import { listOrdersUser,StatusOrdersUser } from "../../api/ordersApi";
import { useApi } from '../../context/apiContext'
import { useCart } from '../../context/cartContext';
import TittleH1 from '../../components/TittleH1Component'

import StyleText from '../../scss/text.module.scss';
import StyleGeneral from '../../scss/general.module.scss';
import StyleButton from '../../scss/btn.module.scss';
import TextBlock from '../../components/TextBlockComponent'
import ButtonLink from '../../components/ButtonLinkComponent'
import TittleH5 from '../../components/TittleH5Component'
import { useEffect } from 'react'
import { useState } from 'react'
import ReactGA from 'react-ga4';



export default function PagoExitosoPage() {

  const [order, setOrder] = React.useState(0)
  const [msgOrder, setMsgOrder] = React.useState('Verificando Pago...');
  const [loading, setLoading] = React.useState(false);
  const [compraid, setCompraId] =useState(0);
  const [datacompra, setDataCompra]=useState(0);
  const [estado, setEstado] =useState(0);
  const {  clearCart} = useCart();
 
  const { getTextByKey, currentLocale } = useApi();
  const [ status, setStatus ] = useState('');

  useEffect(() => {
    /*console.log(location)
    if (location.state) {
      setOrder(location.state.order);
      setMsgOrder(location.state.msg);
    }*/
    //if(location.search){
      setLoading(true);
      const params = new URLSearchParams(location.search);
      const id = params.get('compraid');
    
      setCompraId(id);
      setLoading(false);
      /*getOrderPSE(id).then((res) => {
        console.log(res);
        if(res.data.status){
          setOrder(res.data.reference);
          if(res.data.status == "APPROVED"){
            setMsgOrder( getTextByKey('compra-aprobada') );
          }else if(res.data.status == "PENDING"){
             setMsgOrder( getTextByKey('compra-pendiente') );
          }else{
            setMsgOrder( getTextByKey('compra-rechazada') );
          }
        }
        setLoading(false);
      });*/
   //}
  }, [])

  const obtenerestado=(compraid)=>{
    console.log('compra',compraid);
    byStatus(compraid).then((data)=>{
      if(data.estado!="PAGO EN VALIDACION"){
        setStatus(1);
        if(data.estado == "APROBADO"){
          clearCart();
          setMsgOrder( getTextByKey('compra-aprobada') );
        }else if(data.estado == "PENDIENTE"){
          setMsgOrder( getTextByKey('compra-pendiente') );
        }else{
          setMsgOrder( getTextByKey('compra-rechazada') );
        }
        setEstado(data);
      }
    
    }).catch((error)=>{

    })
  }
 const backShop=()=>{
  location.href='/tienda';
 }
  useEffect(() => {
    console.log('status..');
   const intervalId = setInterval(() => {
      if (compraid !== 0 && status === '') {
        setStatus('');
        obtenerestado(compraid);
        ObtenerCompra(compraid);
      }
    }, 10000); 

    return () => {
      clearInterval(intervalId);
    };
  }, [compraid, status]);

  const ObtenerCompra=(compraid)=>{
    listOrdersUser('', currentLocale,compraid).then((response)=>{
        setDataCompra(response);
    })                  
      .catch ((error)=> {
      console.log('Error: ', error);
    });
  }

  useEffect(() => {
    if(datacompra && datacompra?.estadoCompra=='APROBADO'){
       let itemscar=[];
      datacompra?.product_items?.forEach((item) => {
        itemscar.push({
          item_id:item.plu,
          item_name: item?.nombre,
          price: item?.precio,
          quantity: item.cantidad

        }) 
      })

      ReactGA.event({
        category:'ecommerce',
        action: 'purchase',
        transaction_id:datacompra?.id,
        value: datacompra?.total,
        shipping:datacompra?.costo_domicilio,
        currency: "COP",
        items :itemscar
      });
    }
  },[datacompra]);

  const convertDateAndHourFromIso = (date) => {
    const year = parseInt(date.substring(0, 4), 10);
    const month = parseInt(date.substring(5, 7), 10) - 1;
    const day = parseInt(date.substring(8, 10), 10);
  
    const dateObj = new Date(year, month, day);
    console.log(dateObj)
    const dateStr = dateObj.toLocaleDateString();
    //const hourStr = dateObj.toLocaleTimeString();
    return `${dateStr}`;
  }

  const convertDateFromIso = (date) => {
   
    const dateStr = date.toLocaleDateString();
    //const hourStr = dateObj.toLocaleTimeString();
    return `${dateStr}`;
  }

  return (
    <Fragment>
      {!loading ? (
        <div className={`gray-2-bg-color ${StyleGeneral.minHeight}`}>
          <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <div className='py-2 py-lg-5 mb-0'>
                    <TittleH1 styleGroup={`autography primary-color text-center ${StyleText.h4Special}`}>{msgOrder}</TittleH1>
                  </div>
                </div>
              </div>
              
              <div className='row'>
                <div className='col-lg-8 offset-lg-2'>
                  <div className='p-4 bg-white mb-2 mb-lg-5 border border-2'>
                    {/*<TextBlock styleGroup="mb-0 quinaryC-bg-color p-2">
                      {msgOrder}
      </TextBlock>*/}

                    <div className='text-center py-4'>
                      <div className='border border-1 text-center p-3 p-lg-5 d-inline-block'>
                        <TittleH5 styleGroup="fw-bold mb-2">Detalles de facturación</TittleH5> 
                        <TextBlock styleGroup="mb-2">{getTextByKey('fecha-compra')}: <strong>{convertDateFromIso(new Date())}</strong></TextBlock>
                        <TextBlock styleGroup="mb-2">{getTextByKey('numero-pedido')} <strong>{compraid}</strong></TextBlock>
                        {estado.estado}{/*<TextBlock styleGroup="mb-2">{getTextByKey('metodo-pago')} <strong>Tarjeta Debito/credito</strong></TextBlock>*/}
                        {estado.estado =='APROBADO' &&  <TextBlock styleGroup="mb-2">{getTextByKey('fecha_entrega')} <strong>{convertDateAndHourFromIso(estado.fecha_entrega)}</strong></TextBlock>}
                      </div>
                    </div>

                    <div className='text-center'>
                    <button className={`border-0 ${StyleButton.btn} ${status!=1 ? StyleButton.active :''}`} disabled={status!=1} onClick={backShop}>
                    {getTextByKey('regresar_a_la_tienda')}
                    <span className={StyleButton.loadingContainer}>
                    <span className={StyleButton.dot}>.</span>
                    <span className={StyleButton.dot}>.</span>
                    <span className={StyleButton.dot}>.</span>
                  </span>
                  </button>
                      {/*<ButtonLink to="/tienda"></ButtonLink>*/}
                    </div>
                  </div>
                </div>
              </div>

              
              {/* <h2 className='text-uppercase mb-2 mb-md-4 mb-lg-5'>{getTextByKey('recomendados-titulo')}</h2> */}
              {/* <RecomendadosSliderComponent/> */}

          </div>
        </div>
      ) : (
        <div className='container text-center py-2 py-md-3 py-xl-5'>
          <h2 className='text-uppercase mb-2 mb-md-4 mb-lg-5'>
            <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
          </h2>
        </div>
      )}
    </Fragment>
  )
}
