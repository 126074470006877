import React, { Fragment, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';

import {getRecommendedHome, images_products_route} from "../api/productApi";
import { useApi } from "../context/apiContext";
import { createSuscribe } from "../api/suscribeApi";
import { useCart } from "../context/cartContext";
import ModalLogUserComponent from "./ModalLogUserComponent";
import { updateCurrentUser } from "firebase/auth";
import { useAuth } from "../authContext";
import { Placeholder } from "react-bootstrap";
import { useForm } from "../hooks/useForm";

import StyleFooter from "../scss/footer.module.scss";
import StyeGeneral from "../scss/general.module.scss";
import StyleButton from "../scss/btn.module.scss";
import TextBlock from "./TextBlockComponent";
import TittleH6 from "./TittleH6Component";
import {getImages} from "../api/imageApi";
import {getGallery} from "../api/galleryApi";
import scrollToTop from "./GlobalFunction";
import TextBlockSmall from "./TextBlockSmallComponent";

const suscribeFormFields = {
  email: "",
  lan: "es"
}
export function Footer(){
    const { getTextByKey, extractNameFromLocate, getTextBigByKey, currentLocale } = useApi();
    const navigate = useNavigate();
    const { emailRegex} = useForm(suscribeFormFields);
    const [checkNewsletter, setCheckNewsletter] = useState( false );
    const [emailCorrect, setEmailCorrect] = useState( true );
    const [emailUserNewsletter, setEmailUserNewsletter] = useState( '' );
    const [logosFooter, setLogosFooter] = useState([]);
    const [ message, setMessage ] = useState();
	    const [loading, setLoading] = useState(false);


    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/g;

    React.useEffect(() => {
        getGallery("logos-footer").then((data)=> {
            if (data && Array.isArray(data)) {
                setLogosFooter(data);
            }
        });
    }, [])

    const handleChangeEmailNewsletter = (event) => {
        setEmailUserNewsletter(event.target.value );
        setEmailCorrect(regex.test(emailUserNewsletter) );
    }

    const subscribeNewsletter = async (event) => {
      event.preventDefault();
      let errorFound = false;
    
      if(!emailUserNewsletter.match(emailRegex)){
        errorFound = true;
        setMessage("Email incorrecto")
        return;
      }
      if(!checkNewsletter){
        errorFound = true;
        setMessage('Debes aceptar términos y condiciones');
        return;
      }
       if(!errorFound){
        setLoading(true);
        event.preventDefault();
        const dataSubscribe = {
            email: emailUserNewsletter,
            lan: currentLocale,
        };

         await createSuscribe(dataSubscribe,setMessage).then((data)=>{
          setLoading(false);
         }).catch((error)=>{
          setLoading(false);

         });
      }
    }

    const { hideCart } = useCart();

    const gotoTop = event => {
        hideCart();
        scrollToTop();
        /* window.scrollTo(0, 0); */
    };

    const [showModal, setShowModal] = useState(false);

    const { currentUser, logout} = useAuth();

    const handleShowModal = (currentUser) => {
      if (currentUser) {
        sessionStorage.setItem('something-to-tell', 'feedback-a')
        navigate('/login');
      }else{
        console.log('no user');
      }
    }

    const [isActive, setIsActive] = useState(false);

    const handleClick = event => {
      setIsActive(current => !current);
    };


    return (
      <Fragment>
      <a  target="_blank" href={`https://api.whatsapp.com/send?phone=+${getTextByKey('whatsapp-numero')}&text=${getTextByKey('texto-link-whatsapp')}`} className={StyeGeneral.btnW}>
        <span>Lineas de domicilio</span>
        <Icon className="" icon="ic:baseline-whatsapp"/>
      </a>
            
      <footer className={`primary-bg-color position-relative ${StyleFooter.footer}`}>
        <img className={StyleFooter.deco} src="/img/deco_footer.png" alt="GoPicnic"/>
        <div className={`container ${StyleFooter.container}`}>
          <div className='row'>
            <div className='col-12 col-lg-3 text-center'>
              <NavLink to="/" className={`d-inline-block mb-1 ${StyleFooter.logo}`}>
                  <img src='/img/LogoGoPicnic-white.png' alt="GoPicnic"/>
              </NavLink>
              <TextBlockSmall styleGroup="white-color mb-3">
                La mejor opción en productos saludable
              </TextBlockSmall>
              {/* <TextBlock styleGroup="white-color mb-1">
                La mejor opción en productos saludable
              </TextBlock> */}
              <TextBlock styleGroup="white-color mb-1">
                <a href="mailto:namemail@email.com" target="_Blank" className="white-color">{getTextByKey('footer_mail')}</a>
              </TextBlock>
              <TextBlock styleGroup="white-color">  
                <a href={`tel:${getTextByKey('footer_celu')}`} target="_Blank" className="white-color">
                  <Icon icon="mdi:whatsapp" className="me-2"/>
                  <span>{getTextByKey('footer_celu')}</span>
                </a>
              </TextBlock>
            </div>
            <div className="col-12 col-md-6 offset-md-3 col-lg-5 offset-lg-0">
              <div className={`d-flex align-items-start ${StyleFooter.wpMenu}`}>
                <ul className={`text-start text-lg-start ${StyleFooter.menu}`}>
                  <li><NavLink onClick={gotoTop} to="/tienda">{getTextByKey('menu_tienda')}</NavLink></li>
                  <li><NavLink onClick={gotoTop} to="/nosotros">{getTextByKey('menu_nosotros')}</NavLink></li>
                  <li><NavLink onClick={gotoTop} to="/estilo-de-vida">{getTextByKey('menu_selecciones')}</NavLink></li>
                </ul>
                <ul className={`text-end text-lg-start ${StyleFooter.menu}`}>
                  <li><NavLink onClick={gotoTop} to="/contacto">{getTextByKey('menu_contact')}</NavLink></li>
                  <li><NavLink onClick={gotoTop} to="/legal">{getTextByKey('menu_legales')}</NavLink></li>
                  <li><NavLink onClick={gotoTop} to="/preguntas-frecuentes">{getTextByKey('menu_faq')}</NavLink></li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-0">
              <div className="py-4 py-lg-0">
              
              <TittleH6 styleGroup="white-color mb-3 text-center text-lg-start fw-bold">{getTextByKey('suscribete')}</TittleH6>
              {/* <p className='white-color copy-suscribete'>
                {getTextByKey('copy_suscribe')}
              </p> */}
                <form className={`${StyleFooter.form}`}>
                  <div className={`white-bg-color mb-2 ${StyleFooter.inputMail}`}>
                    {/* <input type='email' placeholder={getTextByKey('ingresa_correo')} className='px-2 border-0' placehoer='mail@mail.com' disabled={!checkNewsletter}
                      onChange={(event) => {handleChangeEmailNewsletter(event);}}
                    /> */}
                    <input type='email' placeholder={getTextByKey('ingresa_correo')} className='p-2 border-0' placehoer='mail@mail.com' onChange={(event) => {handleChangeEmailNewsletter(event);}}/>
                    <button type='submit' className={`btn-g px-lg-5 px-3 ${StyleButton.btn} ${loading ? StyleButton.active: ''}`} disabled={loading} onClick={(event) => subscribeNewsletter(event)}>
                    {getTextByKey('suscribirme')}
                    <span className={StyleButton.loadingContainer}>
												<span className={StyleButton.dot}>.</span>
												<span className={StyleButton.dot}>.</span>
												<span className={StyleButton.dot}>.</span>
											</span>
                    </button>
                  </div>
                  <label className={StyeGeneral.containerCheckS}>
                    <small className="white-color">{getTextByKey("he_leido")}</small>
                    <input type='checkbox' onChange={(event) => { setCheckNewsletter(event.target.checked);}}/>
                    <span className={StyeGeneral.checkmarkS}>
                      <img src='/img/SVG/check.svg'/>
                    </span>
                  </label>
                  {message && <small className='note white-color'>{message}</small>}
                </form>
              </div>
              <div className={`text-center text-lg-start ${StyleFooter.social}`}>
                <a className='me-3' href={getTextByKey("facebook-link")} target='_blank'>
                  <Icon icon='ant-design:facebook-outlined' />
                </a>
                <a className='me-3' href={getTextByKey("instagram-link")} target='_blank'>
                  <Icon icon='akar-icons:instagram-fill' />
                </a>
      
                <a className='me-3' href={getTextByKey("youtube-link")} target='_blank'>
                  <Icon icon='ant-design:youtube-outlined' />
                </a>
                {/* <a className='mx-3 me-xl-2' href={"https://youtube.com/channel/" + getTextByKey("youtube-user")} target='_blank'>
                  <Icon icon='akar-icons:youtube-fill' />
                </a> */}
              </div>
            </div>


            {/* <div className='col-10 offset-1 mb-2 mb-md-0 offset-xl-3 col-md-5 col-lg-4 offset-md-0 col-xl-2 ps-xl-4 text-start'>
              <div className={isActive ? 'toggle-arrow' : ''} onClick={handleClick}>
                <div className="d-flex align-items-center pt-4 options-footer" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                  <p className='gray-1-color mb-0 me-2 carrefourMetis-bold'>
                    {getTextByKey('donde_comprar')}
                  </p>
                  <Icon className="icon-arrow" icon="material-symbols:keyboard-arrow-down"></Icon>
                </div>
              </div>
              <div className="collapse" id="collapseExample">
                <ul className='mb-5 mb-xl-4'>
                  <li>
                    <Link to='/puntos-venta' onClick={gotoTop} className='gray-1-color mb-1'>
                      Santa Elena
                    </Link>
                  </li>
                  <li>
                    <Link to='/puntos-venta' onClick={gotoTop} className='gray-1-color mb-1'>
                      Alameda
                    </Link>
                  </li>
                  <li>
                    <Link to='/puntos-venta' onClick={gotoTop} className='gray-1-color mb-1'>
                      Independencia
                    </Link>
                  </li>
                  <li>
                    <Link to='/puntos-venta' onClick={gotoTop} className='gray-1-color mb-1'>
                      Ciudad Jardín
                    </Link>
                  </li>
                  <li>
                    <Link to='/puntos-venta' onClick={gotoTop} className='gray-1-color mb-1'>
                      Santa Mónica
                    </Link>
                  </li>
                  <li>
                    <Link to='/puntos-venta' onClick={gotoTop} className='gray-1-color mb-1'>
                      Almacenes de Cadena
                    </Link>
                  </li>
                  
                </ul>

              </div>
            
            </div> */}
            {/* <ModalLogUserComponent showModal={showModal} setShowModal={setShowModal}/> */}
            {/* <div className='col-10 offset-1 mb-2 mb-md-0 col-md-4 col-lg-4 col-xl-2 offset-md-0 text-start ps-xl-4'>
              <div className={isActive ? 'toggle-arrow' : ''} onClick={handleClick}>
                <div className="d-flex align-items-center pt-4 options-footer"  data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                  <p className='gray-1-color mb-0 me-2 carrefourMetis-bold'>{getTextByKey('sobre_cervalle')}</p>
                  <Icon className="icon-arrow" icon="material-symbols:keyboard-arrow-down"></Icon>
                </div>
              </div>
              <div className="collapse" id="collapseExample">
                <ul className='mb-5 mb-xl-0'>
                  <li>
                    <Link to='/nosotros' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("nosotros")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/noticias' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("blog")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/recetas' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("recetas")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/contacto' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("contacto")}
                    </Link>
                  </li>
    
              
                </ul>
              </div>
            </div>
            <div className='col-10 offset-1 mb-2 mb-md-0 col-md-3  col-lg-4 col-xl-2 offset-md-0 text-start ps-xl-4'>
              <div className={isActive ? 'toggle-arrow' : ''} onClick={handleClick}>
                <div className="d-flex align-items-center pt-4 options-footer"  data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                  <p className='gray-1-color mb-0 me-2 carrefourMetis-bold'>{getTextByKey('politicas')}</p>
                  <Icon className="icon-arrow" icon="material-symbols:keyboard-arrow-down"></Icon>
                </div>
              </div>
              <div className="collapse" id="collapseExample">
                <ul className='mb-5 mb-xl-0'>
                  <li>
                    <Link to='/politicas-de-garantia' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("politica_garantia")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/politica-de-cambios-devoluciones' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("politica_cambios")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/politicas-de-retracto' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("politica_retracto")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/politicas-de-reversion' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("politica_reversion")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/derechos-y-deberes' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("derechos_deberes")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/aviso-de-privacidad' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("politica_privacidad")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/politica-de-datos' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("politica_datos")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/politica-seguridad' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("politica_seguridad")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/manual' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("manual")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/programa-transparencia' onClick={gotoTop} className='gray-1-color mb-1'>
                      {getTextByKey("programa_transparencia")}
                    </Link>
                  </li>
              
                </ul>
              </div>
              
            </div> */}
          </div>
          <hr  className="white-color"/>
          
          {/* <div className='row'>
            <div className="col-md-5 col-xl-2 offset-0 col-12 offset-md-0 my-3 my-md-0 text-center text-lg-start">

              <div className="gray-1-color d big-text" dangerouslySetInnerHTML={{ __html: getTextBigByKey("lineas_footer") }}></div>

              <div className="d-flex align-items-center mb-0 options-footer-2">
                <a target="_blank" href={getTextByKey("whatsapp-link")} className="icon-info-banner mb-2">
                      <Icon className="primary-color" icon="ic:baseline-whatsapp"/>
                </a>
                <a target="_blank" href={getTextByKey("whatsapp-link")} className="icon-info-banner mx-1">
                    <h3 className="primary-color  mb-0">{getTextByKey('numero')}</h3>
                </a>
              </div>

            </div>
          </div>
 */}
          <hr className="hr-color d-block d-lg-none" />
          {/* <div className="row">
            <div className="col-12">
              <div className="logos text-center py-3 py-lg-4">
                  {logosFooter.map(logo => (
                      <img key={logo.id} src={images_products_route + logo.image} alt={logo.id} className={StyleFooter.logos}/>
                  ))}
              </div>
            </div>
          </div> */}
          <div className="row">
          <div className='text-center'>
              <a className={StyleFooter.iridian} target="_blank" href="https://iridian.co/">
                <img className='' src='/img/iridian.png' alt="Powered by Iridian"/>
              </a>
            </div>
          </div>
        </div>
        <div className="primary-bg-color text-center py-2">
          <p className="white-color sm-p py-1">{getTextByKey('copyright')}</p> 
        </div>
      </footer>
      </Fragment> 
    );
  
}
