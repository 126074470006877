import React, { useState, useEffect, useCallback  } from "react";
import { NavLink, Link ,useLocation} from "react-router-dom";
import { Icon } from '@iconify/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import OpctionsHeaderComponent from "./OpctionsHeaderComponent";
import CarritoComponent from "./CarritoComponent";
import { useApi } from "../context/apiContext";
import { useCart } from "../context/cartContext";
import { default as ModalCompra } from "./ModalCompra";
import BuscadorComponent from "./BuscadorComponent";

import styles from "../scss/header.module.scss";
import StylesSearcher from "../scss/searcher.module.scss";
import MenuHeader from "./MenuHeaderComponent";
import { Fragment } from "react";
import scrollToTop from "./GlobalFunction";

import { showMenu, backgroundMenuShow, logoShow, wpShow, btnShow, logoHide, wpHide, btnHide, backgroundMenuHide, hideMenu } from "./headerFunctionComponent";
import TextBlock from "./TextBlockComponent";
import { getEnvVariables } from "../helpers";
import {getSeo } from "../api/productApi";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';




export function Header(){

    const { getTextByKey } = useApi();

   const IMAGES_URL_PRODUCTS = getEnvVariables().IMAGES_URL_PRODUCTS;

    const [ localizationName, setLocalizationName ] = useState(false);

    const { hideCart } = useCart();

    const [showModalInicio, setShowModalInicio] = useState(false);

    const handleShowModalInicio = () => {
          setShowModalInicio(true);     
    }

    const [isClicked, setIsClicked] = useState(false);

    
    const [metadescripcion, setMetaDescripcion] = useState('Go Picnic - Mercado saludable. Recetas, noticias, tips y más de 100 productos de diferentes marcas y categorías');
    const [metakeywords, setMetaKeywords] = useState('Go Picnic, Picnic, Leches vegetales, bebidas vegetales, Mercado saludable, Fitness');
    const [urlMeta, setUrlMeta] = useState('https://go-picnic.com.co/');
    const [metatitle, setMetaTitle] = useState('GoPicnic');
    const [metaimage, setMetaImage] = useState('https://api.go-picnic.com.co/uploads/products/canasta-65133e7c2bf7c436180429.png');

    function handleClick() {
        setIsClicked(!isClicked);
    }

    function closeMenuMobile() {
        setIsClicked(false);
    }
        
    useEffect(() => {
        const element = document.getElementById('menu-mobile');
        const backgrounMenu = document.getElementById('backGround');
        const menu = document.getElementById('wpMobile');
        const logoMenu = document.getElementById('logoMenu');
        const btnClose = document.getElementById('btnClose');
        const time = 300;
        const timeOut = 500;

        function hideAll() {
            logoHide(logoMenu);
            wpHide(menu);
            btnHide(btnClose);
            backgroundMenuHide(backgrounMenu);
            setTimeout(() => {
                hideMenu(element);    
            }, timeOut);
        }

        if (isClicked) {
            //esta dos fuinciones las dejo fuera del runFunctionsInSequence porque son las iniciales 
            showMenu(element);
            backgroundMenuShow(backgrounMenu);

            function runFunctionsInSequence(funcs, time) {
                if (funcs.length === 0) return;
                const [funcToRun, ...remainingFuncs] = funcs;
                setTimeout(() => {
                  funcToRun();
                  runFunctionsInSequence(remainingFuncs, time);
                }, time);
            }

            const menuFunctions = [                
                () => logoShow(logoMenu),
                () => wpShow(menu),
                () => btnShow(btnClose)
            ];
            
            runFunctionsInSequence(menuFunctions, time);

        } else {
            hideAll();
        }

    }, [isClicked]);


    const [elementClass, setElementClass] = useState('');
    const [addClass, setAddClass] = useState(false);

    function searchClick() {
        if (addClass) {
            setElementClass('');
        } else {
            setElementClass(StylesSearcher.show);
        }
        setAddClass(!addClass);
    }

    function    removeClass() {
        setElementClass('');
        setAddClass(false);
    }


    const location = useLocation();
    useEffect(() => {
        getSeo(location.pathname).then((res) => {
            
            if(res){
                if(res.length>0){
                    setMetaTitle(res[0].titulo);
                    setUrlMeta(`https://${window.location.host}${location.pathname}`);
                    setMetaDescripcion(res[0].descripcion);
                    const img=IMAGES_URL_PRODUCTS+res?.[0]?.imagen;
                    setMetaImage(img);
                    setMetaKeywords(res[0].keywords)
                }
            }
        })
      
        ReactGA.initialize("G-7BCPM5E0YG");
        const tagManagerArgs = {
          gtmId: 'GTM-WGK94RMD'
      }
      
       TagManager.initialize(tagManagerArgs);
      

       ReactPixel.init('816646626911371');
       ReactPixel.pageView();
      
       console.log('tag',TagManager,ReactPixel);
    }, [location]);



    return(
        <Fragment>
        <HelmetProvider>
          <Helmet>
                <meta property="og:image" content={metaimage} />
                <meta property="og:url" content={urlMeta} />
                <link rel="canonical" href={urlMeta} />
                <title>{metatitle}</title>
                <meta name="description" content={metadescripcion} />
                <meta property="og:description" content={metadescripcion} />
                <meta property="og:title" content={metatitle} />
                <meta name="keywords" content={metakeywords} />

               </Helmet>
        </HelmetProvider>
        
            
            <div className="quaternary-bg-color p-2 text-center">
                <TextBlock styleGroup="white-color mb-0">{getTextByKey("barra-cobertura")}</TextBlock>
            </div>
            <div className={styles.wpMenu}>
                <div className={styles.colLogo}>
                    <button className={`d-md-none ${styles.showMenu}`} onClick={() => handleClick()}>
                        <Icon icon="solar:hamburger-menu-bold"/>
                    </button>
                    <span className={`d-md-none ${styles.separator}`}>|</span>
                    <NavLink to="/" onClick={scrollToTop} className={`${styles.logo} my-2 my-lg-3`}>
                        <img className='w-100' src='/img/LogoGoPicnic.png' alt="GoPicnic"/>
                    </NavLink>
                </div>
                <div className={styles.colMenu}>
                    <div className="d-none d-md-block">
                        <MenuHeader/>
                    </div>
                </div>
                <div className={styles.colOptions}>
                    <OpctionsHeaderComponent searchClick={searchClick}/>
                </div>
            </div>

            <CarritoComponent/>

            <div className={`p-4 d-flex justify-content-center align-items-center d-md-none ${styles.menuMobile}`} id="menu-mobile">
                <div className={`primary-bg-color ${styles.backGroundMenu}`} id="backGround"></div>
                <div className="p-2 text-center">
                    
                    <NavLink to="/" className={`d-inline-block mb-5 w-75 ${styles.logoMenu}`} id="logoMenu" onClick={closeMenuMobile}>
                        <img className='w-100' src='/img/LogoGoPicnic-white.png' alt="GoPicnic"/>
                    </NavLink>

                    <div className={styles.wpMobile} id="wpMobile">
                        <MenuHeader/>
                    </div>
            
                    <button className={`mt-5 ${styles.closeMenu}`} onClick={closeMenuMobile} id="btnClose">
                        <Icon icon="solar:close-square-line-duotone"/>
                    </button>
                    
                </div>

            </div>

            
            <ModalCompra setLocalizationName={setLocalizationName} />

            <div className={`${StylesSearcher.searcherWp} ${elementClass}`}>
                <BuscadorComponent removeClass={removeClass}/>
            </div>
            
                
            
        
        </Fragment>
       
    );
}