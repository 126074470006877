import React, { useState, useEffect, useContext, createContext } from 'react';
import sellyApi from "../api/sellyApi";
import { getInventario } from '../api/productApi'

import StylesQuickCart from '../scss/quickCar.module.scss';
import { showCart, hideCart } from '../components/GlobalFunction';
import product from "../classes/product";
import quantyControls from "../components/QuantyControls";

const CartContext = createContext();

export function useCart() {
    return useContext(CartContext);
}

const PRODUCT_LIMIT = 10;
const PRODUCT_LIMIT_WITH_OFF = 3;

export const carrito = document.getElementById('carrito-rapido');

export function CartProvider({ children }) {

    const [currentCart, setCurrentCart] = useState([]);
    const [currentCartOld, setCurrentCartOld] = useState([]);
    const [cartIsShowing, setCartIsShowing] = useState(false);
    const [total, setTotal] = useState(0);
    const [subtotal, setSubTotal] = useState(0);
    const [ impuesto, setImpuesto ] = useState(0);
    const [envio, setEnvio] = useState(0);
    const [compraMinima, setCompraMinima] = useState(0);
    const [impuestoBolsa, setImpuestoBolsa ] = useState(0);
    const [ pesoXbolsa, setPesoXbolsa ] = useState(0);
    const [descuento, setDescuento] = useState(0);
    const [ pesoTotal, setPesoTotal ] = useState(0);
    const [direccion, setDireccion] = useState(null);
    const [tiempoentrega, setTiempoEntrega] = useState(1);
    const [coupon, setCoupon] = useState([]);
    const [tiendaGeneral, setTiendaGeneral] = useState(localStorage.getItem('cart_tienda'));
    const [creditBenefitType, setCreditBenefitType] = useState({
        type: "general",
        code: ""
    });

    const showCart = () => {
        setCartIsShowing(true);
        /* carrito.classList.add(StylesQuickCart.showQuickCar); */
        console.log("Actual Cart",currentCart);
    }

    const hideCart = () => {
        setCartIsShowing(false);
    }

    const addToCart = (product, quantity) => {
      
        const newCart = [...currentCart];
        const index = newCart.findIndex((item) => item.plu === product.plu);
            
        if (index === -1) {
            if(quantity>product.cantidad)
                quantity=product.cantidad;
            product.quantity = parseInt(quantity) ? parseInt(quantity) : 1;
            newCart.push(product);
        } else {
            let cantidadprod=quantity+newCart[index].quantity;
            if(cantidadprod>product.cantidad){
                cantidadprod=product.cantidad;
            }
            newCart[index].quantity =cantidadprod;
        }
        localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
        setCurrentCart(newCart);
    };

    const addToCartOld = (product, quantity) => {
      
        const newCart = [...currentCartOld];
        const index = newCart.findIndex((item) => item.plu === product.plu);
            
        if (index === -1) {
            if(quantity>product.cantidad)
                quantity=product.cantidad;
            product.quantity = parseInt(quantity) ? parseInt(quantity) : 1;
            newCart.push(product);
        } else {
            let cantidadprod=quantity+newCart[index].quantity;
            if(cantidadprod>product.cantidad){
                cantidadprod=product.cantidad;
            }
            newCart[index].quantity =cantidadprod;
        }
        localStorage.setItem('cart_old', JSON.stringify(newCart));
        setCurrentCartOld(newCart);
    };

    const validarCart= async(tienda)=>{
        let products = [...currentCart];
        if(currentCartOld.length>0){
            console.log('cart old');
             products = [...currentCartOld];
        }
        const eliminados=[]
        console.log('entro validar cart', tienda,products);

        let inicial=products.length;
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            //const productLimit =  product.price?.sindescuento ? limitePromo : limite;
            let inventario = await getInventario(product.plu, tienda);
            console.log('inventario',inventario);
                if(inventario.response && inventario.response.status === 400){
                   console.log("errr",inventario );
                    products.splice(i, 1);
                    eliminados.push(product);
                    i--;
                }else{
                    product.price.precio=inventario.precio;
                    product.price.precio_sin_descuento=inventario.precio_sin_descuento;
                    
                    if(inventario.cantidad>0){
                        let cantidad=product.quantity;
                        /*if(cantidad>productLimit){
                            cantidad=productLimit;
                        }*/
                        if(cantidad > inventario.cantidad){
                           cantidad=inventario.cantidad
                        }  
                        /*if(inventario.cantidad-cantidad<=agotado){
                            cantidad=inventario.cantidad-agotado;
                            if(cantidad<=0){
                             products.splice(i, 1);
                             eliminados.push(product);
                            }
                        }*/
                        product.quantity=cantidad;
                     }else{
                        products.splice(i, 1);
                            i--;
                     }               
                }
            
        }
         
        localStorage.setItem('cart_shido_data', JSON.stringify(products));
        localStorage.removeItem('cart_old');
        setCurrentCartOld([]);
        setCurrentCart(products);
        let total=inicial-products.length;
        const response= {ok:true, total:total,eliminados:eliminados};
        return response;
    }
    
    const addToCartMas = async (product, quantity) => {
      
        const newCart = currentCart;
        const index = newCart.findIndex((item) => item.plu === product.plu);
            
        if (index === -1) {
            if(quantity>product.cantidad)
                quantity=product.cantidad;
            product.quantity = parseInt(quantity) ? parseInt(quantity) : 1;
            newCart.push(product);
        } else {
            let cantidadprod=quantity+newCart[index].quantity;
            if(cantidadprod>product.cantidad){
                cantidadprod=product.cantidad;
            }
            newCart[index].quantity =cantidadprod;
        }
        localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
        setCurrentCart(newCart);
        return true;
    };


    const setCartQuantityValue = (product, quantity) => {
        const newCart = [...currentCart];
        const index = newCart.findIndex((item) => item.plu === product.plu);
         if (index !== -1) {
           
            if(quantity>product.cantidad){
                quantity=product.cantidad;
            }
            newCart[index].quantity =quantity;
            if (newCart[index].quantity < 1) newCart[index].quantity = 1;
        }
        localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
        setCurrentCart(newCart);
    }

    const addSeveralToCart = (products) => {
        const newCart = [...currentCart];
        products.forEach(product => {
            const productLimit = product.price.sindescuento ? PRODUCT_LIMIT_WITH_OFF : PRODUCT_LIMIT;
            const index = newCart.findIndex((item) => item.plu === product.plu);
            if (index === -1) {
                product.quantity = parseInt(product.cantidad) <= productLimit ? parseInt(product.cantidad) : productLimit;
                newCart.push(product);
            } else {
                newCart[index].quantity += parseInt(product.cantidad);
                if (newCart[index].quantity > productLimit) newCart[index].quantity = productLimit;
            }
        });
        localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
        setCurrentCart(newCart);
    };



    const removeToCart = (index) => {
        const newCart = [...currentCart];
        newCart.splice(index, 1);
        localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
        setCurrentCart(newCart);
    }

    const updateCart = (index, product) => {
        const newCart = [...currentCart];
        newCart[index] = product;
        localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
        setCurrentCart(newCart);
    }

    const calculateSubtotal = async () => {
        let subtotal = 0;
      
        const newCart = [...currentCart];
        newCart.forEach((item)=>{
            //let precio= item.price.precio_sin_descuento || item.price.precio;
            let precio= item.price.precio;
            let impuesto=0;
            if(item.impuestos){
                item.impuestos.forEach((impt) =>{
                    impuesto +=precio-(precio / (1+(impt.porcentaje)));   
                })              
            }
          
            subtotal +=(precio*item.quantity)-(impuesto*item.quantity);
          
        });

        setSubTotal(subtotal);
    }


    const calculateTotal = async () => {
        let total = 0;
        const newCart = [...currentCart];
        newCart.forEach((item) => {
            if(item.type === "giftcard"){
                total += item.price * item.quantity;
            } else {
                total += item.price.precio * item.quantity;
           }  
          
        });
        setTotal(total);
    }


    const calculateImpt = async () => {
        let impuesto = 0;
        const newCart = [...currentCart];
        newCart.forEach((item)=>{
            if(item.impuestos){
                //let precio= item.price.precio_sin_descuento || item.price.precio;
                let precio= item.price.precio;
                item.impuestos.forEach((impt) =>{
                    impuesto += (precio-(precio / (1+(impt.porcentaje))))*item.quantity;   
                })              
            }
        });
        setImpuesto(impuesto);
    }

    const calculatePeso = async () => {
        let pesoTotal = 0;
        const newCart = [ ...currentCart ];
        newCart.forEach((item) =>{
            pesoTotal += item.peso;
        })
        setPesoTotal(pesoTotal);
    }

    const calculateDescuento = async () => {
        let descuento = 0;
        const newCart = [...currentCart];
        newCart.forEach((item) => {
            if(item.price.precio_sin_descuento){
                descuento += ((item.price.precio_sin_descuento - item.price.precio)*item.quantity);
            }
         });
        setDescuento(descuento);
    }

    const checkIfExistInCart = (id) => {
        const newCart = [...currentCart];
        const index = newCart.findIndex((item) => item.plu === id);
        if (index === -1) {
            return false;
        } else {
            return true;
        }
    }

    const getNumberProducts = () => {
        return currentCart.length;
    }
    const clearAll=()=>{
        localStorage.removeItem('cart_shido_data');
        localStorage.removeItem('cart_shido_direccion');
        localStorage.removeItem('cart_tienda');
        localStorage.removeItem('cart_current_delivery');
        localStorage.removeItem('city');
        localStorage.removeItem('departamento');
        localStorage.removeItem('actualizo');
        localStorage.removeItem('cambio-datos');
        setTiendaGeneral(null);
        setDireccion(null);
        setCurrentCart([]);
      }

    const clearCart = () => {
        localStorage.removeItem('cart_shido_data');
        setCurrentCart([]);
       
    }
    const updateTiempoEntrega= (tiempo)=>{
         setTiempoEntrega(tiempo);
    } 

    const updateDireccion = (direccion) => {
        localStorage.setItem('cart_shido_direccion', direccion);
        setDireccion(direccion);
    }
    const updateTienda = (tienda) => {
        localStorage.setItem('cart_tienda', tienda);
        setTiendaGeneral(tienda);
    }

    const updateCoupon = (cupon) => {
        console.log('cupoooo',coupon);
        localStorage.setItem('cart_cupon',  JSON.stringify(cupon));
        setCoupon(cupon);
    }

    const applyCoupon = (value) => {
        let descuento=0;
        if (coupon!=0) {
            if(coupon.porcentaje){
               descuento=value-(value * (1 - coupon.descuento / 100));
            }else{
                if(value>0)
                    descuento=value - coupon.descuento;
            } 
            
        }
        return descuento;
    }

    const getEnvioPrice = async () => {
        try {
            if(!localStorage.getItem('costo_envio')){
                let costo= await sellyApi.get(`/store/get-setting?llave=costo_envio&formato=JSON`);
                
                localStorage.setItem('costo_envio',costo.data);
            }
            if(!localStorage.getItem('compra_gratis')){
                let gratis= await sellyApi.get(`/store/get-setting?llave=envio-gratis-compra&formato=JSON`);
                localStorage.setItem('compra_gratis',gratis.data) ;
            }
           
            const costo=localStorage.getItem('costo_envio');
            const compragratis=localStorage.getItem('compra_gratis');
           
          
            if(total >=compragratis){
               return 0 
            }
         
            return costo;
        } catch (error) {
            return 0;
        }
    };

    const getValidacompraMinima = async () => {
        try {
            if(!localStorage.getItem('compra_minima')){
                let valor= await sellyApi.get(`/store/get-setting?llave=compra_minima&formato=JSON`);
                
                localStorage.setItem('compra_minima',valor.data);
            }
                
            const valor=localStorage.getItem('compra_minima');
                   
            return valor;
        } catch (error) {
            return 0;
        }
    };

    const updateBenefitType = ({type, code}) => {
        setCreditBenefitType({type, code});
    };

    const getImpuestoBolsa = async () => {
        try {
            const { data } = await sellyApi.get(`/store/get-setting?llave=impuesto-bolsa&formato=JSON`);
          
            return data;
        } catch (error) {
            return error;
        }
    }

    const getPesoMaxXBolsa = async() =>{
        try {
            const { data } = await sellyApi.get(`/store/get-setting?llave=pesoMaxXbolsa&formato=JSON`);
            return data;
        } catch (error) {
            return error;
        }
    }

    useEffect(() => {
       
        localStorage.getItem('cart_shido_data') && setCurrentCart(JSON.parse(localStorage.getItem('cart_shido_data')));
        localStorage.getItem('cart_old') && setCurrentCartOld(JSON.parse(localStorage.getItem('cart_old')));
        localStorage.getItem('cart_shido_direccion') && setDireccion(localStorage.getItem('cart_shido_direccion'));
        //getEnvioPrice();
        localStorage.getItem('compra_minima') ? setCompraMinima(parseFloat(localStorage.getItem('compra_minima'))) : getValidacompraMinima();
        localStorage.getItem('cart_cupon') && setCoupon(JSON.parse(localStorage.getItem('cart_cupon')));
        localStorage.getItem('cart_tienda') && setTiendaGeneral(localStorage.getItem('cart_tienda'));
        calculateImpt();
        calculateSubtotal();
        calculatePeso();
        /*getImpuestoBolsa().then((data) => {
            setImpuestoBolsa(data);
        })
        getPesoMaxXBolsa().then((data)=>{
            setPesoXbolsa(data);
        })*/
       }, []);

    useEffect(()=>{
       getEnvioPrice().then((data) => {
            setEnvio(parseFloat(data));
          });
          getValidacompraMinima().then((data) => {
            setCompraMinima(parseFloat(data));
          });  
    },[total]);

    const value = {
        currentCart,
        currentCartOld,
        addToCart,
        addToCartOld,
        addToCartMas,
        addSeveralToCart,
        removeToCart,
        updateCart,
        validarCart,
        calculateTotal,
        getNumberProducts,
        clearCart,
        clearAll,
        cartIsShowing,
        showCart,
        hideCart,
        total,
        subtotal,
        impuesto,
        envio,
        compraMinima,
        impuestoBolsa,
        getEnvioPrice,
        descuento,
        pesoTotal,
        pesoXbolsa,
        direccion,
        updateDireccion,
        tiempoentrega,
        updateTiempoEntrega,
        coupon,
        updateCoupon,
        checkIfExistInCart,
        calculateDescuento,
        calculateImpt,
        calculatePeso,
        calculateSubtotal,
        applyCoupon,
        setCartQuantityValue,
        updateBenefitType,
        creditBenefitType,
        tiendaGeneral,
        updateTienda
    }

    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    )
}