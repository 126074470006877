import React from "react";
import { NavLink } from "react-router-dom";
import { useApi } from "../../../context/apiContext";

export function  NavpoliticasComponent(){
    const { getTextByKey } = useApi();
    return(
         <ul className='NavPoliticas'>
         <li className="mb-2">
           <NavLink to='/politicas-de-garantia'  className='gray-1-color'>
             {getTextByKey("politica_garantia")}
           </NavLink>
         </li>
         <li className="mb-2">
           <NavLink to='/politica-de-cambios-devoluciones'  className='gray-1-color'>
             {getTextByKey("politica_cambios")}
           </NavLink>
         </li>
         <li className="mb-2">
           <NavLink to='/politicas-de-retracto'  className='gray-1-color'>
             {getTextByKey("politica_retracto")}
           </NavLink>
         </li>
         <li className="mb-2">
           <NavLink to='/politicas-de-reversion'  className='gray-1-color'>
             {getTextByKey("politica_reversion")}
           </NavLink>
         </li>
         <li className="mb-2">
           <NavLink to='/derechos-y-deberes'  className='gray-1-color'>
             {getTextByKey("derechos_deberes")}
           </NavLink>
         </li>
         <li className="mb-2">
           <NavLink to='/aviso-de-privacidad'  className='gray-1-color'>
             {getTextByKey("politica_privacidad")}
           </NavLink>
         </li>
         <li className="mb-2">
           <NavLink to='/politica-de-datos'  className='gray-1-color'>
             {getTextByKey("politica_datos")}
           </NavLink>
         </li>
         <li className="mb-2">
           <NavLink to='/politica-seguridad'  className='gray-1-color'>
             {getTextByKey("politica_seguridad")}
           </NavLink>
         </li>
         <li className="mb-2">
           <NavLink to='/manual'  className='gray-1-color'>
             {getTextByKey("manual")}
           </NavLink>
         </li>
         <li className="mb-2">
           <NavLink to='/programa-transparencia'  className='gray-1-color'>
             {getTextByKey("programa_transparencia")}
           </NavLink>
         </li>
     
       </ul>
    );
}