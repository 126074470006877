import { Fragment, Suspense } from "react";
import { batch, Fade, FadeIn,  Move, Sticky, StickyIn, ZoomIn } from "react-scroll-motion";
import { useApi } from "../../context/apiContext";
import { getGallery } from "../../api/galleryApi";

import React, { useState, useEffect } from 'react';
import Banner from "../../components/BannerComponent";
import StyleNosotros from '../../scss/nosotros.module.scss';
import TittleH5 from "../../components/TittleH5Component";
import Picture from "../../components/PictureComponent";
import StyleText from "../../scss/text.module.scss";
import StyleGeneral from "../../scss/general.module.scss";
import ButtonMapComponent from "../../components/ButtonMapComponent";
import { MapComponent } from "../../components/MapComponent";
import TittleH4 from "../../components/TittleH4Component";
import { getAlmacenes, getStores } from "../../api/storeApi";

const LazyBanner = React.lazy(() => import('../../components/BannerComponent'));

export default function AcercaPage() {
  const { currentLocale, getTextByKey } = useApi();
  const [imgBanner, setImgBannerHome] = useState([]);
  const [nosotros, setNosotros] = useState([]);
  const [location, setLocation] = useState(false);
  const [stores, setStores] = useState([]);
  const [nearestStore, setNearestStore] = useState(false);
  const [ubicacion, setUbicacion] = useState(false);

  useEffect(() => {
    getGallery("nosotros").then((data) => {
      setNosotros(data);
      // console.log("nosotros ->> ", data);
    });

    getGallery("nosotros-banner").then((data) => {
      setImgBannerHome(data);
      // console.log("nosotros-banner ->> ", data);
    });
  },[]);

  useEffect(() => {
      
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ lat: latitude, lng: longitude });
        setUbicacion(true);
      },
      (error) => {
        setLocation({
            lat: 3.4337724100366582,
            lng: -76.52496088872111
        })
        setUbicacion(false);
       console.log(error);
      }
    );
  }, []);
  useEffect(() => {
    getStores(currentLocale).then(data => {
      setStores(data);
      setNearestStore(data[0]);
     
    })
  }, []);

  return (
    <Fragment>
      
      {imgBanner.length > 0 &&
        <Suspense fallback={<div>Loading..</div>}>
          <LazyBanner src={imgBanner[0].image}
            styleGroup="col-lg-6 mb-lg-5" alt="Rosmi"
            titleHead={imgBanner[0].names[0].titulo}
            titlecolor="secundary-color" />
        </Suspense>
      }



      {nosotros.length > 0 &&
        <div className={`py-lg-5 py-3 ${StyleNosotros.containerItem} `}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className={StyleNosotros.txt}>
                  <TittleH5 styleGroup="primary-color fw-bold mb-lg-4 mb-3">{nosotros[0].names[0].titulo}</TittleH5>
                  <div className={`${StyleText.paragraph}`}>
                    <div dangerouslySetInnerHTML={{ __html: nosotros[0].names[0].descripcion }}></div>
                    
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                  <div className="sticky-xl-top" style={{ top: '10rem', zIndex: 0 }}>
                    <Picture src={nosotros[0].image} alt="Rosmi" />
                  </div>
              </div>
            </div>
          </div>
        </div>
      }

      {
        nosotros.length > 0 &&
        <div className={`specialC-bg-color py-lg-5 py-3 ${StyleNosotros.containerItem} `}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 order-lg-2">
                <div className={StyleNosotros.txt}>
                  {
                    nosotros[1].names[0].titulo === null &&
                    <TittleH5 styleGroup="primary-color fw-bold mb-0">{nosotros[1].names[0].titulo}</TittleH5>
                  }
                  <div className={`${StyleText.paragraph}`}>
                    <div dangerouslySetInnerHTML={{ __html: nosotros[1].names[0].descripcion }}></div>
                  </div>
                  
                </div>
              </div>
              <div className="col-12 col-lg-6 order-lg-1">
                <div className="sticky-xl-top" style={{ top: '10rem', zIndex: 0 }}>
                  <Picture src={nosotros[1].image} alt="Rosmi" />
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {
        nosotros.length > 0 &&
        <div className={`py-3 py-md-5`}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                  <TittleH4 styleGroup={`primary-color my-4 text-center fw-bold `}>{getTextByKey('ours_title_maps')}</TittleH4>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-5">
                <div className={`${StyleNosotros.contentAux} ${StyleGeneral.boxShadow}`}>
                  {stores.map(store =>
                      (
                          <ButtonMapComponent
                              title={store.nombre}
                              description={store.direccion}
                              telefono={store.telefono}
                              horarios={store.horarios_text}
                          ></ButtonMapComponent>
                      )
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-7">
                <div className={`mb-5 ${StyleNosotros.hMap} ${StyleNosotros.contentAux} ${StyleGeneral.boxShadow}`}>
                  {
                    location ?
                        <MapComponent setNearestLocation={setNearestStore} navigator={ubicacion} locations={stores} locationMarker={location} />
                        :
                        <div>{getTextByKey('ours_no_localitation')}</div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </Fragment>
  )
}
