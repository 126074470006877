import styles from "../scss/header.module.scss";
import StylesSearcher from "../scss/searcher.module.scss";
import StylesSpecial from "../scss/specialProducts.module.scss";
import StyleGeneral from "../scss/general.module.scss";

// funcion para mostrar

export function showSearcher (i) {
    i.classList.add(StylesSearcher.visible);
}

// funciones para mostrar el menu
export function showMenu (i) {
    i.classList.add(styles.visible);
}

export function backgroundMenuShow (i) {
    i.classList.add(styles.backgroundShow);
}

export function logoShow (i) {
    i.classList.add(styles.logoShow);
}

export function wpShow (i) {
    i.classList.add(styles.wpShow);
}

export function btnShow (i) {
    i.classList.add(styles.btnCloseShow);
}

// funciones para ocultar el menu
export function logoHide (i) {
    i.classList.remove(styles.logoShow);
}

export function wpHide (i) {
    i.classList.remove(styles.wpShow);
}

export function btnHide (i) {
    i.classList.remove(styles.btnCloseShow);
}  

export function backgroundMenuHide (i) {
    i.classList.remove(styles.backgroundShow);
}

export function hideMenu (i) {
    i.classList.remove(styles.visible);
}

// funciones para mostrar la vista especial horizontal en el home

export function showItem (i, delay) {
    setTimeout(() => {
        i.classList.add(StyleGeneral.fadeIn);
    }, delay);
}

export function hideItem (i) {
    i.classList.add(StyleGeneral.fadeOut);
    setTimeout(() => {
        i.classList.remove(StyleGeneral.fadeIn);
        i.classList.remove(StyleGeneral.fadeOut);
    }, 300);
}

export function itemUp (i, delay) {
    setTimeout(() => {
        i.classList.add(StyleGeneral.itemUp);
    }, delay);
}

export function itemDown (i) {
    i.classList.remove(StyleGeneral.itemUp);
}

export function itemFocus (i) {
    i.classList.add(StylesSpecial.itemFocus);
}

export function itemUnFocus (i) {
    i.classList.remove(StylesSpecial.itemFocus);
}