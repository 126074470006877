
class Product {
    constructor(id, name, price, quantity, color, size) {
        this.id = id;
        this.name = name;
        this.price = price;
        this.quantity = quantity;
        this.color = color;
        this.size = size;
    }
}

export default new Product();