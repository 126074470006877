import GifLoader from "react-gif-loader";
import React, { Component } from "react";
import { useApi } from "../context/apiContext";
import { useEffect } from "react";

export default function ReactGifLoader() {

    const { loading } = useApi();

    useEffect (() => {
    }, [loading])

    if(!loading) 
        return null;

    return (
        // <div classNameName="gif-loader">
        //     <img src="../img/loader.gif" alt="" />
        // </div>
        <div id="loader-nuevo" className="gif-loader text-center">
            <div className="loader-contenido">
                <span style={{'--i': '1'}} className="puntos punto-1"></span>
                <span style={{'--i': '2'}} className="puntos punto-2"></span>
                <span style={{'--i': '3'}} className="puntos punto-3"></span>
                <span style={{'--i': '4'}} className="puntos punto-4"></span>
                <span style={{'--i': '5'}} className="puntos punto-5"></span>
                <span style={{'--i': '6'}} className="puntos punto-6"></span>
                <span style={{'--i': '7'}} className="puntos punto-7"></span>
                <span style={{'--i': '8'}} className="puntos punto-8"></span>
                <span style={{'--i': '9'}} className="puntos punto-9"></span>
                <span style={{'--i': '10'}} className="puntos punto-10"></span>
                <span style={{'--i': '11'}} className="puntos punto-11"></span>
                <span style={{'--i': '12'}} className="puntos punto-12"></span>
                <div className="content-loader"></div>
                <p className="primary-color text-loader">Cargando...</p>
            </div>
        </div>
    )
}