import React from "react";
import styleTittle from "../scss/text.module.scss";

const TittleH3 = ({ children, styleGroup }) => {
  
  return (
    <h3 className={`${styleGroup} ${styleTittle.h3}`}>{children}</h3>
  );
};

export default TittleH3;