//import { Icon } from '@iconify/react';
//import { getAllByText } from '@testing-library/react'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { listAddressUser } from '../../../api/addressApi';
import { useAuth } from '../../../authContext';
import { useApi } from "../../../context/apiContext";
import { useCart } from '../../../context/cartContext';
import { getStore } from '../../../api/storeApi';

//import StyleBtn from '../../../scss/btn.module.scss';
import StyleGeneral from '../../../scss/general.module.scss';
import TittleH6 from '../../../components/TittleH6Component';
import { AlertComponent } from "../../../components/AlertComponent";
import { Fragment } from 'react';

export default function SelectDireccionComponent({ step,handleShowModal,setErrors }) {
  const { getTextByKey, currentLocale } = useApi();
  const [addressList, setAddressList] = useState([]);
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const { updateDireccion, direccion,updateTienda,validarCart } = useCart();
  
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const selectAddress = async(e) => {
    e.preventDefault();
        let dirsel=e.target.value;
        let dir= addressList.find((address)=>address.id==dirsel);
        console.log(dir);
        if(dir){
          setLoading(true);
          updateDireccion(dirsel);
          let city=dir.ciudades[0].id;
          let tienda= await getStore(parseInt(city)) 
          if(tienda.id){
            updateTienda(tienda.id);
            let carritoajustar =await validarCart(tienda.id);
            console.log(carritoajustar);
            if(carritoajustar.ok){
             if(carritoajustar.total>0){
                let mensaje=getTextByKey('productos-eliminados-car')+': ';
                mensaje += carritoajustar.eliminados.map((eliminado) => eliminado.name.nombre).join(', ');
                setErrors(mensaje);
                setLoading(false);
                handleShowModal();
              }
              
            }
            setLoading(false);
          
          
          }else{
           setErrors('No tiene cobertura');
           handleShowModal();
           setLoading(false);
          }
        }
  }


  useEffect (() => {
    listAddressUser(currentUser.uid).then((addressResponse) => {
      setAddressList(addressResponse);
     console.log('dir activa' ,localStorage.getItem('cart_shido_direccion'));
     
  })
    /*console.log(localStorage.getItem('cart_shido_direccion'));
      async function getListAddress() {
          try {
              const listAddressResponse = await listAddressUser(currentUser.uid);
              setAddressList( listAddressResponse )
              // if (!localStorage.getItem('cart_shido_direccion')) {
              //   navigate('/checkout-b');
              // }
          } catch (error) {
              console.log('Error: ', error)
          }
      }
      getListAddress();*/
     }, [])

     if (loading)
    return (
      <div className='container'>
        <div className="text-center-c">
          <div className="loader-box">
            <img src="/img/SVG/loader.svg" alt="" />
          </div>
        </div>
      </div>
    );

  return (
    <Fragment>
    <div className='select-direccion border border-1 rounded p-3 position-relative'>
        { addressList.length>0 ? 
          <Fragment>
            <div className='form-group'>
               <select className={`w-100 p-2 ${StyleGeneral.input}`} onChange={selectAddress} defaultValue={direccion}>
                <option value='0' >{currentLocale ==='es' ? 'Seleccionar' : 'Select'}</option>
                {addressList.map((address, index) => (
                  <option key={index} value={address.id}>{address.address} {address.ciudades[0].nombre}</option>
                ))}
              </select>
              {/* <button className={StyleBtn.btn}>{getTextByKey('enviar_direccion')}</button> */}
              
            </div>
          </Fragment>
          : 
          <TittleH6 styleGroup="primary-color">No tienes direcciones guardadas</TittleH6>
        }
        {/* {step !== 3 ? (
        <div className="form-group">
          {direccion == null ? (
            <div className='btn-g bb py-2 px-3' role="button">{getTextByKey('select-address')}</div>
          ) : (
            <NavLink to="/checkout-c" className="btn-g bb py-2 px-3">{getTextByKey('enviar-direccion')}</NavLink>
          )}
        </div>
        ) : null } */}
    </div>
   
   </Fragment>
  )
}
