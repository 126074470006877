import { useEffect, useState } from "react";
import { getGallery } from "../../api/galleryApi";
import { createGiftCard } from "../../api/giftCardApi";
import { images_products_route } from "../../api/productApi";
import { useAuth } from "../../authContext";
import { PrecioSinDcto } from "../../components/PrecioSinDcto";
import { useApi } from "../../context/apiContext";
import { useForm } from '../../hooks/useForm';
import { useCart } from "../../context/cartContext";

const giftCardFields = {
  monto: "",
  paraNombre: "",
  paraApellido: "",
  email: "",
  emailremitente: "",
  mensaje: "",
  desde: "",
  hora: "",
  diseno: ""
};

export default function TarjetabPage() {
  const [gallery, setGallery] = useState([]);
  const [valoresTarjeta, setValoresTarjeta] = useState([]);
  const [disenoActivo, setdisenoActivo] = useState("");
  const [error, seterror] = useState('')
  const [succesfull, setsuccesfull] = useState('')
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false)

  const { getTextByKey, currentLocale, convertCurrency } = useApi();
  const { addToCart, showCart } = useCart();
  const [currentImage, setCurrentImage] = useState('');

  const {
    onInputChange: onGiftCardInputChange,
    formState,
  } = useForm(giftCardFields);

  const { paraApellido, paraNombre, email, emailremitente, mensaje, desde, hora } = formState

  useEffect(() => {
    getGallery("giftcards").then((data) => {
      const galleryAux = [];
      data.forEach((image, index) => {
        galleryAux.push({
          id: image.id,
          image: image.image,
          active: index === 0 ? true : false
        })
      })
      setdisenoActivo(galleryAux[0])
      setGallery(galleryAux);
    });
  }, []);

  useEffect(() => {
    getGallery("values-giftcards").then((data) => {
      const listadoAux = [];
      data.forEach(element => {
        element.names.forEach(content => {
          if (content.locale === 'es') {
            listadoAux.push(content.titulo)
          }
        })
      });
      setValoresTarjeta(listadoAux);
    });
  }, [currentLocale])

  const handleSubmitFormGiftCard = async (event) => {
    event.preventDefault();
    seterror('')
    setsuccesfull('');

    formState.diseno = disenoActivo.id;
    formState.monto = formState.monto.replace(/[^0-9\.]+/g, "");
    formState.desde = `${formState.desde} ${formState.hora}:00`

    const validate = validateForm(formState);

    if (!validate) {
      setLoading(true)
      await createGiftCard(formState, setLoading).then(({ id, code, status }) => {
        if (status === 200) {
          //TODO validate price to buy gift card and checkout flow
          let cartData = {
            id,
            type: "giftcard",
            price: formState.monto,
            quantity: 1,
            image: currentImage
          }
          addToCart(cartData, 1);
          showCart();

          setsuccesfull(getTextByKey('succesfull-create-giftcard'))
          setLoading(false)
        }

      }).catch((error) => {
        seterror(error)
      });
    }

  }

  const handleChangeDesign = (design) => {
    gallery.forEach((image) => {
      if (image.active) {
        image.active = false;
      }

      if (image.id === design.id) {
        setCurrentImage(image.image)
        image.active = true;
      }
    })

    setdisenoActivo(design);
  }

  const validateForm = (formState) => {
    let errorFound = false;
    const { email, emailremitente } = formState

    //Validate the email format
    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || !emailremitente.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      seterror("El email no tiene un formato válido");
      errorFound = true;
    }

    //validate empty values
    for (const [key, value] of Object.entries(formState)) {
      if (value === "") {
        errorFound = true;
        seterror(getTextByKey('error-all-fields-form'));
      }
    }

    setLoading(false);
    return errorFound;
  }

  return (
    <div className="gif-card-page">
      <div className="py-4">
        <h2 className="text-center text-uppercase mb-4">{getTextByKey('title-gift-card-page')}</h2>
        <h4 className="text-center gotham-Bold text-uppercase gray-1-color">{getTextByKey('subtitle-gift-card-page')}</h4>
      </div>
      <form className="py-4" onSubmit={handleSubmitFormGiftCard}>
        {succesfull ? <div className='succesfull-msg'>{succesfull}</div> : null}
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">

              <div className="form-group pb-4">
                <label className="text-uppercase gotham-Bold primary-color mb-4">{getTextByKey('select-design-gift-card')}</label>
                <img className="w-100 mb-4" src={images_products_route + disenoActivo.image} />
                <div className="d-flex justify-content-between align-items-center">
                  {
                    gallery.map((image) => (
                      !image.active && <img className="w-25" src={images_products_route + image.image} key={image.id} onClick={() => { handleChangeDesign(image) }} />
                    ))
                  }
                </div>
              </div>
              <div className="form-group pb-4">
                <label className="primary-color gotham-Bold text-uppercase mb-2">{getTextByKey('add-message-gift-card')}</label>
                <textarea className="p-2" name="mensaje" onChange={onGiftCardInputChange} value={mensaje}></textarea>
                <p className="text-end mb-0">250/250</p>
              </div>
              <div className="form-group">
                <label className="primary-color gotham-Bold text-uppercase mb-2">{getTextByKey('send-after-gift-card')}</label>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="form-group w-50 pe-4">
                    <label>Fecha</label>
                    <input type="date" className="p-2" name="desde" onChange={onGiftCardInputChange} value={desde} />
                  </div>
                  <div className="form-group w-50">
                    <label>Hora</label>
                    <input type="time" className="p-2" name="hora" onChange={onGiftCardInputChange} value={hora} />
                  </div>
                </div>
              </div>

            </div>
            <div className="col-12 col-lg-6">
              <div className="px-lg-5">
                <div className="form-group mb-4 mt-4 mt-lg-0">
                  <label className="text-uppercase gotham-Bold primary-color mb-4">{getTextByKey('amount-gift-card')}</label>
                  {
                    valoresTarjeta.map((valor, index) =>
                      <div className='precio mb-2 me-2'>
                        <input
                          type="radio"
                          className="css-checkbox"
                          name="monto"
                          id={`checkbox${index + 1}`}
                          value={convertCurrency(valor)}
                          onChange={onGiftCardInputChange}
                          key={index}
                        />
                        <label
                          key={`label${index}`}
                          for={`checkbox${index + 1}`}
                          name={`checkbox${index + 1}_lbl`}
                          className="css-label lite-green-check p-4">
                          {convertCurrency(valor)}
                        </label>
                      </div>
                    )
                  }
                </div>
                <div className="form-group">
                  <label className="text-uppercase gotham-Bold primary-color mb-4">{getTextByKey('title-form-gift-card')}</label>
                </div>
                <div className="form-group mb-4">
                  <label className="text-uppercase primary-color mb-1">{getTextByKey('names-form-gift-card')}</label>
                  <input type="text" className="p-2" name="paraNombre" onChange={onGiftCardInputChange} value={paraNombre} />
                </div>
                <div className="form-group mb-4">
                  <label className="text-uppercase primary-color mb-1">{getTextByKey('lastnames-form-gift-card')}</label>
                  <input type="text" className="p-2" name="paraApellido" onChange={onGiftCardInputChange} value={paraApellido} />
                </div>
                <div className="form-group mb-4">
                  <label className="text-uppercase primary-color mb-1">{getTextByKey('from-of-form-gift-card')}</label>
                  <input type="text" className="p-2" name="email" onChange={onGiftCardInputChange} value={email} />
                </div>
                <div className="form-group mb-4">
                  <label className="text-uppercase primary-color mb-1">{getTextByKey('email-receptor-form-gift-card')}</label>
                  <input type="text" className="p-2" name="emailremitente" onChange={onGiftCardInputChange} value={emailremitente} />
                </div>
                {error ? <div className='error-msg'>{error}</div> : null}
                <div className="form-group text-center mb-4">
                  <button
                    className="btn-g py-2 px-4 text-uppercase"
                    type="submit"
                    disabled={loading}
                  >
                    {getTextByKey('add-to-bag-gift-card')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
