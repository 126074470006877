import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import '../../scss/compra.scss'
import { useApi } from '../../context/apiContext'


export default function CompraPage() {
  const { getTextByKey, getTextBigByKey } = useApi();
  return (
    <Fragment>
        <div className="compra position-relative">
            <img className='w-100' src="/img/compra.jpg"/> 
            <div className='content-compra text-center'>
                
                {/* <p class='text-uppercase'>Gracias por unirte a esta conversación, esperamos saber de ti pronto.</p>
                <p class='text-uppercase mb-lg-5'>ingresa el codigo shido21 como BENEFICIO DE CRÉDITO en tu checkout.</p>

                <p class='text-uppercase'>Thanks for joining this conversation, we hope to hear from you soon.</p>
                <p class='text-uppercase mb-lg-5'>enter the code shido21 as CREDIT BENEFIT at your checkout.</p> */}
                
                <div className="text-center text-uppercase" dangerouslySetInnerHTML={{ __html: getTextBigByKey("message-bebefit-credit") }}></div>

                <Link to="/tienda" className='btn-g py-2 px-4 border-0 text-uppercase'>{getTextByKey("comprar-ahora")}</Link>
            </div>
        </div>
    </Fragment>
  )
}
