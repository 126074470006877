import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { default as ModalConfirmarEntrega } from './ModalConfirmarEntrega';



export default function ModalDireccionEnvio({ modalNuevaDireccion, setModalNuevaDireccion, setShowModalDireccion }) {

    const { getTextByKey } = useApi();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setModalNuevaDireccion(false);
        gotoTop();
    }

    const volverModal = () => {
      setModalNuevaDireccion(false);
      setShowModalDireccion(true);
    }

    const [ modalConfirmar, setModalConfirmar ] = useState(false);

    const confirmModal = () => {
      setModalNuevaDireccion(false);
      setModalConfirmar(true);
    }

    return (
        <Fragment>
            <Modal className="modal-compra" show={modalNuevaDireccion } onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-1-color' icon="material-symbols:close-rounded" width="30" height="30" onClick={handleClose}></Icon>
                    </div>
                    <div className="container">
                      <div className="text-center">
                        <h4 className='gray-1-color'>{getTextByKey('direccion-envio-titulo')}</h4>
                        <p className='gray-1-color'>{getTextByKey('selecciona-modal-direccion')}</p>
                      </div> 
                      <form action="" className='pb-4 pt-3'>
                        <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-4">
                            <select className='p-2 gray-1-color' name="ciudad">
                              <option  defaultValue="ciudad">{getTextByKey("ciudad")}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-2">
                            <select className='p-2 gray-1-color' name="ciudad">
                              <option  defaultValue="ciudad">{getTextByKey('direccion')}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12">
                            <div className='d-flex form-group mb-3'>
                              <input type="text" />
                              <span className='mx-3'>#</span>
                              <input type="text" />
                              <span className='mx-3'>-</span>
                              <input type="text" />
                            </div>
                        </div>
                        </div>
                      </form>
                      <div className="text-center pt-3">
                          <button className='btn-g bb px-5 mx-3' onClick={volverModal}>{getTextByKey('volver')}</button>
                          <button className='btn-g px-4 mx-3' onClick={confirmModal}>{getTextByKey('confirmar')}</button>
                      </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ModalConfirmarEntrega modalConfirmar={modalConfirmar} setModalConfirmar={setModalConfirmar}/>
        </Fragment>
    )
}
