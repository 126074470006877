import Modal from 'react-bootstrap/Modal';
import React, { Component, Fragment } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { createTarjeta } from '../../../api/productApi';
import { useAuth } from '../../../authContext';
import { useApi } from '../../../context/apiContext';

export default function Modalmetodo({ updateTarjetas, showModal, setShowModal }) {

    const { getTextByKey } = useApi();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { currentUser } = useAuth();

    const formatCard = (e) => {
        let card = e.target.value;
        card = card.replace(/\s/g, '').replace(/(\d{4})/g, '$1 ').trim();
        if (card.length > 19) {
            card = card.substring(0, 19);
        }
        e.target.value = card;
    }

    const createNewCard = (e) => {
        e.preventDefault();
        setLoading(true);
        console.log(e.target);
        let number = (e.target.number.value).replace(/\s/g, '');
        console.log(number);
        const card = {
            "brand": getBrandFromCard(number),
            "number": number,
            "titular": e.target.names.value + " " + e.target.lastnames.value,
            "year": e.target.year.value,
            "month": e.target.month.value,
            "cvv": e.target.cvv.value,
            "uid": currentUser.uid,
            "cuota": 1
        }

        createTarjeta(card.uid, card).then((res) => {
            console.log(res);
            updateTarjetas();
            setLoading(false);
            setShowModal( false )
        }).catch((err) => {
            console.log(err);
            updateTarjetas();
            setLoading(false);
            setShowModal( false )
            setError(err.response.data.msg);
        })
        // //post request
        // fetch('https://d3gheix0usthz9.cloudfront.net/api/card/create', {
        //     method: 'POST',
        //     referrerPolicy: 'no-referrer',
        //     mode: 'no-cors',
        //     redirect: 'follow',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': '*',
        //         'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
        //         'Access-Control-Allow-Headers': 'Content-Type',
        //         tokenAPI: "fanguelo91"
        //     },
        //     body: JSON.stringify(card),
        // })
        // .then(response => response.json())
        // .then(data => {
        //     console.log('Success:', data);
        // }
        // )
    }

    const getBrandFromCard = (number) => {
        let card = number;
        card = card.replace(/\s/g, '');
        let cardType = '';
        if (card.length > 0) {

            if (card[0] == '4') {
                cardType = 'visa';
            } else if (card[0] == '5') {
                cardType = 'mastercard';
            } else if (card[0] == '3') {
                cardType = 'amex';
            } else if (card[0] == '6') {
                cardType = 'discover';
            }
        }
        return cardType;
    }



    return (
        <Modal show={showModal} className="modal-new-card">
            <Modal.Header className='justify-content-center px-5'>
                {/* <Modal.Title className="px-5">
                </Modal.Title> */}
                <h3 className="w-100 modal-title text-center mb-0" id="exampleModalLabel">{getTextByKey("add-card-title")}</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal( false )}></button>
            </Modal.Header>
            <Modal.Body className="px-5">
                <div className="w-100 px-5">
                    <form onSubmit={createNewCard}>
                        <div className="form-group mb-3">
                            <label>{getTextByKey("names-form")}*</label>
                            <input type="text" className="p-2" name="names" />
                        </div>
                        <div className="form-group mb-3">
                            <label>{getTextByKey("last-names-form")}*</label>
                            <input type="text" className="p-2" name="lastnames" />
                        </div>
                        <div className="form-group mb-3">
                            <label>{getTextByKey("card-number")}*</label>
                            <input type="text" className="p-2" onChange={formatCard} name="number" />
                        </div>
                        <div className="form-group mb-3">
                            <label>{getTextByKey("card-date")}*</label>

                            <div className="d-flex flex-wrap">
                                <div className="col-12 col-md-6 pe-0 pe-2">
                                    <input type="text" className="p-2" name="month" placeholder='Mes' />
                                </div>
                                <div className="col-12 col-md-6 ps-0 ps-2 ms-auto">
                                    <input type="text" className="p-2" name="year" placeholder='Año' />
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-5">
                            <label>CVV*</label>
                            <input type="password" className="p-2" maxLength={3} name="cvv" />
                        </div>
                        {error ? <div className='error-msg text-end'>*{error}</div> : null}
                        <div className="form-group mb-2 text-center">
                            <button className="btn-g py-2 px-4 text-uppercase" type='submit'>
                                {loading ? <span className="spinner-border text-dark" role="status"></span> : <span>{getTextByKey("add-card-button")}</span>}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}