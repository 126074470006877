import {React,useEffect, useState} from 'react';
import { Link, NavLink, Router } from 'react-router-dom';
import { useApi } from "../../context/apiContext";
import { useAuth } from "../../authContext";
import { useCart } from "../../context/cartContext";
import TittleH1 from '../../components/TittleH1Component';
import TittleH2 from '../../components/TittleH2Component';
import TittleH3 from '../../components/TittleH3Component';
import TextBlock from '../../components/TextBlockComponent';
import ButtonLink from '../../components/ButtonLinkComponent';
import  styleText from '../../scss/text.module.scss';
import StyleGeneral from '../../scss/general.module.scss';
import StyleButton from '../../scss/btn.module.scss';
import { getAuth, signInAnonymously } from "firebase/auth";
import { createAnonymousUser } from '../../helpers/userHelper';
import { Button } from 'bootstrap';
import { FormularioDireccion } from '../../components/FormularioDireccion';
import { Fragment } from 'react';
import { AlertComponent } from '../../components/AlertComponent';

export const CheckoutaPage = () => {
  
  const { getTextByKey } = useApi();
  const { currentUser } = useAuth();
  const [ load, setLoad] =useState(false);
  const { tiendaGeneral } = useCart();
  const [errors, setErrors] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const handleShowModal = () => setShowAlert(true);
  const handleCloseModal = () => setShowAlert(false);


  useEffect(() => {
    if (currentUser) {
        if(currentUser?.isAnonymous){
          console.log('tienda',tiendaGeneral)
          if(tiendaGeneral){
            console.log('entro');
           window.location.href = "/checkout-b";
          }
        }else{
          if(tiendaGeneral){
            window.location.href = "/tienda";
           }else{
            window.location.href='/direccion-envio';
           }
         
        }
         
    }

}, []);

  const onGuestContinueClick = () => {
    console.log('entro');
    setLoad(true);
    const auth = getAuth();
    signInAnonymously(auth)
      .then(async ({ user }) => {

        const { uid } = user;
        const registerFormFields = {
          email: `${uid}@anonymous.rosmi.com`,
          email_confirm: `${uid}@anonymous.rosmi.com`,
          password: `${uid}`,
          nombre: `${uid}`,
          apellido: `${uid}`,
          celular: "3111111111",
          uid: `${uid}`,
          pais: 48,
          phonecode: 48,
          ciudad:151,
          fechaNacimiento: "",
          documento: `${uid}`,
          providerData: "unset",
          sexo: "",
          autoriza: true,
          tipoDocumento:2,
          marketing: false,
          anonimo: true,
          tratamiento: "Sr.",
          newsletter: "unset",
          politicas: "unset",
          
        }

        await createAnonymousUser(registerFormFields).then((response)=>{
           if(response)
             console.log(response);
              //location.href='/checkout-b'  
        }).catch((error)=>{
          setLoad(false);
        
        });
      })
      .catch((error) => {
        setLoad(false);
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // ...
      });
  }

  return (
    <Fragment>
      <div className={`py-5 gray-2-bg-color ${StyleGeneral.minHeight}`}>
          <div className={`container mb-3 mb-lg-5 ${StyleGeneral.container70}`}>
          <div className='row'>
            <div className='col-12'>
              <TittleH1 styleGroup={`text-center autography primary-color h4Special ${styleText.h4Special}`}>{getTextByKey('mi-carrito')}</TittleH1>
            </div>
          </div>
          <div className="container py-4 px-md-2 px-lg-5 my-4 white-bg-color border border-2">
            <div className="row">
                <div className="col-md-6">
                  <div className="p-2 p-lg-5">
                    <TittleH2 styleGroup="text-center text-md-start">{getTextByKey('texto-checkout-a')}</TittleH2>
                  </div>
                </div>
                <div className="col-md-6">
                { !currentUser ? (<>
                   <div className="p-4 my-3 text-center border border-1">
                    <TittleH3 styleGroup={'primary-color fw-600 mb-4'}>{getTextByKey('iniciar-sesion-checkout')}</TittleH3>
                    {/* <form action="">
                        <div className="form-group mb-2">
                          <label className="my-4 d-flex justify-content-center align-items-center gap-2">
                            <input className="p-2" type="checkbox"/>
                            {getTextByKey('recordarme')}
                          </label>
                        </div>
                    </form> */}
                    <div className='container'>
                      <div className='row'>
                          <div className='col-md-10 offset-md-1'>
                            <ButtonLink styleGroup="w-100" to={'/login'}>{getTextByKey('iniciar-sesion')}</ButtonLink>
                          </div>
                      </div>
                    </div>
                </div>
                <div className="p-4 my-3 text-center border border-1">
                    <TittleH3 styleGroup={'primary-color fw-600 mb-4'}>{getTextByKey('iniciar-sesion-invitado')}</TittleH3>
                    {/* <TextBlock styleGroup={'my-4'}>{getTextByKey('iniciar-sesion-invitado-texto')}</TextBlock> */}
                    <div className='container'>
                      <div className='row'>
                          <div className='col-md-10 offset-md-1'>
                          <button className={`border-0 ${StyleButton.btn} ${load ? StyleButton.active :''}`}   disabled={load} onClick={onGuestContinueClick}>
                            {getTextByKey('continuar-invitado')}
                                  <span className={StyleButton.loadingContainer}>
                                  <span className={StyleButton.dot}>.</span>
                                  <span className={StyleButton.dot}>.</span>
                                  <span className={StyleButton.dot}>.</span>
                                </span>
                            </button>
                            <Link className='w-100' ></Link>
                          </div>
                      </div>
                    </div>
                </div>
                  </>
               ):(
               currentUser?.isAnonymous ? 
                <FormularioDireccion seccion={1} setErrors={setErrors}  handleShowModal={handleShowModal} />
               :<></>
               )
              }
              </div>
          </div>
          </div>
        </div>
      </div>

      <AlertComponent title="Error" msn={errors} type="error"
                                            showAlert={showAlert}
                                            mandatory={true}
                                            handleShowModal={handleShowModal}
                                            handleCloseModal={handleCloseModal}/>
    </Fragment>
  )
}
