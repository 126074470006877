import sellyApi from './sellyApi'
import Product from '../classes/product'
import { getEnvVariables } from '../helpers'

export const images_products_route = getEnvVariables().IMAGES_URL_PRODUCTS;
export const images_collections_route = getEnvVariables().IMAGES_URL_COLLECTIONS;
export const images_categories_route = getEnvVariables().IMAGES_URL_CATEGORIES;

export const getRecommendedHome = async (tiendaid=null) => {
  try {
    let params='';
    if(tiendaid){
      params='?tienda='+tiendaid;
    }
    const { data } = await sellyApi.get(`/product/recomendados_home`+params,{id:"getRecomendado",cache: {
      ttl: 10,
    }});
    return data;
  } catch (error) {
    return error;
  }
}

export const getRelacionados = async (id,tiendaid=null) => {
  try {
    let params='';
    if(tiendaid)
     params='?tienda='+tiendaid;
    const { data } = await sellyApi.get(`/product/relacionados_producto/${id}`+params);
    return data;
  } catch (error) {
    return error;
   }
}
export const getRelationProducts = async (products,tiendaid=null) => {
  if (!Array.isArray(products)) {
    throw new Error('Products should be an array of ids');
  }
  if (!products.length) {
    return await getProducts();
  }
  let params='';
  if(tiendaid){
    params='?tienda='+tiendaid;
  }
  try {
    const { data } = await sellyApi.get(`/product/relacionados_productos/${products.toString()}/`+params);
    /* console.log(data) */
    return data;
  } catch (error) {
    return error;
  }
}


export const misFavoriteUser = async (uidUser) => {
  try {
    const { data } = await sellyApi.get(`/product/favoritos?usuario=${uidUser}`);
    return data;
  } catch (error) {
    console.log("Error listando dirección", error);
    return [];
  }
};

export const toggleFav = async (user, product) => {
  try {
    const { data } = await sellyApi.post(`/product/tooglefavorito`, JSON.stringify({
      "usuario": user,
      "producto": product,
    }));

    return data;
  } catch (error) {
    return error;
  }
};

export const favoritouser = async (uidUser, productId) => {
  try {
    const { data } = await sellyApi.get(`/product/favoritouser?usuario=${uidUser}&producto=${productId}`);
    return data;
  } catch (error) {
    return [];
  }
};

export const getNewCollections = async () => {
  try {
    const { data } = await sellyApi.get(`/collection/colecciones_nuevas`)
    return data;
  } catch (error) {
    return error;
  }
}

export const getInventario = async (id, tienda_id) => {
  try {
    const { data } = await sellyApi.get(`inventario/inventario_producto?producto=${id}&tienda=${tienda_id}`);
    return data;
  } catch (error) {
    return error;
   }
}

export const getProducts= async (params) => {
  try {
    let query = "";
 
    if(params.categorias && params.categorias!=0){
      query += `categorias=${params.categorias}`;
    }
    if(params.limit && params.limit!=0){
     query += `&limit=${params.limit}`;
    }
    if(params.marcas && params.marcas!=0){
      query += `&marcas=${params.marcas}`;
    }
    if(params.page && params.page!=0){
      query += `&page=${params.page}`;
     }

     if(params.orden && params.orden!=0){
      query += `&orden=${params.orden}`;
     } 
     if(params.tienda){
      query += `&tienda=${params.tienda}`;
     }
   
   
    const { data } = await sellyApi.get(`/product/products?${query}`, {id:"getProducts",cache: {
      ttl: 10,
    }});
    console.log("prods_respuesta",data)
    return data;
  } catch (error) {
    return error;
  }
}

export const getProductsSearch = async (search,tiendaid=null) => {
  
  const defaultValue = {products: {list: [], pages: 1}, recipes: []};
  try {
    let params='';
    if(tiendaid){
      params='?tienda='+tiendaid;
    }
    const {data}  = await sellyApi.get(`/search/${search}`+params).catch((error) => {
      console.error(error)
      return defaultValue;
    })
    return data || defaultValue;
  } catch (error) {
    console.error(error)
    return defaultValue;
  }
}

export const getProductBestSellers= async () => {
  try {
    const { data } = await sellyApi.get(`product/mas_vendidos`)
    return data;
  } catch (error) {
    return error;
  }
}

export const getProductDetail = async (id,tiendaid=null) => {
  try {
    let params='';
    if(tiendaid){
      params='?tienda='+tiendaid;
    }
    const { data } = await sellyApi.get(`/product/detail/${id}`+params,{id:"getProductoDetail",cache: {
      ttl: 10,
    }})
    return data;
  } catch (error) {
    throw error;
  }
}

export const getCombinationsById = async (id) => {
  try {
    const { data } = await sellyApi.get(`/combinations/${id}`)
    return data;
  } catch (error) {
    return error;
  }
}

export const getTallasById = async (id) => {
  try {
    const { data } = await sellyApi.get(`/tallas/${id}`)
    return data;
  } catch (error) {
    return error;
  }
}

export const createUser = async (body) => {
  try {
    const { data } = await sellyApi.post(`/user/create`, body)
    return data;
  } catch (error) {
    return error;
  }
}

export const getPaises = async () => {
  try {
    const { data } = await sellyApi.get(`/user/paises`)
    return data;
  } catch (error) {
    return error;
  }
}

export const getDepartamentos = async () => {
  try {
    let departamentos=[];
     if(localStorage.getItem('departamentos')){
       departamentos=JSON.parse(localStorage.getItem('departamentos'));
    
     }
    return departamentos;
    

   
  } catch (error) {
    return error;
  }
}

export const getCiudades = async (id) => {
  try {
    let ciudades=[];
    if(localStorage.getItem('ciudades')){
      let cities=JSON.parse(localStorage.getItem('ciudades'));
      ciudades=cities.filter(ciudad=>ciudad.departamento==id);
      
    }
   
    return ciudades;
  } catch (error) {
    return error;
  }
}


export const getBarrios = async () => {
  try {
    const { data } = await sellyApi.get(`/barrio`)
    return data;
  } catch (error) {
    return error;
  }
}

export const getTiposDocumentos = async () => {
  try {
    const { data } = await sellyApi.get(`/document_type`)
    return data;
  } catch (error) {
    return error;
  }
}

//Tarjetas
export const getTarjetas = async (uid) => {
  try {
    const { data } = await sellyApi.get(`/card?uid=${uid}`)
    return data;
  } catch (error) {
    return error;
  }
}

export const createTarjeta = async (uid,body) => {
  try {
    const { data } = await sellyApi.post(`/card/create?uid=${uid}`, body)
    return data;
  } catch (error) {
    return error;
  }
}

export const deleteTarjeta = async (id) => {
  try {
    const { data } = await sellyApi.delete(`/card/${id}`)
    return data;
  } catch (error) {
    return error;
  }
}

export const validateCoupon = async (coupon,email) => {
  try {
    const encodedEmail = encodeURIComponent(email);
    const { data } = await sellyApi.get(`/cart/discount?code=${coupon}&email=${encodedEmail}`)
    return data;
  } catch (error) {
    throw error;
  }
}

export const createOrder = async (json) => {
  try {
    //change baseurl sellyapi
    // sellyApi.defaults.baseURL = getEnvVariables().API_URL_TEST;
    const { data } = await sellyApi.post(`cart/general/create`, json);
    return data;
  } catch (error) {
    throw new Error('error al crear la compra');
  }
}

export const pagarOrderWompi = async (json) => {
  try {
    const { data } = await sellyApi.post(`card/pagar`, json);
    return data;
  } catch (error) {
    return error;
  }
}

export const getBanks = async () => {
  try {
    const { data } = await sellyApi.get(`pagos/bancos`).catch((error) => {
      return [];
    })
    return data;
  } catch (error) {
    return error;
  }
}

export const pagarOrderPSE = async (json) => {
  try {
    // sellyApi.defaults.baseURL = getEnvVariables().API_URL_TEST;
    const { data } = await sellyApi.post(`pagos`, json);
    return data;
  } catch (error) {
    return error;
  }
}

export const getOrderPSE = async (id) => {
  try {
    const { data } = await sellyApi.get(`pagos/getStatus?transaction_id=${id}`);
    return data;
  } catch (error) {
    return error;
  }
}

export const byStatus = async (id) => {
  try {
    const { data } = await sellyApi.get(`/pagos/buyStatus?transaction_id=${id}`)
    return data;
  } catch (error) {
    throw error;
  }
}
export const getSeo = async (url) => {
  try {
    if(url === "/") url = "home";
    url = url.replace(/^\/+/g, '');
    const { data } = await sellyApi.get(`seo/getSeo?url=${url}`);
    return data;
  } catch (error) {
    return error;
  }
}


export default {
  getRecommendedHome,
  getNewCollections,
  getProducts,
  getProductBestSellers,
  getProductDetail,
  getCombinationsById,
  getTallasById,
  createUser,
  getPaises,
  getTarjetas,
  createTarjeta,
  deleteTarjeta,
  toggleFav,
  getRelationProducts,
  validateCoupon,
  images_products_route
}
