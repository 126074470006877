import React, { Fragment, useRef } from 'react'
import { Link } from 'react-router-dom';
import { BolsaComponent } from '../../components/Bolsa';
import SelectDireccionComponent from './components/SelectDireccionComponent';
import { useApi } from "../../context/apiContext";
import { useState } from 'react';
import { useEffect } from 'react';
import { createOrder } from '../../api/productApi';
import { useAuth } from '../../authContext';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../context/cartContext';
import Modalmetodo from '../PerfilPage/components/Modalmetodo';
import { FormularioDireccion } from '../../components/FormularioDireccion';
import { getImages } from '../../api/imageApi';
import { getUser } from '../../helpers/userHelper';

import TittleH1 from '../../components/TittleH1Component';
import TittleH6 from '../../components/TittleH6Component';
import StyleText from '../../scss/text.module.scss';
import StyleButton from '../../scss/btn.module.scss';
import TextBlock from '../../components/TextBlockComponent';
import { AlertComponent } from "../../components/AlertComponent";
import { DatosEnvio } from '../../components/DatosEnvioComponent';
import ReactGA from 'react-ga4';

import StyleFilter from '../../scss/filter.module.scss';

const CheckoutcPage = () => {
  //TODO revisar flujos de pagos
  const [order, setOrder] = useState({});
  const { getTextByKey, getTextBigByKey, currentCurrency, currentLocale } = useApi();
  const { currentUser } = useAuth();
  const { total, envio, compraMinima,creditBenefitType,calculateTotal,subtotal,impuesto,coupon,descuento, direccion, 
    currentCart, clearCart, applyCoupon,tiempoentrega,tiendaGeneral } = useCart();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [nombreUser, setNombreUser] = useState("");
  const [dirUser, setDirUser] = useState("");
  const [celularUser, setCelularUser] = useState("");
  const [emailUser, setEmailUser] = useState("");
  const [documentoUser, setDocumentoUser] = useState("");
  const [tipoDocUser, setTipoDocUser] = useState("");
  const [ciudadUser, setCiudadUser] = useState("");
  const [llavepublica, setLlavePublica] = useState(0);
  const [llaveintegridad, setLlaveIntegridad] = useState(0);
 
  const [compraid, setCompraId] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [terminos, setTerminos] = useState(false);
  const [notificacion, setNotificacion] = useState(false);
  const [imgSecurity, setImgSecurity ] = useState([]);
  const [uservisitante, setUserVisitante] = useState(0);
  const [cambiocarrito, setCambioCarrito] = useState(0);
  const formRef = useRef(null);
  const protocolo = window.location.protocol;
  const dominio = window.location.hostname;

  const changeTerminos = (e) => {
    setTerminos(e.target.checked)
   
  }
  const changeNotifica = (e) => {
    setNotificacion(e.target.checked)
   
  }

  useEffect(() => {
    getImages("checkout-pagos").then((data)=> {
      if (data) {
        setImgSecurity(data);
      }
    });
  },[])

  useEffect(() => {
    if (!currentUser) {
      location.href = "/checkout-a";
    }
    console.log(currentCart);
    if(currentCart.length==0){
      //location.href ="/tienda"
    }
  }, [currentCart]);

  useEffect(() => {
    if (!currentUser) {
      location.href = "/checkout-a";
    }
   
  }, []);

  useEffect(() => {
  
     getUser(currentUser?.uid).then(async({ user }) => {
      if(currentUser){
        let nuevouser =await user;
          setUserVisitante(nuevouser);
      }
        let nombreuser=user.nombre;
        if(!user.juridico){
          if(user.nombredos)
            nombreuser+=' '+user.nombredos;
          nombreuser+=' '+user?.apellido;
          nombreuser+=' '+user?.apellidodos;
        }
        setNombreUser(nombreuser);
        setCelularUser(user.celular);
        setDirUser(user.direccion);
        setEmailUser(user.emailnotifica);
        setTipoDocUser(user.tipodoc);
        setDocumentoUser(user.documento);
        setCiudadUser(user.city);
    })
  
  },[])

  useEffect(() => {
    calculateTotal();
    console.log('totallll',total,compraMinima);
     if(total < compraMinima){
      
      //location.href = "/checkout-b";
      setShowAlert(true);
     }
         
      
  },[currentCart]);
  


  useEffect(() => {
    console.log('uservisitante:', uservisitante);
   }, [uservisitante,direccion]);

  useEffect(() => {
    if (llavepublica!=0 && compraid!=0 && llaveintegridad!=0) {
      formRef.current.submit();
    }
  }, [llavepublica, compraid, llaveintegridad]);

  const createCheckoutOrder = async () => {
    setLoading(true);

    if(!terminos){
      setLoading(false);
      setErrors('Debe aceptar los terminos');
      handleShowModal(true);
      return;
    }

    if(!direccion || direccion==0){
      setLoading(false);
      setErrors('Debe tener una dirección de envio');
      handleShowModal(true);
      return;
    }

    order.uid = currentUser.uid;
    order.delivery_cost = envio;
    order.subtotal = parseFloat(subtotal.toFixed(0));
    order.store= tiendaGeneral;
    order.total = parseFloat((total-(applyCoupon(total))+envio).toFixed(0));
    order.address = parseInt(direccion);
    order.billing = parseInt(direccion);
    order.taxes= parseFloat(impuesto.toFixed(0));
    //order.descuentos=descuento;
    order.descuentos=0;
    order.descuento=coupon ? coupon.coupon :0;
    order.creditBenefitType = creditBenefitType;
    order.aceptanotifica=notificacion;
    //order.card = selectedCard;
    order.method = 'ONLINE';
    order.buyItems = [];
    order.comments = "";
    order.currency = currentCurrency;
    order.tiempoentrega=tiempoentrega;
    let itemscar=[];

    currentCart.forEach((item) => {
      if (item.type === "giftcard") {
        order.buyItems.push({
          productId: item.id,
          type: item.type,
          price: item.price,
          quantity: item.quantity,
          comments: '',
          discount: 0,
          toppings: '',
          promotionId: '',
        });
      } else {
        order.buyItems.push({
          productId: item.plu,
          type: 'product',
          //price: item.price.precio_sin_descuento ? item.price.precio_sin_descuento*item.quantity  : item.price.precio*item.quantity,
          price: item.price.precio*item.quantity,
          quantity: item.quantity,
          comments: '',
          discount: item.price.precio_sin_descuento ? (item.price.precio_sin_descuento-item.price.precio)*item.quantity  :0,
          toppings: '',
          promotionId: '',
          products: item.products ? item.products : []
        });
        itemscar.push({
          item_id:item.plu,
          item_name: item.name?.nombre,
          item_category: item.categorias?.[0]?.nombre,
          //price: item.price.precio_sin_descuento ? item.price.precio_sin_descuento*item.quantity  : item.price.precio*item.quantity,
          quantity: item.quantity

        });
      }

    });
     console.log('aca' ,order);
    createOrder(order).then((response) => {

      ReactGA.event({
        category:'ecommerce',
        action: 'begin_checkout',
        value: parseFloat((total-(applyCoupon(total))+envio).toFixed(0)),
        currency: "COP",
        items :itemscar
      });
     
      console.log('response: ', response)
      if (!response.compra.id) {
        setErrors("Error al crear la orden");
        handleShowModal(true);
        setLoading(false);
      } else {
        setCompraId(response.compra.id);
        setLlavePublica(response.pago.llavepub);
        setLlaveIntegridad(response.pago.key);
      }
    }).catch((error) => {
      setLoading(false);
      console.log('error: ', error)
      setErrors(error.message);
      handleShowModal(true);
    })
  }
  const handleShowModal = () => setShowAlert(true);
  const handleCloseModal = () => setShowAlert(false);

  return (
    <Fragment>
      
      <div className="py-lg-4 py-3 checkout gray-2-bg-color">
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className="text-center mb-3 mb-lg-5">
                  <TittleH1 styleGroup={`primary-color  autography mb-0 ${StyleText.h4Special}`}>{getTextByKey('realizar-pedido-titulo')}</TittleH1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
     
              <div className='bg-white border border-2 p-4 p-md-2 p-md-4 p-lg-5 mb-3 mb-lg-5'>
                <TittleH6 styleGroup="primary-color fw-bold mb-2 mb-lg-4">{getTextByKey('detalles-envio-titulo')} </TittleH6>
                { currentUser?.isAnonymous ? <></>:(
                  <div className='mb-4'>
                    <SelectDireccionComponent step={3} handleShowModal={handleShowModal} setErrors={setErrors} />
                  </div>)}
               
                  { currentUser?.isAnonymous ? (
                    !direccion ?
                    <FormularioDireccion usuario={uservisitante} setCambioCarrito={setCambioCarrito} handleShowModal={handleShowModal} setErrors={setErrors} />
                   :
                    <DatosEnvio user={uservisitante} />
                 ):(
                  <FormularioDireccion  usuario={uservisitante} setCambioCarrito={setCambioCarrito} handleShowModal={handleShowModal} setErrors={setErrors}/>
                 )
                }
              

                
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="mt-lg-0 mt-5">
                <div className="bg-white mb-5 border border-2 p-2 p-md-4 p-lg-4 mb-3 mb-lg-5">
                  <BolsaComponent />
               
                <div className="p-3 rounded my-4">
                  <div className='quaternary-bg-color p-2 rounded d-flex align-items-start'>
                    <div className='icon-alert me-2 text-light'>
                      <Icon icon="la:exclamation-circle"></Icon>
                    </div>
                    <TextBlock styleGroup="text-light mb-0">
                      <small dangerouslySetInnerHTML={{ __html: getTextBigByKey("alerta-checkout-1") }}></small>
                    </TextBlock>
                  </div>
                  
                  <form action="" className='py-3'>

                    <label className={`w-auto py-1 ${StyleFilter.containerCheckS}`}>
                        <small className='ms-1'><Link to='/legal#collapseSix' className='black-color' target='_blank'>{ getTextByKey('he_leido') }</Link></small>
                        <input type='checkbox'
                            name='terminos'
                            value='1'   onChange={changeTerminos} />
                        <span className={StyleFilter.checkmarkS}>
                            <img src='/img/SVG/check.svg'/>
                        </span>
                    </label>

                    <label className={`w-auto py-1 ${StyleFilter.containerCheckS}`}>
                        <small className='ms-1'>{ getTextByKey('acepto_notificaciones') }</small>
                        <input type='checkbox'
                            name='acepto'
                            value='1'  onChange={changeNotifica}/>
                        <span className={StyleFilter.checkmarkS}>
                            <img src='/img/SVG/check.svg'/>
                        </span>
                    </label>

                  </form>



                </div>

                <div className="text-center">
                  <button className={`border-0 ${StyleButton.btn} ${loading ? StyleButton.active :''} ${!terminos ?  StyleButton.btnDisabled : ''}`} disabled={loading} onClick={createCheckoutOrder}>
                    {getTextByKey('realizar-pedido-titulo')}
                    <span className={StyleButton.loadingContainer}>
                      <span className={StyleButton.dot}>.</span>
                      <span className={StyleButton.dot}>.</span>
                      <span className={StyleButton.dot}>.</span>
                    </span>
                  </button>
                </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>

        <form ref={formRef} action="https://checkout.wompi.co/p/" method="GET">
 
          <input type="hidden" name="public-key" value={llavepublica} />
          <input type="hidden" name="currency" value="COP" />
          <input type="hidden" name="amount-in-cents" value={(Math.round(total-(applyCoupon(total))+envio)*100)} />
          <input type="hidden" name="reference" value={compraid} />
          <input type="hidden" name="signature:integrity" value={ llaveintegridad }/>
          <input type="hidden" name="redirect-url" value={`${protocolo}//${dominio}/payment-success?compraid=${compraid}`}/>
          <input type="hidden" name="tax-in-cents:vat" value={(impuesto*100)} />
          <input type="hidden" name="customer-data:email" value={emailUser} />
          <input type="hidden" name="customer-data:full-name" value={nombreUser} />
          <input type="hidden" name="customer-data:phone-number" value={celularUser} />
          <input type="hidden" name="customer-data:legal-id" value={documentoUser} />
          <input type="hidden" name="customer-data:legal-id-type" value={tipoDocUser==2 ? 'CC' : 'NIT'} />
          <input type="hidden" name="shipping-address:address-line-1" value={dirUser} />
          <input type="hidden" name="shipping-address:phone-number" value={celularUser}/>
          <input type="hidden" name="shipping-address:city" value={ciudadUser} />
          <input type="hidden" name="shipping-address:region" value={ciudadUser} />
          <input type="hidden" name="shipping-address:country" value="CO" />
      
      

        </form>
        <AlertComponent title="Ups" msn={errors} type="continuarCompra"
              showAlert={showAlert}
              handleShowModal={handleShowModal}
              handleCloseModal={handleCloseModal}
              totalCompra={total}
              mandatory={true}
              compraMinima={compraMinima}/>
        <Modalmetodo />

      </div>
    </Fragment>
  )
}

export default CheckoutcPage