import React from 'react'
import { Fragment } from 'react'
import styleSelection from "../../../scss/selection.module.scss";
import TittleH4 from '../../../components/TittleH4Component';
import StyleText from "../../../scss/text.module.scss";
import TextBlock from '../../../components/TextBlockComponent';
import ButtonLink from '../../../components/ButtonLinkComponent';
import styleButton from "../../../scss/btn.module.scss"
import Picture from '../../../components/PictureComponent';
import { Children } from 'react';
import { useApi } from '../../../context/apiContext';


export default function SelecctionsComponent({ link, bgColor, styleGroup, text, title, img, children }) {
  const { getTextByKey } = useApi();
  return (
    <Fragment>
      <div className={`d-md-flex aling-items-center ${styleGroup} `}>
        <div className={`d-flex align-items-center justify-content-center ${styleSelection.txt} ${bgColor}`}>
          <div className={styleSelection.cardBase}>
            <TittleH4 styleGroup={`mb-3 mb-lg-4 w-100 white-color autography ${StyleText.h4Special}`}>{title}</TittleH4>
            <div className={`white-color ${StyleText.paragraph}`}>
              {children}
            </div>
            <ButtonLink to={link}>{getTextByKey('ver-mas')}</ButtonLink>
          </div>
        </div>
        <div className={styleSelection.img}>
          <Picture styleGroup={styleSelection.imgSelecciones} src={img} alt="Rosmi" />
        </div>
      </div>
    </Fragment>
  )
}
